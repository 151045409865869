<ng-container *ngIf="error">

  <ng-container *ngIf="error.status_code && [502, 503, 429].indexOf(error.status_code) < 0; else serverErrorTemplate">
    <div class="flex-center rpn-maintenance flex-column">
      <div class="d-flex align-items-center">
        <div class="rpn-maintenance_code_border-right">
          {{error.status_code || ''}}
        </div>
        <div class="rpn-maintenance_message">
          <div [innerHTML]="error.header"></div>
          <ng-container *ngIf="error.message"> ({{error.message}})</ng-container>
        </div>
      </div>

      <div class="d-flex mb-3" *ngIf="[404, 403].indexOf(error.status_code) >= 0">
        <a [routerLink]="['/']" (click)="returnToMain()" [skipLocationChange]="true" class="rpn-link mr-3">
          На главную
        </a>
        <a [routerLink]="[previousUrl || '/']" [skipLocationChange]="true" class="rpn-link">
          Назад
        </a>
      </div>

      <div>
        <a [routerLink]="['/logout']" class="rpn-link">
          Выйти
        </a>
      </div>
    </div>

    <div class="rpn-maintenance_merci" *ngIf="!error.status_code || error.status_code === StatusCodes.SERVICE_UNAVAILABLE">
      Мы уже работаем над этим.
      Если это результат ошибки, инцидент будет передан разработчикам и они поправят в минимальные сроки.
    </div>
  </ng-container>

  <ng-template #serverErrorTemplate>
    <div class="flex-center rpn-maintenance flex-column">
      <div>
        <div class="d-flex justify-content-center">
          <div class="rpn-maintenance_code">
           {{error.status_code ? 'Сервер перегружен запросами или ведутся технические работы' : standard_text}}
          </div>
        </div>

        <ng-container *ngIf="error.status_code">
          <hr>

          <div class="d-flex align-items-center justify-content-center flex-column">
            <div class="rpn-maintenance_message">
              {{error.status_code}}
            </div>
          </div>
        </ng-container>

        <hr>

        <div class="d-flex align-items-center justify-content-center flex-column">
          <div class="rpn-maintenance_message">
            Попробуйте повторить запрос позднее
          </div>
          <div class="rpn-maintenance_timer">
            {{('00' + (isNaN(timer_min) ? 0 : timer_min)).slice(-2)}}:{{('00' + (isNaN(timer_sec) ? 0 : timer_sec)).slice(-2)}}
          </div>
        </div>
      </div>

      <div class="d-flex mb-3">
        <a [routerLink]="['/']" (click)="returnToMain()" class="rpn-link mr-3">
          На главную
        </a>
        <a [routerLink]="[previousUrl || '/']" class="rpn-link">
          Назад
        </a>
      </div>

      <div>
        <a [routerLink]="['/logout']" class="rpn-link">
          Выйти
        </a>
      </div>
    </div>

    <div class="rpn-maintenance_merci">
      Приносим извинения за доставленные неудобства.
    </div>
  </ng-template>

</ng-container>
