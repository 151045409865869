import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageErrorComponent } from 'src/app/pages/page.error/page-error.component';
import { PageWaitComponent } from 'src/app/pages/page.wait/page-wait.component';
import { NotFoundPageGuard } from 'src/app/providers/_guards/not-found-page.guard';
import { ICustomRoutes } from 'src/app/providers/_interfaces/common';

const routesData = {
  main: {
    labelGenerator: () => ({
      label: 'Главная',
    }),
  },
};

const routes: ICustomRoutes = [
  { path: '', redirectTo: 'rpn', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('src/app/auth/auth.routes').then((m) => m.AUTH_ROUTES),
  },
  {
    path: 'rpn',
    data: routesData.main,
    loadChildren: () => import('src/app/pages/main.routes').then((m) => m.MAIN_ROUTES)
  },
  { path: 'error-page', component: PageErrorComponent },
  { path: 'wait-page', component: PageWaitComponent },
  { path: '**', component: PageErrorComponent, canActivate: [NotFoundPageGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      // onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
