import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { OnvosActionTypes, LoadOnvosRequest, OnvosLoadedSuccess, OnvosLoadedError } from 'src/app/store/onvos/actions';

@Injectable({ providedIn: 'root' })
export class OnvosEffects {
  // REPORTS

  loadObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnvosActionTypes.LoadObjectRequest),
      switchMap((action: LoadOnvosRequest) =>
        this.svcRestService.fetchOnvos(action.payload.id).pipe(
          map((data: any) => new OnvosLoadedSuccess({ data })),
          catchError(() => of(new OnvosLoadedError())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private svcRestService: SvcRestService,
  ) {}
}
