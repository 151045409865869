import { NgSwitch, NgStyle, NgSwitchCase, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getIcon } from 'src/app/modules/app-icon/icon';
import { IconsSet } from 'src/app/modules/app-icon/icons';

export interface IAppIconsData {
  1?: string | number;
  2?: string | number;
  3?: string | number;
  width?: string;
  height?: string;
  fill2?: string;
  fill3?: string;
}

@Component({
  selector: 'app-icon',
  templateUrl: 'app-icon.component.html',
  styleUrls: ['app-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgSwitch,
    NgStyle,
    NgClass,
    NgSwitchCase,
  ],
})
export class AppIconComponent {
  @Input() @HostBinding('class.rpn-icon-static') isStatic!: boolean;

  @Input() name: IconsSet;
  @Input() width = 'auto';
  @Input() height = '32px';
  @Input() lineHeight: string;
  @Input() fill: string;
  @Input() stroke: string;
  @Input() classes = '';

  @Input() rotate: string;
  @Input() data: IAppIconsData = {};

  public IconsSet = IconsSet;
  public getIcon = getIcon;

  constructor(public sanitizer: DomSanitizer) {}
}
