export interface IPmImportFileOpen {
  modalHeader: string;
  importAccept: string;
  importExt: string[];
  importUrl: string;
  importApi: EPmImportFileApi;
  submitText: string;
}

export enum EPmImportFileApi {
  sufd = 'sufd',
  opening_balance = 'opening_balance',
}
