import { NgIf, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppIconComponent } from 'src/app/modules/app-icon/app-icon.component';
import { IconsSet } from 'src/app/modules/app-icon/icons';

@Component({
  selector: 'app-modal-attachment-sign-result',
  templateUrl: './modal-attachment-sign-result.component.html',
  styleUrls: ['./modal-attachment-sign-result.component.scss'],
  standalone: true,
  imports: [AppIconComponent, NgIf, DatePipe],
})
export class ModalAttachmentSignResultComponent implements OnInit {
  heading: string;
  content: { result: any; hide: () => void };
  statusId: string;
  IconsSet = IconsSet;
  result: any;

  ngOnInit() {
    this.result = this.content && this.content.result;
  }

  public close(): void {
    if (this.content) {
      this.content.hide();
    }
  }
}
