import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ICharge } from 'src/app/providers/_interfaces/charge.interface';
import { ChargeActionTypes, ChargeUnion } from 'src/app/store/payment-charge/actions';

export const adapter: EntityAdapter<ICharge> = createEntityAdapter<ICharge>({
  selectId: (instance) => instance.id,
});

export interface ChargeState extends EntityState<ICharge> {
  currentChargeId: string;
}

export const initialState: ChargeState = adapter.getInitialState({
  currentChargeId: null,
});

export function chargeReducer(state: ChargeState = initialState, action: ChargeUnion): ChargeState {
  switch (action.type) {
    // LOAD Charge
    case ChargeActionTypes.LoadObjectRequest:
      return {
        ...state,
        currentChargeId: action.payload.id,
      };

    case ChargeActionTypes.ObjectLoadedSuccess:
      return adapter.upsertOne(action.payload.data, state);

    default:
      return state;
  }
}
