export const timestampYear = 31536000000;
export const UI_GOST_DATE_FORMAT = 'DD.MM.YYYY';
export const UI_GOST_DATE_FORMAT_EXT = 'DD.MM.YYYY HH:mm';
export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const ATOM_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const dateTimeStringToGost = (string) => {
  return new Date(string).toLocaleString('ru-RU');
};

export const dateStringToGost = (string) => {
  return new Date(string).toLocaleString('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

export const checkFormatStringToDate = (str: string): string => {
  if (str?.length === 24 && str[10] === 'T') {
    return dateTimeStringToGost(str);
  }

  if (str?.length === 10 && str[4] === '-' && str[7] === '-') {
    return dateStringToGost(str);
  }

  return str;
};
