import { Component, ElementRef, inject, OnInit } from '@angular/core';
import { ButtonsModule } from 'ng-uikit-pro-standard';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import b3Chat from 'src/assets/b3-chat/b3-chat.umd.js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-b3-chat',
  templateUrl: './modal-b3-chat.component.html',
  styleUrls: ['./modal-b3-chat.component.scss'],
  standalone: true,
  imports: [ButtonsModule],
})
export class ModalBig3ChatComponent implements OnInit {
  content: {
    result; hide: () => void,
    request: string;
  };
  private elementRef = inject(ElementRef);
  private auth = inject(AuthenticationService);

  constructor(
  ) {}

  ngOnInit() {
    const element = this.elementRef.nativeElement.querySelector('#b3-chat-container');

    //Сделаем все вокрун чата примерно как в макете РПН
    const innerElement = document.createElement('div');
    innerElement.style.setProperty('height', '100%');
    innerElement.style.setProperty('background-color', '#fff');
    innerElement.style.setProperty('padding', '10px');
    innerElement.style.setProperty('box-sizing', 'border-box');
    element.appendChild(innerElement);

    //Инициализация чата
    const chat = new b3Chat({
      //Сообщение, которые будет отправлено после инициализации чата
      initialRequestMessage: this.content.request || '',

      //Авторизация
      xAccessToken: localStorage.getItem('x-access-token'),
      backendApiKey: 'aba787cc4924293a5027f3d4a7895ef7b7853c46',

      //Бэкэнд
      backendUrl: '/svc/mls/',
      backendPath: '/api/v1/chat/history/',
      options: {
        manager_id: 6,
        xsrf_token: localStorage.getItem('XSRF-TOKEN'),
        host: environment.DOMAIN_V2
      },

      //История сообщений
      loadHistory: true,
      messageLoadCount: 20,

      //Массив расширейний разрешенных форматов файлов
      messageFileFormats: ['docx', 'pdf', 'png', 'jpg'],
      //Максимальный размер файла в байтах
      messageFileMaxSize: 10 * 1024 * 1024,

      //Быстрые действия
      hintConfig: {
        path: '/api/v1/recognize/datasets/',
        pipeline: 'quick_actions_temp',
        hintsCount: 5
      },

      //Стилизация
      cssClass: 'rpn-chat',
      imagesUrl: 'https://205129.selcdn.ru/b3-gis/lib-chat/images/',

      //Колбэк на события чата
      onEvent: (eventType, eventData) => {
        window.console.log(eventType, eventData);
      }
    });

    //Добавление чата в разметку
    innerElement.appendChild(chat.render());

    // this.auth.user$?.value?.id
    // chat.sendRequest(messageText: string);
  }

  close() {
    this.content.hide();
  }
}
