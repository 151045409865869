import { IconsSet } from 'src/app/modules/app-icon/icons';

export function getIcon(name: IconsSet, params: any = {}) {
  switch (name) {
    case IconsSet.arrowLeft:
      return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <polygon points="14 26 15.41 24.59 7.83 17 28 17 28 15 7.83 15 15.41 7.41 14 6 4 16 14 26"/>
      </svg>`
    case IconsSet.arrowRight:
      return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 32 32"
        fill="${params.fill}">
        <polygon points="18,6 16.6,7.4 24.1,15 3,15 3,17 24.1,17 16.6,24.6 18,26 28,16 "/>
      </svg>`;
    case IconsSet.sigma:
      return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <polygon points="24 5 7 5 7 7.414 15.586 16 7 24.586 7 27 24 27 24 25 9.414 25 18.414 16 9.414 7 24 7 24 5"/>
      </svg>`;
    case IconsSet.area:
      return `<svg
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M30,6a3.9916,3.9916,0,0,0-7.9773-.2241L9.5864,8.2627A3.99,3.99,0,1,0,5,13.8579v8.2842A3.9915,3.9915,0,1,0,9.8579,27h8.2842a3.9912,3.9912,0,1,0,5.595-4.5864l2.487-12.4361A3.9945,3.9945,0,0,0,30,6ZM26,4a2,2,0,1,1-2,2A2.0023,2.0023,0,0,1,26,4ZM4,10a2,2,0,1,1,2,2A2.0023,2.0023,0,0,1,4,10ZM6,28a2,2,0,1,1,2-2A2.0023,2.0023,0,0,1,6,28Zm12.1421-3H9.8579A3.9942,3.9942,0,0,0,7,22.1421V13.8579a3.9871,3.9871,0,0,0,2.9773-3.6338L22.4136,7.7373a4.0053,4.0053,0,0,0,1.8493,1.8491l-2.487,12.4361A3.9874,3.9874,0,0,0,18.1421,25ZM22,28a2,2,0,1,1,2-2A2.0023,2.0023,0,0,1,22,28Z" transform="translate(0 0)"/>
        </svg>`;
    case IconsSet.reference:
      return `<svg id="icon" xmlns="http://www.w3.org/2000/svg" fill="${params.fill || '#000'}" viewBox="0 0 32 32">
        <polygon points="4 20 4 22 8.586 22 2 28.586 3.414 30 10 23.414 10 28 12 28 12 20 4 20"/>
        <rect x="20" y="22" width="4" height="2"/>
        <rect x="16" y="18" width="4" height="2"/>
        <rect x="20" y="14" width="4" height="2"/>
        <rect x="8" y="14" width="4" height="2"/>
        <path d="M27,3H5A2.0027,2.0027,0,0,0,3,5V16H5V11H27V27H16v2H27a2.0027,2.0027,0,0,0,2-2V5A2.0027,2.0027,0,0,0,27,3Zm0,6H5V5H27Z"/>
      </svg>`;
    case IconsSet.point_editor:
      return `<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <rect x="21" y="15" width="2" height="2"/>
      <path d="M24,23H20a2.0023,2.0023,0,0,1-2-2V11a2.002,2.002,0,0,1,2-2h4a2.0023,2.0023,0,0,1,2,2V21A2.0027,2.0027,0,0,1,24,23ZM20,11h0V21h4V11Z"/>
      <rect x="11" y="15" width="2" height="2"/>
      <path d="M14,23H10a2.0023,2.0023,0,0,1-2-2V11a2.002,2.002,0,0,1,2-2h4a2.0023,2.0023,0,0,1,2,2V21A2.0027,2.0027,0,0,1,14,23ZM10,11h0V21h4V11Z"/>
      <rect x="4" y="21" width="2" height="2"/>
      </svg>`;
    case IconsSet.renew:
      return `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 288 176">
        <path fill="#000000" opacity="1.000000" stroke="none"
          d="
        M230.338913,84.638641
          C236.291916,90.513313 241.574570,96.480713 246.234177,103.021057
          C248.123199,105.672554 247.972855,107.320663 245.191315,109.066353
          C241.102951,111.632217 237.053787,114.291672 233.206894,117.200798
          C230.400375,119.323143 228.691116,118.882317 226.750534,116.055702
          C219.788635,105.915192 211.090836,97.570282 200.668457,90.945145
          C175.033325,74.649841 136.050552,76.607361 112.303703,95.562920
          C111.434464,96.256783 110.275429,96.768982 109.929901,98.567215
          C115.734161,101.660057 122.621529,102.722595 128.904373,106.864128
          C124.680038,111.537254 120.063248,115.236580 115.357689,118.796570
          C113.979111,119.839531 112.151489,119.173370 110.509117,118.798180
          C96.484428,115.594284 83.056717,117.729561 70.137245,123.605820
          C67.088043,124.992722 64.880371,125.460991 62.339748,122.433952
          C59.891510,119.516991 60.905937,117.546356 62.560577,114.947563
          C69.714500,103.711533 73.589859,91.436653 72.778046,78.054001
          C72.474327,73.047150 73.779892,69.419128 77.823364,66.503113
          C80.887901,64.293068 83.356407,61.283154 87.358238,59.161400
          C90.494080,65.815300 91.041443,72.931801 92.779984,79.599136
          C94.833885,80.209061 95.664627,78.634689 96.780128,77.794853
          C115.689041,63.558681 137.153015,56.646633 160.629105,56.979637
          C187.060516,57.354557 210.360168,66.561417 230.338913,84.638641
        z"/>
        </svg>`;
    case IconsSet.ruler:
      return `<svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 32 32">
             <path d="M29.41,21.67,10.34,2.58a2,2,0,0,0-2.83,0L2.59,7.51a2,2,0,0,0,0,2.82L21.66,29.42a2,2,0,0,0,1.42.58,2,2,0,0,0,1.41-.58l4.92-4.93A2,2,0,0,0,29.41,21.67ZM23.08,28,4,8.92,8.92,4l3.79,3.79L10.46,10l1.41,1.41L14.12,9.2l4.13,4.13L16,15.58,17.42,17l2.25-2.25,4.13,4.13-2.25,2.25L23,22.54l2.25-2.25L28,23.08Z"/>
            </svg>`;
  }
}

export function getIconContainer(width, height, rotate, lineHeight, name: IconsSet, cls: string, params: any = {}) {
  return `<span
    class="rpn-icon ${cls}"
    style="transform: rotate(${rotate});width: ${width};height: ${height};line-height:${lineHeight || height}">${getIcon(name, params)}</span>`;
}
