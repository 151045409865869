import { Directive, ElementRef, Renderer2, OnChanges, Input } from '@angular/core';

@Directive({
  selector: '[routerLink]',
  standalone: true,
})
export class EmptyRouterLinkDirective implements OnChanges {
  @Input() routerLink: any;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges() {
    if (this.routerLink === null || this.routerLink === void 0) {
      setTimeout(() => this.renderer.removeAttribute(this.elRef.nativeElement, 'href'), 0);
    }
  }
}
