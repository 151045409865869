import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { switchMap, map, catchError, debounceTime, filter, withLatestFrom, tap } from 'rxjs/operators';
import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { TableType } from 'src/app/providers/_enum';
import { IEssenceParams } from 'src/app/providers/_interfaces/report.interface';
import { RpnHttpParams, SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { findLastRouteWithUrl } from 'src/app/providers/_utils/utils';
import { EssenceSelectors } from 'src/app/store';
import {
  EssenceActionTypes,
  LoadEssenceRequest,
  EssenceLoadedSuccess,
  EssenceLoadedError,
  SelectRoute,
  RouteLoadedSuccess,
  RouteLoadedError,
  CreateEssenceObjectRequest,
  EssenceObjectCreatedSuccess,
  UpdateEssenceObjectRequest,
  DeleteEssenceObjectRequest,
  UpdateEssenceRequest,
  EssenceUpdatedSuccess,
  EssenceUpdatedError,
  EssenceObjectUpdatedSuccess,
  EssenceObjectDeletedSuccess,
  EssenceObjectDeletedError,
  ManualUpdateTreeSuccess,
  ManualUpdateTreeError,
  ManualUpdateTree,
  DeleteAllSelectedObjectsRequest,
  AllSelectedObjectsDeletedSuccess,
  RestoreAllSelectedObjectsRequest,
  AllSelectedObjectsRestoredSuccess,
  AllSelectedObjectsRestoredError,
} from 'src/app/store/essence/actions';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EssenceEffects {
  // Load Root Essence
  loadEssence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EssenceActionTypes.LoadEssenceRequest),
      switchMap((action: LoadEssenceRequest) => {
        const { essenceType, essenceId } = action.payload;
        const disabledHints = !!localStorage.getItem('disabledHints');

        return combineLatest([
          this.svcRestService.fetchEssenceByID(essenceType, essenceId),
          !disabledHints && (environment.SYSTEM_TYPE === EnvironmentType.lk)
            ? this.svcRestService.httpGetWithCache('/svc/mls/api/hints/get-hints/', {params: {report_name: essenceType}}).pipe(
              catchError(() => of({}))
            )
            : of({})
        ]).pipe(
          map(([essence, hints]: [any, any]) => new EssenceLoadedSuccess({ essence: {...essence, hints}, forms: essence.forms })),
          catchError((error) => of(new EssenceLoadedError({ error }))),
        );
      }),
    ),
  );

  // Update Root Essence
  updateEssence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EssenceActionTypes.UpdateEssenceRequest),
      withLatestFrom(this.store.pipe(select(EssenceSelectors.selectCurrentRouteParams))),
      switchMap(([action, params]: [UpdateEssenceRequest, IEssenceParams]) => {
        const { body } = action.payload;
        const { id, type } = params;

        return this.svcRestService.updateEssence(TableType[type], id, body).pipe(
          map((res: any) => new EssenceUpdatedSuccess({ essence: res })),
          catchError((error) => of(new EssenceUpdatedError({ error }))),
        );
      }),
    ),
  );

  // SelectRoute
  selectRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EssenceActionTypes.SelectRoute),
      switchMap((action: SelectRoute) => {
        const { route, relations, routeParams, operation, obj, treePath, sectionName, cacheKey, oldValuesScope } = action.payload;
        const url = route ? (operation ? route[operation] : route.show) : '';
        const params: RpnHttpParams = { ...(relations ? { initParams: { relations } } : null), ...obj };

        if (operation === 'new') {
          return of(null).pipe(
            map(() => new RouteLoadedSuccess({ object: {}, treePath, sectionName })),
            catchError((error) => of(new RouteLoadedError({ error }))),
          );
        }

        if (oldValuesScope) {
          params.params = params.params || {};
          params.params['scope[byOldValues]'] = 'notnull';
        }

        return this.svcRestService.fetchEssenceByRoute(url, routeParams, params).pipe(
          filter((x) => !!x),
          map((res: any) => {
            if (treePath.length) {
              return new RouteLoadedSuccess({ object: res, treePath, sectionName, cacheKey });
            } else {
              return new EssenceLoadedSuccess({ essence: res, forms: res.forms });
            }
          }),
          catchError((error) => of(new RouteLoadedError({ error }))),
        );
      }),
    ),
  );

  // ReturnBack
  returnBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EssenceActionTypes.EssenceObjectCreatedSuccess,
          EssenceActionTypes.EssenceObjectUpdatedSuccess,
          EssenceActionTypes.EssenceObjectDeletedSuccess,
          EssenceActionTypes.ManualUpdateTreeSuccess,
        ),
        filter(
          (action: EssenceObjectCreatedSuccess | EssenceObjectUpdatedSuccess | EssenceObjectDeletedSuccess | ManualUpdateTreeSuccess) => {
            return action?.payload?.returnBack;
          },
        ),
        tap(() => this.router.navigate(['../'], { relativeTo: findLastRouteWithUrl(this.router.routerState.root) })),
      ),
    { dispatch: false },
  );

  // Reload after deleteMany/RestoreMany
  updateAfterDelete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EssenceActionTypes.AllSelectedObjectsDeletedSuccess, EssenceActionTypes.AllSelectedObjectsRestoredSuccess),
        tap(() => {
          this.document.defaultView.location.reload();
        }),
      ),
    { dispatch: false },
  );

  // Create Object
  addObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EssenceActionTypes.CreateEssenceObjectRequest),
      switchMap((action: CreateEssenceObjectRequest) => {
        const { body, route, routeParams } = action.payload;

        return this.svcRestService.createEssence(route.store, routeParams, body).pipe(
          map((object: any) => {
            let returnBack = true;

            if (object.id) {
              returnBack = false;

              this.router
                .navigate(['../'], {
                  relativeTo: findLastRouteWithUrl(this.router.routerState.root),
                  skipLocationChange: true,
                })
                .then(() => this.router.navigateByUrl(`${this.router.url}/${object.id}`));
            }

            return new EssenceObjectCreatedSuccess({ object, returnBack, withNewTree: object.form });
          }),
          catchError((error) => of(new RouteLoadedError({ error }))),
        );
      }),
    ),
  );

  // Update Object
  updateObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EssenceActionTypes.UpdateEssenceObjectRequest),
      withLatestFrom(this.store.pipe(select(EssenceSelectors.selectCurrentRouteParams))),
      switchMap(([action, params]: [UpdateEssenceObjectRequest, IEssenceParams]) => {
        const { body, route, routeParams, method, reload_flag, returnBack } = action.payload;

        return this.svcRestService.updateEssenceByRoute(route.update, routeParams, body, method).pipe(
          map((object: any) => {
            if (reload_flag) {
              this.router
                .navigate(['/rpn/requests/all_requests'], { skipLocationChange: true })
                .then(
                  () => this.router.navigate([params.urlForEssence || '/rpn/requests/all_requests/details', params.type, params.id])
                );
            }

            if (object.id === params.id) {
              return new EssenceUpdatedSuccess({ essence: object });
            }

            return new EssenceObjectUpdatedSuccess({ object, returnBack, withNewTree: object.form });
          }),
          catchError((error) => of(new RouteLoadedError({ error }))),
        );
      }),
    ),
  );

  // Restore All Selected Objects
  restoreSelectObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EssenceActionTypes.RestoreAllSelectedObjectsRequest),
      withLatestFrom(this.store.pipe(select(EssenceSelectors.selectCurrentRouteParams))),
      switchMap(([action]: [RestoreAllSelectedObjectsRequest, IEssenceParams]) => {
        const { body, route, routeParams } = action.payload;

        return this.svcRestService.updateEssenceByRoute(route.update, routeParams, body).pipe(
          debounceTime(100),
          map((object: any) => new AllSelectedObjectsRestoredSuccess({ withNewTree: object.form })),
          catchError((error) => of(new RouteLoadedError({ error }), new AllSelectedObjectsRestoredError({ error }))),
        );
      }),
    ),
  );

  // Delete Object
  deleteObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EssenceActionTypes.DeleteEssenceObjectRequest),
      withLatestFrom(this.store.pipe(select(EssenceSelectors.selectCurrentRouteParams))),
      switchMap(([action, params]: [DeleteEssenceObjectRequest, IEssenceParams]) => {
        const { route, routeParams, cascadeDelete } = action.payload;

        return this.svcRestService.deleteEssenceByRoute(route.delete, routeParams, cascadeDelete).pipe(
          switchMap(() =>
            this.svcRestService.fetchTreeByRequestId(params.id).pipe(
              debounceTime(100),
              map((tree) => new EssenceObjectDeletedSuccess({ returnBack: true, withNewTree: tree })),
              catchError((error) => of(new RouteLoadedError({ error }), new EssenceObjectDeletedError({ error }))),
            ),
          ),
          catchError((error) => of(new RouteLoadedError({ error }), new EssenceObjectDeletedError({ error }))),
        );
      }),
    ),
  );

  // Delete All Selected Objects
  deleteAllSelectedObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EssenceActionTypes.DeleteAllSelectedObjectsRequest),
      withLatestFrom(this.store.pipe(select(EssenceSelectors.selectCurrentRouteParams))),
      switchMap(([action, params]: [DeleteAllSelectedObjectsRequest, IEssenceParams]) => {
        const { route, routeParams, ids, cascadeDelete } = action.payload;

        return this.svcRestService.deleteSelectByRoute(route.store, routeParams, ids, cascadeDelete).pipe(
          switchMap(() =>
            this.svcRestService.fetchTreeByRequestId(params.id).pipe(
              debounceTime(100),
              map((tree) => new AllSelectedObjectsDeletedSuccess({ withNewTree: tree })),
              catchError((error) => of(new RouteLoadedError({ error }), new EssenceObjectDeletedError({ error }))),
            ),
          ),
          catchError((error) => of(new RouteLoadedError({ error }), new EssenceObjectDeletedError({ error }))),
        );
      }),
    ),
  );

  // Update TREE
  manualUpdateTree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EssenceActionTypes.ManualUpdateTree),
      withLatestFrom(this.store.pipe(select(EssenceSelectors.selectCurrentRouteParams))),
      switchMap(([action, params]: [ManualUpdateTree, IEssenceParams]) => {
        const returnBack = action.payload.returnBack;
        return this.svcRestService.fetchTreeByRequestId(params.id).pipe(
          debounceTime(100),
          map((tree) => new ManualUpdateTreeSuccess({ returnBack, withNewTree: tree })),
          catchError((error) => of(new RouteLoadedError({ error }), new ManualUpdateTreeError({ error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private svcRestService: SvcRestService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {}
}
