import { NgIf, NgFor, NgClass, NgStyle, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { isObject } from 'lodash';
import { TooltipModule } from 'ng-uikit-pro-standard';
import { Subscription, Subject } from 'rxjs';
import { AppIconComponent } from 'src/app/modules/app-icon/app-icon.component';
import { BaseSectionFacade } from 'src/app/modules/details-edit-mode/base-section/base-section.facade';
import { DetailsEditModeCardActions } from 'src/app/modules/details-edit-mode/details-edit-mode-card/details-edit-mode-card.interface';
import { fadeInAnimation } from 'src/app/providers/_animations/fade-in.animation';
import { EmptyRouterLinkDirective } from 'src/app/providers/_directives/routerlink/empty-routerlink.directive';
import { EnvelopeSpinnerDirective } from 'src/app/providers/_directives/spinner/spinner.directive';
import { TrimPipe } from 'src/app/providers/_pipes/trim.pipe';

@Component({
  selector: 'app-details-edit-mode-card-actions',
  templateUrl: './details-edit-mode-card-actions.component.html',
  styleUrls: ['./details-edit-mode-card-actions.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    TooltipModule,
    NgStyle,
    RouterLink,
    EmptyRouterLinkDirective,
    AppIconComponent,
    EnvelopeSpinnerDirective,
    AsyncPipe,
    TrimPipe,
  ],
})
export class DetailsEditModeCardActionsComponent implements OnDestroy, OnChanges {
  @Input() data: any;
  @Input() addTitle: string;
  @Input() isDisabled: boolean;
  @Input() hasBack: boolean;
  @Input() hasNext: boolean;
  @Input() customButtons: DetailsEditModeCardActions[];
  @Input() customAdditionalButtons: DetailsEditModeCardActions[];
  @Input() customBtnTitles: any[];
  @Input() set isShowSpinner(isShow) {
    this.isShowSpinner$.next(isShow);
  }
  @Input() onlySave = false;
  isShowSpinner$: Subject<any> = new Subject();

  @Output() action = new EventEmitter<DetailsEditModeCardActions>();

  subscriptions: Subscription[] = [];
  saveTitle = 'Сохранить';

  constructor(
    public cdr: ChangeDetectorRef,
    public baseSectionFacade: BaseSectionFacade,
  ) {}

  ngOnDestroy() {
    this.clear();
  }

  clear() {
    this.subscriptions.forEach((s) => s?.unsubscribe());
    this.subscriptions = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      const data = changes.data.currentValue;
      this.subscribe(data);
    }
  }

  subscribe(data: any) {
    this.clear();

    const action = () => {
      this.cdr.detectChanges();
    };

    const isControl = (v) => {
      return v instanceof UntypedFormGroup || v instanceof UntypedFormArray || v instanceof UntypedFormControl;
    };

    if (isControl(data)) {
      this.subscriptions.push(data.valueChanges.subscribe(action));
    } else if (isObject(data)) {
      Object.keys(data).forEach((key) => {
        const v = data[key];
        if (isControl(v)) {
          this.subscriptions.push(v.valueChanges.subscribe(action));
        }
      });
    }
  }

  getBtnClass(color) {
    switch (color) {
      case 'success':
        return 'rpn-button_primary';
      case 'danger':
        return 'rpn-button_bordered';
      default:
        return 'rpn-button_bordered rpn-button_bordered-green';
    }
  }

  public refresh() {
    this.cdr.detectChanges();
  }
}
