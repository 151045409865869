import { ControlPosition } from 'leaflet';

export const DEFAULT_MAP_LOCATION = { lat: 55.751244, lon: 37.618423 };
export const VOLGA_MAP_LOCATION = { lat: 49.550996, lon: 45.139984 };
export const CENTER_RUSSIA_MAP_LOCATION = { lat: 66.4167, lon: 94.25 };
export const LAYERS_CONTROL_OPTIONS_LEFT = { position: 'topleft' as ControlPosition };
export const LAYERS_CONTROL_OPTIONS_RIGHT = { position: 'topright' as ControlPosition };
export const COMMON_OPTIONS_LAYER = {
  maxNativeZoom: 18,
  maxZoom: 21,
  attributionControl: false,
};
export const ASSISTANCE_COLOR = '#c15342';

export enum MapObjectTypes {
  wasteCollectionPoint = 26,
  dischargeSource = 31,
  onvos = 37,
  onvos_alt = 32,
  registry = 33,
  stationarySource = 34,
  wasteDisposalFacility = 35,
  accumulationFacility = 39,
  processingFacility = 40,
  utilizationFacility = 41,
  neutralizeFacility = 42,
  nearObjectsOnlyNonExcluded = 999,
  nearObjectsOnlyExcluded = 1000,
  imaginary = 1001,
}

export const ALLOWED_MAP_SEARCH_TYPES = [
  MapObjectTypes.dischargeSource,
  MapObjectTypes.stationarySource,
  MapObjectTypes.wasteDisposalFacility,
  MapObjectTypes.onvos,
];

export const MapGeometryType = {
  Point: 'Точечный',
  LineString: 'Линейный',
  MultiLineString: 'Мультилинейный',
  Polygon: 'Площадной',
};

export const MAP_OBJECTS_TYPES_COLORS = {
  1: '#A71D31',
  2: '#F1F0CC',
  3: '#D5BF86',
  4: '#8D775F',
  5: '#ff6f59',
  6: '#254441',
  7: '#43aa8b',
  8: '#b2b09b',
  9: '#ef3054',
  10: '#93a3b1',
  11: '#7c898b',
  12: '#636564',
  13: '#4c443c',
  14: '#322214',
  15: '#ddfafa',
  16: '#aed9e0',
  17: '#9fa0c3',
  18: '#8b687f',
  19: '#7b435b',
  20: '#c2f970',
  21: '#6a00b1',
  22: '#564d80',
  23: '#98a6d4',
  24: '#d3fcd5',
  25: '#1a1ae9',
  26: '#cf5c36',
  27: '#efc88b',
  28: '#f4e3b2',
  29: '#d3d5d7',
  30: '#ff7477',
  [MapObjectTypes.dischargeSource]: '#e69597',
  [MapObjectTypes.onvos]: '#002cff',
  [MapObjectTypes.onvos_alt]: '#002cff',
  [MapObjectTypes.registry]: '#002cff',
  [MapObjectTypes.stationarySource]: '#f7ff00',
  [MapObjectTypes.wasteDisposalFacility]: '#00ffba',
  [MapObjectTypes.nearObjectsOnlyExcluded]: '#c15342',
  [MapObjectTypes.nearObjectsOnlyNonExcluded]: '#c15342',
  [MapObjectTypes.imaginary]: '#eceef0',
};
