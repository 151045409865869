import { Injectable } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';

const TOAST_CONFIG = { timeOut: 6000, positionClass: 'md-toast-bottom-right', tapToDismiss: false, toastClass: 'rpn-toast' };

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor(private toastr: ToastService) {}

  success(message: string, title?: any) {
    this.toastr.success(message, title, TOAST_CONFIG);
  }

  error(message: string, title?: any) {
    this.toastr.error(message, title, TOAST_CONFIG);
  }

  info(message: string, title?: any) {
    this.toastr.info(message, title, TOAST_CONFIG);
  }
}
