import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IBreadcrumb } from 'src/app/providers/_interfaces/common';
import { BreadcrumbsService } from 'src/app/providers/_services/breadcrumbs.service';
import {
  AddBreadcrumbsError,
  AddBreadcrumbsSuccess,
  BreadcrumbsActionsTypes,
  UpdateBreadcrumbsError,
  UpdateBreadcrumbsSuccess,
} from 'src/app/store/breadcrumbs/actions';

@Injectable({ providedIn: 'root' })
export class Effects {
  // ADD BREADCRUMBS
  addBreadcrumbs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BreadcrumbsActionsTypes.AddBreadcrumbsRequest),
      map(() => this.breadcrumbsService.buildBreadcrumbs()),
      map((breadcrumbs: IBreadcrumb[]) => new AddBreadcrumbsSuccess({ breadcrumbs })),
      catchError(() => of(new AddBreadcrumbsError())),
    ),
  );

  // UPDATE BREADCRUMBS
  updateBreadcrumbs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BreadcrumbsActionsTypes.UpdateBreadcrumbsRequest),
      map(() => this.breadcrumbsService.buildBreadcrumbs()),
      map((breadcrumbs: IBreadcrumb[]) => new UpdateBreadcrumbsSuccess({ breadcrumbs })),
      catchError(() => of(new UpdateBreadcrumbsError())),
    ),
  );

  constructor(
    private actions$: Actions,
    private breadcrumbsService: BreadcrumbsService,
  ) {}
}
