import { Action } from '@ngrx/store';

export enum OnvosActionTypes {
  LoadObjectRequest = '[OnvosView Bid/Onvos] Load Object',
  ObjectLoadedSuccess = '[OnvosView Bid/Onvos] Object Loaded Success',
  ObjectLoadedError = '[OnvosView Bid/Onvos] Object Loaded Error',
}

// LOAD
export class LoadOnvosRequest implements Action {
  readonly type = OnvosActionTypes.LoadObjectRequest;

  constructor(public payload: { id: string }) {}
}

export class OnvosLoadedSuccess implements Action {
  readonly type = OnvosActionTypes.ObjectLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class OnvosLoadedError implements Action {
  readonly type = OnvosActionTypes.ObjectLoadedError;
}

export type OnvosUnion = LoadOnvosRequest | OnvosLoadedSuccess | OnvosLoadedError;
