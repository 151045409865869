import { Injectable } from '@angular/core';

export interface FormSuggestionItem {
  value: string;
  formKey: string;
}

@Injectable({
  providedIn: 'root',
})
export class FormSuggestionsService {
  private getKey(formIdentity: string, fieldName: string): string {
    return `${formIdentity}[${fieldName}]`;
  }

  public get(formIdentity: string, fieldName: string): FormSuggestionItem[] {
    const key = this.getKey(formIdentity, fieldName);
    const values = sessionStorage.getItem(key);

    if (!values) {
      return [];
    }

    try {
      return JSON.parse(values);
    } catch (e) {
      return [];
    }
  }

  public put(formIdentity: string, formId: string, fieldName: string, value: string): void {
    const key = this.getKey(formIdentity, fieldName);
    const trimmedValue = (value || '') && value.trim();
    let values = this.get(formIdentity, fieldName);

    if (trimmedValue.length === 0) {
      return;
    }

    if (values.find((item) => item.value === trimmedValue)) {
      return;
    }

    const oldValue = values.find((item) => item.formKey === formId);

    if (oldValue) {
      oldValue.value = trimmedValue;
    } else {
      if (values.length === 6) {
        values = values.splice(1);
      }

      values.push({
        value: trimmedValue,
        formKey: formId,
      });
    }

    sessionStorage.setItem(key, JSON.stringify(values));
  }
}
