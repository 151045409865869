import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, Subject, throwError, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ICert } from 'src/app/providers/_interfaces/esign.interface';
import { ToastrService } from 'src/app/providers/_services/toastr.service';
declare const window: Window & typeof globalThis & { cadesplugin: any };

const cadesplugin: any = window.cadesplugin;
const MaxRetry = 2;

type promiseExecutor<T> = (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void;
class RetryablePromise<T> extends Promise<T> {
  static retry<T>(retries: number, executor: promiseExecutor<T>): Promise<T> {
    return new RetryablePromise(executor).catch((error) =>
      retries > 0 ? RetryablePromise.retry(retries - 1, executor) : RetryablePromise.reject(error),
    );
  }
}

export function JSON_stringify(s: any, emitUnicode: boolean): string {
  if (typeof s === 'string') {
    return s;
  }
  const json = JSON.stringify(s);
  return emitUnicode ? json : json.replace(/[\u007f-\uffff]/g, (c) => '\\u' + ('0000' + c.charCodeAt(0).toString(16)).slice(-4));
}

@Injectable({
  providedIn: 'root',
})
export class SignatureService {
  private selectCertificate$: BehaviorSubject<ICert | null> = new BehaviorSubject<ICert | null>(null);
  private progressListeners: Subject<number>[] = [];
  private signature_hash = '';

  constructor(private toastrService: ToastrService) {}

  set selectCertificate(data: ICert | null) {
    this.selectCertificate$.next(data);
  }

  get selectCertificate(): ICert | null {
    return this.selectCertificate$.getValue();
  }

  addProgressListener(listener: Subject<number>) {
    this.progressListeners.push(listener);
  }

  removeProgressListener(listener: Subject<number>) {
    const index = this.progressListeners.findIndex((l) => l === listener);

    if (index === -1) {
      return;
    }

    this.progressListeners.splice(index, 1);
  }

  public fetchCertList(): Promise<{ value: ICert; error: string }[]> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let isPluginLoaded = false;
      let isPluginWorked = false;
      let isPluginEnabled = false;

      try {
        isPluginLoaded = true;
        isPluginEnabled = true;
        const oStore = await cadesplugin.CreateObjectAsync('CAdESCOM.Store');
        isPluginWorked = true;

        await oStore.Open(
          cadesplugin.CAPICOM_CURRENT_USER_STORE,
          cadesplugin.CAPICOM_MY_STORE,
          cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
        );

        const CertificatesObj = await oStore.Certificates;
        const Count = await CertificatesObj.Count;
        const certSelectOption: { value: any; error: string }[] = [];
        if (Count === 0) {
          this.toastrService.error('Сертификат не найден');
          throw new Error('Certificate not found: ');
        } else {
          for (let i = 1; i <= Count; i++) {
            const cert = await CertificatesObj.Item(i);
            const privateKey = await cert.HasPrivateKey();
            const SubjectName: string = await cert.SubjectName;
            const Thumbprint = await cert.Thumbprint;
            const SerialNumber = await cert.SerialNumber;
            const ValidFromDate = await cert.ValidFromDate;
            const ValidToDate = await cert.ValidToDate;
            let isValid = false;
            try {
              const Validator = await cert.IsValid();
              isValid = await Validator.Result;
            } catch (e) {
              console.log(e);
            }
            const dataFrom = await cert.ValidFromDate;
            const certFromDate = new Date(dataFrom as Date);
            const dataTill = await cert.ValidToDate;
            const certTillDate = new Date(dataTill as Date);
            const Now = new Date();
            let error = '';
            if (Now < certFromDate) {
              error = 'Срок действия не наступил';
            } else if (Now > certTillDate) {
              error = 'Срок действия истек';
            } else if (!privateKey) {
              error = 'Нет привязки к закрытому ключу';
            } else if (!isValid) {
              error = 'Ошибка при проверке цепочки сертификатов';
            }

            const value: any = {};
            SubjectName.split(',').forEach((element) => {
              const a = element.split('=');
              value[a[0].trim()] = a[1] || '';
            });
            value.NN = SerialNumber;
            value.FD = ValidFromDate;
            value.TD = ValidToDate;
            value.Thumbprint = Thumbprint;

            certSelectOption.push({ value, error });
          }
          resolve(certSelectOption);
        }
      } catch (err) {
        let error = '';
        if (!(isPluginLoaded && isPluginEnabled)) {
          error = `КриптоПро ЭП Browser plug-in не установлен.
              Нажмите Ок для перехода на страницу "Проверка работы КриптоПро ЭП Browser plug-in"`;
          if (window.confirm(error)) {
            const a = document.createElement('a');
            a.href = 'https://www.cryptopro.ru/sites/default/files/products/cades/demopage/simple.html';
            a.target = '_blank';
            a.click();
          }
        } else if (!isPluginWorked) {
          error = 'КриптоПро ЭП Browser plug-in не был разрешен.';
        }
        reject(error || err);
      }
    });
  }

  private async Verify(sSignedMessage, oHashedData) {
    const oSignedData = await cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
    try {
      oSignedData.VerifyHash(oHashedData, sSignedMessage, cadesplugin.CADESCOM_CADES_BES);
    } catch (err) {
      alert('Failed to verify signature. Error: ' + cadesplugin.getLastError(err));
      return false;
    }

    return true;
  }

  private SignCreate(thumbprint: string, file: Blob): Promise<string> {
    const reportProgress = (progress: number) => {
      (this.progressListeners || []).forEach((listener) => listener.next(progress));
    };

    return RetryablePromise.retry<string>(MaxRetry, async (resolve, reject) => {
      const oStore = await cadesplugin.CreateObjectAsync('CAdESCOM.Store');
      await oStore.Open();
      const CertificatesObj = await oStore.Certificates;
      let oCertificates = await CertificatesObj.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, thumbprint);
      oCertificates = await oCertificates.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_TIME_VALID);
      const Count = await oCertificates.Count;

      if (Count === 0) {
        throw new Error('empty');
      }
      const oCertificate = await oCertificates.Item(Count);
      await oStore.Close();

      const oSigner = await cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
      await oSigner.propset_Certificate(oCertificate);

      const oSigningTimeAttr = await cadesplugin.CreateObjectAsync('CADESCOM.CPAttribute');
      await oSigningTimeAttr.propset_Name(cadesplugin.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME);
      const oTimeNow = new Date();
      await oSigningTimeAttr.propset_Value(oTimeNow);
      const attr = await oSigner.AuthenticatedAttributes2;
      await attr.Add(oSigningTimeAttr);

      const oDocumentNameAttr = await cadesplugin.CreateObjectAsync('CADESCOM.CPAttribute');
      await oDocumentNameAttr.propset_Name(cadesplugin.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME);
      await oDocumentNameAttr.propset_Value('Document Name');
      await attr.Add(oDocumentNameAttr);

      const pbKeyInfo = await oCertificate.PublicKey();
      const alg = await pbKeyInfo.Algorithm;
      const algoOid = await alg.Value;

      const oHashedData = await cadesplugin.CreateObjectAsync('CAdESCOM.HashedData');
      await oHashedData.propset_DataEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);

      console.log(algoOid);
      switch ('' + algoOid) {
        case '1.2.643.7.1.1.2.2':
        case '1.2.643.7.1.1.3.2':
        case '1.2.643.7.1.1.1.1':
          await oHashedData.propset_Algorithm(cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256);
          break;
        case '1.2.643.7.1.1.2.3':
        case '1.2.643.7.1.1.3.3':
        case '1.2.643.7.1.1.1.2':
          await oHashedData.propset_Algorithm(cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512);
          break;
        case '1.2.643.2.2.19':
        default: {
          await oHashedData.propset_Algorithm(cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411);
          break;
        }
      }

      const blobSlice = File.prototype.slice || (File.prototype as any).mozSlice || (File.prototype as any).webkitSlice;
      const chunkSize = 3 * 1024 * 1024; // 3MB
      const chunks = Math.ceil(file.size / chunkSize);
      let currentChunk = 0;

      reportProgress(0);

      const loadNext = () => {
        const fileReader = new FileReader();

        fileReader.onerror = () => alert('File load error.');

        fileReader.onload = async (data: any) => {
          const header = ';base64,';
          const sFileData = '' + data.target.result;
          console.log(sFileData);
          const sBase64Data = sFileData.substring(sFileData.indexOf(header) + header.length);
          oHashedData.Hash(sBase64Data);
          const percentLoaded = Math.round((currentChunk / chunks) * 100);

          if (percentLoaded <= 100) reportProgress(percentLoaded);
          currentChunk++;

          if (currentChunk < chunks) {
            loadNext();
          } else {
            reportProgress(100);

            const oSignedData = await cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
            // Вычисляем значение подписи
            try {
              const sSignedMessage = await oSignedData.SignHash(oHashedData, oSigner, cadesplugin.CADESCOM_CADES_BES);
              console.log(sSignedMessage);
              try {
                this.signature_hash = '' + (await oHashedData.Value);
              } catch (err) {
                console.log(err);
                this.signature_hash = '';
              }
              console.log(this.signature_hash);

              // Проверим подпись
              const verifyResult = await this.Verify(sSignedMessage, oHashedData);
              if (verifyResult) {
                console.log('Signature verified');
              }
              resolve(sSignedMessage);
            } catch (err) {
              console.log(err);
              console.log('Не удалось подписать данные. Сертификат невалидный. Повторная попытка подписания');
              reject({
                title: '' + err === 'empty' ? 'Нет валидного сертификата' : 'Не удалось подписать данные. Сертификат невалидный.',
                message: cadesplugin.getLastError(err),
              });
            }
          }
        };

        const start = currentChunk * chunkSize;
        const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
        fileReader.readAsDataURL(blobSlice.call(file, start, end));
      };

      loadNext();
    });
  }

  public signByCertificate(blob: Blob): Observable<{ sign: string; signature_hash: string; type: string }> {
    const cert: ICert | null = this.selectCertificate;
    const { signature_hash } = this;
    const { type } = blob;

    if (cert) {
      return from(this.SignCreate(cert.Thumbprint || '', blob)).pipe(
        catchError((err) => {
          console.log(err);
          return throwError(err);
        }),
        map((sign) => ({ sign, signature_hash, type })),
      );
    }
    return of({ sign: '', signature_hash: '', type: '' });
  }
}
