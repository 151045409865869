import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
  Input,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { IconsModule, TooltipModule } from 'ng-uikit-pro-standard';
import { FormFieldValidationComponent } from 'src/app/components/form-field-validation/form-field-validation.component';
import { inOutAnimation } from 'src/app/providers/_animations/in-out-animation';
import { AppFormFieldClass } from 'src/app/providers/_classes/app.form.field.class';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';

@Component({
  selector: 'app-switchbox',
  templateUrl: './switchbox.component.html',
  styleUrls: ['./switchbox.component.scss'],
  providers: AppFormFieldClass.providers(SwitchboxComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [inOutAnimation],
  standalone: true,
  imports: [FormsModule, NgIf, NgClass, IconsModule, TooltipModule, ReactiveFormsModule, FormFieldValidationComponent, AsyncPipe],
})
export class SwitchboxComponent extends AppFormFieldClass<boolean> implements OnInit, OnChanges, OnDestroy {
  @Input() formControlName: string;
  @Input() isLoaded = true;
  @Input() position_class = '';
  @Output() selectValueUserEvent = new EventEmitter();

  constructor(
    public dictionaryService: DictionaryService,
    public sanitizer: DomSanitizer,
    public cdr: ChangeDetectorRef,
    public fcd: FormGroupDirective,
    public auth: AuthenticationService,
  ) {
    super(fcd, cdr, dictionaryService);
  }

  writeValue(value: boolean) {
    this.control.setValue(value);
    this.updateFormValue(value, { emitEvent: false });

    if (!this.destroyed) {
      this.cdr.markForCheck();
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public selectValue(_event: Event): void {
    const controlValue = this.control.value;
    this.selectValueUserEvent.emit(controlValue);
    this.updateFormValue(!!controlValue);
  }
}
