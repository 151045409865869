import { UntypedFormGroup } from '@angular/forms';
import { IApplicant } from 'src/app/providers/_interfaces/common';
import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';
import { IOnvos } from 'src/app/providers/_interfaces/onvos/onvos.interface';
import { IReportTransition } from 'src/app/providers/_interfaces/report.interface';
import { IStateServices } from 'src/app/providers/_interfaces/state.services.interface';

export interface ICheckListData {
  checkListData: ICheckListDataInstance;
  controlCategoriesDict?: any[];
  checkListQuestionsDict?: ICheckListQuestionsInstance[];
  formData: UntypedFormGroup;
}

export interface ICheckListQuestionsInstance extends IDictionaryItem {
  id: number;
  root_id: number;
  control_category_id: number;
  is_active: boolean;
  text: string;
  links: string;
  name: string;
}

export interface ITypeOfAnswer extends IDictionaryItem {
  id: ECheckListAnswerValue;
  name: string;
}

export enum ECheckListAnswerValue {
  yes = '1',
  no = '0',
  not_apply = '2',
}

export interface ICheckListAnswer {
  id: number;
  service_request_id: number;
  question_id: number;
  value: ECheckListAnswerValue;
  comment: string;
}

export interface ICheckListDataInstance {
  id: number;
  control_categories_ids: number[];
  auto_control_categories_ids?: number[];
  name: string;
  onv_id: number;
  onv: IOnvos;
  applicant_id: number;
  applicant: IApplicant;
  status_id: number;
  status_code: string;
  status?: { name: string };
  answers: ICheckListAnswer[];
  answers_hash?: { [question_id: number]: ICheckListAnswer };
  transitions?: IReportTransition[];
}

export const CErpInspection = {
  address_type: 'address_type',
  control_categories: 'control_categories',
  created_at: 'created_at',
  date_end: 'date_end',
  date_start: 'date_start',
  erp_id: 'erp_id',
  id: 'id',
  inspection_docs: 'inspection_docs',
  inspection_events: 'inspection_events',
  inspection_law: 'inspection_law',
  inspection_reason: 'inspection_reason',
  inspection_status: 'inspection_status',
  inspection_type: 'inspection_type',
  inspectors: 'inspectors',
  is_active: 'is_active',
  ogrn: 'ogrn',
  onv_address: 'onv_address',
  onv_number: 'onv_number',
  onv_id: 'onv_id',
  organization_inn: 'organization_inn',
  organization_name: 'organization_name',
  prosec_name: 'prosec_name',
  region_name: 'region_name',
  root_id: 'root_id',
  territory_org_name: 'territory_org_name',
  territory_org_id: 'territory_org_id',
  result_comment: 'result_comment',
  state_service: 'state_service',
  signed_http_request_id: 'signed_http_request_id',
  signs_count: 'signs_count',
  results: 'results',
} as const;

export interface IErpInspection extends IDictionaryItem {
  [CErpInspection.address_type]: string; // "Место фактического осуществления деятельности"
  [CErpInspection.control_categories]: string; // "Федеральный государственный экологический надзор"
  [CErpInspection.created_at]: string; // "2021-06-09T10:57:55+0000"
  [CErpInspection.date_end]: string; // null
  [CErpInspection.date_start]: string; // "2021-10-01"
  [CErpInspection.erp_id]: number; // 512105294150
  [CErpInspection.id]: number; // 6961
  [CErpInspection.inspection_docs]: { doc_name: string; doc_date: string; doc_atr: string }[]; // "[]"
  [CErpInspection.inspection_events]: string; // "[]"
  [CErpInspection.inspection_law]: string; // "294 ФЗ"
  [CErpInspection.inspection_reason]: string; // "['ч. 9.3. ст. 9 Федерал...']"
  [CErpInspection.inspection_status]: string; // "Ожидает проведения"
  [CErpInspection.inspection_type]: string; // "Плановая проверка"
  [CErpInspection.inspectors]: { position: string; full_name: string }[]; // "[]"
  [CErpInspection.is_active]: boolean; // true
  [CErpInspection.ogrn]: string; // "1025100561012"
  [CErpInspection.onv_address]: string; // "184250, Мурманская область, г. Кировск, ул. Ленинградская, д.1"
  [CErpInspection.onv_number]: string; // "47-0151-000313-П"
  [CErpInspection.onv_id]: number;
  [CErpInspection.organization_inn]: string; // "5103070023"
  [CErpInspection.organization_name]: string; // "Акционерное общество \"Апатит\" (объект \"Кировский филиал АО \"Апатит\"
  [CErpInspection.prosec_name]: string; // "Прокуратура Мурманской области"
  [CErpInspection.region_name]: string; // "Мурманская область"
  [CErpInspection.root_id]: number; // 6961
  [CErpInspection.territory_org_name]: string; // "Балтийско-Арктическое межрегиональное управление...
  [CErpInspection.territory_org_id]: number; //
  [CErpInspection.result_comment]: string;
  [CErpInspection.state_service]: IStateServices;
  [CErpInspection.signed_http_request_id]: number;
  [CErpInspection.signs_count]: number;
  [CErpInspection.results]: { protocols: any[]; resolutions: any[] };
  sign_exists?: any;
}

export const CKndRegistry = {
  id: 'id',
  service_request_id: 'service_request_id',
  region_id: 'region_id',
  territory_org_id: 'territory_org_id',
  onv_id: 'onv_id',
  erp_id: 'erp_id',
  type_id: 'type_id',
  status_id: 'status_id',
  status: 'status',
  controlled_entity_id: 'controlled_entity_id',
  organization_inn: 'organization_inn',
  organization_kpp: 'organization_kpp',
  organization_ogrn: 'organization_ogrn',
  organization_name: 'organization_name',
  address: 'address',
  address_type_id: 'address_type_id',
  prosecutor_office: 'prosecutor_office',
  date_start: 'date_start',
  date_end: 'date_end',
  inspectors: 'inspectors',
  laws: 'laws',
  goals: 'goals',
  reasons: 'reasons',
  search: 'search',
  created_at: 'created_at',
  updated_at: 'updated_at',
  deleted_at: 'deleted_at',
  results: 'results',
  inspection_type: 'inspection_type',
  inspection_status: 'inspection_status',
} as const;

export interface IKndRegistry extends IDictionaryItem {
  [CKndRegistry.id]: number; // 15
  [CKndRegistry.service_request_id]: number; // 5151169
  [CKndRegistry.region_id]: number; // 1
  [CKndRegistry.territory_org_id]: number; // 206
  [CKndRegistry.onv_id]: number; // 586195
  [CKndRegistry.erp_id]: number;
  [CKndRegistry.type_id]: number; // 2
  [CKndRegistry.status_id]: number; // 1
  [CKndRegistry.status]: { id: number; name: string }; // []
  [CKndRegistry.controlled_entity_id]: number;
  [CKndRegistry.organization_inn]: number;
  [CKndRegistry.organization_kpp]: number;
  [CKndRegistry.organization_ogrn]: number;
  [CKndRegistry.organization_name]: string;
  [CKndRegistry.address]: string; // Московская обл
  [CKndRegistry.address_type_id]: number; // 2
  [CKndRegistry.prosecutor_office]: string; // 123
  [CKndRegistry.date_start]: string; // 2021-09-01T00:00:00+0300
  [CKndRegistry.date_end]: string; // 2021-09-05T00:00:00+0300
  [CKndRegistry.inspectors]: string;
  [CKndRegistry.laws]: string; // "[]"
  [CKndRegistry.goals]: { name: string; comment: string }[]; // "[]"
  [CKndRegistry.reasons]: { name: string; comment: string }[]; // "[]"
  [CKndRegistry.search]: string;
  [CKndRegistry.created_at]: string; // 2021-09-29T10:52:37+0000
  [CKndRegistry.updated_at]: string; // 2021-09-29T10:52:37+0000
  [CKndRegistry.deleted_at]: string;
  [CKndRegistry.results]: { protocols: any[]; resolutions: any[] };
  [CKndRegistry.inspection_type]: string;
  [CKndRegistry.inspection_status]: string;
}
