import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { FullNameMap, FullNameRoivMap, ObjectTypeByStateServiceIdMap, TableTypeMap } from 'src/app/providers/_const/fgen-object.const';
import { DICT, DictKEYS } from 'src/app/providers/_dictionary';
import { TableType } from 'src/app/providers/_enum';
import { IReportTransition } from 'src/app/providers/_interfaces/report.interface';
import { UserScopesRepo } from 'src/app/providers/_scopes';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { UIShortAdjustReasonStatus } from 'src/app/providers/_text/cabinet';
import { EssenceSelectors } from 'src/app/store';

@Injectable({
  providedIn: 'root',
})
export class DetailsEditModeService {
  public transitionFunctionCall$ = new Subject<IReportTransition>();
  public transitionClick$ = new Subject<IReportTransition>();
  public transitionEarlyClick$ = new Subject<IReportTransition>();
  public printPreviewIsLoaded$ = new BehaviorSubject(false);
  public buttonsText$ = new BehaviorSubject<{ color: string; text: string }>(null);
  public topStatus$ = new BehaviorSubject<{ text: string }>(null);

  constructor(
    private store: Store,
    private dictionaryService: DictionaryService,
  ) {}

  public stateServiceNameMapFunction() {
    return map(([essence, scopeRepo]: [any, UserScopesRepo]) => {
      const type = essence.state_service
        ? (TableTypeMap[essence.state_service.code] || essence.state_service.code)
        : ObjectTypeByStateServiceIdMap[essence.state_service_id];
      switch (type) {
        case 'onv-exclusion': {
          if (essence.emission_object?.exclude_reason_id === 5 || essence.emission_object?.exclude_reason_id === 4) {
            return 'Исключение учетных сведений об объекте негативного воздействия на окружающую среду из реестра';
          }
          break;
        }
        case TableType.fgen_object:
        case TableType.fggn_object:
        case TableType.fgzn_object:
        case TableType.fgan_object:
        case TableType.fgahn_object:
        case TableType.fgohn_object:
        case TableType.fgotn_object:
        case TableType.fglesn_object: {
          return scopeRepo.fgen_roiv.isActive ? FullNameRoivMap[type] : FullNameMap[type];
        }
        case TableType.recycler_actualization: {
          if (essence.status_code !== 'draft') {
            return essence?.request_type?.request_type?.name || essence.state_service?.name;
          }
        }

      }

      return essence.state_service ? essence.state_service.short_name : FullNameMap[type];
    });
  }

  public initAdvancedTitle(essenceType) {
    this.buttonsText$.next(null);

    return this.store.pipe(
      select(EssenceSelectors.selectCurrentEssence),
      withLatestFrom(this.dictionaryService.getDictionaryStorage(DICT, DictKEYS.adjust_reasons).dictionary$),
      filter((essence) => !!essence),
      map(([essence, _dict]) => {
        if (
          essenceType === TableType.onv_request_adjust_v2 ||
          ((essenceType === TableType.onv_request_inclusion_v2 || essenceType === TableType.onv_request_exclusion) &&
            (essence.advanced_documents_comment === 'union' || essence.advanced_documents_comment === 'disunion'))
        ) {
          if (essence.advanced_documents_comment) {
            this.buttonsText$.next(null);

            const text = UIShortAdjustReasonStatus[essence.advanced_documents_comment];

            if (text) {
              this.topStatus$.next({
                text,
              });
            }
          } else {
            const text = 'Для продолжения выберите причину корректировки сведений об объекте НВОС';
            const color = '#3B4256';

            this.buttonsText$.next({
              color,
              text,
            });

            this.topStatus$.next(null);
          }
        }
      }),
    );
  }
}
