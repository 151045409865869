export enum EInputType {
  text = 'text',
  number = 'number',
  number_with_null = 'number_with_null',
  currency = 'currency',
  percentage = 'percentage',
  datetime = 'datetime',
  phone_strict = 'phone_strict',
  mineral_license = 'mineral_license',
  rafp_number = 'rafp_number',
}
