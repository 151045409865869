import { Injectable, TemplateRef } from '@angular/core';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { MDBModalRef } from 'ng-uikit-pro-standard/lib/free/modals/modal.options';
import { Observable, Subject } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { ModalShowTemplateComponent } from 'src/app/modules/modal-show-template/modal-show-template.component';
import { MODAL_BASE_OPTIONS } from 'src/app/modules/treeview/treeview.const';
import { TableType } from 'src/app/providers/_enum';
import { IReportCheckError } from 'src/app/providers/_interfaces/report.interface';


export interface IOpenModalResponse {
  modalRef: MDBModalRef;
  closeModalSubject$: Subject<any>;
  closeModal$: Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class ModalShowTemplateService {
  constructor(private modalService: MDBModalService) {}

  openModal(
    headerTitle: string,
    bodyText: string,
    bodyTemplate: TemplateRef<any> | null,
    modalOptions = {},
    closeButtonLabel?: string,
    footerTemplate?: TemplateRef<any>,
    contextData?: any,
    modalTemplateComponent?: any,
    essenceType?: TableType,
    errors?: IReportCheckError[],
    showIdOfErrorElement?: boolean,
  ): IOpenModalResponse {
    const closeModal$ = new Subject<any>();

    let modalComponent = ModalShowTemplateComponent;

    if (modalTemplateComponent) {
      modalComponent = modalTemplateComponent;
    }

    const modalRef = this.modalService.show(modalComponent, {
      ...MODAL_BASE_OPTIONS,
      data: {
        content: {
          headerTitle,
          bodyText,
          bodyTemplate,
          closeModal$,
          closeButtonLabel,
          footerTemplate,
          essenceType,
          errors,
          showIdOfErrorElement,
        },
        contextData,
      },
      ...modalOptions,
    });

    return {
      modalRef,
      closeModalSubject$: closeModal$,
      closeModal$: closeModal$.pipe(
        tap(() => modalRef?.hide()),
        finalize(() => modalRef?.hide()),
      ),
    };
  }
}
