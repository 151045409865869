import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { isEqual } from 'lodash';
import { IEssence } from 'src/app/providers/_interfaces/essence.interface';
import { deepClone } from 'src/app/providers/_utils/utils';
import { EssenceActionTypes, EssenceUnion } from 'src/app/store/essence/actions';

export const adapter: EntityAdapter<IEssence> = createEntityAdapter<IEssence>({
  selectId: (instance) => instance && instance.id,
});

export interface EssenceState extends EntityState<IEssence> {
  currentEssenceId: number;
  currentEssenceType: string;
  currentForms: any;
  urlForEssence: string;
  needLoadRoot: boolean;

  currentRoute: any;
  currentTreePath: any;
  currentRouteParams: { [key: string]: any };
  currentRouteRelations: any;
  currentOperation: any;
  currentQueryObject: any;
  currentObject: any;
  currentSectionName: any;
  currentTree: any;
}

export const initialState: EssenceState = adapter.getInitialState({
  currentEssenceId: null,
  currentEssenceType: null,
  currentForms: null,
  urlForEssence: null,
  needLoadRoot: null,

  currentRoute: null,
  currentTreePath: null,
  currentRouteParams: null,
  currentRouteRelations: null,
  currentOperation: null,
  currentQueryObject: null,
  currentObject: null,
  currentSectionName: null,
  currentTree: null,
});

export function essenceReducer(state: EssenceState = initialState, action: EssenceUnion): EssenceState {
  switch (action.type) {
    // LOAD ROOT OF REPORT
    case EssenceActionTypes.LoadEssenceRequest: {
      // console.log('LoadRootEssenceRequest');
      return adapter.removeAll({
        ...state,
        currentForms: null,
        currentRoute: null,
        currentTreePath: null,
        currentRouteParams: null,
        currentRouteRelations: null,
        currentOperation: null,
        currentQueryObject: null,
        currentObject: null,
        currentSectionName: null,
        currentEssenceId: action.payload.essenceId,
        currentEssenceType: action.payload.essenceType,
        urlForEssence: action.payload.rpnUrl,
        needLoadRoot: false,
        currentTree: null,
      });
    }

    // LOAD ROOT OF REPORT SUCCESS
    case EssenceActionTypes.EssenceLoadedSuccess: {
      // console.log('RootEssenceLoadedSuccess');
      return adapter.upsertOne(action.payload.essence, {
        ...state,
        currentRouteParams: { [state.currentEssenceType]: state.currentEssenceId, essenceId: state.currentEssenceId },
        currentForms: action.payload.forms,
        currentObject: action.payload.essence,
        needLoadRoot: false,
        currentTree: { ...action.payload.essence.form, created_at: +new Date() },
      });
    }

    // UPDATE ROOT OF REPORT SUCCESS
    case EssenceActionTypes.EssenceUpdatedSuccess: {
      // console.log('RootEssenceUpdateSuccess');
      const essence = action.payload.essence;
      const currentEssence = state.entities[state.currentEssenceId];
      const currentForms = currentEssence?.form;
      const currentTransitions = currentEssence?.transitions;
      return adapter.upsertOne(
        {
          ...currentEssence,
          ...essence,
          ...{
            form: essence.form || currentForms,
            transitions: essence.transitions || currentTransitions,
          },
        },
        {
          ...state,
          currentRouteParams: { [state.currentEssenceType]: state.currentEssenceId, essenceId: state.currentEssenceId },
          currentForms: essence.forms || state.currentForms,
          currentObject: essence,
          needLoadRoot: false,
          currentTree: essence.form ? { ...essence.form, created_at: +new Date() } : state.currentTree,
        },
      );
    }

    case EssenceActionTypes.EssenceObjectUpdatedSuccess: {
      // console.log('ObjectEssenceUpdateSuccess');
      return {
        ...state,
        currentObject: action.payload.object,
        currentTree: action.payload.withNewTree ? { ...action.payload.withNewTree, created_at: +new Date() } : state.currentTree,
      };
    }

    case EssenceActionTypes.EssenceObjectDeletedSuccess:
    case EssenceActionTypes.AllSelectedObjectsDeletedSuccess:
    case EssenceActionTypes.AllSelectedObjectsRestoredSuccess:
    case EssenceActionTypes.EssenceObjectCreatedSuccess:
    case EssenceActionTypes.ManualUpdateTreeSuccess: {
      // console.log('ManualUpdateTreeSuccess');
      return {
        ...state,
        needLoadRoot: true,
        currentTree: action.payload.withNewTree ? { ...action.payload.withNewTree, created_at: +new Date() } : state.currentTree,
      };
    }

    case EssenceActionTypes.SelectRoute: {
      // console.log('Load Select Route');
      return {
        ...state,
        currentRoute: action.payload.route,
        currentTreePath: action.payload.treePath,
        currentRouteParams: action.payload.routeParams,
        currentRouteRelations: action.payload.relations,
        currentOperation: action.payload.operation,
        currentQueryObject: action.payload.obj,
        currentObject: null,
        currentSectionName: action.payload.sectionName,
        needLoadRoot: !!(state.needLoadRoot || action.payload.needLoadRoot),
        currentTree: action.payload.withNewTree ? { ...action.payload.withNewTree, created_at: +new Date() } : state.currentTree,
      };
    }

    case EssenceActionTypes.RouteLoadedSuccess: {
      // console.log('Load Select Route Success');
      const object = action.payload.object;

      if (object) {
        const thisPath = action.payload.treePath || {};
        const currentPath = state.currentTreePath || {};

        if (thisPath.length !== currentPath.length || !isEqual(currentPath, thisPath)) {
          // console.log('2 overlapped request. Apply the last one');
          return state;
        }

        return adapter.upsertOne(
          deepClone(state.entities[state.currentEssenceId] || { id: null }),
          { ...state, currentObject: object }
        );
      }
      break;
    }

    default: {
      return state;
    }
  }
}
