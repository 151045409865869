import { IMyOptions } from 'ng-uikit-pro-standard';
import { UI_GOST_DATE_FORMAT } from 'src/app/providers/_const/time';

export const Months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
export const ShortMonths = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сент', 'Окт', 'Ноя', 'Дек'];

export const MyDatePickerOptions: IMyOptions = {
  dayLabels: { su: 'Вс', mo: 'Пн', tu: 'Вт', we: 'Ср', th: 'Чт', fr: 'Пт', sa: 'Сб' },
  dayLabelsFull: { su: 'Воскресенье', mo: 'Понедельник', tu: 'Вторник', we: 'Среда', th: 'Четверг', fr: 'Пятница', sa: 'Суббота' },
  monthLabels: {
    1: ShortMonths[0],
    2: ShortMonths[1],
    3: ShortMonths[2],
    4: ShortMonths[3],
    5: ShortMonths[4],
    6: ShortMonths[5],
    7: ShortMonths[6],
    8: ShortMonths[7],
    9: ShortMonths[8],
    10: ShortMonths[9],
    11: ShortMonths[10],
    12: ShortMonths[11],
  },
  monthLabelsFull: {
    1: Months[0],
    2: Months[1],
    3: Months[2],
    4: Months[3],
    5: Months[4],
    6: Months[5],
    7: Months[6],
    8: Months[7],
    9: Months[8],
    10: Months[9],
    11: Months[10],
    12: Months[11],
  },
  dateFormat: UI_GOST_DATE_FORMAT.toLowerCase(),
  useDateObject: false,
  todayBtnTxt: 'Сегодня',
  clearBtnTxt: 'Очистить',
  closeBtnTxt: 'Закрыть',
  showTodayBtn: true,
  showClearDateBtn: true,
  closeAfterSelect: true,
  inputIcon: true,
  minYear: 1800,
  maxYear: 2200,
  inline: true,
};

export enum EFieldType {
  primitive = 'primitive',
  object = 'object',
  array = 'array',
}

export enum ESubSectionRename {
  organisation_info = 'applicant_information',
  organization_info = 'applicant_information',
  organisation_contact = 'applicant_information',
  organization_contact = 'applicant_information',
  organisation_add_info = 'applicant_information',
  organization_add_info = 'applicant_information',
  organisation_okveds = 'applicant_information',
  organization_okveds = 'applicant_information',
  statement = '',
  process = '',
}

export enum FormFieldType {
  text = 'text',
  date = 'date',
  bool = 'bool',
  number = 'number',
  dadata = 'dadata',
  inThousands = 'thousands',
  dictionary = 'dictionary',
  dictionaryArray = 'dictionaryArray',
  criteria_nvos = 'criteria_nvos',
}

export enum ServiceRequestEnum {
  'DktRequest' = 'dkt_request',
  'EnvHarmRequest' = 'env_harm_request',
  'LicenseRequestIssue' = 'license_request_issue',
  'LicenseRequestCorrection' = 'license_request_correction',
  'LicenseRequestReplace' = 'license_request_replace',
  'LicenseRequestWriteout' = 'license_request_writeout',
  'LicenseRequestDepartmentWriteout' = 'request_department_writeout',
  'LicenseRequestTermination' = 'license_request_termination',
  'LicenseRequestForcedTermination' = 'request_forced_termination',
  'LicenseRequestInsertion' = 'license_request_insertion',
  'LicenseRequestModification' = 'license_request_modification',
  'UtilizationRequest' = 'utilization_request',
  'PublicInspectorRequest' = 'public_inspector_request',
  'EekCertificateImportIssue' = 'eek_certificate_import_issue',
}

export enum FormEnum {
  'HarmInfo' = 'harm_info',
  'EnvHarmRequest' = 'statement',
  'LicenseRequestIssue' = 'statement',
  'LicenseRequestCorrection' = 'statement',
  'LicenseRequestReplace' = 'statement',
  'LicenseRequestWriteout' = 'statement',
  'LicenseRequestTermination' = 'statement',
}

const LicenseRequestApplicantInformationFields = {
  inn: {
    title: 'ИНН',
    displayType: FormFieldType.text,
  },
  full_name_organization: {
    title: 'Наименование ЮЛ(полн.)',
    displayType: FormFieldType.text,
  },
  short_name_organization: {
    title: 'Наименование ЮЛ (сокр.)',
    displayType: FormFieldType.text,
  },
  is_non_resident: {
    title: 'Не является резидентом РФ',
    displayType: FormFieldType.bool,
  },
  egrul_egrip_number: {
    title: 'Государственный регистрационный номер записи в ЕГРЮЛ/ЕГРИП',
    displayType: FormFieldType.text,
  },
  egrul_egrip_date: {
    title: 'Дата включения в ЕГРЮЛ/ЕГРИП',
    displayType: FormFieldType.date,
  },
  ogrn: {
    title: 'ОГРН/ОГРНИП',
    displayType: FormFieldType.text,
  },
  register_date: {
    title: 'Дата регистрации ЮЛ/ИП',
    displayType: FormFieldType.date,
  },
  legal_address_street: {
    title: 'Адрес',
    displayType: FormFieldType.text,
  },
  legal_address_oktmo: {
    title: 'ОКТМО',
    displayType: FormFieldType.text,
  },
  legal_address_post: {
    title: 'Почтовый индекс',
    displayType: FormFieldType.text,
  },
  passport: {
    title: 'Данные документа удостоверяющего личность (ИП)',
    displayType: FormFieldType.text,
  },
  nsi_okopf_id: {
    title: 'ОКОПФ',
    displayType: FormFieldType.dictionary,
    dictKey: 'okopfs',
  },
  kpp: {
    title: 'КПП',
    displayType: FormFieldType.text,
  },
  brand: {
    title: 'Фирменное название',
    displayType: FormFieldType.text,
  },
  fns_doc: {
    title: 'Реквизиты документа о постановке на учёт в налоговом органе',
    displayType: FormFieldType.text,
  },
  fns_doc_date: {
    title: 'Дата документа о постановке на учет в налоговом органе',
    displayType: FormFieldType.date,
  },
  surname: {
    title: 'Фамилия',
    displayType: FormFieldType.text,
  },
  name: {
    title: 'Имя',
    displayType: FormFieldType.text,
  },
  patronymic: {
    title: 'Отчество',
    displayType: FormFieldType.text,
  },
  is_ceo: {
    title: 'Является руководителем организации?',
    displayType: FormFieldType.bool,
  },
  work_position: {
    title: 'Должность',
    displayType: FormFieldType.text,
  },
  attorney_details: {
    title: 'Реквизиты доверенности',
    displayType: FormFieldType.text,
  },
  phone: {
    title: 'Телефон',
    displayType: FormFieldType.text,
  },
  email: {
    title: 'E-mail',
    displayType: FormFieldType.text,
  },
};

const LicenseRequestDocumentFields = {
  issuer: {
    title: 'Орган, выдавший документ',
    displayType: FormFieldType.text,
  },
  issued_at: {
    title: 'Дата выдачи',
    displayType: FormFieldType.date,
  },
  number: {
    title: 'Номер',
    displayType: FormFieldType.text,
  },
  name: {
    title: 'Наименование документа',
    displayType: FormFieldType.text,
  },
  lists: {
    title: 'Кол-во листов',
    displayType: FormFieldType.text,
  },
  comment: {
    title: 'Доп. Сведения',
    displayType: FormFieldType.text,
  },
};

const place_activities = {
  fkko_code: {
    title: 'Код ФККО',
    displayType: FormFieldType.text,
  },
  fkko_name: {
    title: 'Название ФККО',
    displayType: FormFieldType.text,
  },
  hazard_class_id: {
    title: 'Класс опасности',
    displayType: FormFieldType.dictionary,
    dictKey: 'wasteHazardClasses',
  },
  activity_ids: {
    title: 'Виды работ',
    displayType: FormFieldType.dictionaryArray,
    dictKey: 'waste_activities',
  },
};

const places = {
  address: {
    title: 'Адрес',
    displayType: FormFieldType.text,
  },
  oktmo: {
    title: 'ОКТМО',
    displayType: FormFieldType.text,
  },
};

const acceptance = {
  number: {
    title: 'Номер лицензии',
    displayType: FormFieldType.text,
  },
  issued_at: {
    title: 'Дата выдачи лицензии',
    displayType: FormFieldType.date,
  },
  issuer_order: {
    title: 'Номер приказа',
    displayType: FormFieldType.text,
  },
  issuer_order_at: {
    title: 'Дата приказа',
    displayType: FormFieldType.date,
  },
  issuer_id: {
    title: 'Орган, выдавший лицензию',
    displayType: FormFieldType.dictionary,
    dictKey: 'licensing_orgs',
  },
};

const writeout = {
  authorized_person_id: {
    title: 'Уполномоченное лицо',
    displayType: FormFieldType.dictionary,
    dictKey: 'user_profile_id',
    dictKeyName: 'user.full_name',
  },
};

export const FormInfoFields = {
  // ВРЕДЫ
  env_harm_request: {
    // СЕКЦИЯ
    harm_info: {
      is_located_in_sea: {
        title: 'Надзор на море',
        displayType: FormFieldType.bool,
        requerid: false,
      },

      org_inn: {
        title: 'ИНН',
        displayType: FormFieldType.number,
        requerid: true,
      },

      subject: {
        title: 'Хозяйствующий субъект',
        displayType: FormFieldType.text,
        requerid: true,
      },

      org_ogrn: {
        title: 'ОГРН',
        displayType: FormFieldType.text,
        requerid: false,
      },

      org_kpp: {
        title: 'КПП',
        displayType: FormFieldType.text,
        requerid: false,
      },

      org_okopf: {
        title: 'ОКОПФ',
        displayType: FormFieldType.text,
        requerid: false,
      },

      onvos_code: {
        title: 'Код ОНВОС',
        displayType: FormFieldType.text,
        requerid: false,
      },

      onvos_location: {
        title: 'Местонахождение ОНВОС',
        displayType: FormFieldType.text,
        requerid: true,
      },

      department: {
        title: 'Отрасль промышленности',
        displayType: FormFieldType.text,
        requerid: false,
      },

      environment_component_id: {
        title: 'Наименование компонента природной среды, которому причинен вред',
        displayType: FormFieldType.dictionary,
        dictKey: 'environment_component_id',
        requerid: true,
      },

      hydronym_type_id: {
        title: 'Вид водного объекта',
        displayType: FormFieldType.dictionary,
        dictKey: 'hydronym_types_id',
        requerid: false,
      },

      hydronym_name: {
        title: 'Наименование водного объекта',
        displayType: FormFieldType.text,
        requerid: false,
      },

      cause: {
        title: 'Краткое описание причины и места загрязнения',
        displayType: FormFieldType.text,
        requerid: false,
      },

      detection_date: {
        title: 'Дата выявления правонарушения',
        displayType: FormFieldType.date,
        requerid: true,
      },

      penalty_number: {
        title: 'Номер постановления о назначении административного наказания',
        displayType: FormFieldType.text,
        requerid: true,
      },

      penalty_date: {
        title: 'Дата постановления о назначении административного наказания',
        displayType: FormFieldType.date,
        requerid: true,
      },

      koap: {
        title: 'Статья КоАП РФ',
        displayType: FormFieldType.text,
        requerid: false,
      },

      pollution_area: {
        title: 'Информация о загрязнении нефтепродуктами: Площадь загрязнения, м2',
        displayType: FormFieldType.number,
        requerid: false,
      },

      pollution_volume: {
        title: 'Информация о загрязнении нефтепродуктами: Объем поступивших в окружающую среду нефтепродуктов, м3',
        displayType: FormFieldType.text,
        requerid: false,
      },

      penalty_amount: {
        title: 'Сумма штрафа (руб.)',
        displayType: FormFieldType.inThousands,
        requerid: false,
      },

      calculation_start_date: {
        title: 'Дата начала расчёта размера вреда',
        displayType: FormFieldType.date,
        requerid: false,
      },

      method: {
        title: 'Методика исчисления размера вреда, пункт',
        displayType: FormFieldType.text,
        requerid: false,
      },

      harm_amount: {
        title: 'Рассчитанная сумма вреда (руб.)',
        displayType: FormFieldType.inThousands,
        requerid: false,
      },

      foiv_id: {
        title: 'Расчет произведен',
        displayType: FormFieldType.dictionary,
        dictKey: 'foiv',
        requerid: false,
      },

      agreement_org_name: {
        title: 'Наименование органа производившего согласование расчета размера вреда',
        displayType: FormFieldType.text,
        requerid: false,
      },

      agreement_calculation_date: {
        title: 'Дата направления расчета на согласование',
        displayType: FormFieldType.date,
        requerid: false,
      },

      has_agreement: {
        title: 'Принятое решение (согласовано/ не согласовано)',
        displayType: FormFieldType.bool,
        requerid: false,
      },

      agreed_harm_amount: {
        title: 'Сумма размера вреда, согласованная (руб.)',
        displayType: FormFieldType.inThousands,
        requerid: false,
      },

      agreement_date: {
        title: 'Дата о согласовании (несогласовании)',
        displayType: FormFieldType.date,
        requerid: false,
      },

      agreement_letter_number: {
        title: 'Номер письма о согласовании (несогласовании)',
        displayType: FormFieldType.text,
        requerid: false,
      },

      refusal_reason: {
        title: 'Основания отказа в согласовании расчета размера вреда',
        displayType: FormFieldType.text,
        requerid: false,
      },

      presented_harm_amount: {
        title: 'Сумма предъявленного размера вреда (руб.)',
        displayType: FormFieldType.inThousands,
        requerid: false,
      },

      demand_date: {
        title: 'Дата направления требования о добровольном возмещении размера вреда',
        displayType: FormFieldType.date,
        requerid: false,
      },

      unforced_date: {
        title: 'Дата взыскания размера вреда в добровольном порядке',
        displayType: FormFieldType.date,
        requerid: false,
      },

      unforced_amount: {
        title: 'Сумма  вреда, фактически  погашенная в добровольном (досудебном) порядке, (руб.)',
        displayType: FormFieldType.inThousands,
        requerid: false,
      },

      trial_date: {
        title: 'Дата обращения в суд с иском о возмещении вреда',
        displayType: FormFieldType.date,
        requerid: false,
      },

      trial_result: {
        title: `Стадия и (или) результат рассмотрения дела (дата вступления судебного акта в
          законную силу)/передано в службу судебных приставов`,
        displayType: FormFieldType.text,
        requerid: false,
      },

      trial_harm_amount: {
        title: 'Размер вреда, подлежащий взысканию, согласно судебному акту (руб.)',
        displayType: FormFieldType.inThousands,
        requerid: false,
      },

      unforced_trial_fact_amount: {
        title: 'Денежная сумма, фактически взысканная по решению суда в добровольном порядке (руб.)',
        displayType: FormFieldType.inThousands,
        requerid: false,
      },

      executive_amount: {
        title: 'Денежная сумма, взысканная в рамках исполнительного производства (руб.)',
        displayType: FormFieldType.inThousands,
        requerid: false,
      },

      trial_penalty_date: {
        title: 'Дата взыскания размера вреда, установленного решением суда',
        displayType: FormFieldType.date,
        requerid: false,
      },

      note: {
        title: 'Примечание',
        displayType: FormFieldType.text,
        requerid: false,
      },
    },

    // СЕКЦИЯ
    statement: {
      territory_org_id: {
        title: 'Территориальный орган',
        type: EFieldType.primitive,
        displayType: FormFieldType.dictionary,
        dictKey: 'territory_org_id',
        required: true,
      },
      region_id: {
        title: 'Субъект РФ',
        type: EFieldType.primitive,
        displayType: FormFieldType.dictionary,
        dictKey: 'region',
        required: true,
      },
    },
  },

  license_request_issue: {
    applicant_information: LicenseRequestApplicantInformationFields,
    organization_info: LicenseRequestApplicantInformationFields,
    organization_contact: LicenseRequestApplicantInformationFields,
    place_activities,
    places,
    acceptance,
    statement: {
      territory_org_id: {
        title: 'Территориальный орган',
        type: EFieldType.primitive,
        displayType: FormFieldType.dictionary,
        dictKey: 'territory_org_id',
        required: true,
      },
      region_id: {
        title: 'Субъект РФ',
        type: EFieldType.primitive,
        displayType: FormFieldType.dictionary,
        dictKey: 'region',
        required: true,
      },
      get_method_id: {
        title: 'Способ получения Услуги',
        displayType: FormFieldType.dictionary,
        dictKey: 'get_method_id',
      },
    },
    writeout,
    docs_san: LicenseRequestDocumentFields,
    docs_gee: LicenseRequestDocumentFields,
    docs_building: LicenseRequestDocumentFields,
    docs_facility: LicenseRequestDocumentFields,
    docs_transport: LicenseRequestDocumentFields,
    docs_equipment: LicenseRequestDocumentFields,
    docs_certificate: LicenseRequestDocumentFields,
    docs_admitter: LicenseRequestDocumentFields,
  },

  license_request_termination: {
    applicant_information: LicenseRequestApplicantInformationFields,
    organization_info: LicenseRequestApplicantInformationFields,
    organization_contact: LicenseRequestApplicantInformationFields,
    acceptance: {
      order_number: {
        title: 'Номер приказа',
        displayType: FormFieldType.text,
      },
      order_date: {
        title: 'Дата приказа',
        displayType: FormFieldType.date,
      },
      issuer_id: {
        title: 'Орган, прекративший действие лицензии',
        displayType: FormFieldType.dictionary,
        dictKey: 'licensing_orgs',
      },
    },
    requisite: {
      license_number: {
        title: 'Номер лицензии',
        displayType: FormFieldType.text,
      },
      license_date: {
        title: 'Дата предоставления лицензии',
        displayType: FormFieldType.date,
      },
      issuer_id: {
        title: 'Орган, выдавший лицензию',
        displayType: FormFieldType.dictionary,
        dictKey: 'licensing_orgs',
      },
    },
    statement: {
      territory_org_id: {
        title: 'Территориальный орган',
        type: EFieldType.primitive,
        displayType: FormFieldType.dictionary,
        dictKey: 'territory_org_id',
        required: true,
      },
      region_id: {
        title: 'Субъект РФ',
        type: EFieldType.primitive,
        displayType: FormFieldType.dictionary,
        dictKey: 'region',
        required: true,
      },
      get_method_id: {
        title: 'Способ получения Услуги',
        displayType: FormFieldType.dictionary,
        dictKey: 'get_method_id',
      },
      reason_id: {
        title: 'Способ получения Услуги',
        displayType: FormFieldType.dictionary,
        dictKey: 'law_termination_reasons',
      },
    },
    writeout,
  },

  license_request_writeout: {
    applicant_information: LicenseRequestApplicantInformationFields,
    organization_info: LicenseRequestApplicantInformationFields,
    organization_contact: LicenseRequestApplicantInformationFields,
    requisite: {
      number: {
        title: 'Номер лицензии',
        displayType: FormFieldType.text,
      },
      issued_at: {
        title: 'Дата предоставления лицензии',
        displayType: FormFieldType.date,
      },
    },
    statement: {
      territory_org_id: {
        title: 'Территориальный орган',
        type: EFieldType.primitive,
        displayType: FormFieldType.dictionary,
        dictKey: 'territory_org_id',
        required: true,
      },
      region_id: {
        title: 'Субъект РФ',
        type: EFieldType.primitive,
        displayType: FormFieldType.dictionary,
        dictKey: 'region',
        required: true,
      },
      get_method_id: {
        title: 'Способ получения Услуги',
        displayType: FormFieldType.dictionary,
        dictKey: 'get_method_id',
      },
    },
    writeout,
  },

  request_department_writeout: {
    applicant_information: LicenseRequestApplicantInformationFields,
    organization_info: LicenseRequestApplicantInformationFields,
    organization_contact: LicenseRequestApplicantInformationFields,
    department_information: LicenseRequestApplicantInformationFields,
    requisite: {
      number: {
        title: 'Номер лицензии',
        displayType: FormFieldType.text,
      },
      issued_at: {
        title: 'Дата предоставления лицензии',
        displayType: FormFieldType.date,
      },
    },
    statement: {
      territory_org_id: {
        title: 'Территориальный орган',
        type: EFieldType.primitive,
        displayType: FormFieldType.dictionary,
        dictKey: 'territory_org_id',
        required: true,
      },
      region_id: {
        title: 'Субъект РФ',
        type: EFieldType.primitive,
        displayType: FormFieldType.dictionary,
        dictKey: 'region',
        required: true,
      },
      get_method_id: {
        title: 'Способ получения Услуги',
        displayType: FormFieldType.dictionary,
        dictKey: 'get_method_id',
      },
    },
    writeout,
  },

  license_request_correction: {
    applicant_information: LicenseRequestApplicantInformationFields,
    organization_info: LicenseRequestApplicantInformationFields,
    organization_contact: LicenseRequestApplicantInformationFields,
    place_activities,
    places,
    acceptance,
    writeout,
    statement: {
      territory_org_id: {
        title: 'Территориальный орган',
        displayType: FormFieldType.dictionary,
        dictKey: 'territory_org_id',
      },
      region_id: {
        title: 'Субъект РФ',
        displayType: FormFieldType.dictionary,
        dictKey: 'region',
      },
      get_method_id: {
        title: 'Способ получения Услуги',
        displayType: FormFieldType.dictionary,
        dictKey: 'get_method_id',
      },
    },
  },

  license_request_replace: {
    applicant_information: LicenseRequestApplicantInformationFields,
    organization_info: LicenseRequestApplicantInformationFields,
    organization_contact: LicenseRequestApplicantInformationFields,
    place_activities,
    places,
    acceptance,
    writeout,
    statement: {
      territory_org_id: {
        title: 'Территориальный орган',
        displayType: FormFieldType.dictionary,
        dictKey: 'territory_org_id',
      },
      region_id: {
        title: 'Субъект РФ',
        displayType: FormFieldType.dictionary,
        dictKey: 'region',
      },
      get_method_id: {
        title: 'Способ получения Услуги',
        displayType: FormFieldType.dictionary,
        dictKey: 'get_method_id',
      },
      stopped_at: {
        title: 'Дата окончания оказания услуг',
        displayType: FormFieldType.date,
      },
      reason_id: {
        title: 'Причина переоформления',
        displayType: FormFieldType.dictionary,
        dictKey: 'law_replace_reasons',
      },
    },
    reorganization_info: {
      egr_doc: {
        title: 'Данные документа, подтверждающего факт внесения соответствующих изменений в единый государственный реестр юридических лиц',
        displayType: FormFieldType.text,
      },
    },
  },

  // ОБЩЕСТВЕННЫЕ ИНСПЕКТОРЫ
  public_inspector_request: {
    // Удостоверение инспекторов
    inspector_certificate: {
      cert_number: {
        title: '№ удостоверения',
        type: FormFieldType.text,
        requerid: false,
      },
      cert_issue_date: {
        title: 'Дата выдачи',
        type: FormFieldType.date,
        required: true,
      },
      surname: {
        title: 'Фамилия',
        type: FormFieldType.text,
        required: true,
      },
      forename: {
        title: 'Имя',
        type: FormFieldType.text,
        required: true,
      },
      patronymic: {
        title: 'Отчество',
        type: FormFieldType.text,
        required: false,
      },
      address_line: {
        title: 'Адрес места жительства',
        type: FormFieldType.dadata,
        dictKey: 'address',
        required: true,
      },
      cert_expiration_date: {
        title: 'Срок действия',
        type: FormFieldType.date,
        required: true,
      },
      activity: {
        title: 'Информация о привлечении инспектора к мероприятиям по контролю',
        type: FormFieldType.text,
        required: false,
      },
      comments: {
        title: 'Примечание',
        type: FormFieldType.text,
        required: false,
      },
    },
    // Общие сведения об
    public_inspector: {
      territory_org_id: {
        type: EFieldType.primitive,
        required: true,
      },
      region_id: {
        type: EFieldType.primitive,
        required: true,
      },
    },
  },

  statement: {
    territory_org_id: {
      type: EFieldType.primitive,
      required: true,
    },
    region_id: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  doer: {
    doer_id: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  examination_object: {
    name: {
      type: EFieldType.primitive,
      required: true,
    },
    address: {
      type: EFieldType.primitive,
      required: true,
    },
    oktmo: {
      type: EFieldType.primitive,
      required: true,
    },
    latitude: {
      type: EFieldType.primitive,
      required: true,
    },
    longitude: {
      type: EFieldType.primitive,
      required: true,
    },
    work_type: {
      type: EFieldType.primitive,
      required: false,
    },
    work_type_id: {
      type: EFieldType.primitive,
      required: true,
    },
    is_oopt: {
      type: EFieldType.primitive,
      required: false,
    },
  },

  public_hearings: {
    date: {
      type: EFieldType.primitive,
      required: true,
    },
    log_attachments: {
      type: EFieldType.array,
      required: true,
    },
    log_attachments_files: {
      type: EFieldType.primitive,
      required: false,
    },
    publication_attachments: {
      type: EFieldType.array,
      required: true,
    },
    publication_attachments_files: {
      type: EFieldType.array,
      required: false,
    },
    name: {
      type: EFieldType.primitive,
      required: true,
    },
    comment: {
      type: EFieldType.primitive,
      required: false,
    },
  },

  documents: {
    name: {
      type: EFieldType.primitive,
      required: true,
    },
    date: {
      type: EFieldType.primitive,
      required: true,
    },
    gee_attachments: {
      type: EFieldType.array,
      required: true,
    },
    gee_attachments_files: {
      type: EFieldType.array,
      required: false,
    },
    comment: {
      type: EFieldType.primitive,
      required: false,
    },
  },

  organisation_info: {
    full_name_organization: {
      type: EFieldType.primitive,
      required: true,
    },
    short_name_organization: {
      type: EFieldType.primitive,
      required: true,
    },
    is_non_resident: {
      type: EFieldType.primitive,
      required: true,
    },
    inn: {
      type: EFieldType.primitive,
      required: true,
    },
    ogrn: {
      type: EFieldType.primitive,
      required: true,
    },
    kpp: {
      type: EFieldType.primitive,
      required: true,
    },
    egrul_egrip_date: {
      type: EFieldType.primitive,
      required: true,
    },
    register_date: {
      type: EFieldType.primitive,
      required: true,
    },
    code_okpo: {
      type: EFieldType.primitive,
      required: false,
    },
    legal_address_oktmo: {
      type: EFieldType.primitive,
      required: true,
    },
    legal_address_post: {
      type: EFieldType.primitive,
      required: true,
    },
    legal_address_city: {
      type: EFieldType.primitive,
      required: true,
    },
    legal_address_street: {
      type: EFieldType.primitive,
      required: true,
    },
    post_address_oktmo: {
      type: EFieldType.primitive,
      required: true,
    },
    post_address_post: {
      type: EFieldType.primitive,
      required: true,
    },
    post_address_city: {
      type: EFieldType.primitive,
      required: true,
    },
    post_address_street: {
      type: EFieldType.primitive,
      required: true,
    },
    bank: {
      type: EFieldType.primitive,
      required: true,
    },
    bik: {
      type: EFieldType.primitive,
      required: true,
    },
    account: {
      type: EFieldType.primitive,
      required: true,
    },
    correspondent_account: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  organisation_contact: {
    ceo_name: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_surname: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_patronymic: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_position: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_details: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_phone: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_email: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_name: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_surname: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_patronymic: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_position: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_details: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_phone: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_email: {
      type: EFieldType.primitive,
      required: true,
    },
    name: {
      type: EFieldType.primitive,
      required: true,
    },
    surname: {
      type: EFieldType.primitive,
      required: true,
    },
    patronymic: {
      type: EFieldType.primitive,
      required: true,
    },
    work_position: {
      type: EFieldType.primitive,
      required: true,
    },
    phone: {
      type: EFieldType.primitive,
      required: true,
    },
    email: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  organisation_add_info: {
    brand: {
      type: EFieldType.primitive,
      required: true,
    },
    tax_info: {
      type: EFieldType.primitive,
      required: true,
    },
    part_of_association: {
      type: EFieldType.primitive,
      required: true,
    },
    is_separate: {
      type: EFieldType.primitive,
      required: true,
    },
    is_producer_of_goods: {
      type: EFieldType.primitive,
      required: true,
    },
    is_importer_of_goods: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  organisation_okveds: {
    okveds: {
      type: EFieldType.array,
      required: true,
    },
  },

  applicant_information: {
    full_name_organization: {
      type: EFieldType.primitive,
      required: true,
    },
    short_name_organization: {
      type: EFieldType.primitive,
      required: true,
    },
    is_non_resident: {
      type: EFieldType.primitive,
      required: true,
    },
    inn: {
      type: EFieldType.primitive,
      required: true,
    },
    ogrn: {
      type: EFieldType.primitive,
      required: true,
    },
    kpp: {
      type: EFieldType.primitive,
      required: true,
    },
    egrul_egrip_date: {
      type: EFieldType.primitive,
      required: true,
    },
    register_date: {
      type: EFieldType.primitive,
      required: true,
    },
    code_okpo: {
      type: EFieldType.primitive,
      required: false,
    },
    legal_address_oktmo: {
      type: EFieldType.primitive,
      required: true,
    },
    legal_address_post: {
      type: EFieldType.primitive,
      required: true,
    },
    legal_address_city: {
      type: EFieldType.primitive,
      required: true,
    },
    legal_address_street: {
      type: EFieldType.primitive,
      required: true,
    },
    post_address_oktmo: {
      type: EFieldType.primitive,
      required: true,
    },
    post_address_post: {
      type: EFieldType.primitive,
      required: true,
    },
    post_address_city: {
      type: EFieldType.primitive,
      required: true,
    },
    post_address_street: {
      type: EFieldType.primitive,
      required: true,
    },
    bank: {
      type: EFieldType.primitive,
      required: true,
    },
    bik: {
      type: EFieldType.primitive,
      required: true,
    },
    account: {
      type: EFieldType.primitive,
      required: true,
    },
    correspondent_account: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_name: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_surname: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_patronymic: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_position: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_details: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_phone: {
      type: EFieldType.primitive,
      required: true,
    },
    ceo_email: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_name: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_surname: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_patronymic: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_position: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_details: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_phone: {
      type: EFieldType.primitive,
      required: true,
    },
    accountant_email: {
      type: EFieldType.primitive,
      required: true,
    },
    name: {
      type: EFieldType.primitive,
      required: true,
    },
    surname: {
      type: EFieldType.primitive,
      required: true,
    },
    patronymic: {
      type: EFieldType.primitive,
      required: true,
    },
    work_position: {
      type: EFieldType.primitive,
      required: true,
    },
    phone: {
      type: EFieldType.primitive,
      required: true,
    },
    email: {
      type: EFieldType.primitive,
      required: true,
    },
    brand: {
      type: EFieldType.primitive,
      required: true,
    },
    tax_info: {
      type: EFieldType.primitive,
      required: true,
    },
    part_of_association: {
      type: EFieldType.primitive,
      required: true,
    },
    is_separate: {
      type: EFieldType.primitive,
      required: true,
    },
    is_producer_of_goods: {
      type: EFieldType.primitive,
      required: true,
    },
    is_importer_of_goods: {
      type: EFieldType.primitive,
      required: true,
    },
    okveds: {
      type: EFieldType.array,
      required: true,
    },
  },

  document_receiving_info: {
    address: {
      type: EFieldType.primitive,
      required: true,
    },
    zip_code: {
      type: EFieldType.primitive,
      required: true,
    },
    is_legal_agent: {
      type: EFieldType.primitive,
      required: true,
    },
    is_personally: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  criterion: {
    criteria: {
      type: EFieldType.object,
      required: true,
    },
  },

  organisation_order: {
    order_date: {
      type: EFieldType.primitive,
      required: false,
    },
    order_number: {
      type: EFieldType.primitive,
      required: false,
    },
  },

  estimate_preparation: {
    expert_cost: {
      type: EFieldType.primitive,
      required: true,
    },
    lead_cost: {
      type: EFieldType.primitive,
      required: true,
    },
    expert_count: {
      type: EFieldType.primitive,
      required: true,
    },
    estimate_attachment: {
      type: EFieldType.primitive,
      required: true,
    },
    invoice_attachment: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  commission_members: {
    doer_id: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  commission_meetings: {
    date: {
      type: EFieldType.primitive,
      required: true,
    },
    place: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  member_questions: {
    member_id: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  commission_documents: {
    name: {
      type: EFieldType.primitive,
      required: true,
    },
    date: {
      type: EFieldType.primitive,
      required: true,
    },
  },

  renewal_orders: {
    consideration_period: {
      type: EFieldType.primitive,
      required: false,
    },
    order_date: {
      type: EFieldType.primitive,
      required: false,
    },
    order_number: {
      type: EFieldType.primitive,
      required: false,
    },
  },

  conclusion: {
    conclusion: {
      type: EFieldType.primitive,
      required: false,
    },
    approval_date: {
      type: EFieldType.primitive,
      required: false,
    },
    notification_requisites: {
      type: EFieldType.primitive,
      required: false,
    },
    approval_order_date: {
      type: EFieldType.primitive,
      required: false,
    },
    approval_order_number: {
      type: EFieldType.primitive,
      required: false,
    },
    scan_attachment: {
      type: EFieldType.primitive,
      required: false,
    },
    scan_attachment_file: {
      type: EFieldType.primitive,
      required: false,
    },
  },
};

export enum EDocType {
  primary = 1,
  corrected = 2,
}

export enum EDocTypeForFilter {
  primary = '1',
  corrected = '2',
}
