export const getStatuses = (dict: any[], state_service_ids: number[], getKey: (status) => string, excludeStatuses: string[] = []) => {
  const statuses = {};

  for (const status of dict) {
    if (state_service_ids.indexOf(status.state_service_id) === -1) continue;
    if (excludeStatuses.includes(status.name.toLowerCase())) continue;

    const key = getKey(status);

    if (statuses[key]) {
      statuses[key].ids.push(status.id);
      statuses[key].codes[status.code] = true;
    } else {
      statuses[key] = {
        name: status.name,
        ids: [status.id],
        codes: { [status.code]: true },
      };
    }
  }

  return Object.keys(statuses).map((key) => {
    return {
      id: statuses[key].ids.join(','),
      codes: statuses[key].codes,
      name: statuses[key].name,
    };
  });
};
