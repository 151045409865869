import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { TableType } from 'src/app/providers/_enum';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ModalConfirmComponent {
  type: TableType;
  id: string;
  body: any;
  closeModal: Subject<{ type: TableType; id: string; body: any } | null>;
  title: string;
  text: string;
  confirm: string;
  isHtml = false;

  close(type?: TableType) {
    const { id, body } = this;
    this.closeModal.next(type ? { type, id, body } : null);
  }
}
