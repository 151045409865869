import { NgIf, NgSwitch, NgSwitchCase, NgClass, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TooltipModule, CardsModule, MdProgressSpinnerModule, ButtonsModule } from 'ng-uikit-pro-standard';
import { of, Subscription, Observable, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AppIconComponent } from 'src/app/modules/app-icon/app-icon.component';
import { IconsSet } from 'src/app/modules/app-icon/icons';
import { EnvelopeSpinnerDirective } from 'src/app/providers/_directives/spinner/spinner.directive';
import { ETypeOfModal } from 'src/app/providers/_enum';
import { EDownloadBlobFileType } from 'src/app/providers/_interfaces/download.interface';
import { ICert } from 'src/app/providers/_interfaces/esign.interface';
import { IResultAttachmentFile } from 'src/app/providers/_interfaces/print.response.interface';
import { SafePipe } from 'src/app/providers/_pipes/safe-sanitizer.pipe';
import { TrimPipe } from 'src/app/providers/_pipes/trim.pipe';
import { SignatureService } from 'src/app/providers/_services/signature.service';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';


@Component({
  selector: 'app-modal-attachment-sign-process',
  templateUrl: './modal-attachment-sign-process.component.html',
  styleUrls: ['./modal-attachment-sign-process.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    AppIconComponent,
    NgSwitch,
    NgSwitchCase,
    NgClass,
    EnvelopeSpinnerDirective,
    TooltipModule,
    CardsModule,
    NgFor,
    MdProgressSpinnerModule,
    ButtonsModule,
    AsyncPipe,
    DatePipe,
    TrimPipe,
    SafePipe
  ],
})
export class ModalAttachmentSignProcessComponent implements OnInit, OnDestroy {
  heading: string;
  content: any;
  statusId: string;
  IconsSet = IconsSet;
  step = 1;
  acceptBox = false;
  certificates: { value: ICert; error: string }[] = [];
  select: string;
  loading = true;
  subscribtions: Subscription[] = [];
  printSlug: string;
  idForPrint: number;
  preview$: Observable<any>;

  downloadedFile$: Observable<any>;
  downloadResponse: {
    blob: Blob;
    blobUrl: string;
  };
  fileToSign: IResultAttachmentFile;

  signProgressPercent$: BehaviorSubject<number> = new BehaviorSubject(0);

  private readonly _pdfLink$ = new BehaviorSubject<string>('');
  readonly pdfLink$ = this._pdfLink$.asObservable();

  constructor(
    public signatureService: SignatureService,
    private svcRestService: SvcRestService,
  ) {}

  ngOnDestroy() {
    this.signatureService.removeProgressListener(this.signProgressPercent$);
    this.subscribtions.forEach((sub) => sub?.unsubscribe());
    if (this.downloadResponse.blobUrl) URL.revokeObjectURL(this.downloadResponse.blobUrl);
  }

  ngOnInit() {
    const { fileToSign, step }: { fileToSign: IResultAttachmentFile, step: ETypeOfModal } = this.content;
    this.fileToSign = fileToSign;
    this.step = step || ETypeOfModal.sign;
    this.loading = true;

    if (this.fileToSign?.has_pdf && this.fileToSign?.pdf_link) {
      this.subscribtions.push(
        this.svcRestService.downloadPreviewBlob(this.fileToSign?.pdf_link, EDownloadBlobFileType['pdf']).pipe(
          tap(res => this._pdfLink$.next(res?.blobUrl || '')),
        ).subscribe(),
      );
    }

    this.downloadedFile$ = this.svcRestService.downloadPreviewBlob(
      fileToSign.download_link,
      EDownloadBlobFileType[fileToSign.extension]
    ).pipe(
      tap((res) => {
        this.loading = false;
        this.downloadResponse = res;
      }),
    );

    if (this.step !== ETypeOfModal.view) {
      this.signatureService.selectCertificate = null;
      this.signatureService.addProgressListener(this.signProgressPercent$);
      this.signatureService
        .fetchCertList()
        .then((res: { value: ICert; error: string }[]) => (this.certificates = res))
        .catch((err) => console.log(err));
    }
  }

  public close(result: any): void {
    const { closeModal } = this.content;

    closeModal.next(result);
  }

  public selectCert(cert: { value: ICert; error: string }): void {
    if (!cert.error) {
      this.select = cert.value.Thumbprint || '';
      this.signatureService.selectCertificate = cert.value;
    } else {
      alert(cert.error);
    }
  }

  public sentSignedResponse(blob: Blob): void {
    this.step = 3;
    const body = { needSign: true, data: blob };

    this.subscribtions.push(
      this.svcRestService
        .sentSignedAttachment(this.fileToSign.id, this.fileToSign.md5, body)
        .pipe(
          catchError((signRes) =>
            of({
              ...signRes,
              ...{ error: signRes.error || { header: signRes.title || '', type: signRes.message || '' } },
            }),
          ),
          switchMap((signRes) => (signRes && signRes.error ? of(signRes) : of({ result: signRes }))),
        )
        .subscribe((signRes) => this.close(signRes)),
    );
  }
}
