import { IPagination } from 'src/app/providers/_interfaces/pagination.interface';

export interface IMessageSrc {
  id: number;
  reply_user_message_id?: number;
  rpn_service_request_id?: number;
  user_id: number;
  to_user_id: number;
  subject: string;
  message: string;
  message_attachments?: any[];
  read_at?: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export class Message {
  id: number;
  subject: string;
  message: string;
  createdAt: number;
  deletedAt: number;
  readAt: number;

  isExpanded: boolean;

  static buildFromSrc(src: IMessageSrc): Message {
    const t = new this();
    t.id = src.id;
    t.subject = src.subject;
    t.message = src.message;
    t.createdAt = +new Date(src.created_at);
    t.deletedAt = +new Date(src.deleted_at);
    t.readAt = src.read_at === null ? null : +new Date(src.read_at);
    return t;
  }

  static buildListFromSrc(listSrc: IMessageSrc[]): Message[] {
    return listSrc.map((src) => Message.buildFromSrc(src));
  }

  static buildPaginationFromSrc(paginationSrc: IPagination<IMessageSrc>): IPagination<Message> {
    return {
      ...paginationSrc,
      data: paginationSrc.data.map((src) => Message.buildFromSrc(src)),
    };
  }

  toggleIsExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
