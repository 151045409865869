import { IWasteDisposalFacility } from 'src/app/modules/details-edit-mode/onv-requests/sections/negative-impact-info/waste-disposal-facilities/waste-disposal-facility.interface';
import { IDischargeSource } from 'src/app/providers/_interfaces/discharge-source.interface';
import { IStationarySource } from 'src/app/providers/_interfaces/stationar-source.interface';

export enum AmsState {
  'OK' = 'OK',
  'ERROR' = 'ERROR',
  'MAINTENANCE' = 'MAINTENANCE',
  'UNKNOWN' = 'UNKNOWN',
}

export enum ElectronicSealState {
  'OK' = 'OK',
  'ERROR' = 'ERROR',
  'MAINTENANCE' = 'MAINTENANCE',
  'UNKNOWN' = 'UNKNOWN',
}

export interface IMonitoringSource {
  parameters: {
    [key: string]: {
      enabled: boolean;
      is_approved: boolean;
      parameter: {
        code: string;
        name: string;
        pollutant: {
          code: string;
          id: number;
          name: string;
        };
      };
    };
  };
  source: IStationarySource | IWasteDisposalFacility | IDischargeSource;
}

export interface CertificateDevice {
  id:                   number;
  source_uuid:          string;
  device_uuid:          string;
  operating_mode:       string;
  operating_start_date: null;
  created_at:           string;
  updated_at:           string;
  deleted_at:           null;
  device_pollutants:    DevicePollutant[];
  comment:              string;
  attachments:          any[];
  attachments_files: any[];
}

export interface DevicePollutant {
  id:                  number;
  device_id:           number;
  code:                string;
  is_active:           boolean;
  power:               null;
  power_ker:           null;
  signature:           null;
  signature_timestamp: null;
  created_at:          string;
  updated_at:          string;
  deleted_at:          null;
}

export interface IMonitoringCertificate {
  devices: CertificateDevice[];
  ams_state: AmsState;
  electronic_seal_state: ElectronicSealState;
  last_received_at: string;
  name: string;
  device_uuid: string;
  certificate_serial: string;
  onv_number: string;
  sources: { [key: string]: IMonitoringSource };
}

export interface IMonitoringSourceConfigRequest {
  [key: string]: any;
}

export interface IMonitoringDeviceConfigRequest {
  name: string;
}

export interface IMonitoringPermission {
  is_permitted?: boolean;
  is_request_received?: boolean;
}
