import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OnvosEffects } from 'src/app/store/onvos/effects';
import * as OnvosReducer from 'src/app/store/onvos/reducer';
import { featureName } from 'src/app/store/onvos/selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(featureName, OnvosReducer.onvosReducer),
    EffectsModule.forFeature([OnvosEffects])
  ],
})
export class OnvosStoreModule {}
