<ng-template #textarea>

  <textarea mdbInput
    *ngIf="!hasAutocomplete"
    [mdbTooltip]="hoverTooltip"
    [class.form-control--filled]="filled"
    [formControl]="likeFormControl(control)"
    [attr.id]="id"
    [required]="required"
    [placeholder]="placeholder"
    type="text"
    [readonly]="isReadonly"
    [rows]="rowsCount"
    [ngClass]="[
      'b3-test_' + formControlName,
      'md-textarea md-textarea-auto form-control',
      useNewStylesForPlaceholder ? 'blue-gray-placeholder' : ''
    ]"
    [ngStyle]="inputStyle()"
  ></textarea>
  <ng-container *ngIf="hasAutocomplete">
    <textarea mdbInput
      [mdbTooltip]="hoverTooltip"
      [class.form-control--filled]="filled"
      [formControl]="likeFormControl(control)"
      [attr.id]="id"
      [required]="required"
      [placeholder]="placeholder"
      type="text"
      [readonly]="isReadonly"
      [rows]="rowsCount"
      [ngClass]="['b3-test_' + formControlName, 'md-textarea md-textarea-auto completer-input mdb-autocomplete form-control']"
      autocomplete="off"
      [mdbAutoCompleter]="auto"
      (click)="auto.show()"
      (blur)="onBlur()"
    ></textarea>

    <mdb-auto-completer
      #auto="mdbAutoCompleter"
      [appendToBody]="false"
      [clearButton]="false"
      (selected)="selectSuggestion($event)"
    >

      <mdb-option *ngFor="let sValue of autocompleteStaticValues" [value]="{value: sValue}">
        <div class="text-left rpn-autocomplete__option">
          {{ sValue }}
        </div>
      </mdb-option>

      <mdb-option *ngFor="let suggestion of suggestions" [value]="suggestion">
        <div class="text-left rpn-autocomplete__option">
          {{ suggestion.value }}
        </div>
      </mdb-option>
    </mdb-auto-completer>
  </ng-container>
  <label *ngIf="labelTop" [for]="id" [ngStyle]="labelStyle()"  [mdbTooltip]="labelTop" [ngClass]="[labelCls]">
    {{labelTop}}
    <span *ngIf="tooltip">
      <mdb-icon far icon="question-circle" class="mx-2" [mdbTooltip]="tooltip" #tooltipDirective="mdb-tooltip"></mdb-icon>
    </span>
    <ng-container *ngIf="diff$ | async as diff">
      <div *ngIf="diff">
        <ng-template #diffTemplate>
          <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(diff)"></div>
        </ng-template>

        <mdb-icon fas icon="edit" [mdbTooltip]="diffTemplate" #tooltipDirective="mdb-tooltip" class="red-text mx-1"></mdb-icon>
      </div>
    </ng-container>
  </label>
</ng-template>

<ng-template #input>
  <label *ngIf="labelTop" [for]="id" class="label-top" [mdbTooltip]="labelTop" [ngClass]="[labelCls]">
    {{labelTop}}
    <span *ngIf="tooltip">
      <mdb-icon far icon="question-circle" class="mx-2" [mdbTooltip]="tooltip" #tooltipDirective="mdb-tooltip"></mdb-icon>
    </span>
    <ng-container *ngIf="diff$ | async as diff">
      <span *ngIf="diff">
        <ng-template #diffTemplate>
          <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(diff)"></div>
        </ng-template>

        <mdb-icon fas icon="edit" [mdbTooltip]="diffTemplate" #tooltipDirective="mdb-tooltip" class="red-text mx-1"></mdb-icon>
      </span>
    </ng-container>
  </label>
  <ng-container *ngIf="maskString && formControl && formControl.enabled && control.enabled; else withoutMaskTemplate">
    <input mdbInput
      #inputControl
      [mdbTooltip]="hoverTooltip"
      [formControl]="likeFormControl(control)"
      [class.form-control--filled]="filled"
      [attr.id]="id"
      [required]="required"
      [placeholder]="placeholder"
      [readonly]="isReadonly"
      [ngClass]="['b3-test_' + formControlName, 'form-control']"
      [type]="EInputType.text"
      [showMaskTyped]="showMask"
      [attr.b3-test]="1"
      [mask]="maskString"
      [patterns]="maskPatterns"
      [specialCharacters]="specialCharacters"
      (blur)="onBlur()"
      (input)="onInput($event)"
      [dropSpecialCharacters]="!(specialCharacters && specialCharacters.length)">
  </ng-container>

  <ng-template #withoutMaskTemplate>
    <ng-container *ngIf="type !== EInputType.text && type !== EInputType.phone_strict && type !== EInputType.mineral_license && type !== EInputType.rafp_number; else textTemplate">
      <ng-container *ngIf="!hasAutocomplete">
      <input mdbInput
        #inputControl
        (onmousewheel)="onMouseWheel()"
        pattern="\d*"
        [class.form-control--filled]="filled"
        [mdbTooltip]="hoverTooltip"
        [formControl]="likeFormControl(control)"
        [attr.id]="id"
        [type]="EInputType.number" onwheel="this.blur()"
        [placeholder]="placeholder"
        [readonly]="isReadonly"
        [ngClass]="['b3-test_' + formControlName, 'form-control']"
        [min]="minValue"
        [max]="maxValue"
        (blur)="onBlur()"
        (input)="onInput($event)">
      <small class="input-text grey-text my-auto" *ngIf="type === EInputType.percentage"> % </small>
      <small *ngIf="type === EInputType?.currency && inThousands" class="input-text grey-text my-auto">
        <ng-container *ngIf="!!inputControl.value">({{(+inputControl.value) / 1000 | numberFormat:'1.0-5':','}} <span>тыс.руб.</span>)</ng-container>
      </small>
      </ng-container>

      <ng-container *ngIf="hasAutocomplete">
        <input mdbInput
           #inputControl
           (onmousewheel)="onMouseWheel()"
           pattern="\d*"
           [class.form-control--filled]="filled"
           [mdbTooltip]="hoverTooltip"
           [formControl]="likeFormControl(control)"
           [attr.id]="id"
           [type]="EInputType.number"  onwheel="this.blur()"
           [required]="required"
           [placeholder]="placeholder"
           [readonly]="isReadonly"
           [ngClass]="['b3-test_' + formControlName, 'form-control']"
           [min]="minValue"
           [max]="maxValue"
           autocomplete="off"
           [mdbAutoCompleter]="auto"
           (click)="auto.show()"
           (blur)="onBlur()"
           (input)="onInput($event)">

          <mdb-auto-completer
            #auto="mdbAutoCompleter"
            [appendToBody]="false"
            [clearButton]="false"
            (selected)="selectSuggestion($event)"
          >

            <mdb-option *ngFor="let sValue of autocompleteStaticValues" [value]="{value: sValue}">
              <div class="text-left rpn-autocomplete__option">
                {{ sValue }}
              </div>
            </mdb-option>
          </mdb-auto-completer>

        <small class="input-text grey-text my-auto" *ngIf="type === EInputType.percentage"> % </small>
        <small *ngIf="type === EInputType?.currency && inThousands" class="input-text grey-text my-auto">
          <ng-container *ngIf="!!inputControl.value">({{(+inputControl.value) / 1000 | numberFormat:'1.0-5':','}} <span>тыс.руб.</span>)</ng-container>
        </small>
      </ng-container>
    </ng-container>

    <ng-template #textTemplate>
      <ng-container *ngIf="disabledAsUrl ? !(control.disabled || isDisabled) || !control.value : true">
        <input mdbInput
          *ngIf="!hasAutocomplete"
          #inputControl
          [mdbTooltip]="hoverTooltip"
          [pattern]="type === EInputType.number ? '\d*' : ''"
          [type]="type !== EInputType.text && type !== EInputType.phone_strict && type !== EInputType.mineral_license && type !== EInputType.rafp_number ? EInputType.number : EInputType.text "
          [formControl]="likeFormControl(control)"
          [attr.id]="id"
          [required]="required"
          [class.form-control--filled]="filled"
          [placeholder]="(type === EInputType.phone_strict || type === EInputType.mineral_license) ? '' : placeholder"
          class="form-control b3-test_{{formControlName}}"
          (keydown)="onKeydown($event)"
          (blur)="onBlur()"
          (input)="onInput($event)"
          [readonly]="isReadonly"
        >
      </ng-container>
      <ng-container *ngIf="disabledAsUrl ? (control.disabled || isDisabled) && control.value : false">
        <a class="border-0 form-control green-text compact-text"
          target="_blank"
          [attr.href]="control.value">{{control.value}}</a>
      </ng-container>
      <ng-container *ngIf="hasAutocomplete">
        <input mdbInput
          #inputControl
          [mdbTooltip]="hoverTooltip"
          [pattern]="type === EInputType.number ? '\d*' : ''"
          [type]="type !== EInputType.text && type !== EInputType.phone_strict && type !== EInputType.mineral_license && type !== EInputType.rafp_number ? EInputType.number : EInputType.text "
          [formControl]="likeFormControl(control)"
          [attr.id]="id"
          [required]="required"
          [class.form-control--filled]="filled"
          [placeholder]="placeholder"
          autocomplete="off"
          class="completer-input form-control mdb-autocomplete mb-0 b3-test_{{formControlName}}"
          [mdbAutoCompleter]="auto"
          (blur)="onBlur()"
          (input)="onInput($event)"
          [readonly]="isReadonly"
          (click)="auto.show()"
        >

        <mdb-auto-completer
          #auto="mdbAutoCompleter"
          [appendToBody]="false"
          [clearButton]="false"
          (selected)="selectSuggestion($event)"
        >

          <mdb-option *ngFor="let sValue of autocompleteStaticValues" [value]="{value: sValue}">
            <div class="text-left rpn-autocomplete__option">
              {{ sValue }}
            </div>
          </mdb-option>

          <mdb-option *ngFor="let suggestion of suggestions" [value]="suggestion">
            <div class="text-left rpn-autocomplete__option">
              {{ suggestion.value }}
            </div>
          </mdb-option>
        </mdb-auto-completer>
      </ng-container>
    </ng-template>

  </ng-template>

</ng-template>

<form class="row position-relative"
  (mouseover)="focusEvent$.next(true)"
  (mouseout)="focusEvent$.next(false)">
  <label
    [ngClass]="[
      labelSize ? 'col-sm-' + labelSize : 'col-sm-12',
      control.disabled || isDisabled || isReadonly || !isLoaded ? 'label_disabled' : '',
      'col-form-label',
      'position-relative',
      labelCls
    ]"
    [attr.for]="id" *ngIf="label">
    {{label}}

    <span *ngIf="tooltip">
      <mdb-icon far icon="question-circle" [mdbTooltip]="tooltipTemplate" #tooltipDirective="mdb-tooltip" class="mx-1"></mdb-icon>
      <ng-template #tooltipTemplate>
        <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(tooltip)"></div>
      </ng-template>
    </span>

    <div class="position-absolute" style="top:5px; right: -10px;">
      <ng-container *ngIf="diff$ | async as diff">
        <div *ngIf="diff">
          <ng-template #diffTemplate>
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(diff)"></div>
          </ng-template>

          <mdb-icon fas icon="edit" [mdbTooltip]="diffTemplate" #tooltipDirective="mdb-tooltip" class="red-text mx-1"></mdb-icon>
        </div>
      </ng-container>
    </div>
  </label>
  <div [ngClass]="[label ? 'col-sm-' + size : 'col-sm-12', 'position-relative']">
    <form class="input-group md-form mt-0 mb-0 position-relative"
      [ngClass]="{
        'rpn-autocomplete': hasAutocomplete,
        'phone-strict--empty': type === EInputType.phone_strict && !control.value
      }"
      (appClickOutside)="clickOutside()"
      [mdbTooltip]="control.valueChanges | async">

      <ng-container *ngIf="isLoaded" [ngSwitch]="category">
        <ng-container *ngSwitchCase="InputCategory.input" [ngTemplateOutlet]="input"></ng-container>
        <ng-container *ngSwitchCase="InputCategory.textarea" [ngTemplateOutlet]="textarea"></ng-container>
      </ng-container>

      <app-template-button
        *ngIf="templateTags?.groupTag && templateTags?.formTag &&
          formControlName &&
          formControl!.enabled && control!.enabled &&
          isLoaded"
        [currentValue]="{value: formControl.value, caption: control.value}"
        [isHidded]="formControl.disabled && control.disabled && !(isLoaded)"
        [groupTag]="templateTags.groupTag"
        [formTag]="templateTags.formTag"
        [fieldTag]="formControlName"
        [fieldLabel]="label"
        (selectItemValueEmit)="selectItemFromTemplate($event)">
      </app-template-button>

      <div *ngIf="!isLoaded" class="rpn-skeleton form-control">&nbsp;</div>

      <ng-container [ngSwitch]="append">
        <ng-container *ngSwitchCase="InputAppend.price">
          <div class="input-group-append">
            <span class="input-group-text">руб.</span>
          </div>
        </ng-container>
      </ng-container>

      <ng-content></ng-content>

    </form>

    <div class="mb-2rem">
      <app-form-field-validation
        *ngIf="!isFilter && formControl && formControl.enabled && control.enabled && isLoaded"
        [errors]="formControl.errors"
        [dirty]="formControl.dirty"
        [notSavedDataMessage]="notSavedDataMessage"
        [valid]="formControl.valid"
        [value]="formControl.value"
        [useNewStylesForError]="useNewStylesForError"
      />
    </div>

    <ng-container *ngIf="{isVisible: isTooltipeVisible$ | async, scopeRepo: auth.scopeRepo$ | async, hint: hint$ | async} as state">
      <div *ngIf="state?.hint && state.isVisible && formControl.enabled && control.enabled && isLoaded
        && !!(state?.scopeRepo?.admin?.isActive || state?.scopeRepo?.admin_menu?.isActive)"
        class="tooltip__helper"
        (mouseover)="focusEvent$.next(true)"
        (mouseout)="focusEvent$.next(false)">
        <div
          [mdbTooltip]="state?.hint || 'Ручной ввод'"
          [placement]="'left'"
          [container]="'body'"
          #tooltipDirective="mdb-tooltip" class="mx-1"
          (click)="openB3Chat(state?.hint)"
          [@inOutAnimation]
          class="rpn-cursor-pointer">
          <img src="/assets/svgs/b3-chat.svg" width="70">
        </div>
      </div>
    </ng-container>

  </div>

</form>

