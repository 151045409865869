<!-- HEADER -->
<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="close(null)">
    <app-icon [name]="IconsSet.closeModal" width="11px" height="11px"></app-icon>
  </button>
  <h4 class="modal-title">
    {{content.headerTitle}}
  </h4>
</div>
<!-- /HEADER -->

<!-- BODY -->
<div class="modal-body" style="overflow-y: auto">
  <div class="modal-wrapper">
    <ng-container *ngIf="content.bodyText"><div [innerHTML]="content.bodyText | safe:'HTML'"></div></ng-container>
    <ng-container *ngIf="content.bodyTemplate">
      <ng-container *ngTemplateOutlet="content.bodyTemplate"></ng-container>
    </ng-container>
  </div>
</div>
<!-- /BODY -->

<!-- FOOTER -->
<div class="modal-footer justify-content-center">
  <ng-container *ngIf="content.footerTemplate; else closeButton">
    <ng-container *ngTemplateOutlet="content.footerTemplate; context: { content: content, contextData: contextData }"></ng-container>
  </ng-container>
  <ng-template #closeButton>
    <button class="rpn-button rpn-button_md rpn-button_bordered-dark rpn-button_close" mdbBtn type="button"
            color="default" (click)="close(null)">
      {{ closeButtonLabel }}
    </button>
  </ng-template>
</div>
<!-- /FOOTER -->

