export enum EDownloadBlobFileType {
  pdf = 'application/pdf',
  html = 'text/html',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc = 'application/msword',
  xml = 'application/xml',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip = 'application/zip',
  '7z' = 'application/x-7z-compressed',
}

export enum EFileType {
  'application/pdf' = 'pdf',
  'text/html' = 'html',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'docx',
  'application/msword' = 'doc',
  'application/xml' = 'xml',
  'application/vnd.ms-excel' = 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'xlsx',
  'application/zip' = 'zip',
  'application/x-7z-compressed' = '7z',
}
