<div class="row position-relative"
  (mouseover)="focusEvent$.next(true)"
  (mouseout)="focusEvent$.next(false)"
  *ngIf="state$ | async as state">
  <label
    [ngClass]="[
      'col-form-label position-relative',
      labelSize ? 'col-sm-' + labelSize : 'col-sm-12',
      control.disabled || isDisabled || isReadonly || !isLoaded ? 'label_disabled' : '',
      labelCls
    ]"
    [attr.for]="id" *ngIf="label"
  >
    {{label}}

    <ng-container *ngIf="tooltip">
      <mdb-icon far icon="question-circle" [mdbTooltip]="tooltip" #tooltipDirective="mdb-tooltip" class="mx-1"></mdb-icon>
    </ng-container>

    <div *ngIf="showSignedBadge && (atLeastOneSigned$ | async) === true">
      <mdb-badge class="rpn-badge rpn-badge_sign">
        <span>ЭП</span>
      </mdb-badge>
    </div>

    <div class="position-absolute" style="top:5px; right: -10px;">
      <ng-container *ngIf="diff$ | async as diff">
        <div *ngIf="diff">
          <ng-template #diffTemplate>
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(diff)"></div>
          </ng-template>
          <mdb-icon fas icon="edit" [mdbTooltip]="diffTemplate" #tooltipDirective="mdb-tooltip" class="red-text mx-1"></mdb-icon>
        </div>
      </ng-container>
    </div>

  </label>


  <form [ngClass]="['file-field flex-wrap flex-column justify-content-center', label ? 'col-sm-' + size : 'col-sm-12']">

    <ng-container *ngIf="state.completed">
      <ng-container *ngFor="let file of state.completed">
        <div [ngClass]="['d-flex position-relative', control.disabled ? '' :'mb-3']">
          <div *ngIf="!isLoaded" class="rpn-skeleton rpn-upload-file-filler h-auto position-absolute" style="min-height: 44px;">&nbsp;</div>

          <div class="file-path-wrapper flex-fill pl-0">
            <input class="file-path form-control" [ngClass]="[control.disabled ? '' : 'rpn-file-text-upload']" readonly type="text"
              [placeholder]="getFilePlaceholder(file)"/>
          </div>

          <ng-container *ngIf="isLoaded">
            <div class="upload-file__bar">
              <ng-container *ngIf="!massUploadFile">
                <a class="rpn-button rpn-button_sm rpn-button_icon rpn-button_square"
                  *ngIf="showDownloadButton"
                  [href]="file?.response?.download_link">
                  <app-icon #icon [name]="icon.IconsSet.upload" width="17px" height="19px" ></app-icon>
                </a>
                <a class="rpn-button rpn-button_sm rpn-button_icon rpn-button_square"
                  *ngIf="file?.response?.pdf_link">
                  <app-icon
                    (click)="openModal(file?.response, ETypeOfModal.view)"
                    #icon
                    [data]="{'1': '#4A9D86'}"
                    [name]="icon.IconsSet.dataView"
                    width="19px"
                    height="19px" ></app-icon>
                </a>
              </ng-container>
              <button class="rpn-button rpn-button_sm rpn-button_icon rpn-button_square rpn-button_transparent"
                type="button"
                (click)="removeFile(file)"
                *ngIf="!control.disabled">
                <app-icon #icon [name]="icon.IconsSet.iconTrash" width="15px" height="20px" ></app-icon>
              </button>
            </div>
          </ng-container>
        </div>

        <div *ngIf="isLoaded && showPreview" class="rpn-pointer" (click)="openModalPreview(file?.response?.download_link)">
          <img [src]="file?.response?.download_link" alt="file_preview" style="max-height: 150px; max-width: 150px;">
        </div>

        <ng-container *ngIf="showSigns && isLoaded">
          <ng-container *ngIf="has(file, 'response.signs_count'); else justUploaded">
            <ng-container *ngIf="file?.response?.signs_count === 0">
              <div class="d-flex" *ngIf="!control.disabled && canSign">
                <button mdbBtn type="button" [size]="'sm'" (click)="openModal(file?.response)">Подписать ЭП</button>
              </div>
            </ng-container>
            <ng-container *ngIf="file?.response?.signs_count > 0">
              <div [ngClass]="['d-flex', control.disabled ? 'mb-3' : '']">
                <a mdbBtn type="button" class="btn" [size]="'sm'" (click)="downloadAttachSign(file?.response)">
                  Скачать подпись
                  <mdb-icon fas icon="file-signature" class="ml-3"></mdb-icon>
                </a>
                <a href="https://www.gosuslugi.ru/pgu/eds" target="_blank" rel="noopener noreferrer"
                  class="my-auto mx-2 px-2"
                  mdbTooltip="Скачанный файл и подпись можно проверить на странице https://www.gosuslugi.ru/pgu/eds'"
                  #tooltipDirective="mdb-tooltip">
                  <mdb-icon far icon="question-circle"></mdb-icon>
                </a>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #justUploaded>
            <div class="d-flex" *ngIf="(!control.disabled || isEnabledSignButton) && canSign">
              <button mdbBtn type="button" [size]="'sm'" (click)="openModal(file?.response)">Подписать ЭП</button>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="d-flex">
      <ng-container *ngIf="state.progress">
        <ng-container *ngFor="let file of state.progress">
          <div *ngIf="file?.progress !== 100" class="file-path-wrapper pl-0 mb-2" style="width: 80px;">
            <input class="file-path form-control" readonly type="text" [placeholder]="file?.progress + '%'">
          </div>
        </ng-container>
      </ng-container>


      <div class="md-form input-group rpn-form__input-file mt-0" *ngIf="!(isSingleFile && state.completed.length); else emptyDivTemplate">

        <div *ngIf="!isLoaded" class="rpn-skeleton form-control position-absolute h-100"  style="min-height: 44px;">&nbsp;</div>

        <a
          *ngIf="!control.disabled && isLoaded; else emptyTemplate"
          class="waves-light justify-content-center py-2"
          [ngClass]="{'cursor-not-allowed': !canUploadByClick}"
        >
          <ng-container *ngIf="state?.completed">
            <ng-template #defUploadButtonTemplate let-text>
              <span
                class="rpn-text-upload"
                [ngClass]="{'cursor-not-allowed': !canUploadByClick}"
                (click)="canUploadByClick && file.el.click()"
              >
                <ng-container *ngIf="canUploadByClick">
                  {{ text }}
                  <mdb-icon fas icon="file-upload" class="pl-1"></mdb-icon>
                </ng-container>
              </span>
            </ng-template>

            <ng-template #uploadButtonTemplate let-text>
              <ng-container *ngIf="!altButtonForUpload">
                <ng-container *ngTemplateOutlet="defUploadButtonTemplate; context: {$implicit: text}">
                </ng-container>
              </ng-container>

              <button type="button"
                *ngIf="altButtonForUpload"
                class="rpn-button rpn-button_primary rpn-button_xs"
                (click)="file.el.click()">{{text}}</button>
            </ng-template>

            <ng-container *ngIf="!state.completed.length">
              <ng-container *ngTemplateOutlet="uploadButtonTemplate; context: {$implicit: uploadFileText}">
              </ng-container>
            </ng-container>

            <ng-container *ngIf="state.completed.length && !isSingleFile">
              <ng-container *ngTemplateOutlet="uploadButtonTemplate; context: {$implicit: 'ещё файл'}">
              </ng-container>
            </ng-container>
          </ng-container>
          <input type="file" [attr.accept]="accept" #file mdbFileSelect (uploadOutput)="uploadFile($event)" [required]="required" style="width: 0;">
        </a>

        <ng-template #emptyTemplate>
          <input mdbInput *ngIf="!state.completed.length;" class="b3-test_empty_file form-control" disabled/>
          <div class="mb-3"></div>
        </ng-template>

        <app-form-field-validation
          *ngIf="!isFilter && formControl && formControl.enabled && control.enabled && isLoaded"
          [class.top-40]="!control.disabled && state?.completed && (!state.completed.length || state.completed.length && !isSingleFile)"
          [errors]="errors"
          [dirty]="formControl.dirty"
          [notSavedDataMessage]="notSavedDataMessage"
          [valid]="valid"
          [value]="formControl.value">
        </app-form-field-validation>
      </div>
    </div>

    <ng-template #emptyDivTemplate>
      <div class="mb-3"></div>
    </ng-template>

    <ng-container *ngIf="{isVisible: isTooltipeVisible$ | async, scopeRepo: auth.scopeRepo$ | async, hint: hint$ | async} as state">
      <div *ngIf="state?.hint && state.isVisible && formControl.enabled && control.enabled && isLoaded
        && !!(state?.scopeRepo?.admin?.isActive || state?.scopeRepo?.admin_menu?.isActive)"
        class="tooltip__helper"
        (mouseover)="focusEvent$.next(true)"
        (mouseout)="focusEvent$.next(false)">
        <div
          [mdbTooltip]="state?.hint || 'Загрузка файлов'"
          [placement]="'left'"
          [container]="'body'"
          #tooltipDirective="mdb-tooltip" class="mx-1"
          (click)="openB3Chat(state?.hint)"
          [@inOutAnimation]
          class="rpn-cursor-pointer">
          <img src="/assets/svgs/b3-chat.svg" width="70">
        </div>
      </div>
    </ng-container>

  </form>
</div>

<div mdbModal #previewModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">

  <div  class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="previewModal.hide()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body rpn-img_container">
        <img [src]="photoUrl" alt="file_preview" >
      </div>
      <div class="modal-footer">
        <button type="button"class="rpn-button rpn-button_md rpn-button_bordered-dark"
                (click)="previewModal.hide()">Закрыть</button>
      </div>
    </div>
  </div>
</div>
