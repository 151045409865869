import { Feature, LineString, Point, Polygon, MultiLineString } from 'geojson';
import { GeoJSONOptions, LatLngBounds } from 'leaflet';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { BaseSection } from 'src/app/modules/details-edit-mode/base-section/base-section.class';
import { IWasteDisposalFacility } from 'src/app/modules/details-edit-mode/onv-requests/sections/negative-impact-info/waste-disposal-facilities/waste-disposal-facility.interface';
import { MapObjectTypes } from 'src/app/modules/map.component/map.const';
import { OnvosRequestType } from 'src/app/providers/_enum';
import { IDischargeSource } from 'src/app/providers/_interfaces/discharge-source.interface';
import { IStationarySource } from 'src/app/providers/_interfaces/stationar-source.interface';
import { ICenterLngLat } from 'src/app/providers/_utils/center-lng-lat';


interface GeoProperties {
  RegistryCode: string;
  Address: string;
  Type: string;
  Name: string;
}

export interface MapItemPoint {
  centroid: Point;
  id?: number;
  geometry?: SupportedGeometry;
  properties?: GeoProperties;
  map_layer_id?: number;
  model_type?: string;
  count?: number;
  model?: MapItemDataModel;
  is_excluded?: boolean;
}

export interface IMapView {
  zoom: number;
  boundsStr: string;
  boundsStr_latlon: string;
}

export interface MapItemDataModel {
  id: number;
  service_request_id: number;
  name: string;
  oktmo: string;
  address: string;
  municipality_id: number;
  hydronym_id?: any;
  sea_location_id: number;
  emission_object_type_id: number;
  emission_source_type_id: number;
  geometry: SupportedGeometry;
  processes: any[][];
  height: string;
  diameter: string;
  length: string;
  width: string;
  temperature_gvs: string;
  speed_gvs: string;
  volume_gvs: string;
  percent_png: string;
  value_co2: string;
  urban_air: boolean;
  iski: string;
  is_complete: boolean;
  groro_number?: any;
  index_number?: any;
  number?: string;
  onv_id: number;
}

export enum SupportedGeoJsonObjectsTypes {
  'Point' = 'Point',
  'LineString' = 'LineString',
  'Polygon' = 'Polygon',
  'MultiLineString' = 'MultiLineString',
}

export type SupportedGeometry = Point | Polygon | LineString | MultiLineString;

export type EditorFeatureInstance = Feature<SupportedGeometry, EditorFeatureProperties>;

export interface EditorFeatureProperties {
  draggable?: boolean;
  mapObjectType: MapObjectTypes;
  corrected?: boolean;
  old?: boolean;
  selected?: boolean;
  assistance?: boolean;
  objectData: BehaviorSubject<{ id: number; map_layer_id: MapObjectTypes } | IStationarySource | IWasteDisposalFacility | IDischargeSource>;
  openSubj?: Subject<any>;
  index?: number;
  viewIndex?: number;
  tooltipText?: string;
}

export interface EditorFeature {
  geometry: SupportedGeometry;
  properties: EditorFeatureProperties;
}

export interface SourceGeoDataOnvosRequestComponent extends GeoComponent, BaseSection {
  center: ICenterLngLat;
  editedObjectData?: BehaviorSubject<IStationarySource | IWasteDisposalFacility | IDischargeSource>;
  editedObjectDataOld: BehaviorSubject<IStationarySource | IWasteDisposalFacility | IDischargeSource>;
  geometryEditingIsDisabled?: boolean;
}

export interface GeoComponent {
  editedObjectType: MapObjectTypes;
  onvosRequestType: OnvosRequestType;
  corrected: boolean;
  mapId: string;
  subscriptions: Subscription[];
  mapOptions: MapCustomOptions;
  objectId: string;
}

export type GeometryWithOptions = {
  extendedGeometries: EditorFeature[];
  options: GeoJSONOptions;
  contextName: string;
  mapId: string;
  cluster?: boolean;
};

export interface MapDataIsLoadingState {
  [MapObjectTypes.stationarySource]?: { total: number; current: number };
  [MapObjectTypes.dischargeSource]?: { total: number; current: number };
  [MapObjectTypes.wasteDisposalFacility]?: { total: number; current: number };
  [MapObjectTypes.neutralizeFacility]?: { total: number; current: number };
  [MapObjectTypes.accumulationFacility]?: { total: number; current: number };
  [MapObjectTypes.processingFacility]?: { total: number; current: number };
  [MapObjectTypes.utilizationFacility]?: { total: number; current: number };
}

export interface MapMenuItem {
  title: string;
  fn: (value: boolean) => void;
  type: 'checkbox' | 'radio';
  name: string;
  checked: boolean;
  disabled?: boolean;
}

export interface MapCustomOptions {
  rulerRx?: BehaviorSubject<LatLngBounds>;
  lassoRx?: BehaviorSubject<LatLngBounds>;
  stats?: BehaviorSubject<{ all: number }>;
  showHistory?: (value: boolean) => void;
  showCurrent?: (value: boolean) => void;
  currentIsActualization?: boolean;
  hideGeometryName?: boolean;
  menuControl?: {
    items: MapMenuItem[];
  };
  pointEditorRx?: BehaviorSubject<boolean>;
  fullScreenRx?: BehaviorSubject<boolean>;
}

export interface IGeometryBase {
  geometry: SupportedGeometry;
  address: string;
  service_request_id?: number;
  onv_id?: number;
  groro_number?: string;
  old_geometry?: SupportedGeometry;
}
