import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IOnvos } from 'src/app/providers/_interfaces/onvos/onvos.interface';
import { OnvosActionTypes, OnvosUnion } from 'src/app/store/onvos/actions';

export const adapter: EntityAdapter<IOnvos> = createEntityAdapter<IOnvos>({
  selectId: (instance) => instance.id,
});

export interface OnvosState extends EntityState<IOnvos> {
  currentOnvosId: string;
}

export const initialState: OnvosState = adapter.getInitialState({
  currentOnvosId: null,
});

export function onvosReducer(state: OnvosState = initialState, action: OnvosUnion): OnvosState {
  switch (action.type) {
    // LOAD ONVOS
    case OnvosActionTypes.LoadObjectRequest:
      return {
        ...state,
        currentOnvosId: action.payload.id,
      };

    case OnvosActionTypes.ObjectLoadedSuccess:
      return adapter.upsertOne(action.payload.data, state);

    default:
      return state;
  }
}
