import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { get } from 'lodash';
import moment from 'moment';
import { Observable, BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { tap, catchError, withLatestFrom } from 'rxjs/operators';
import { IOpenModalResponse, ModalShowTemplateService } from 'src/app/modules/modal-show-template/services/modal-show-template.service';
import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { DetailsRouteMap, ShortNameMap, ShortNameRoivMap } from 'src/app/providers/_const/fgen-object.const';
import { ESubSectionRename } from 'src/app/providers/_const/fields.const';
import { TreeEntitySettings } from 'src/app/providers/_const/tree.const';
import { TableType, Sort } from 'src/app/providers/_enum';
import { INodeTree, IEssenceTreeMetaHash, IEssenceForm } from 'src/app/providers/_interfaces/common';
import { IReportCheckError, IReportTransition, IReportTransitionBody } from 'src/app/providers/_interfaces/report.interface';
import { ERequestModelToType } from 'src/app/providers/_interfaces/request.interface';
import { ITree, IRouteParamsFromActiveRoute, IMetaForBranch } from 'src/app/providers/_interfaces/tree.interface';
import { UserScopesRepo } from 'src/app/providers/_scopes';
import { LoadedService } from 'src/app/providers/_services/load.service';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { restoreFilterOptions } from 'src/app/providers/_utils/filter.order.utils';
import { deepClone } from 'src/app/providers/_utils/utils';
import { EssenceSelectors } from 'src/app/store';
import { LoadEssenceRequest, ManualUpdateTree } from 'src/app/store/essence/actions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TreeHelperService {
  static currentTree: INodeTree[];

  public tree$: BehaviorSubject<INodeTree | null> = new BehaviorSubject<INodeTree | null>(null);
  public meta$: BehaviorSubject<IEssenceTreeMetaHash> = new BehaviorSubject({});
  public statusErrors$: Subject<{ essenceType: TableType; errors: IReportCheckError[] }> = new Subject();
  public changeSort$: Subject<string> = new BehaviorSubject(null);
  public updateExpandTree$: Subject<null> = new Subject();

  readonly reInitReport$ = new BehaviorSubject<null>(null);

  public get isLK(): boolean {
    return environment.SYSTEM_TYPE === EnvironmentType.lk;
  }
  public get isKSV(): boolean {
    return environment.SYSTEM_TYPE === EnvironmentType.ksv;
  }

  public currentTreeViewRoute: ActivatedRoute;
  public formCopy: { [key: string]: any } | null;

  private modalSubscription: Subscription;
  private _modal: IOpenModalResponse;
  private get modal(): IOpenModalResponse {
    return this._modal;
  }

  private set modal(modal: IOpenModalResponse) {
    this.closeModal();
    this._modal = modal;
    this.modalSubscription = modal.closeModal$.subscribe();
  }

  constructor(
    private svcRestService: SvcRestService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    protected modalService: ModalShowTemplateService,
    public loadedService: LoadedService,
  ) {}

  public walkTree<T extends ITree<T>>(
    treeArr: T[],
    cb: (treeArr: T[], current: T, parent: T | null) => { stopIterate: boolean },
    parent: T | null = null,
  ): void {
    for (const tree of treeArr) {
      if (cb(treeArr, tree, parent).stopIterate) {
        return;
      }

      if (tree.children) {
        this.walkTree(tree.children, cb, tree);
      } else if (tree.allChildren) {
        this.walkTree(tree.allChildren, cb, tree);
      }
    }
  }

  getMetaForTree(tree: INodeTree[]): IEssenceTreeMetaHash {
    const ignoreNodes = ['main-info'];
    const hash: IEssenceTreeMetaHash = {};

    this.walkTree<INodeTree>(tree, (branchTree, currentBranch, parent) => {
      const routerLink = [];

      if (parent && hash[parent.id] && ignoreNodes.indexOf(currentBranch.id) < 0) {
        const parentHash = hash[parent.id].find((h) => h.identity === parent.identity);

        if (parentHash) {
          routerLink.push(...parentHash.routerLink);
          routerLink.push(currentBranch.id);
        }
      }

      if (!hash[currentBranch.id]) {
        hash[currentBranch.id] = [];
      }

      hash[currentBranch.id].push({
        name: currentBranch.name,
        identity: currentBranch.identity || '',
        is_editable: currentBranch.is_editable || false,
        is_validatable: currentBranch.is_validatable || false,
        inquiry_kind_template_id: currentBranch.inquiry_kind_template_id || '',
        inquiry_kind_id: currentBranch.inquiry_kind_id || '',
        show_button: currentBranch.show_button || false,
        is_readable: currentBranch.is_readable || false,
        is_valid: currentBranch.is_valid || false,
        is_complete: currentBranch.is_complete || false,
        is_dirty: currentBranch.is_dirty || false,
        routerLink,
      });

      return { stopIterate: false };
    });

    return hash;
  }

  routeLinkCreate(treeNodes: INodeTree[], parentRouteLink = '.'): any {
    treeNodes.forEach((element) => {
      const slashIndx = element.router_link ? ('' + element.router_link).indexOf('/') : -1;
      if (slashIndx >= 0) {
        if (element.children) {
          element.children = this.routeLinkCreate(element.children, element.router_link);
        } else if (element.allChildren) {
          element.allChildren = this.routeLinkCreate(element.allChildren, element.router_link);
        }
        return;
      }

      element.router_link = element.router_link ? `${parentRouteLink}${element.router_link}/` : `${parentRouteLink}`;

      if (element.router_link.charAt(element.router_link.length - 1) !== '/') {
        element.router_link = `${element.router_link}/`;
      }

      if (element.children) {
        element.children = this.routeLinkCreate(element.children, element.router_link);
      } else if (element.allChildren) {
        element.allChildren = this.routeLinkCreate(element.allChildren, element.router_link);
      }
    });

    return treeNodes;
  }

  fixRoutesForBranch(branch: INodeTree) {
    if (!branch) {
      return;
    }

    [branch.children, branch.allChildren].forEach((children) => {
      if (!children) {
        return;
      }

      children.forEach((n) => {
        const emptyRoutes = ['check', 'delete', 'index', 'show', 'store', 'update'].reduce((acc, name) => {
          return acc + (n.route && n.route[name] ? 1 : 0);
        }, 0);

        if (emptyRoutes === 0) {
          n.route = { check: null, delete: null, index: null, show: null, store: null, update: null };

          ['check', 'delete', 'index', 'show', 'store', 'update'].forEach((name) => {
            if (branch.route && branch.route[name]) {
              if (n.canAddChildren) {
                n.route[name] = branch.route[name].replace(`{${branch.essence_name}}`, `{${n.essence_name}}`);
              } else {
                n.route[name] = branch.route[name];
              }
            }
          });
        }

        this.fixRoutesForBranch(n);
      });
    });
  }

  public getCleanResolvedIndex(form: any, activeBranch?: any): string {
    if (!form || !form.resolved_route) {
      return '';
    }

    const indexRoute = form.resolved_route.index;

    if (!indexRoute) {
      return '';
    }

    const model_id = (form.properties && form.properties.model_id) || null;

    try {
      const fullURL = new URL(indexRoute, 'http://localhost');

      if (fullURL.searchParams.has('page')) {
        const page = fullURL.searchParams.get('page') || 0;

        if (+page !== model_id) {
          return '';
        }

        return `${fullURL.pathname}?page=${page}`;
      }

      if (model_id) {
        if (activeBranch && activeBranch.children_pagination) {
          const page = +activeBranch.children_pagination.current_page || 0;

          if (+model_id === page) {
            return `${fullURL.pathname}?page=${page}`;
          }
        }

        return '';
      }

      return fullURL.pathname;
    } catch (err) {
      console.error(err);
    }

    return '';
  }

  public fixRouteForList(node: INodeTree, form: any): void {
    if (form.is_has_one) {
      return;
    }

    if (form.route && form.resolved_route) {
      const indexRoute = this.getCleanResolvedIndex(form);
      const pageIndex = indexRoute ? indexRoute.indexOf('page=') : -1;
      const identity = form.identity; // + (form.properties.model_id || '');

      if (!indexRoute && form.properties.model_id) {
        return;
      }

      node.listUrl = indexRoute;

      if (pageIndex === -1) {
        return;
      }

      const curPage = +indexRoute.substr(pageIndex + 5);

      node.id = `${form.relation}${curPage}`;
      node.children_counter = 0;
      node.router_link = '';
      node.canAddChildren = false;
      node.canDeleteChild = false;
      node.essenceId = '';
      node.identity = `${identity}.page${curPage}`;
      node.children_pagination = {
        current_page: curPage,
      };
    }
  }

  private getFormIdFromForm(rootEssence: any, form: IEssenceForm): any {
    const rootIdentity = rootEssence.form.identity;
    const thisIdentity = form.identity;

    if (rootIdentity === thisIdentity) {
      return form.relation;
    }

    if (form.is_has_one) {
      return form.list_child ? form.properties.model_id || form.relation : form.relation;
    } else {
      return form.relation;
    }
  }

  private getNameFromTemplate(templateString: string, formData: any): string {
    if (!templateString || !templateString.length) {
      return formData.tree_name;
    }

    let resultedName = '';
    let restTemplate = templateString;

    while (restTemplate) {
      const templateStartIndex = restTemplate.indexOf('{');
      const templateEndIndex = restTemplate.indexOf('}');

      if (templateEndIndex === -1 || templateStartIndex === -1) {
        resultedName += restTemplate || '';
        break;
      }

      const fieldName = restTemplate.slice(templateStartIndex + 1, templateEndIndex);

      resultedName += restTemplate.slice(0, templateStartIndex) || '';
      resultedName += get(formData, fieldName) || '';

      restTemplate = restTemplate.slice(templateEndIndex + 1);
    }

    return resultedName;
  }

  private getFormNameFromForm(form: IEssenceForm, parentForm: IEssenceForm): any {
    let originalName = get(form, 'description') || get(form, 'properties.name') || get(form, 'properties.model_id') || '';

    if (form?.list_child) {
      const newName = this.getNameFromTemplate(parentForm.title_template, get(form, 'properties.data') || {});
      if (newName) originalName = newName;
    }

    if (form?.properties?.model_id && originalName.length > 256) {
      const index = originalName.lastIndexOf(' ', 256);
      originalName = originalName.substr(0, index >= 0 ? index : 256) + '...';
    }

    return originalName;
  }

  private getFormEssenceIdFromForm(rootEssence: any, form: IEssenceForm): any {
    const rootIdentity = rootEssence.form.identity;
    const thisIdentity = form.identity;

    if (rootIdentity === thisIdentity) return rootEssence.id;

    if (form.is_has_one && form.list_child) return form.properties.model_id || null;

    return null;
  }

  private getFormModelIdFromForm(form: IEssenceForm): any {
    if (form.is_has_one) return form.list_child ? form.properties.model_id || null : null;
    return null;
  }

  private sortChildrenBranches(forms: IEssenceForm[], parentForm: IEssenceForm): any {
    const sortById = (left: IEssenceForm, right: IEssenceForm, order: Sort) => {
      const leftModelId = this.getFormModelIdFromForm(left);
      const rightModelId = this.getFormModelIdFromForm(right);

      return order === Sort.asc ? leftModelId - rightModelId : rightModelId - leftModelId;
    };

    const sortByField = (left: IEssenceForm, right: IEssenceForm, fieldName: string, order: Sort) => {
      const leftFieldValue = get(left, `properties.data.${fieldName}`);
      const rightFieldValue = get(right, `properties.data.${fieldName}`);

      if (leftFieldValue < rightFieldValue) {
        return order === Sort.asc ? -1 : 1;
      }

      if (leftFieldValue > rightFieldValue) {
        return order === Sort.asc ? 1 : -1;
      }

      return sortById(left, right, order);
    };

    const sortByName = (left: IEssenceForm, right: IEssenceForm, order: Sort) => {
      const leftName = this.getFormNameFromForm(left, parentForm);
      const rightName = this.getFormNameFromForm(right, parentForm);

      if (leftName < rightName) {
        return order === Sort.asc ? -1 : 1;
      }

      if (leftName > rightName) {
        return order === Sort.asc ? 1 : -1;
      }

      return sortById(left, right, order);
    };

    const sortByDate = (left: IEssenceForm, right: IEssenceForm, fieldName: string, order: Sort) => {
      const leftFieldDate = moment(get(left, `properties.data.${fieldName}`)).toDate();
      const rightFieldDate = moment(get(right, `properties.data.${fieldName}`)).toDate();

      if (leftFieldDate < rightFieldDate) {
        return order === Sort.asc ? -1 : 1;
      }

      if (leftFieldDate > rightFieldDate) {
        return order === Sort.asc ? 1 : -1;
      }

      return sortById(left, right, Sort.desc);
    };

    const oldFilters = restoreFilterOptions(true, parentForm.identity);
    const orderBy = (oldFilters && oldFilters.orderBy) || {};
    const orderKeys = Object.keys(orderBy);

    if (!orderKeys.length) {
      // No sorting. Use default
      return forms;
    }

    const firstKey = orderKeys[0];
    const sortOrder = orderBy[firstKey];
    let sortFnc: (left: IEssenceForm, right: IEssenceForm) => number;

    // Determine best sort function for field
    if (firstKey === 'id') {
      sortFnc = (left, right) => sortById(left, right, sortOrder);
    } else if (firstKey === 'name') {
      sortFnc = (left, right) => sortByName(left, right, sortOrder);
    } else if (firstKey === 'updated_at') {
      sortFnc = (left, right) => sortByDate(left, right, 'updated_at', sortOrder);
    } else if (firstKey) {
      sortFnc = (left, right) => sortByField(left, right, firstKey, sortOrder);
    }

    return sortFnc ? [...forms].sort(sortFnc) : forms;
  }

  public createNodesForTree(rootEssence: any, essence: any, dryRun: boolean = false): INodeTree {
    const formName = this.getFormNameFromForm(rootEssence.form, essence.form) || rootEssence.state_service?.name;
    const formId = this.getFormIdFromForm(rootEssence, essence.form);
    const modelId = this.getFormModelIdFromForm(essence.form);

    const node: INodeTree = {
      name: formName,
      id: formId,
      identity: essence.form.identity + (modelId || ''),
      originalIdentity: essence.form.identity,
      router_link: formId,
      fields: essence.fields,
      route: essence.form.route,
      essence_name: essence.form.name,
      is_editable: essence.form.properties?.is_editable,
      is_validatable: essence.form.is_validatable,
      inquiry_kind_template_id: essence.form.properties?.inquiry_kind_template_id || '',
      inquiry_kind_id: essence.form.properties?.inquiry_kind_id || '',
      show_button: essence.form.properties?.show_button || false,
      is_readable: essence.form.properties?.is_readable,
      is_complete: essence.form.properties?.is_complete,
      is_valid: essence.form.properties?.is_valid,
      is_branch_has_error: essence.form.is_branch_has_error,
      is_dirty: essence.form.properties?.is_dirty,
      children: [],
      allChildren: [],
      expanded: true,
      essenceId: this.getFormEssenceIdFromForm(rootEssence, essence.form),
      hasChildren: !dryRun && !essence.form.is_has_one,
      validation: essence.form.validation || null,
      use_frontend_validation: essence.form.use_frontend_validation || false,
      current_errors: essence.form.properties?.errors || null,
      relation: essence.form.relation,
      children_counter: 0,
    };

    this.fixRouteForList(node, essence.form);

    if (essence.form && essence.form.forms) {
      node.is_readable = node.is_readable !== null && node.is_readable !== undefined ? true : node.is_readable;
      node.children = node.is_readable
        ? this.createBranchForTree(rootEssence, essence, essence.form.forms, essence.form.identity, essence.form)
        : [];
    }
    this.fixRoutesForBranch(node);
    return node;
  }

  createBranchForTree(
    rootEssence: any,
    essence: any,
    forms: IEssenceForm[],
    parentIdentity: string,
    parentForm: IEssenceForm,
  ): INodeTree[] {
    const tree = [];

    for (const $node of forms) {
      if (typeof $node !== 'object') {
        continue;
      }

      const formId = this.getFormIdFromForm(rootEssence, $node);
      const formName = this.getFormNameFromForm($node, parentForm);
      const modelId = this.getFormModelIdFromForm($node);

      const router_link = <keyof typeof ESubSectionRename>formId;
      // const renamedLink = <keyof typeof TreeEntitySettings> (ESubSectionRename[router_link] || router_link);
      const stateProperties = $node.list_child ? parentForm.properties : $node.properties;
      const is_readable =
        stateProperties.is_readable === null || stateProperties.is_readable === undefined ? true : stateProperties.is_readable;
      const is_editable = stateProperties.is_editable;

      if (
        !is_readable /* || (TreeEntitySettings[renamedLink] &&
        TreeEntitySettings[renamedLink].hideSection &&
        TreeEntitySettings[renamedLink].hideSection!(essence, rootEssence) &&
        !essence.hasOwnProperty('last_page'))
        */
      ) {
        continue;
      }

      if ($node.list_child && (((essence.data || []) as any[]).find((x) => x.id === $node.properties.model_id) || {}).deleted_at) {
        continue;
      }

      const settings = get(TreeEntitySettings, `${$node.identity}`) || get(TreeEntitySettings, `${$node.relation}`);

      const branch: INodeTree = {
        canAddChildren: !!(!$node.is_has_one && get(settings, 'canDeleteChild', true)),
        id: formId,
        essence_name: $node.list_child ? parentForm.name : $node.name,
        router_link,
        name: formName,
        is_editable,
        is_readable,
        fields: $node.fields,
        is_validatable: $node.list_child ? parentForm.is_validatable : $node.is_validatable,
        inquiry_kind_template_id: $node.properties.inquiry_kind_template_id || '',
        inquiry_kind_id: $node.properties.inquiry_kind_id || '',
        show_button: $node.properties.show_button || false,
        is_complete: $node.properties.is_complete,
        is_valid: $node.properties.is_valid,
        is_branch_has_error: $node.is_branch_has_error,
        is_dirty: $node.properties.is_dirty,
        identity: ($node.list_child ? parentForm.identity : $node.identity) + (modelId || ''),
        originalIdentity: $node.list_child ? parentForm.identity : $node.identity,
        hasChildren: !$node.is_has_one,
        children: [],
        allChildren: [],
        essenceId: modelId,
        validation: ($node.list_child ? parentForm.validation : $node.validation) || null,
        use_frontend_validation: ($node.list_child ? parentForm.use_frontend_validation : $node.use_frontend_validation) || false,
        current_errors: $node.properties.errors || null,
        relation: $node.list_child ? parentForm.relation : $node.relation,
        children_counter: 0,
        route: { check: null, delete: null, index: null, show: null, store: null, update: null },
      };

      if ($node.list_child) {
        if (parentForm.route) {
          branch.route = parentForm.route;
        }
      } else {
        if ($node.route) {
          branch.route = $node.route;
        }
      }

      if (!$node.is_has_one) {
        branch.children_counter = ($node.forms && $node.forms.length) || 0;
      }

      this.fixRouteForList(branch, $node);

      if (parentIdentity === $node.identity) {
        if ($node.forms && $node.forms.length) {
          const notResolved = $node.forms.find(
            (f) => f.resolved_route && f.resolved_route.index && f.resolved_route.index.indexOf('{') !== -1,
          );

          if (notResolved) {
            tree.push(branch);
            continue;
          }
        }
      }

      if ($node.is_has_one || (!$node.is_has_one && $node.properties.model_id)) {
        if ($node.forms && $node.forms.length) {
          branch.children = this.createBranchForTree(rootEssence, {}, $node.forms, $node.identity || '', $node);
          this.fixRoutesForBranch(branch);
        }
      } else {
        // http://localhost:4200/rpn/license-activity-waste/requests-replace/details/license_request_replace/5148116/statement/places
        if ($node.forms && $node.forms.length) {
          const sortedChilds = this.sortChildrenBranches($node.forms, $node);

          branch.children = null;
          branch.allChildren = this.createPaginationForChilds(rootEssence, {}, sortedChilds, $node.identity || '', $node, branch);
          this.fixRoutesForBranch(branch);
        }
      }

      tree.push(branch);
    }

    return tree;
  }

  public createPaginationForChilds(
    rootEssence: any,
    essence: any,
    forms: IEssenceForm[],
    parentIdentity: string,
    parentForm: IEssenceForm,
    parentBranch: INodeTree,
  ): INodeTree[] {
    const perPage = 25;

    if (forms.length <= perPage) {
      return this.createBranchForTree(rootEssence, {}, forms, parentIdentity, parentForm);
    }

    const arrLengthCount = forms.length;
    const arr = forms;
    const pages = Math.ceil(arrLengthCount / perPage);
    const paginators = Array(pages)
      .fill(0)
      .map((x, index) => {
        const pageBranch = {
          ...parentBranch,
          id: `${parentBranch.id}.${index + 1}`,
          identity: `${parentBranch.identity}.${index + 1}`,
          name: `${parentBranch.name}`,
          children_pagination: {
            current_page: index + 1,
            per_page: perPage,
          },
          router_link: null,
          canAddChildren: false,
          canDeleteChild: false,
          essenceId: null,
        };

        const itemsOnPage = index + 1 === pages ? Math.floor(arrLengthCount % perPage) || perPage : perPage;
        const pageItems = Array(itemsOnPage)
          .fill(0)
          .map((y, ind) => {
            return arr[index * perPage + ind];
          });
        pageBranch.is_branch_has_error = !!pageItems.filter(
          (item) => item.is_branch_has_error === true || item.properties.is_valid === false,
        ).length;

        pageBranch.children = this.createBranchForTree(rootEssence, essence, pageItems, pageBranch.identity, parentForm);
        pageBranch.name =
          itemsOnPage < perPage
            ? `${1 + index * perPage} - ${1 + index * perPage + (itemsOnPage - 1)}`
            : `${1 + index * perPage} - ${(index + 1) * perPage}`;

        this.fixRoutesForBranch(pageBranch);

        return pageBranch;
      });

    return paginators;
  }

  restoreRouteParamsFromActiveRoute(pathFromRoot: ActivatedRouteSnapshot[]): IRouteParamsFromActiveRoute {
    const routeParams: { [key: string]: string } = {};
    let branch: INodeTree | null = null;
    const treePath: string[] = [];
    const treePathBranches: INodeTree[] = [];
    const relationPath: { relation: string; id?: string }[] = [];

    const pathTree = pathFromRoot.slice(
      pathFromRoot.findIndex((path) => {
        return (
          path &&
          !!path.url.find((part) => {
            const urlPart = part.path;
            return urlPart === 'details';
          })
        );
      }) + 1,
    );

    for (const path of pathTree) {
      const url = path.url;

      for (const part of url) {
        const urlPart = part.path;

        if (urlPart.length === 0) {
          continue;
        }

        const tree: INodeTree | null = this.tree$.getValue();

        if (tree && urlPart === tree.id) {
          routeParams[tree.id] = tree.essenceId;
          routeParams.essenceId = tree.essenceId;
          branch = tree;
          break;
        } else if (branch) {
          let children: INodeTree[] = branch.children || branch.allChildren || [];
          const hasPaginators = children.findIndex((c) => c.children_pagination) !== -1;

          if (hasPaginators) {
            children = [];

            (branch.children || branch.allChildren || []).forEach((c) => {
              children.push(...(c.children || []));
            });
          }

          const node = children.find((c) => {
            if ('' + c.id === '' + urlPart) {
              return true;
            }

            return `${branch.id}${urlPart}` === '' + c.id;
          });

          if (!node) {
            if (branch.hasChildren) {
              routeParams[branch.essence_name || branch.id] = urlPart;
              treePath.push(urlPart);
              treePathBranches.push(branch);

              branch = {
                id: urlPart,
                fields: branch.fields,
                identity: branch.originalIdentity || '',
                originalIdentity: branch.originalIdentity,
                use_frontend_validation: branch.use_frontend_validation,
                validation: branch.validation,
                name: '',
                children: [],
                allChildren: [],
                router_link: '',
                essenceId: '',
                children_counter: 0,
                route: deepClone(branch.route),
              };

              this.fixRoutesForBranch(branch);
            } else {
              return {
                branch: null,
                routeParams,
                treePath,
                treePathBranches,
              };
            }
          } else {
            branch = node;

            if (node.essenceId) {
              routeParams[node.essence_name || branch.id] = node.essenceId;
              relationPath[relationPath.length - 1].id = node.essenceId;
            } else {
              relationPath.push({
                relation: urlPart,
              });
            }

            treePathBranches.push(branch);
            treePath.push(urlPart);
          }
        }
      }
    }

    return {
      branch,
      routeParams,
      treePath,
      treePathBranches,
    };
  }

  public getMetaForBranch(branch: INodeTree): IMetaForBranch {
    const meta = this.meta$.getValue();
    if (!meta || !meta[branch.id]) {
      return null;
    }
    return meta[branch.id].find((hash) => hash.identity === branch.identity);
  }

  public getMetaForPath(branch: INodeTree, treePathBranches: INodeTree[]): IMetaForBranch {
    const meta = this.meta$.getValue();
    if (branch.id === 'new' || !isNaN(+branch.id)) {
      const path = treePathBranches.slice(0, -1);
      const parentBranch = path.pop() || { id: '', identity: '' };
      const parentName = parentBranch.id;
      const metaName = Object.keys(meta).find((key) => {
        if (key === parentName) {
          return true;
        }

        if (key.indexOf(parentName) >= 0) {
          const id = path[path.length - 1]?.id;
          const fullKey = id ? `${id}.${parentName}` : false;

          return key === fullKey;
        }

        return false;
      });

      return metaName ? meta[metaName].find((hash) => hash.identity === parentBranch.identity) || null : null;
    }

    if (meta[branch.id]) {
      return meta[branch.id].find((hash) => hash.identity === branch.identity) || null;
    }

    return null;
  }

  public changeStatus(
    transition: IReportTransition | null,
    essenceType: TableType,
    essenceId: string,
    statusId: number,
    body: IReportTransitionBody,
    withoutNavigate?: boolean,
    isReportFormDirty?: boolean,
  ): Observable<any> {
    const is_complete: boolean = '' + get(transition, 'properties.is_complete') === 'true';
    const is_async: boolean = '' + get(transition, 'properties.is_async') === 'true';
    const params: IReportTransitionBody = deepClone(body || {});
    this.loadedService.loadStart();

    if (is_complete) params.is_complete = true;

    const request = transition?.id
      ? this.svcRestService.changeStatusByTransition(essenceType, +essenceId, transition.id, is_async, params)
      : this.svcRestService.changeStatus(essenceType, +essenceId, statusId, params);

    return request.pipe(
      withLatestFrom(this.store.pipe(select(EssenceSelectors.selectCurrentRouteParams))),
      tap(([_res, routeParams]) => {
        if (!withoutNavigate) {
          this.store.dispatch(new LoadEssenceRequest({ essenceType, essenceId: +essenceId, rpnUrl: routeParams.urlForEssence }));

          if (routeParams.urlForEssence) {
            const prevShouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
              return false;
            };
            this.router
              .navigate(['./'], { skipLocationChange: true, queryParams: { random: '' } })
              .then(() => this.router.navigate([routeParams.urlForEssence, routeParams.type, routeParams.id]))
              .finally(() => (this.router.routeReuseStrategy.shouldReuseRoute = prevShouldReuseRoute));
          } else {
            // TODO Add case all_requests / all_reports
            this.router.navigate(['/rpn/requests/all_requests/details', routeParams.type, routeParams.id]);
          }
        }
      }),
      catchError((err) => {
        if (err.status === 302 && err.error) {
          const context: { id: string; params: { message: string[] }; type: keyof typeof ERequestModelToType } = err.error.context;

          if (context.params.message?.length) {
            this.modal = this.modalService.openModal('Внимание!', context.params.message.map((x) => `<div>${x}</div>`).join(''), null);
          }

          if (context) {
            const { id, type } = context;

            if (id && type && ERequestModelToType[type]) {
              let url = '';
              const tableType: TableType = ERequestModelToType[type];

              switch (tableType) {
                // ONVOS Request
                case TableType.onv_request_inclusion:
                case TableType.onv_request_inclusion_v2:
                case TableType.onv_request_edit:
                case TableType.onv_request_edit_v2:
                case TableType.onv_request_adjust_v2:
                case TableType.onv_request_exclusion:
                case TableType.onv_request_correction: {
                  url = this.isLK ? '/rpn/requests/all_requests/details' : '/rpn/pto-uonvos/onv_request/details';
                  break;
                }
                // License Request
                case TableType.license_request_insertion:
                case TableType.license_request_modification:
                case TableType.license_request_issue:
                case TableType.license_request_writeout:
                case TableType.request_department_writeout:
                case TableType.license_request_replace:
                case TableType.license_request_termination:
                case TableType.request_forced_termination: {
                  url = this.isLK ? '/rpn/requests/all_requests/details' : '/rpn/license-activity-waste/all-requests/details';
                  break;
                }

                case TableType.license_request_correction: {
                  url = this.isLK ? '/rpn/requests/all_requests/details' : '/rpn/license-activity-waste/requests-correction/details';
                  break;
                }
                case TableType.animal_registry: {
                  url = '/rpn/animal-registry/details';
                  break;
                }
                case TableType.addcharge: {
                  url = '/rpn/pm/nvos/dpnvos/add_charge/details';
                  break;
                }
                case TableType.waste_class_assignment_issue: {
                  url = this.isLK ? '/rpn/requests/all_requests/details' : '/rpn/nvos-waste-certification/details';
                  break;
                }
                case TableType.administrative_matter:
                case TableType.administrative_matter_order:
                case TableType.matters_submission : {
                  url = this.isLK ? '/rpn/requests/all_requests/details' : DetailsRouteMap[tableType];
                  break;
                }

                // Report & Declarations
                default: {
                  url = '/rpn/requests/all_reports/details';
                  break;
                }

              }

              let requestTypeUrl = ERequestModelToType[type] as string;

              const v2Index = requestTypeUrl.length - 3;
              const v2Transition = transition.properties.to_v2 === 'true' && requestTypeUrl.indexOf('_v2') !== v2Index;

              if (v2Transition) {
                requestTypeUrl += '_v2';
              }

              this.router
                .navigate(['../'], { skipLocationChange: true, relativeTo: this.route })
                .then(() => this.router.navigate([url, requestTypeUrl, id]));
              this.loadedService.loadStart();
            }
          }
        }

        if (err.status === 424 && err.error) {
          const context = err.error.context;

          if (context && context.errors && context.errors.length) {
            context.essenceType = TableType[essenceType];
            this.statusErrors$.next(context);
          }
        }

        if (isReportFormDirty) {
          this.reInitReport$.next(null);
        }

        return of(null);
      }),
    );
  }

  public nameForRootTreeFGEN(treeNode: INodeTree, scopeRepo: UserScopesRepo): string {
    const type = TableType[treeNode.identity];
    if (
      [
        TableType.fgen_object,
        TableType.fggn_object,
        TableType.fgzn_object,
        TableType.fgan_object,
        TableType.fgahn_object,
        TableType.fgohn_object,
        TableType.fgotn_object,
        TableType.fglesn_object,
      ].includes(type)
    ) {
      return scopeRepo.fgen_roiv.isActive ? ShortNameRoivMap[type] : ShortNameMap[type];
    }
    return treeNode.name;
  }

  public addItem(parentBranch: INodeTree, formToCopy?: { [key: string]: any }) {
    if (formToCopy) {
      this.formCopy = formToCopy;
    }

    this.router.navigate([`${parentBranch.router_link}new`], { relativeTo: this.currentTreeViewRoute });
  }

  public navigateAfterCopy(parentBranch: INodeTree, id: number | string) {
    this.router.navigate([`${parentBranch.router_link}${id}`], { relativeTo: this.currentTreeViewRoute });
    this.store.dispatch(new ManualUpdateTree());
  }

  private closeModal() {
    if (this.modal) {
      this.modal.modalRef.hide();
      this._modal = null;
    }
    this.modalSubscription?.unsubscribe();
  }
}
