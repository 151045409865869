import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, PaymentNvosState } from 'src/app/store/payment-nvos/reducer';

export const featureName = 'payment-nvos';

const featureSelector = createFeatureSelector<PaymentNvosState>(featureName);

const selectCurrentDeclarationId = (state: PaymentNvosState) => state && state.currentDeclarationId;
const selectCurrentDeclarationSection = (state: PaymentNvosState) => state && state.currentDeclarationSection;
const selectCurrentDeclarationSectionCorrections = (state: PaymentNvosState) => state && state.currentCorrections;
const selectCurrentDeclarationOktmo = (state: PaymentNvosState) => state && state.currentSelectedOktmo;
const selectCurrentDeclarationSectionDocuments = (state: PaymentNvosState) => state && state.currentDeclarationDocuments;
const selectCurrentDeclarationDocumentSources = (state: PaymentNvosState) => state && state.currentDeclarationDocumentSources;
const selectCurrentDeclarationSourceId = (state: PaymentNvosState) => state && state.currentSourceId;
const selectCurrentDeclarationSourcePollutants = (state: PaymentNvosState) => state && state.currentSourcePollutants;
const selectCurrentDeclarationDocumentPollutants = (state: PaymentNvosState) => state && state.currentDocumentPollutants;
const selectCurrentDeclarationWastes = (state: PaymentNvosState) => state && state.currentDeclarationWastes;
const selectCurrentDeclarationWasteDocuments = (state: PaymentNvosState) => state && state.currentDeclarationWasteDocuments;
const selectCurrentDeclarationWasteCorrections = (state: PaymentNvosState) => state && state.currentDeclarationWasteCorrections;

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectPaymentNvosEntities = createSelector(featureSelector, selectEntities);

export const selectPaymentDeclarationsList = createSelector(featureSelector, selectAll);

export const selectCurrentId = createSelector(featureSelector, selectCurrentDeclarationId);

export const selectCurrentOnvosId = createSelector(featureSelector, (state) => state && state.currentOnvosId);

export const selectCurrentSection = createSelector(featureSelector, selectCurrentDeclarationSection);

export const selectCurrentOktmo = createSelector(featureSelector, selectCurrentDeclarationOktmo);

export const selectCurrentDocumentsForSection = createSelector(featureSelector, selectCurrentDeclarationSectionDocuments);

export const selectCurrentSourcesForDocument = createSelector(featureSelector, selectCurrentDeclarationDocumentSources);

export const selectCurrentDeclarationSourceIdForDocument = createSelector(featureSelector, selectCurrentDeclarationSourceId);

export const selectCurrentCorrectionsForOnv = createSelector(featureSelector, selectCurrentDeclarationSectionCorrections);

export const selectCurrentPollutantsForSource = createSelector(featureSelector, selectCurrentDeclarationSourcePollutants);

export const selectCurrentPollutantsForDocument = createSelector(featureSelector, selectCurrentDeclarationDocumentPollutants);

export const selectCurrentWastesForOnv = createSelector(featureSelector, selectCurrentDeclarationWastes);

export const selectCurrentDocumentsForWaste = createSelector(featureSelector, selectCurrentDeclarationWasteDocuments);

export const selectCurrentCorrectionsForWaste = createSelector(featureSelector, selectCurrentDeclarationWasteCorrections);

export const selectCurrentDeclaration = createSelector(selectPaymentNvosEntities, selectCurrentId, (dict, currentId) => dict[currentId]);

export const selectCurrentSelectedSection = createSelector(
  selectPaymentNvosEntities,
  selectCurrentId,
  selectCurrentSection,
  selectCurrentOktmo,
  (entity, currentId, currentSection, currentOktmo) => {
    return {
      payment_onvos_id: currentId,
      section_name: currentSection,
      oktmo: currentOktmo,
    };
  },
);

export const selectCurrentSelectedSource = createSelector(
  selectPaymentNvosEntities,
  selectCurrentId,
  selectCurrentSection,
  selectCurrentDeclarationSourceIdForDocument,
  (entity, currentId, currentSection, currentSourceId) => {
    return {
      payment_onvos_id: currentId,
      section_name: currentSection,
      source_id: currentSourceId,
    };
  },
);

export const selectCurrentDocuments = createSelector(
  selectPaymentNvosEntities,
  selectCurrentId,
  selectCurrentSection,
  selectCurrentDocumentsForSection,
  (entity, currentId, currentSection, currentDocuments) => {
    return currentDocuments;
  },
);

export const selectCurrentSources = createSelector(
  selectPaymentNvosEntities,
  selectCurrentId,
  selectCurrentSection,
  selectCurrentSourcesForDocument,
  (entity, currentId, currentSection, currentSources) => currentSources,
);

export const selectCurrentSourcePollutants = createSelector(
  selectPaymentNvosEntities,
  selectCurrentId,
  selectCurrentSection,
  selectCurrentDeclarationSourceIdForDocument,
  selectCurrentPollutantsForSource,
  (entity, currentId, currentSection, currentSourceId, currentSourcePollutants) => currentSourcePollutants,
);

export const selectCurrentDocumentPollutants = createSelector(
  selectPaymentNvosEntities,
  selectCurrentId,
  selectCurrentSection,
  selectCurrentPollutantsForDocument,
  (entity, currentId, currentSection, currentDocumentPollutants) => currentDocumentPollutants,
);

export const selectCurrentCorrections = createSelector(
  selectPaymentNvosEntities,
  selectCurrentId,
  selectCurrentSection,
  selectCurrentCorrectionsForOnv,
  (entity, currentId, currentSection, currentCorrections) => currentCorrections,
);
