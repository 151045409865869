import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { ButtonsModule } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { AppIconComponent } from 'src/app/modules/app-icon/app-icon.component';
import { IconsSet } from 'src/app/modules/app-icon/icons';
import { SafePipe } from 'src/app/providers/_pipes/safe-sanitizer.pipe';


@Component({
  selector: 'app-modal-show-template',
  templateUrl: './modal-show-template.component.html',
  styleUrls: ['./modal-show-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AppIconComponent, NgIf, NgTemplateOutlet, ButtonsModule, SafePipe],
})
export class ModalShowTemplateComponent {
  content: {
    headerTitle: string;
    bodyText: string;
    bodyTemplate: TemplateRef<any>;
    closeModal$: Subject<void>;
    footerTemplate?: TemplateRef<any>;
    closeButtonLabel?: string;
  };
  contextData: any;

  get closeButtonLabel() {
    return (this.content && this.content.closeButtonLabel) || 'Закрыть';
  }

  get IconsSet() {
    return IconsSet;
  }

  public close(res): void {
    this.content.closeModal$.next(res);
  }
}
