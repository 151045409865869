import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChargeEffects } from 'src/app/store/payment-charge/effects';
import * as ChargeReducer from 'src/app/store/payment-charge/reducer';
import { featureName } from 'src/app/store/payment-charge/selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(featureName, ChargeReducer.chargeReducer),
    EffectsModule.forFeature([ChargeEffects])
  ],
})
export class ChargeStoreModule {}
