import { Directive, Output, EventEmitter, HostListener, Input, OnInit, OnDestroy } from '@angular/core';

const directives: ClickOutsideDirective[] = [];

@Directive({
  selector: '[appClickOutside]',
  exportAs: 'appClickOutside',
  standalone: true,
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Output() public appClickOutside = new EventEmitter();
  @Input() public appClickOutsideEnabled = false;
  @HostListener('document:click', []) public onDocumentClick() {
    this.appClickOutside.emit();
  }
  @HostListener('click', ['$event']) public onElementClick(event: MouseEvent) {
    for (const d of directives) {
      if (d !== this) {
        d.appClickOutside.emit();
      }
    }

    event.stopPropagation();
  }

  ngOnDestroy() {
    directives.splice(directives.indexOf(this), 1);
  }

  ngOnInit() {
    directives.push(this);
  }
}
