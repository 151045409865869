import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

export type SecurityContextStr = keyof typeof SecurityContext;

@Pipe({
  name: 'safe',
  standalone: true,
})
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any, type: SecurityContextStr): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (SecurityContext[type]) {
      case SecurityContext.HTML:
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case SecurityContext.STYLE:
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case SecurityContext.SCRIPT:
        return this.sanitizer.bypassSecurityTrustScript(value);
      case SecurityContext.URL:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case SecurityContext.RESOURCE_URL:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
