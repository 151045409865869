<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="close(null)">
            <span aria-hidden="true"></span>
        </button>
        <h4 class="modal-title w-100 mr-3" id="myModalLabel">{{title}}</h4>
    </div>
    <div class="modal-body">
        <p *ngIf="!isHtml">{{text}}</p>
        <div *ngIf="isHtml" [innerHTML]="text"></div>
    </div>
    <div class="modal-footer">
        <button type="button" mdbBtn color="primary"
          class="rpn-button rpn-button_md rpn-button_primary m-0 mr-2"
          (click)="close(type)">
          {{confirm}}
        </button>
        <button type="button" mdbBtn color="default"
          class="rpn-button rpn-button_bordered rpn-button_bordered-dark rpn-button_md" aria-label="Close"
          (click)="close(null)">
          Отмена
        </button>
    </div>
</div>
