<div (click)="togglePanel()" [ngClass]="['panel-header', headerClass || '']" style="margin-top: 0;">
  <div class="title title_{{titleType}}"
    [appSkeleton]="contentIsLoaded">
    <div>
      <div class="d-flex align-items-center">
        <span>{{ contentIsLoaded ? title : '' }}</span>
        <div *ngIf="contentIsLoaded && titleTemplate" class="ml-3">
          <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
        </div>
      </div>
      <mdb-badge [pill]="true" [primary]="true" *ngIf="contentIsLoaded && badge">{{badge}}</mdb-badge>
    </div>
    <div *ngIf="additional">
      <small>{{contentIsLoaded ? additional : ''}}</small>
    </div>
  </div>

  <div class="right-info-block">
    <span class="right-text" *ngIf="rightInfo">
      <span *ngIf="contentIsLoaded" [innerHTML]="rightInfo">
      </span>
      <span appSpinner
            *ngIf="!contentIsLoaded"
            [spinnerColor]="'#4A9D86'"
            [showSpinner]="true"></span>
    </span>

    <div *ngIf="contentIsLoaded" class="triangle" [ngClass]="{'closed': !isOpened}">
      <app-icon #icon [width]="'32px'" [height]="'32px'" [name]="icon.IconsSet.triangleOnCircle"></app-icon>
    </div>
  </div>
</div>

<div class="panel-body" *ngIf="isOpened && contentIsLoaded" @fadeInAnimation>
  <ng-content *ngIf="contentIsLoaded"></ng-content>
  <div class="row justify-content-end" *ngIf="showNextButton">
    <button
      type="button"
      class="rpn-button rpn-button_bordered rpn-button_bordered-green rpn-button_md rpn-button_save rpn-button_action"
      (click)="toggleNextPanel()"
    >
      Далее
    </button>
  </div>
  <div appSpinner
       *ngIf="!contentIsLoaded"
       [spinnerColor]="'#4A9D86'"
       [showSpinner]="true"></div>
</div>
