import { Action } from '@ngrx/store';

export enum PaymentNvosActionTypes {
  // List Declarations
  ListDeclarationsRequest = '[OnvosView Bid/PaymentNvos] List Declarations',
  DeclarationsListLoadedSuccess = '[OnvosView Bid/PaymentNvos] Declarations List Loaded Success',
  DeclarationsListLoadedError = '[OnvosView Bid/PaymentNvos] Declarations List Loaded Error',

  // Select Declaration
  SelectDeclaration = '[OnvosView Bid/PaymentNvos] Select Declaration',

  // List corrections
  ListCorrectionsRequest = '[OnvosView Bid/PaymentNvos] List Corrections',
  CorrectionsListLoadedSuccess = '[OnvosView Bid/PaymentNvos] Corrections List Loaded Success',
  CorrectionsListLoadedError = '[OnvosView Bid/PaymentNvos] Corrections List Loaded Error',

  // List documents
  ListDocumentsRequest = '[OnvosView Bid/PaymentNvos] List Documents',
  DocumentsListLoadedSuccess = '[OnvosView Bid/PaymentNvos] Documents List Loaded Success',
  DocumentsListLoadedError = '[OnvosView Bid/PaymentNvos] Documents List Loaded Error',

  // List document sources
  ListSourcesRequest = '[OnvosView Bid/PaymentNvos] List Sources',
  SourcesListLoadedSuccess = '[OnvosView Bid/PaymentNvos] Sources List Loaded Success',
  SourcesListLoadedError = '[OnvosView Bid/PaymentNvos] Sources List Loaded Error',

  // List pollutants for source
  ListSourcePollutantsRequest = '[OnvosView Bid/PaymentNvos] List Source Pollutants',
  SourcePollutantsListLoadedSuccess = '[OnvosView Bid/PaymentNvos] Source Pollutants List Loaded Success',
  SourcePollutantsListLoadedError = '[OnvosView Bid/PaymentNvos] Source Pollutants List Loaded Error',

  // List wastes
  ListWastesRequest = '[OnvosView Bid/PaymentNvos] List Wastes',
  WastesListLoadedSuccess = '[OnvosView Bid/PaymentNvos] Wastes List Loaded Success',
  WastesListLoadedError = '[OnvosView Bid/PaymentNvos] Wastes List Loaded Error',

  // List waste corrections
  ListWasteCorrectionsRequest = '[OnvosView Bid/PaymentNvos] List Waste Corrections',
  WasteCorrectionsListLoadedSuccess = '[OnvosView Bid/PaymentNvos] Waste Corrections List Loaded Success',
  WasteCorrectionsListLoadedError = '[OnvosView Bid/PaymentNvos] Wastes Corrections List Loaded Error',

  // List waste documents
  ListWasteDocumentsRequest = '[OnvosView Bid/PaymentNvos] List Waste Documents',
  WasteDocumentsListLoadedSuccess = '[OnvosView Bid/PaymentNvos] Waste Documents List Loaded Success',
  WasteDocumentsListLoadedError = '[OnvosView Bid/PaymentNvos] Wastes Documents List Loaded Error',

  // List pollutants for document
  ListDocumentPollutantsRequest = '[OnvosView Bid/PaymentNvos] List Document Pollutants',
  DocumentPollutantsListLoadedSuccess = '[OnvosView Bid/PaymentNvos] Document Pollutants List Loaded Success',
  DocumentPollutantsListLoadedError = '[OnvosView Bid/PaymentNvos] Document Pollutants List Loaded Error',
}

// List Declarations
export class ListDeclarationsRequest implements Action {
  readonly type = PaymentNvosActionTypes.ListDeclarationsRequest;

  constructor(public payload: { id: number }) {}
}

export class DeclarationsListLoadedSuccess implements Action {
  readonly type = PaymentNvosActionTypes.DeclarationsListLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class DeclarationsListLoadedError implements Action {
  readonly type = PaymentNvosActionTypes.DeclarationsListLoadedError;
}

// Select declaration
export class SelectDeclaration implements Action {
  readonly type = PaymentNvosActionTypes.SelectDeclaration;

  constructor(public payload: { id: string; onvosId: string }) {} // declaration id & onvos id
}

// List corrections
export class ListCorrectionsRequest implements Action {
  readonly type = PaymentNvosActionTypes.ListCorrectionsRequest;

  constructor(public payload: { pagination?: { page?: number; per_page?: number } }) {}
}

export class CorrectionsListLoadedSuccess implements Action {
  readonly type = PaymentNvosActionTypes.CorrectionsListLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class CorrectionsListLoadedError implements Action {
  readonly type = PaymentNvosActionTypes.CorrectionsListLoadedError;
}

// List documents
export class ListDocumentsRequest implements Action {
  readonly type = PaymentNvosActionTypes.ListDocumentsRequest;

  constructor(public payload: { section: string; oktmo: string }) {} // section name
}

export class DocumentsListLoadedSuccess implements Action {
  readonly type = PaymentNvosActionTypes.DocumentsListLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class DocumentsListLoadedError implements Action {
  readonly type = PaymentNvosActionTypes.DocumentsListLoadedError;
}

// List document sources
export class ListSourcesRequest implements Action {
  readonly type = PaymentNvosActionTypes.ListSourcesRequest;

  constructor(public payload: { id: string }) {} // document id
}

export class SourcesListLoadedSuccess implements Action {
  readonly type = PaymentNvosActionTypes.SourcesListLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class SourcesListLoadedError implements Action {
  readonly type = PaymentNvosActionTypes.SourcesListLoadedError;
}

// List pollutants for source
export class ListSourcePollutantsRequest implements Action {
  readonly type = PaymentNvosActionTypes.ListSourcePollutantsRequest;

  constructor(public payload: { id: string; pagination?: { page?: number; per_page?: number } }) {} // source id
}

export class SourcePollutantsListLoadedSuccess implements Action {
  readonly type = PaymentNvosActionTypes.SourcePollutantsListLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class SourcePollutantsListLoadedError implements Action {
  readonly type = PaymentNvosActionTypes.SourcePollutantsListLoadedError;
}

// List wastes
export class ListWastesRequest implements Action {
  readonly type = PaymentNvosActionTypes.ListWastesRequest;

  constructor(
    public payload: {
      id: string;
      section: string;
      oktmo: string;
    },
  ) {} // onvos id & section name
}

export class WastesListLoadedSuccess implements Action {
  readonly type = PaymentNvosActionTypes.WastesListLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class WastesListLoadedError implements Action {
  readonly type = PaymentNvosActionTypes.WastesListLoadedError;
}

// List waste corrections
export class ListWasteCorrectionsRequest implements Action {
  readonly type = PaymentNvosActionTypes.ListWasteCorrectionsRequest;

  constructor(public payload: { id: string; pagination?: { page?: number; per_page?: number } }) {} // waste id
}

export class WasteCorrectionsListLoadedSuccess implements Action {
  readonly type = PaymentNvosActionTypes.WasteCorrectionsListLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class WasteCorrectionsListLoadedError implements Action {
  readonly type = PaymentNvosActionTypes.WasteCorrectionsListLoadedError;
}

// List waste documents
export class ListWasteDocumentsRequest implements Action {
  readonly type = PaymentNvosActionTypes.ListWasteDocumentsRequest;

  constructor(public payload: { id: string; section: string }) {} // waste id
}

export class WasteDocumentsListLoadedSuccess implements Action {
  readonly type = PaymentNvosActionTypes.WasteDocumentsListLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class WasteDocumentsListLoadedError implements Action {
  readonly type = PaymentNvosActionTypes.WasteDocumentsListLoadedError;
}

// List pollutants for document
export class ListDocumentPollutantsRequest implements Action {
  readonly type = PaymentNvosActionTypes.ListDocumentPollutantsRequest;

  constructor(public payload: { id: string; pagination?: { page?: number; per_page?: number } }) {} // document id
}

export class DocumentPollutantsListLoadedSuccess implements Action {
  readonly type = PaymentNvosActionTypes.DocumentPollutantsListLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class DocumentPollutantsListLoadedError implements Action {
  readonly type = PaymentNvosActionTypes.DocumentPollutantsListLoadedError;
}

export type PaymentNvosUnion =
  // List Declarations
  | ListDeclarationsRequest
  | DeclarationsListLoadedSuccess
  | DeclarationsListLoadedError
  // Select declaration
  | SelectDeclaration
  // List corrections
  | ListCorrectionsRequest
  | CorrectionsListLoadedSuccess
  | CorrectionsListLoadedError
  // List documents
  | ListDocumentsRequest
  | DocumentsListLoadedSuccess
  | DocumentsListLoadedError
  // List document sources
  | ListSourcesRequest
  | SourcesListLoadedSuccess
  | SourcesListLoadedError
  // List pollutants for source
  | ListSourcePollutantsRequest
  | SourcePollutantsListLoadedSuccess
  | SourcePollutantsListLoadedError
  // List wastes
  | ListWastesRequest
  | WastesListLoadedSuccess
  | WastesListLoadedError
  // List waste documents
  | ListWasteDocumentsRequest
  | WasteDocumentsListLoadedSuccess
  | WasteDocumentsListLoadedError
  // List waste corrections
  | ListWasteCorrectionsRequest
  | WasteCorrectionsListLoadedSuccess
  | WasteCorrectionsListLoadedError
  // List pollutants for document
  | ListDocumentPollutantsRequest
  | DocumentPollutantsListLoadedSuccess
  | DocumentPollutantsListLoadedError;
