import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppBreakpoints } from 'src/app/providers/_enum';

type AppBreakpointsState = Observable<{ [K in AppBreakpoints]: boolean }>;

@Injectable({
  providedIn: 'root',
})
export class AppBreakpointsService {
  public state: AppBreakpointsState;
  public sidenavToggle$ = new BehaviorSubject(false);
  public withoutMenu$ = new BehaviorSubject(false);
  public isTreePage$ = new BehaviorSubject(false);

  public isMobileXS: Observable<boolean>;
  public isMobileS: Observable<boolean>;
  public isMobile: Observable<boolean>;
  public isTablet: Observable<boolean>;
  public isDesktop: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.state = this.breakpointObserver
      .observe([AppBreakpoints.desktop, AppBreakpoints.tablet, AppBreakpoints['mobile-s'], AppBreakpoints['mobile-xs']])
      .pipe(map((v) => v.breakpoints)) as AppBreakpointsState;

    this.isMobileXS = this.state.pipe(map((v) => v[AppBreakpoints['mobile-xs']]));
    this.isMobileS = this.state.pipe(map((v) => v[AppBreakpoints['mobile-s']]));
    this.isMobile = this.state.pipe(map((v) => v[AppBreakpoints['mobile-s']] || v[AppBreakpoints['mobile-xs']]));
    this.isTablet = this.state.pipe(map((v) => v[AppBreakpoints.tablet]));
    this.isDesktop = this.state.pipe(map((v) => v[AppBreakpoints.desktop]));
  }
}
