import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, OnvosState } from 'src/app/store/onvos/reducer';

export const featureName = 'onvos';

const featureSelector = createFeatureSelector<OnvosState>(featureName);
const selectCurrentOnvosId = (state: OnvosState) => state.currentOnvosId;
const { selectEntities } = adapter.getSelectors();

export const selectOnvosEntities = createSelector(featureSelector, selectEntities);

export const selectCurrentId = createSelector(featureSelector, selectCurrentOnvosId);

export const selectCurrentOnvos = createSelector(selectOnvosEntities, selectCurrentId, (dict, currentOnvosId) => dict[currentOnvosId]);
