import { Location, NgIf, AsyncPipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import moment from 'moment';
import { of, Subscription } from 'rxjs';
import { switchMap, map, filter, delay, expand } from 'rxjs/operators';
import { PreloaderComponent } from 'src/app/components/preloader/preloader.component';
import { ModalShowErrorService } from 'src/app/modules/modal-show-error/services/modal-show-error.service';
import { IPageError } from 'src/app/providers/_interfaces/page.error';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { ErrorPageService } from 'src/app/providers/_services/error.page.service';
import { MaintenanceService } from 'src/app/providers/_services/maintenance.service';
import { environment } from 'src/environments/environment';

export function reloadDebuggerScript() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = '; (function () { debugger; }());';
  document.documentElement.appendChild(script);
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, PreloaderComponent, RouterOutlet, AsyncPipe],
})
export class AppComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  set subscriptionsPush(x: Subscription) {
    this._subscriptions.push(x);
  }

  public is$ = this.maintenance.checkByEnvOrFile().pipe(
    switchMap((is) => (is ? of(true) : this.auth.checkUser())),
    map(() => {
      return true;
    }),
  );

  constructor(
    private location: Location,
    private router: Router,
    private maintenance: MaintenanceService,
    private auth: AuthenticationService,
    private errorPageService: ErrorPageService,
    private modalShowErrorService: ModalShowErrorService, // Not remove, need for initialization,
  ) {
    window['appEnvironment'] = environment;
    window['moment'] = moment;
    if (environment.production) {
      this.subscriptionsPush = this.auth.scopeRepo$.pipe(
        filter(scopeRepo => !(scopeRepo.admin.isActive || scopeRepo.staff.isActive)),
        expand((scopeRepo) => of(scopeRepo).pipe(delay(1000))),
      ).subscribe(() => reloadDebuggerScript());
    }
  }

  ngOnInit(): void {
    this.subscriptionsPush = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const previousUrl = sessionStorage.getItem('currentUrl') || '';
      const previousUrlWithOutParams = previousUrl.split('?')[0] || '';

      const currentUrl = this.location.path() || '';
      const currentUrlWithOutParams = currentUrl.split('?')[0] || '';

      if (
        previousUrlWithOutParams !== currentUrlWithOutParams &&
        !['/error-page', '/wait-page', '/login', '/logout', '/company'].includes(currentUrlWithOutParams)
      ) {
        sessionStorage.setItem('previousUrl', previousUrl);
        sessionStorage.setItem('currentUrl', currentUrl);
        let error: IPageError;

        try {
          error = JSON.parse(sessionStorage.getItem('lastPageError'));
        } catch (err) {}

        if (error && !(error.status_code === 404 && error.header === 'Страница не найдена')) {
          this.errorPageService.clearErrorInStorage();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
