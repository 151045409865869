import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { get } from 'lodash';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, finalize, filter, map, catchError, switchMap, shareReplay, debounceTime } from 'rxjs/operators';
import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { IPublicRouteEnv, IPublicRouteFn } from 'src/app/providers/_interfaces/common';
import { IGuardOptions } from 'src/app/providers/_interfaces/guard.intefaces';
import { Organization, User2Org } from 'src/app/providers/_interfaces/organization.interface';
import { IPagination } from 'src/app/providers/_interfaces/pagination.interface';
import { IRegistrationRequest, IRegistrationResponse } from 'src/app/providers/_interfaces/registration.interface';
import { roivTerOrgsWithFederalRights, TerritoryOrg, TerritoryOrgType } from 'src/app/providers/_interfaces/territory.org';
import { Profile, RpnUser } from 'src/app/providers/_interfaces/user.interface';
import { UserScopesRepo } from 'src/app/providers/_scopes';
import { ErrorPageService } from 'src/app/providers/_services/error.page.service';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { ToastrService } from 'src/app/providers/_services/toastr.service';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    authenticationService: AuthenticationService;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userTokenCacheTimeout: number;
  private userTokenCache$: undefined | Observable<RpnUser | null>;
  public jwtToken$ = new BehaviorSubject('');
  public isXSRFTokenExist$ = new BehaviorSubject(false);
  public changeEditMode = new BehaviorSubject(false);
  public user$: BehaviorSubject<RpnUser | null> = new BehaviorSubject<RpnUser | null>(null);
  public updateDataEmit$ = new BehaviorSubject(null);

  public publicRoutesByEnv: IPublicRouteEnv[] = [
    {
      url: '/',
      role: { id: null, name: 'USER_UONVOS_PUBLIC', description: '' },
      environment: EnvironmentType.onvos,
    },
    {
      url: '/',
      role: { id: null, name: 'REESTRS_PUBLIC', description: '' },
      environment: EnvironmentType.registries,
    },
  ];
  public isDisabledPublicOnvosReestr$ = new BehaviorSubject(false);

  public publicRoutesByUrl: IPublicRouteFn[] = [
    {
      fn: (next) => {
        return (
          next.routeConfig.path === '' &&
          next.firstChild.routeConfig.path === 'documents-registry' &&
          next.firstChild.children[0].routeConfig.path === ''
        );
      },
      role: { id: null, name: 'DOCUMENTS_PUBLIC', description: '' },
    },
    {
      fn: (next) => {
        return (
          next.routeConfig.path === '' &&
          next.firstChild.routeConfig.path === 'documents-registry' &&
          next.firstChild.firstChild.routeConfig.path === 'document/view/:id'
        );
      },
      role: { id: null, name: 'DOCUMENTS_PUBLIC', description: '' },
    },
  ];

  public checkJwtToken$(): Observable<any> {
    let token = this.jwtToken$.value;
    if (!token)
      try {
        token = localStorage.getItem('jwt');
      } catch (err) {}

    const isNullUser = this.user$.value?.id === null;

    if (isNullUser) {
      return of(null);
    }

    const tokenDecode = token ? jwtDecode<JwtPayload>(token) : null;
    const expDate = tokenDecode?.exp || 0;
    const currDate = new Date().getTime() / 1000;
    const hasValid = currDate < expDate - 10 * 60;
    if (!hasValid)
      try {
        localStorage.remove('jwt');
      } catch (err) {}

    return hasValid
      ? of(token).pipe(tap((token) => this.jwtToken$.next(token)))
      : this.svcRestService.postByUrl('/api/auth/jwt').pipe(
          catchError((error) => {
            this.toastr.error(error.message, 'JWT Error');

            return of({ token: '' });
          }),
          tap((res: { public_key: string; token: string }) => {
            // const public_key = res.public_key.replace('-----BEGIN PUBLIC KEY-----\n', '').replace('\n-----END PUBLIC KEY-----', '');
            // console.log(public_key);
            try {
              localStorage.setItem('jwt', res.token);
            } catch (err) {}
            this.jwtToken$.next(res.token);
          }),
          map((res) => res.token),
        );
  }

  public scopeRepo$: Observable<UserScopesRepo> = this.user$.pipe(
    filter((user) => !!user),
    map((user) => new UserScopesRepo(this.getUserRoles(user))),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** Количество профилей организаций у пользователя */
  public profilesCount = 0;

  constructor(
    public errorPageService: ErrorPageService,
    public svcRestService: SvcRestService,
    public router: Router,
    public toastr: ToastrService,
  ) {
    window.authenticationService = this;
  }

  getUserRoles(user: RpnUser | null): string[] {
    const roles: string[] = [];

    if (user?.roles) {
      roles.push(...user.roles.map((v) => v.name));
    }

    if (user?.profile?.roles) {
      roles.push(...user.profile.roles.map((v) => v.name));
    }

    return roles;
  }

  setUser(user: RpnUser) {
    this.user$.next(user);
    sessionStorage.setItem('user_info', JSON.stringify(user));
  }

  updateUserProfile(profile: Profile) {
    const user = this.getUser();

    if (user) {
      user.profile = profile;
      this.setUser(user);
    }
  }

  getUser(): RpnUser | null {
    try {
      const user_info = sessionStorage.getItem('user_info');
      const user = user_info ? JSON.parse(user_info) : this.user$.value;
      const isPublicUserMode = !user && !!user?.is_public;
      if (!isPublicUserMode) this.user$.next(user);
      return user;
    } catch (err) {
      console.error(err);
    }
    return null;
  }

  isAdmin(): boolean {
    const roles = get(this.user$.value, ['roles'], null);

    return (roles || []).map((r) => r.name).indexOf('admin') > -1;
  }

  hasRole(role: string): boolean {
    const user = this.getUser();
    const roles = this.getUserRoles(user) || [];
    return !!roles.find((r) => r === role);
  }

  // getUserTerrOrg(): TerritoryOrg {
  //   const user = this.getUser();
  //   return get(user, ['profile', 'organization', 'territory_org'], null);
  // }

  getUserRegionIds(): number[] | null {
    return get(this.user$.value || this.getUser(), ['profile', 'organization', 'territory_org', 'region_ids'], []);
  }

  clearUser() {
    this.profilesCount = 0;
    sessionStorage.removeItem('user_info');
    this.user$.next(null);
  }

  clear() {
    this.clearUserTokenCache();
    this.clearUser();
  }

  onvosForGuard(options: IGuardOptions) {
    return this.scopeRepo$.pipe(
      map((scopeRepo) => {
        if (scopeRepo) {
          if (options.rules) {
            for (const item of options.rules) {
              if (scopeRepo[item.scope].isActive) {
                const isRedirect = Array.isArray(item.result);

                if (isRedirect) {
                  this.router.navigate(item.result as any[]);
                  return true;
                } else {
                  return item.result as boolean
                }
              }
            }
          }
        }
      })
    );
  }

  public createCheckForGuard(options: IGuardOptions) {
    return this.checkUser().pipe(
      switchMap((res) => (res ? this.scopeRepo$ : of(null))),
      map((scopeRepo) => {
        if (scopeRepo) {
          if (options.rules) {
            for (const item of options.rules) {
              if (scopeRepo[item.scope].isActive) {
                const isRedirect = Array.isArray(item.result);

                if (isRedirect) {
                  this.router.navigate(item.result as any[]);
                  return true;
                } else {
                  return item.result as boolean;
                }
              }
            }
          }

          if (options.other) {
            this.router.navigate(options.other);
            return true;
          }
        }

        this.errorPageService.updateErrorInStorage({
          status_code: 403,
          header: 'Доступ запрещен',
        });

        return true;
      }),
    );
  }

  public getTerritoryOrg(): TerritoryOrg {
    return get(this.user$.value || this.getUser(), ['profile', 'organization', 'territory_org'], null);
  }

  public getOrgType(): TerritoryOrgType {
    return get(this.getTerritoryOrg(), ['org_type'], null);
  }

  public isROIV() {
    const terOrg = this.getTerritoryOrg();

    return this.getOrgType() === TerritoryOrgType.roiv && !roivTerOrgsWithFederalRights[terOrg.id];
  }

  public isROIVAll() {
    return this.getOrgType() === TerritoryOrgType.roiv;
  }

  public isSECURE() {
    return this.getOrgType() === TerritoryOrgType.secure;
  }

  public isTO() {
    return this.getOrgType() === TerritoryOrgType.to;
  }

  public isFCAO() {
    return this.getOrgType() === TerritoryOrgType.fcao;
  }

  public isCA() {
    return this.getOrgType() === TerritoryOrgType.ca;
  }

  public login(email: string, password: string): Observable<any> {
    this.clear();
    return this.svcRestService.postByUrl('/api/auth/login', { email, password }).pipe(tap(() => this.gotoMainPageWithCheckUser()));
  }

  public logoutWithEsiaOption(): Observable<any> {
    const user = this.user$.value;
    return this.logout(!!(user && user.esia_authorized && confirm('Выйти из учетной записи ESIA?')));
  }

  public logout(esiaLogout?: boolean): Observable<any> {
    this.clear();

    return this.svcRestService.postByUrl(`/api/auth/logout${esiaLogout ? '?with_esia=true' : ''}`, {}).pipe(
      tap((res: any) => {
        if (res.redirect) {
          window.open(res.redirect, '_blank');
        }
      }),
      debounceTime(2000),
      finalize(() => this.router.navigate(['/'])),
    );
  }

  /** Перейти на главную страницу закрытой части приложения */
  private gotoMainPageWithCheckUser(): void {
    if (this.checkUser()) this.router.navigate(['/']);
  }

  public gotoLogin(): void {
    this.router.navigate(['/login']);
  }

  public gotoLogout(): void {
    this.router.navigate(['/logout']);
  }

  public throwFail(): void {
    this.toastr.error('Необходимо авторизоваться', 'Сессия завершена');
    this.clearUser();
    this.gotoLogin();
  }

  /**
   * Инициализировать или обновить токен,
   * Проверить пользователя и обновить стейт.
   */
  public getUserAndInitToken(withAuthInfoUpdate?: boolean): Observable<RpnUser | null> {
    if (this.userTokenCache$ && !withAuthInfoUpdate) return this.userTokenCache$;

    return (this.userTokenCache$ = this.svcRestService.fetchByUrl<RpnUser>('/api/auth/me').pipe(
      map((newUser) => {
        if (newUser) this.setUser(newUser);
        return newUser;
      }),
      tap((user) => {
        if (user) return (this.userTokenCache$ = this.user$.asObservable());

        this.userTokenCacheTimeout = window.setTimeout(
          () => this.clearUserTokenCache(),
          180000, // 1000 * 60 * 3 minutes
        );
      }),
      catchError(() => {
        this.clearUserTokenCache();
        return of(null);
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    ));
  }

  private clearUserTokenCache(): void {
    this.userTokenCache$ = void 0;
    try {
      localStorage.removeItem('jwt');
    } catch (err) {}
    clearTimeout(this.userTokenCacheTimeout);
  }

  /**
   * Проверить пользователя
   *
   * @param askToMainPage True, если нужно попроситься войти в закрытую часть приложения.
   *
   * @returns Observable\<boolean> isFullAuth = true, если пользователь полностью авторизован,
   * т. е. выбрана организация, подтверждён Email и т. п.
   */
  public checkUser(askToMainPage = true): Observable<boolean> {
    const userValue = this.user$.value;
    if (userValue) return of(askToMainPage ? this.askToMainPage(userValue) : true);
    return this.getUserAndInitToken().pipe(
      map((user) => {
        if (!user) return false;
        return askToMainPage ? this.askToMainPage(user) : true;
      }),
    );
  }

  /** Попроситься войти в закрытую часть приложения */
  private askToMainPage(user: RpnUser): boolean {
    if (
      !user.esia_authorized &&
      !user.email_verified_at &&
      environment.SYSTEM_TYPE !== EnvironmentType.onvos &&
      environment.SYSTEM_TYPE !== EnvironmentType.registries
    ) {
      this.disallowAndRedirect('/verify_email', { error: 'require-verify' });
      return false;
    }
    if (environment.SYSTEM_TYPE === EnvironmentType.onvos) {
      return this.askToMainPageOnvos(user);
    }
    return this.askToMainPageAny(user);
  }

  private askToMainPageOnvos(user: RpnUser): boolean {
    if (!user.profile && !user.is_public) {
      return this.disallowAndRedirect('/company');
    }

    return true;
  }

  private askToMainPageAny(user: RpnUser): boolean {
    if (!this.askToMainPageKsv(user)) return false;

    if (!user.profile && ((environment.SYSTEM_TYPE !== EnvironmentType.lkae) && (environment.SYSTEM_TYPE !== EnvironmentType.lkoi))) return this.disallowAndRedirect('/company');

    if (environment.SYSTEM_TYPE === EnvironmentType.ksv && !get(user, 'profile.organization.territory_org.id', false)) {
      this.toastr.error('Организация не является террорганом', 'Нет доступа');
      return this.disallowAndRedirect('/company');
    }

    return true;
  }

  askToMainPageKsv(user: RpnUser): boolean {
    if (environment.SYSTEM_TYPE === EnvironmentType.ksv && !user.esia_authorized) {
      this.toastr.error('Авторизируйтесь через Госуслуги', 'Нет доступа');
      return this.disallowAndRedirect('/logout');
    }
    return true;
  }

  private disallowAndRedirect(url: string, queryParams?: any): false {
    this.router.navigate([url], { queryParams });
    return false;
  }

  public getMyOrg(pagination?: { [key: string]: any }, filters?: { [key: string]: any }): Observable<IPagination<User2Org>> {
    return this.svcRestService
      .fetchByUrl<IPagination<User2Org>>('/api/auth/me/orgs', { pagination, filters })
      .pipe(tap((user2orgs) => (this.profilesCount = (user2orgs.data || []).length)));
  }

  public selectOrg(id: number): Observable<Organization> {
    return this.svcRestService.postByUrl<Organization>('/api/auth/select_org', { id }).pipe(
      tap(() => {
        this.clear();
        this.gotoMainPageWithCheckUser();
      }),
    );
  }

  public resendVerifyEmail(): Observable<any> {
    return this.svcRestService.postByUrl('/api/auth/email/resend', {});
  }

  public resetPassword(email: string): Observable<any> {
    return this.svcRestService.postByUrl('/api/auth/reset', { email });
  }

  public changePassword(obj: any): Observable<any> {
    return this.svcRestService.postByUrl('/api/auth/reset/change', obj).pipe(
      tap(() => {
        this.clearUserTokenCache();
        this.gotoMainPageWithCheckUser();
      }),
    );
  }

  public registration(data: IRegistrationRequest): Observable<IRegistrationResponse> {
    return this.svcRestService.postByUrl<IRegistrationResponse>('/api/auth/register', data).pipe(
      tap(() => {
        this.clearUserTokenCache();
        this.gotoMainPageWithCheckUser();
      }),
    );
  }

  public addOrgInProfile(data: any): Observable<Organization> {
    return this.svcRestService.postByUrl<User2Org>('/api/auth/me/org', data).pipe(
      switchMap((org) => this.selectOrg(org.id)),
      tap(() => this.toastr.success('Можете пользоваться системой как сотрудник этой организации', 'Организация добавлена')),
    );
  }

  public fetchUser(): Observable<any> {
    return this.svcRestService.fetchByUrl('/api/auth/user');
  }

  public patchUser(data: { [key: string]: any }): Observable<any> {
    return this.svcRestService.postByUrl('/api/auth/user', data);
  }

  public fetchProfile(): Observable<any> {
    return this.svcRestService.httpGetWithCache('/api/auth/profile?relations=attorney_file_attachment_file');
  }

  public fetchRpnEmployee(): Observable<RpnUser['rpn_employee']> {
    return this.getUserAndInitToken().pipe( map(({ rpn_employee }) => rpn_employee) );
  }

  public patchProfile(data: any): Observable<any> {
    return this.svcRestService.postByUrl('/api/auth/profile', data);
  }

  public updateProfile(): Observable<any> {
    return this.svcRestService.postByUrl('/api/auth/profile/update-user-org', {});
  }

  public applicationVersion(): Observable<string> {
    return this.svcRestService.httpGetWithCache<{ version: string }>('/api/version').pipe(
      filter((x) => !!x),
      map((res) => res.version),
      tap((version) => {
        const oldVersion = sessionStorage.getItem('ver');
        if (version !== oldVersion) {
          console.log('CLEAR');
          try {
            const user_info = sessionStorage.getItem('user_info');
            localStorage.clear();
            sessionStorage.clear();
            sessionStorage.setItem('ver', version);
            sessionStorage.setItem('user_info', user_info);
          } catch (err) {}
        }
      }),
    );
  }
}
