import {
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Output,
  EventEmitter,
  Directive,
} from '@angular/core';
import { UntypedFormControl, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { get, isEqual } from 'lodash';
import { MdbAutoCompleterComponent } from 'ng-uikit-pro-standard';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map, tap, shareReplay, startWith } from 'rxjs/operators';
import { IFiasInfo } from 'src/app/components/autocomplete-dadata/form/autocomplete-dadata.component';
import { AppFormFieldClass } from 'src/app/providers/_classes/app.form.field.class';
import { EInputType } from 'src/app/providers/_const/input.type.const';
import { DictionaryDaDataName } from 'src/app/providers/_dictionary';
import { InputCategory } from 'src/app/providers/_enum';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { getHash } from 'src/app/providers/_utils/utils';

@Directive()
export abstract class BaseAutocompleteDadataClass extends AppFormFieldClass<any> implements OnInit, OnChanges, OnDestroy {
  @Input() dictKey: DictionaryDaDataName;
  @Input() searchKey = 'name';
  @Input() sendKey = 'id';
  @Input() additionalKey: string;
  @Input() minChars = 2;
  @Input() isLoaded = true;
  @Input() allowNonDictionary: boolean;
  @Input() public category: InputCategory = InputCategory.input;
  @Input() public filled = false;
  @Input() public needSaveSearchText = false;
  @Input() rowsCount = 3;
  @Input() showClearButton = true;

  // FIAS Variables
  @Input() hasFiasInfo: boolean;
  fias_control: UntypedFormControl;
  fias_control_name = `${this.formControlName}_fias_info`;
  fias_control_init: UntypedFormControl;
  fias_info_control_name = 'fias_info';
  fias_info$: Observable<{ fias_id: string }>;
  // END FIAS Variables

  private valueChanges$: Observable<string> = this.control.valueChanges.pipe(shareReplay({ bufferSize: 1, refCount: true }));
  public InputCategory = InputCategory;
  public autocompleteValue$: Observable<any[]>;
  public EInputType = EInputType;
  public subs: Subscription[] = [];
  public nonImportantErrors: string[] = [];
  public selectedOptionText: string;
  public fiasSelected = true;

  @Output() itemSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('input') public input: ElementRef;
  @ViewChild('auto') public auto: MdbAutoCompleterComponent;

  get showRefreshButton(): boolean {
    return !!(this.auto?.isOpen());
  }

  constructor(
    public dictionaryService: DictionaryService,
    public cdr: ChangeDetectorRef,
    public fcd: FormGroupDirective,
    public sanitizer: DomSanitizer,
  ) {
    super(fcd, cdr, dictionaryService);
  }

  public clickOutside() {
    if (this.auto) {
      this.auto.hide();
    }
  }

  writeValue(value: string) {
    if (value) {
      this.updateControl(null, value, value);
    } else if (this.formValue) {
      this.updateControl(null, null, null);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.initSearchStringPipe();
    this.initAutoSaveSearchText();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.isLoaded && this.hasFiasInfo && this.fcd.form.enabled) {
      this.fiasInit();
    }
  }

  private fiasInit() {
    this.fias_control_init = this.fcd.form.get(this.fias_info_control_name) as UntypedFormControl;

    this.fias_control_name = `${this.formControlName}_fias_info`;
    this.fcd.form.addControl(this.fias_control_name, new UntypedFormControl());
    this.fias_control = this.fcd.form.get(this.fias_control_name) as UntypedFormControl;

    this.fias_info$ = this.fias_control_init?.valueChanges.pipe(
      startWith(this.fias_control_init.value),
      map((fias_info: IFiasInfo[]) =>
        fias_info?.length ? getHash(fias_info, 'field_name')?.[this.formControlName]?.info : this.control.value ? { fias_id: null } : null,
      ),
    );
  }

  private updateControl(
    selectedObject: Record<string, any> | null,
    searchValue: string | null,
    sendValue: string | null,
    emitEventOptions = { emitSearchValue: false, emitSendValue: false },
    needMarkAsTouched = false,
  ): void {
    this.selectedOptionText = searchValue;
    this.control.setValue(searchValue, { emitEvent: emitEventOptions.emitSearchValue });
    this.formValue = sendValue;
    this.updateFormValue(sendValue, { emitEvent: emitEventOptions.emitSendValue });

    if (needMarkAsTouched) {
      this.updateSlaveControls(selectedObject);
      this.propagateTouched(sendValue);
      if (selectedObject) {
        this.itemSelected.emit(selectedObject);
      }
    }
  }

  private initSearchStringPipe(): void {
    this.autocompleteValue$ = this.valueChanges$.pipe(
      debounceTime(500),
      distinctUntilChanged((a, b) => '' + a === '' + b),
      filter((searchString) => !this.minChars || searchString?.length >= this.minChars || !searchString?.length),
      tap((v) => {
        if (this.allowNonDictionary && this.formValue && v && (this.formValue !== v)) {
          this.updateFormValue(v);
        }
      }),
      switchMap((value) =>
        this.searchItems(value).pipe(
          filter(x => !!x),
          map(({ suggestions }) => suggestions),
          map((items) => {
            return items.map((item) => {
              const key = () => this.additionalKey.split('.').pop();
              const name = () => get(item, this.additionalKey, null);
              let highlight = item.value;
              if (this.additionalKey) highlight += `(${key()}: ${name()})`;

              return { ...item, highlight };
            });
          }),
        ),
      ),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private initAutoSaveSearchText(): void {
    if (this.needSaveSearchText) {
      this.subscriptions.push(
        this.valueChanges$
          .pipe(
            debounceTime(500),
            distinctUntilChanged((a, b) => isEqual(a, b)),
            filter((x) => !!(x?.length > 0 && x !== this.selectedOptionText)),
          )
          .subscribe((value) => this.updateFormValue(value, { emitEvent: true })),
      );
    }
  }

  public selectItemValue(item: { text: any }, dict: any[]): void {
    const selectedObject = dict.find((element) => item.text === element);

    if (selectedObject !== undefined || selectedObject !== null) {
      let sendValue = get(selectedObject, this.sendKey, null);

      if (this.sendKey === 'id') {
        sendValue = +sendValue;
      } else {
        switch (sendValue) {
          case 'true': {
            sendValue = true;
            break;
          }
          case 'false': {
            sendValue = false;
            break;
          }
        }
      }
      const searchValue = get(selectedObject, this.searchKey || this.sendKey, sendValue);
      this.updateControl(selectedObject, searchValue, sendValue, { emitSearchValue: true, emitSendValue: true }, true);
    }

    this.input.nativeElement.blur();
  }

  private searchItems(value: string) {
    return value ? this.dictionaryService.getDadataSuggest(value, this.dictKey) : of({ suggestions: [] });
  }

  getOldItemName(oldValue: any): Observable<any> {
    return !oldValue ? of('пусто') : of(oldValue);
  }

  public onBlur(_event: any): void {
    this.control.updateValueAndValidity();
  }

  public onFocus(_event: any): void {
    this.control.updateValueAndValidity();
  }

  resetFias() {
    if (this.hasFiasInfo && this.fias_control && this.fias_control_init.value && this.fiasSelected) {
      this.fias_control.setValue(null);
      this.fias_control.markAsDirty();
      this.fias_control_init?.setValue(null);
    }
  }

  public onClearButtonClick(): void {
    this.resetFias();
    this.updateFormValue(null);
    this.updateSlaveControls(null);
    this.itemSelected.emit(null);
    this.onChange.emit(null);
    this.cdr.detectChanges();
  }
}
