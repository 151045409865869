/* eslint-disable max-len */
export const UISectionText = {
  mineral_license_tooltip: 'Серия: от 1 до 3 букв, номер: от 1 до 6 цифр, вид (тип): от 1 до 2 букв. Например: ААА 00000 АА, АА 00000 АА',
  attorney_file_attachment_tooltip: 'Приложите доверенности, объемом не больше 50 МБ',
  attorney_permitting_document_tooltip: 'Приложите разрешительные документы, объемом не больше 50 МБ',
  main_category_tooltip: 'Заполните категорию негативного воздействия объекта, уровень (виды) государственного надзора',
  category_tooltip:
    'Уровень государственного надзора определяется в соответствии с критериями определения объектов, подлежащих федеральному государственному экологическому контролю (надзору), утвержденными постановлением Правительства РФ от 30.06.2021 № 1096. Виды федерального экологического надзора определяются в соответствии с постановлениями Правительства РФ от 30.06.2021 № 1095, от 30.06.2021 № 1081',
  after_update_criterions: 'После обновления обоснования происходит калькуляция уровня государственного надзора',
  phone_ext_tooltip:
    'Для указания добавочного номера, укажите сначала основной телефон, а затем доб 111, доб. 111, (доб 111) или (доб. 111)',
  from_request:
    'Выбор критериев присвоения категории негативного воздействия и уровня государственного реестра, указанных заявителем в разделе 2.4 "Категория объекта и уровень экологического надзора"',
  gsk_tooltip:
    'Координаты угловых точек объекта в государственной системе координат, установленной в соответствии с ч.  2 ст. 7 ФЗ от 30.12.2015 № 431-ФЗ',
  gsk_tooltip_type:
    'Поле необязательно для заполнения. Тип для объекта с координатами угловых точек в государственной системе координат, установленной в соответствии с ч.  2 ст. 7 ФЗ от 30.12.2015 № 431-ФЗ',
  wgs_tooltip: 'Координаты угловых точек объекта во Всемирной геодезической системе координат (WGS-84)',
  co2_tooltip_v2:
    'В соответствии с методическими указаниями и руководством по количественному определению объема выбросов парниковых газов организациями, осуществляющими хозяйственную и иную деятельность в РФ, утвержденными приказом Минприроды России от 27.05.2022 № 371',
  co2_tooltip:
    'В соответствии с методическими указаниями и руководством по количественному определению объема выбросов парниковых газов организациями, осуществляющими хозяйственную и иную деятельность в РФ, утвержденными приказом Минприроды России от 30 июня 2015 г. N 300, фактическая масса выбросов парникового газа определяется в пересчете на углекислый газ',
};
