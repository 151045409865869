import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isHTMLElement } from 'src/app/providers/_utils/utils';

@Directive({
  selector: '[appSpinner]',
  exportAs: 'appSpinner',
  standalone: true,
})
export class EnvelopeSpinnerDirective implements OnChanges {
  static OBSERVER_CONFIG = { attributes: true, childList: true, characterData: false };

  @Input() public showSpinner: boolean;
  @Input() public sizeMultiplier = 1;
  @Input() public containerWidth: string;
  @Input() public spinnerColor = 'rgba(0,0,0,1)';
  @Input() public spinnerTransparentText = true;
  @Input() public spinnerCenter = false;
  @Input() public spinnerDisplay = 'inline-block';

  private positioningChangedOnParent: boolean;
  private observer?: MutationObserver;
  private id: string;

  constructor(private elRef: ElementRef) {}

  getSpinnerElement(): ChildNode {
    return this.elRef.nativeElement.querySelectorAll(`#${this.id}`)[0];
  }

  addSpinner() {
    if (this.getSpinnerElement()) {
      return;
    }

    const compStyles = getComputedStyle(this.elRef.nativeElement);

    if (compStyles.position === 'static') {
      this.elRef.nativeElement.style.position = 'relative';
      this.positioningChangedOnParent = true;
    }

    this.hideBlockContent();

    this.observer = new MutationObserver(() => {
      this.hideBlockContent();
    });

    this.observer.observe(this.elRef.nativeElement, EnvelopeSpinnerDirective.OBSERVER_CONFIG);

    const div = document.createElement('div');
    this.id = `spinner-container-${Date.now()}`;

    const styles = [`height: ${6 * this.sizeMultiplier}px`, `width: ${24 * this.sizeMultiplier}px`, `display: ${this.spinnerDisplay}`];

    if (this.spinnerCenter) {
      styles.push(...['position: absolute', 'margin: auto', 'left: 0', 'right: 0', 'top: 0', 'bottom: 0']);
    }

    const svg = `<svg xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 120 30"
                      fill="${this.spinnerColor}">
                      <circle cx="15" cy="15" r="15" fill-opacity="0.25">
                            <animate id="dot1"
                                     dur="0.5s"
                                     values="0.25;1"
                                     attributeName="fill-opacity"
                                     begin="0s;dot3.end"
                                     calcMode="linear"/>
                          </circle>
                      <circle cx="55" cy="15" r="15" fill-opacity="0.25">
                        <animate id="dot2"
                                 attributeName="fill-opacity"
                                 begin="dot1.end"
                                 dur="0.5s"
                                 values="0.25;1"
                                 calcMode="linear"/>
                      </circle>
                      <circle cx="95" cy="15" r="15" fill-opacity="0.25">
                        <animate attributeName="fill-opacity"
                                 id="dot3"
                                 values="0.25;1"
                                 begin="dot2.end"
                                 dur="0.5s"
                                 calcMode="linear"/>
                      </circle>
                     </svg>`;

    div.innerHTML = `<div id="${this.id}" style="${styles.join(';')}">${svg}</div>`;

    const el = div.querySelector(`#${this.id}`);
    this.elRef.nativeElement.insertAdjacentElement('beforeend', el);
  }

  removeSpinner() {
    if (!this.getSpinnerElement()) {
      return;
    }

    if (this.observer) {
      this.observer.disconnect();
      delete this.observer;
    }

    if (this.positioningChangedOnParent) {
      this.elRef.nativeElement.style.position = '';
      this.positioningChangedOnParent = false;
    }

    this.showBlockContent();
    const spinnerElement = this.getSpinnerElement();
    if (spinnerElement && isHTMLElement(spinnerElement)) spinnerElement.remove();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { showSpinner } = changes;

    showSpinner.currentValue ? this.addSpinner() : this.removeSpinner();
  }

  getChildrenElements(): HTMLElement[] {
    const arr: HTMLElement[] = Array.from(this.elRef.nativeElement.children);

    return arr.filter((el: HTMLElement) => el.id !== this.id);
  }

  private hideBlockContent() {
    const compStyles = getComputedStyle(this.elRef.nativeElement);

    this.elRef.nativeElement.style.minWidth = this.containerWidth || compStyles.width;
    this.elRef.nativeElement.style.minHeight = compStyles.height;

    if (this.spinnerTransparentText) {
      this.elRef.nativeElement.style.color = 'transparent';
    }

    const childrenElements = this.getChildrenElements();

    for (const childrenElement of childrenElements) {
      childrenElement.style.display = 'none';
    }
  }

  private showBlockContent() {
    this.elRef.nativeElement.style.minWidth = '';
    this.elRef.nativeElement.style.minHeight = '';
    this.elRef.nativeElement.style.color = '';

    const childrenElements = this.getChildrenElements();

    for (const childrenElement of childrenElements) {
      childrenElement.style.display = '';
    }
  }
}
