export interface IAttachment {
  id?: number;
  number?: string;
  name?: string;
  date?: string;
  comment?: string;
  attachments: number[];
  attachments_files: any[];
}

export interface IFileAttachment {
  id?: number;
  number?: string;
  name?: string;
  date?: string;
  created_at: string;
  download_link?: string;
  extension?: string;
  file_name?: string;
  formatted_size?: string;
  gost?: string;
  has_html?: boolean;
  has_pdf?: boolean;
  has_thumb?: boolean;
  html_link?: string;
  md5?: string;
  pdf_link?: string;
  signs_count?: number;
  size?: 59156;
  thumb_link?: string;
  updated_at?: string;
}

export enum BadgeStatus {
  new = 'NEW',
  failure = 'FAILURE',
  success = 'SUCCESS',
  complete = 'COMPLETE',
}
