import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, ChargeState } from 'src/app/store/payment-charge/reducer';

export const featureName = 'charge';

const featureSelector = createFeatureSelector<ChargeState>(featureName);
const selectCurrentChargeId = (state: ChargeState) => state.currentChargeId;
const { selectEntities } = adapter.getSelectors();

export const selectChargeEntities = createSelector(featureSelector, selectEntities);

export const selectCurrentId = createSelector(featureSelector, selectCurrentChargeId);

export const selectCurrentCharge = createSelector(selectChargeEntities, selectCurrentId, (dict, currentChargeId) => dict[currentChargeId]);
