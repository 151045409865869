import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { get } from 'lodash';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ModalShowErrorComponent } from 'src/app/modules/modal-show-error/modal-show-error.component';
import { MODAL_BASE_OPTIONS } from 'src/app/modules/treeview/treeview.const';
import { IServerError } from 'src/app/providers/_interfaces/common';
import { EssenceActionTypes } from 'src/app/store/essence/actions';

@Injectable({
  providedIn: 'root',
})
export class ModalShowErrorService {
  static instance: ModalShowErrorService;

  serverError$: BehaviorSubject<IServerError> = new BehaviorSubject<IServerError>(null);
  closeModal$ = new Subject<void>();

  constructor(
    private modalService: MDBModalService,
    private actions$: Actions,
  ) {
    ModalShowErrorService.instance = this;

    this.actions$
      .pipe(ofType(EssenceActionTypes.RouteLoadedError, EssenceActionTypes.EssenceLoadedError, EssenceActionTypes.EssenceUpdatedError))
      .subscribe((err) => {
        const statusCode = get(err, 'payload.error.status');

        if (statusCode === 422) {
          this.serverError$.next(err);
          return;
        }

        if (statusCode === 424) {
          const isCustomError = get(err, 'payload.error.error.context.errors');

          this.serverError$.next(err);
          this.openModal(isCustomError ? get(err, 'payload.error') : err)
            .pipe(take(1))
            .subscribe();
          return;
        }

        this.serverError$.next(err);
        this.openModal(err).pipe(take(1)).subscribe();
      });
  }

  openModal(error: IServerError): Observable<void> {
    this.closeModal$ = new Subject<void>();

    const modalRef = this.modalService.show(ModalShowErrorComponent, {
      ...MODAL_BASE_OPTIONS,
      data: {
        content: {
          error,
          closeModal$: this.closeModal$,
        },
      },
    });

    return this.closeModal$.pipe(
      tap(() => {
        this.serverError$.next(null);
        modalRef.hide();
      }),
    );
  }
}
