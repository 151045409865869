import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { StatusCodes } from 'http-status-codes';
import { interval, Subscription } from 'rxjs';
import { finalize, takeWhile } from 'rxjs/operators';
import { IPageError } from 'src/app/providers/_interfaces/page.error';
import { ErrorPageService } from 'src/app/providers/_services/error.page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, RouterLink],
})
export class PageErrorComponent implements OnInit, OnDestroy {
  public StatusCodes = StatusCodes;
  private subscription: Subscription;
  timer_min = 0;
  timer_sec = 0;
  throttle_timeout_min = environment.throttle_timeout_min;
  error_start_timestamp = +new Date();
  isNaN = isNaN;
  error: IPageError;
  needRootNavigate = false;
  previousUrl: string;
  standard_text = `Уважаемые коллеги!
  В настоящее время ведутся работы по восстановлению работоспособности системы!`;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public errorPageService: ErrorPageService,
  ) {}

  ngOnInit() {
    this.error = this.errorPageService.lastErrorState;

    try {
      this.previousUrl = (sessionStorage.getItem('previousUrl') || '/').split('?')[0];
    } catch (err) {}

    if (!this.error) this.returnToPrevious();
    if (this.error?.status_code === 404 && this.error.header === 'Страница не найдена') return;

    let tm = 0;
    try {
      tm = +sessionStorage.getItem('error_start_timestamp');
    } catch (err) {}

    if (tm) {
      if (tm + this.throttle_timeout_min * 60 * 1000 > +new Date()) this.error_start_timestamp = tm;
    }
    try {
      sessionStorage.setItem('error_start_timestamp', '' + this.error_start_timestamp);
    } catch (err) {}

    const ms = this.error_start_timestamp + this.throttle_timeout_min * 60 * 1000 - +new Date();
    const timer_end_timestamp = ms ? Math.round(ms / 1000) : 0;
    this.timer_min = timer_end_timestamp ? Math.trunc(timer_end_timestamp / 60) : 0;
    this.timer_sec = timer_end_timestamp ? timer_end_timestamp % 60 : 0;

    this.subscription = interval(1000)
      .pipe(
        takeWhile(() => this.timer_min + this.timer_sec > 1),
        finalize(() => {
          this.timer_min = 0;
          this.timer_sec = 0;
          if (!this.needRootNavigate) this.returnToPrevious();
        }),
      )
      .subscribe(() => {
        const ms = this.error_start_timestamp + this.throttle_timeout_min * 60 * 1000 - +new Date();
        const timer_end_timestamp = ms ? Math.round(ms / 1000) : 0;
        this.timer_min = timer_end_timestamp > 0 ? Math.trunc(timer_end_timestamp / 60) : 0;
        this.timer_sec = timer_end_timestamp > 0 ? timer_end_timestamp % 60 : 0;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  public returnToMain(): void {
    this.needRootNavigate = true;
    this.router.navigate(['/']);
  }

  public returnToPrevious(): void {
    if (this.previousUrl) {
      this.router.navigate([this.previousUrl]);
    } else {
      this.returnToMain();
    }
  }
}
