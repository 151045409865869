<div [@fadeInAnimation]>
  <form *ngIf="reportForm$ | async as reportForm" [formGroup]="reportForm" (submit)="submit(reportForm)">
    <app-details-edit-mode-card
      [formData]="reportForm"
      [isDisabled]="reportForm.disabled"
      [additionalTitleButtons]="additionalTitleButtons"
    >
      <ng-container
        *ngIf="{
          clearForm: clearForm$ | async,
          type,
          is_author_not_separate,
        } as state"
      >
        <app-switchbox
          label="Заявитель не является лицом, выполнившим подготовку проекта ликвида"
          formControlName="is_author_not_separate"
          [isLoaded]="isDataLoaded"
        />

        <ng-container *ngIf="state.is_author_not_separate">
          <app-select
            *ngIf="state.is_author_not_separate"
            label="Тип лица, выполнившего подготовку проекта ликвидации"
            formControlName="type"
            [dictKey]="DictKEYS.applicant_types"
            [isLoaded]="isDataLoaded"
          />

          <app-input
            *ngIf="state.type === GECVAuthorType.legal"
            label="Полное наименование юридического лица выполнившего подготовку проекта"
            formControlName="full_name_organization"
            [required]="true"
            [isLoaded]="isDataLoaded"
          />

          <app-input
            *ngIf="state.type === GECVAuthorType.legal"
            label="Сокращенное наименование юридического лица выполнившего подготовку проекта"
            formControlName="short_name_organization"
            [isLoaded]="isDataLoaded"
          />

          <ng-container
            *ngIf="state.type === GECVAuthorType.legal"
            [ngTemplateOutlet]="legalAddressStreet"
            [ngTemplateOutletContext]="{ $implicit: 'Адрес юридического лица выполнившего подготовку проекта' }"
          ></ng-container>

          <ng-container
            *ngIf="state.type === GECVAuthorType.legal"
            [ngTemplateOutlet]="postAddressStreet"
            [ngTemplateOutletContext]="{ $implicit: 'Почтовый адрес юридического лица выполнившего подготовку проекта' }"
          ></ng-container>

          <ng-container
            *ngIf="state.type === GECVAuthorType.legal"
            [ngTemplateOutlet]="ogrn"
            [ngTemplateOutletContext]="{ $implicit: 'ОГРН юридического лица выполнившего подготовку проекта' }"
          ></ng-container>

          <ng-container
            *ngIf="state.type === GECVAuthorType.legal"
            [ngTemplateOutlet]="inn"
            [ngTemplateOutletContext]="{ $implicit: 'ИНН юридического лица выполнившего подготовку проекта' }"
          ></ng-container>

          <ng-container
            *ngIf="state.type === GECVAuthorType.legal"
            [ngTemplateOutlet]="email"
            [ngTemplateOutletContext]="{ $implicit: 'Email юридического  лица выполнившего подготовку проекта' }"
          ></ng-container>

          <ng-container
            *ngIf="state.type === GECVAuthorType.legal"
            [ngTemplateOutlet]="phone"
            [ngTemplateOutletContext]="{ $implicit: 'Номер телефона юридического лица выполнившего подготовку проекта' }"
          ></ng-container>

          <ng-container
            *ngTemplateOutlet="surname, context: { $implicit: state.type === GECVAuthorType.legal ? 'Фамилия руководителя' : 'Фамилия' }"
          ></ng-container>

          <ng-container
            *ngTemplateOutlet="name; context: { $implicit: state.type === GECVAuthorType.legal ? 'Имя руководителя' : 'Имя' }"
          ></ng-container>

          <ng-container
            *ngTemplateOutlet="patronymic; context: { $implicit: state.type === GECVAuthorType.legal ? 'Отчество руководителя (если есть)' : 'Отчество' }"
          ></ng-container>

          <app-input
            *ngIf="state.type === GECVAuthorType.legal"
            label="Должность руководителя"
            formControlName="position"
            [required]="true"
            [isLoaded]="isDataLoaded"
          />

          <app-input
            *ngIf="state.type === GECVAuthorType.individual"
            label="Данные документа, удостоверяющего личность индивидуального предпринимателя выполнившего подготовку проекта"
            formControlName="passport"
            [required]="true"
            [isLoaded]="isDataLoaded"
          />

          <ng-container
            *ngIf="state.type === GECVAuthorType.individual"
            [ngTemplateOutlet]="legalAddressStreet"
            [ngTemplateOutletContext]="{ $implicit: 'Адрес индивидуального предпринимателя выполнившего подготовку проекта' }"
          ></ng-container>

          <ng-container
            *ngIf="state.type === GECVAuthorType.individual"
            [ngTemplateOutlet]="postAddressStreet"
            [ngTemplateOutletContext]="{ $implicit: 'Почтовый адрес индивидуального предпринимателя выполнившего подготовку проекта' }"
          ></ng-container>

          <ng-container
            *ngIf="state.type === GECVAuthorType.individual"
            [ngTemplateOutlet]="ogrn"
            [ngTemplateOutletContext]="{ $implicit: 'ОГРН индивидуального предпринимателя выполнившего подготовку проекта' }"
          ></ng-container>

          <ng-container>
            <ng-container
              *ngIf="state.type === GECVAuthorType.individual"
              [ngTemplateOutlet]="inn"
              [ngTemplateOutletContext]="{ $implicit: 'ИНН индивидуального предпринимателя выполнившего подготовку проекта' }"
            ></ng-container>
          </ng-container>

          <ng-container>
            <ng-container
              *ngIf="state.type === GECVAuthorType.individual"
              [ngTemplateOutlet]="email"
              [ngTemplateOutletContext]="{
                $implicit: 'Email индивидуального предпринимателя выполнившего подготовку проекта',
                required: true,
              }"
            ></ng-container>
          </ng-container>

          <ng-container>
            <ng-container
              *ngIf="state.type === GECVAuthorType.individual"
              [ngTemplateOutlet]="phone"
              [ngTemplateOutletContext]="{ $implicit: 'Номер телефона индивидуального предпринимателя выполнившего подготовку проекта' }"
            ></ng-container>
          </ng-container>

          <ng-template #inn let-label>
            <app-autocomplete-dadata
              [label]="label"
              formControlName="inn"
              [isLoaded]="isDataLoaded"
              [dictKey]="DictDadataKEYS.party"
              [additionalKey]="'data.inn'"
              [sendKey]="'data.inn'"
              [required]="true"
              [slaveControls]="{
                'data.name.full_with_opf': reportForm.controls.full_name_organization,
                'data.name.short_with_opf': reportForm.controls.short_name_organization,
                'data.ogrn': reportForm.controls.ogrn,
                'data.address.value': [reportForm.controls.legal_address_street, reportForm.controls.post_address_street],
              }"
            />
          </ng-template>

          <ng-template #ogrn let-label>
            <app-input
              [label]="label"
              formControlName="ogrn"
              [required]="true"
              [isLoaded]="isDataLoaded"
            />
          </ng-template>

          <ng-template #email let-label let-required="required">
            <app-input
              [label]="label"
              formControlName="email"
              [validateFunction]="'email'"
              [required]="required"
              [isLoaded]="isDataLoaded"
            />
          </ng-template>

          <ng-template #phone let-label>
            <app-input
              [label]="label"
              formControlName="phone"
              [isLoaded]="isDataLoaded"
              [showMask]="true"
              [maskString]="'+7(000)000-00-00'"
              [maskPatterns]="phonePattern"
              [validateFunction]="validatePhone"
              [required]="true"
              [ignoreValidators]="true"
              [specialCharacters]="['+', '(', ')', '-']"
            />
          </ng-template>

          <ng-template #surname let-label>
            <app-input
              [label]="label"
              formControlName="surname"
              [required]="true"
              [isLoaded]="isDataLoaded"
            />
          </ng-template>

          <ng-template #name let-label>
            <app-input
              [label]="label"
              formControlName="name"
              [required]="true"
              [isLoaded]="isDataLoaded"
            />
          </ng-template>

          <ng-template #patronymic let-label>
            <app-input
              [label]="label"
              formControlName="patronymic"
              [isLoaded]="isDataLoaded"
            />
          </ng-template>

          <ng-template #legalAddressStreet let-label>
            <app-autocomplete-dadata
              [label]="label"
              formControlName="legal_address_street"
              [dictKey]="DictDadataKEYS.address"
              [searchKey]="'value'"
              [sendKey]="'value'"
              [required]="true"
              [allowNonDictionary]="true"
              [isLoaded]="isDataLoaded"
            />
          </ng-template>

          <ng-template #postAddressStreet let-label>
            <app-autocomplete-dadata
              [label]="label"
              formControlName="post_address_street"
              [dictKey]="DictDadataKEYS.address"
              [searchKey]="'value'"
              [sendKey]="'value'"
              [required]="true"
              [allowNonDictionary]="true"
              [isLoaded]="isDataLoaded"
            />
          </ng-template>
        </ng-container>
      </ng-container>
    </app-details-edit-mode-card>
  </form>
</div>
