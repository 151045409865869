import { AbstractControl, UntypedFormControl, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import geojson$ from 'geojson-validation';
import { isArray, isFunction } from 'lodash';
import moment from 'moment';
import { UI_GOST_DATE_FORMAT } from 'src/app/providers/_const/time';
import { EValidatorErrors } from 'src/app/providers/_directives/validator/validator-errors';
import { SupportedGeometry, SupportedGeoJsonObjectsTypes } from 'src/app/providers/_interfaces/geo.interface';
import { REGEXP } from 'src/app/providers/_regexp';
import { decimalRound } from 'src/app/providers/_utils/utils';

type CallBackNumber = () => number;
type CallBackString = () => string;
type CallBackRegExp = () => RegExp;

export const BUDGET_ACCOUNT_NUMBER_START = [
  '3100',
  '3211',
  '3212',
  '3214',
  '3215',
  '3217',
  '3221',
  '3222',
  '3224',
  '3225',
  '3231',
  '3232',
  '3234',
  '3235',
  '3241',
  '3242',
  '3251',
  '3252',
  '3254',
  '3261',
  '3262',
  '3271',
  '3272',
  '3420',
  '4100',
];

export const BUDGET_CORR_ACCOUNT_NUMBER_START = ['401', '402', '403', '405', '406', '407', '408'];

moment.locale('ru');

type IValidationFunction = (control: AbstractControl, args: any[]) => { message: string | null };

export type IValidateInput =
  | keyof IValidateFunctions
  | IValidationFunction | keyof IValidateFunctions | ValidatorFn
  | (IValidationFunction | keyof IValidateFunctions | ValidatorFn)[];

export interface IValidateFunctions {
  inn: IValidationFunction;
  innPrivate: IValidationFunction;
  kpp: IValidationFunction;
  ogrn: IValidationFunction;
  email: IValidationFunction;
  phone: IValidationFunction;
  fax: IValidationFunction;
  phoneStrict: IValidationFunction;
  bik: IValidationFunction;
  bankaccountnumber: IValidationFunction;
  rs: IValidationFunction;
  ks: IValidationFunction;
  unified_treasury_account: IValidationFunction;
  treasury_account: IValidationFunction;
  bankcorrespondentnumber: IValidationFunction;
  ogrnlegal: IValidationFunction;
  ogrnip: IValidationFunction;
  integer: IValidationFunction;
  passportseria: IValidationFunction;
  passportnumber: IValidationFunction;
  snilsnumber: IValidationFunction;
  notfill0: IValidationFunction;
  required: IValidationFunction;
  olderDate: IValidationFunction;
  olderOrEqualDate: IValidationFunction;
  beforeDate: IValidationFunction;
  min: IValidationFunction;
  max: IValidationFunction;
  minLength: IValidationFunction;
  maxLength: IValidationFunction;
  between: IValidationFunction;
  geojson: IValidationFunction;
  size: IValidationFunction;
  maxMinYearValidation: IValidationFunction;
  mineralLicense: IValidationFunction;
  lowerThanZero: IValidationFunction;
  twoDigitsAfterComma: IValidationFunction;
}

export function translateGeoJsonErrors(errors: any) {
  const polygonThreePoint = 'У типа "Площадной" для каждой площади должно быть не менее 3 точек';
  const lineStringTwoPoint = 'У типа "Линейный" должно быть как минимум две точки';
  const twoElements = 'Для точки должны быть заданы две числовых координаты';
  const incorrectCoords = 'Некорректные координаты';

  const geoJsonMessageTranslate = {
    'The first and last positions must be equivalent': '',
    'coordinates must have at least four positions': '',
    'coordinates must have at least three positions': polygonThreePoint,
    'coordinates must have at least two elements': lineStringTwoPoint,
    'Position must be at least two elements': twoElements,
    'Position must only contain numbers': incorrectCoords,
  };

  const getErrorTrs = (e: string) => {
    let text = geoJsonMessageTranslate[<keyof typeof geoJsonMessageTranslate>e];

    if (!text) {
      for (const errorInEn of Object.keys(geoJsonMessageTranslate)) {
        if (e.indexOf(errorInEn) >= 0) {
          return geoJsonMessageTranslate[<keyof typeof geoJsonMessageTranslate>errorInEn];
        }
      }
    }

    text = text || e;

    return text;
  };

  return errors.map((e: any) => `<div>${getErrorTrs(e)}</div>`);
}

export function getErrorsForGeoJson(value: SupportedGeometry): string[] {
  let errors = [];

  if (value?.type && value.coordinates) {
    switch (value.type) {
      case SupportedGeoJsonObjectsTypes.Point:
        errors = geojson$.isPoint(value, true);
        break;
      case SupportedGeoJsonObjectsTypes.LineString:
        errors = geojson$.isLineString(value, true);
        break;
      case SupportedGeoJsonObjectsTypes.MultiLineString:
        errors = geojson$.isMultiLineString(value, true);
        break;
      case SupportedGeoJsonObjectsTypes.Polygon:
        errors = geojson$.isPolygon(value, true);

        if (value.coordinates.length) {
          for (let index = 0; index < value.coordinates.length; index++) {
            const errorFirstLastIndex = errors.indexOf(`at ${index}: The first and last positions must be equivalent`);
            if (errorFirstLastIndex >= 0) errors.splice(errorFirstLastIndex, 1);
            const errorFourIndex = errors.indexOf('coordinates must have at least four positions');
            if (errorFirstLastIndex >= 0) errors.splice(errorFourIndex, 1);

            if (value.coordinates?.[index]?.length < 3) {
              errors.push('coordinates must have at least three positions');
              break;
            }
          }
        } else {
          errors.push('Координаты объекта не указаны');
        }

        console.log(errors);
        break;
    }
  } else {
    errors.push('Координаты объекта не указаны');
  }

  return errors;
}

export function requiredAndNotEmpty(control: AbstractControl): ValidationErrors | null {
  const val = control.value;

  if ((typeof val === 'string' && val.trim() === '') || val == null || val.length === 0) {
    return { required: true };
  }

  return null;
}

const ValidatorsBIK = (control: AbstractControl) => {
  const result: { message: string | null } = { message: null };

  let bik = control.value;

  if (typeof bik === 'number') {
    bik = bik.toString();
  } else if (typeof bik !== 'string') {
    bik = '';
  }
  if (!bik.length) {
    return result;
  } else if (/[^0-9]/.test(bik)) {
    result.message = 'БИК может состоять только из цифр';
  } else if (bik.length !== 9) {
    result.message = 'БИК может состоять только из 9 цифр';
  }

  return result;
};

const ValidatorsRS = (control: AbstractControl, validateArguments) => {
  const result: { message: string | null } = { message: null };

  let rs = control.value;

  if (typeof rs === 'number') {
    rs = rs.toString();
  } else if (typeof rs !== 'string') {
    rs = '';
  }
  if (!rs.length) {
    return result;
  } else if (/[^0-9]/.test(rs)) {
    result.message = 'Р/С Только цифры';
  } else if (rs.length !== 20) {
    result.message = 'Длина 20 (введено цифр: ' + rs.length + ')';
  }

  if (!result.message) {
    const bikName = (validateArguments || []).length ? validateArguments[0] : 'bik';
    const bikControl = control.parent && control.parent.get(bikName);

    if (!bikControl) {
      return result;
    }

    const resultBIK = ValidatorsBIK(bikControl);

    if (resultBIK.message) {
      result.message = 'Для проверки Р/С укажите верный БИК';
      return result;
    }

    let bik = bikControl.value;

    if (typeof bik === 'number') {
      bik = bik.toString();
    } else if (typeof bik !== 'string') {
      bik = '';
    }

    const bikRs = bik.slice(-3) + rs;
    let checksum = 0;
    const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

    for (const i in coefficients) {
      checksum += coefficients[i] * (bikRs[i] % 10);
    }

    if (checksum % 10 === 0) {
      return result;
    } else {
      result.message = 'БИК и Р/С - неверная контрольная сумма';
      return result;
    }
  }

  return result;
};

const ValidatorsKS = (control: AbstractControl) => {
  const result: { message: string | null } = { message: null };

  let ks = control.value;

  if (typeof ks === 'number') {
    ks = ks.toString();
  } else if (typeof ks !== 'string') {
    ks = '';
  }
  if (!ks.length) {
    return result;
  } else if (/[^0-9]/.test(ks)) {
    result.message = 'К/С может состоять только из цифр';
  } else if (ks.length !== 20) {
    result.message = 'Длина 20 (введено цифр: ' + ks.length + ')';
  }

  return result;
};

export const ValidateUnifiedTreasuryAccount = (val) => {
  const result: { message: string | null } = { message: null };

  if (typeof val === 'number') {
    val = val.toString();
  } else if (typeof val !== 'string') {
    val = '';
  }

  if (!val.length) {
    return result;
  } else if (/[^0-9]/.test(val)) {
    result.message = 'Счет может состоять только из цифр';
  } else if (val.length !== 20) {
    result.message = 'Длина 20 (введено цифр: ' + val.length + ')';
  } else if (BUDGET_CORR_ACCOUNT_NUMBER_START.indexOf(val.substring(0, 3)) < 0) {
    result.message = 'Неверный счет';
  }

  return result;
};

const ValidatorsUnifiedTreasuryAccount = (control: AbstractControl) => {
  return ValidateUnifiedTreasuryAccount(control.value);
};

export const ValidateTreasuryAccount = (val) => {
  const result: { message: string | null } = { message: null };

  if (typeof val === 'number') {
    val = val.toString();
  } else if (typeof val !== 'string') {
    val = '';
  }

  const substr25 = BUDGET_ACCOUNT_NUMBER_START;

  if (!val.length) {
    return result;
  } else if (/[^0-9]/.test(val)) {
    result.message = 'Счет может состоять только из цифр';
  } else if (val.length !== 20) {
    result.message = 'Длина 20 (введено цифр: ' + val.length + ')';
  } else if (val.substring(0, 1) !== '0' || substr25.indexOf(val.substring(1, 5)) < 0 || val.substring(5, 8) !== '643') {
    result.message = 'Неверный счет';
  }

  return result;
};

const ValidatorsTreasuryAccount = (control: AbstractControl) => {
  return ValidateTreasuryAccount(control.value);
};

export const VALIDATORS: IValidateFunctions = {
  email: (control: AbstractControl) => ({ message: !control.value || REGEXP.email.test(control.value) ? null : 'Неверный email' }),
  phone: (control: AbstractControl) => ({ message: !control.value || REGEXP.phone.test(control.value) ? null : 'Неверный телефон' }),
  fax: (control: AbstractControl) => ({ message: !control.value || REGEXP.phone.test(control.value) ? null : 'Неверный номер факса' }),
  phoneStrict: (control: AbstractControl) => {
    const message =
      !control.value || REGEXP.phoneStrict.test(control.value) ? null : 'Номер не соотв. маске: +7 (ХХХ) ХХХ-ХХ-ХХ доб. ХХХХХ';
    return { message };
  },
  mineralLicense: (control: AbstractControl) => {
    const message = !control.value || REGEXP.mineralLicense.test(control.value) ? null : 'Неверный формат';

    return { message };
  },
  olderDate: (control: AbstractControl, args: any[]) => {
    const result: { message: string | null } = {
      message: null,
    };

    if (args[0]) {
      const newDate = moment(control.value).toDate().getTime();
      const oldDateMoment = moment(args[0]);
      const oldDate = oldDateMoment.toDate().getTime();

      if (newDate && newDate <= oldDate) {
        result.message = `Установите дату после ${oldDateMoment.format(UI_GOST_DATE_FORMAT)}`;
      }
    }

    return result;
  },
  olderOrEqualDate: (control: AbstractControl, args: any[]) => {
    const result: { message: string | null } = {
      message: null,
    };

    if (args[0]) {
      const newDate = moment(control.value).toDate().getTime();
      const oldDateMoment = moment(args[0]);
      const oldDate = oldDateMoment.toDate().getTime();

      if (newDate && newDate < oldDate) {
        result.message = `Установите дату не ранее ${oldDateMoment.format(UI_GOST_DATE_FORMAT)}`;
      }
    }

    return result;
  },
  beforeDate: (control: AbstractControl, args: any[]) => {
    const result: { message: string | null } = {
      message: null,
    };

    if (args[0]) {
      const oldDate = moment(control.value).toDate().getTime();
      const newDateMoment = moment(args[0]);
      const newDate = newDateMoment.toDate().getTime();

      if (oldDate && oldDate > newDate) {
        result.message = `Установите дату не позже ${newDateMoment.format(UI_GOST_DATE_FORMAT)}`;
      }
    }

    return result;
  },
  innPrivate: (control: AbstractControl) => {
    const { value } = control;

    const result: { message: string | null } = { message: null };

    if (value) {
      // проверка на число
      if (value.match(/\D/)) {
        result.message = 'ИНН не является числом';

        return result;
      }
      // проверка на 12 цифр
      if (value.length !== 12) {
        result.message = 'Длина 12 (введено цифр: ' + value.length + ')';

        return result;
      }

      if (value.length === 12) {
        const dgt11 = String(
          ((7 * value[0] +
            2 * value[1] +
            4 * value[2] +
            10 * value[3] +
            3 * value[4] +
            5 * value[5] +
            9 * value[6] +
            4 * value[7] +
            6 * value[8] +
            8 * value[9]) %
            11) %
            10,
        );

        const dgt12 = String(
          ((3 * value[0] +
            7 * value[1] +
            2 * value[2] +
            4 * value[3] +
            10 * value[4] +
            3 * value[5] +
            5 * value[6] +
            9 * value[7] +
            4 * value[8] +
            6 * value[9] +
            8 * value[10]) %
            11) %
            10,
        );

        if (value[10] !== dgt11 || value[11] !== dgt12) {
          result.message = EValidatorErrors.INN_CONTROL_SUM;

          return result;
        }
      }
    }

    return result;
  },

  inn: (control: AbstractControl) => {
    const { value } = control;

    const result: { message: string | null } = { message: null };

    if (value) {
      // проверка на число
      if (value.match(/\D/)) {
        result.message = 'ИНН не является числом';

        return result;
      }
      // проверка на 10 и 12 цифр
      if (value.length !== 12 && value.length !== 10) {
        result.message = 'Длина 10/12 (введено цифр: ' + value.length + ')';

        return result;
      }
      // проверка по контрольным цифрам
      if (value.length === 10) {
        const dgt10 = String(
          ((2 * value[0] +
            4 * value[1] +
            10 * value[2] +
            3 * value[3] +
            5 * value[4] +
            9 * value[5] +
            4 * value[6] +
            6 * value[7] +
            8 * value[8]) %
            11) %
            10,
        );

        if (value[9] !== dgt10) {
          result.message = EValidatorErrors.INN_CONTROL_SUM;

          return result;
        }
      }

      if (value.length === 12) {
        const dgt11 = String(
          ((7 * value[0] +
            2 * value[1] +
            4 * value[2] +
            10 * value[3] +
            3 * value[4] +
            5 * value[5] +
            9 * value[6] +
            4 * value[7] +
            6 * value[8] +
            8 * value[9]) %
            11) %
            10,
        );

        const dgt12 = String(
          ((3 * value[0] +
            7 * value[1] +
            2 * value[2] +
            4 * value[3] +
            10 * value[4] +
            3 * value[5] +
            5 * value[6] +
            9 * value[7] +
            4 * value[8] +
            6 * value[9] +
            8 * value[10]) %
            11) %
            10,
        );

        if (value[10] !== dgt11 || value[11] !== dgt12) {
          result.message = EValidatorErrors.INN_CONTROL_SUM;

          return result;
        }
      }
    }

    return result;
  },
  kpp: (control: AbstractControl) => {
    const result: { message: string | null } = { message: null };
    let kpp = control.value;

    if (typeof kpp === 'number') {
      kpp = kpp.toString();
    } else if (typeof kpp !== 'string') {
      kpp = '';
    }
    if (!kpp.length) {
      return result;
    } else if (kpp.length !== 9) {
      result.message = 'Длина 9 (введено цифр: ' + kpp.length + ')';
    } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
      result.message = 'Только цифры';
    }

    return result;
  },
  ogrn: (control: AbstractControl) => {
    const result: { message: string | null } = {
      message: null,
    };

    let ogrn = control.value;

    if (typeof ogrn === 'number') {
      ogrn = ogrn.toString();
    } else if (typeof ogrn !== 'string') {
      ogrn = '';
    }
    if (!ogrn.length) {
      return result;
    } else if (/[^0-9]/.test(ogrn)) {
      result.message = 'Только цифры';
    } else if (!(ogrn.length === 13 || ogrn.length === 15)) {
      result.message = 'Длина 13/15 (введено цифр: ' + ogrn.length + ')';
    } else {
      const isOgrn = ogrn.length === 13;

      if (isOgrn) {
        const n13 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 11).toString().slice(-1), 10);

        if (n13 !== parseInt(ogrn[12], 10)) {
          result.message = 'Неверный ОГРН';
        }
      } else {
        const n15 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 13).toString().slice(-1), 10);

        if (n15 !== parseInt(ogrn[14], 10)) {
          result.message = 'ОГРНИП не прошёл проверку';
        }
      }
    }

    return result;
  },
  ogrnlegal: (control: AbstractControl) => {
    const result: { message: string | null } = {
      message: null,
    };

    let ogrn = control.value;

    if (typeof ogrn === 'number') {
      ogrn = ogrn.toString();
    } else if (typeof ogrn !== 'string') {
      ogrn = '';
    }
    if (!ogrn.length) {
      return result;
    } else if (/[^0-9]/.test(ogrn)) {
      result.message = 'Только цифры';
    } else if (ogrn.length !== 13) {
      result.message = 'Длина 13 (введено цифр: ' + ogrn.length + ')';
    } else {
      const n13 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 11).toString().slice(-1), 10);

      if (n13 !== parseInt(ogrn[12], 10)) {
        result.message = 'Неверный ОГРН';
      }
    }

    return result;
  },
  ogrnip: (control: AbstractControl) => {
    const result: { message: string | null } = {
      message: null,
    };

    let ogrnip = control.value;

    if (typeof ogrnip === 'number') {
      ogrnip = ogrnip.toString();
    } else if (typeof ogrnip !== 'string') {
      ogrnip = '';
    }
    if (!ogrnip.length) {
      result.message = 'ОГРНИП пуст';
    } else if (/[^0-9]/.test(ogrnip)) {
      result.message = 'Только цифры';
    } else if (ogrnip.length !== 15) {
      result.message = 'Длина 15 (введено цифр: ' + ogrnip.length + ')';
    } else {
      const n15 = parseInt((parseInt(ogrnip.slice(0, -1), 10) % 13).toString().slice(-1), 10);
      if (n15 === parseInt(ogrnip[14], 10)) {
        return result;
      } else {
        result.message = 'ОГРНИП не прошёл проверку';
      }
    }

    return result;
  },
  bik: ValidatorsBIK,
  bankaccountnumber: ValidatorsRS,
  rs: ValidatorsRS,
  ks: ValidatorsKS,
  unified_treasury_account: ValidatorsUnifiedTreasuryAccount,
  treasury_account: ValidatorsTreasuryAccount,
  bankcorrespondentnumber: ValidatorsKS,
  integer: (control: AbstractControl) => {
    const result: { message: string | null } = { message: null };
    const value = control.value;

    if (value && (isNaN(value) || parseFloat(value) !== parseInt(value, 10))) {
      result.message = 'Только целое число';
    }

    return result;
  },
  passportseria: (control: AbstractControl) => {
    const result = Validators.pattern(REGEXP.passportSeria)(control);

    if (result && result.pattern) {
      return { message: '4 цифры' };
    }

    if (('' + control.value).indexOf('00') === 0) {
      return { message: 'некорректная серия' };
    }

    return { message: null };
  },
  passportnumber: (control: AbstractControl) => {
    const result = Validators.pattern(REGEXP.passportNumber)(control);

    if (result && result.pattern) {
      return { message: '6 цифр' };
    }

    if (parseInt('' + control.value, 10) < 101) {
      return { message: 'некорректный номер' };
    }

    return { message: null };
  },
  snilsnumber: (control: AbstractControl) => {
    const result = Validators.pattern(REGEXP.snilsNumber)(control);

    if (result && result.pattern) {
      return { message: '11 цифр' };
    }

    const value = '' + control.value;
    const digitString = value
      .split('')
      .filter((s) => new RegExp(/\d/).test(s))
      .join('');
    const digitString9 = digitString.substring(0, 9);
    const digitString1011 = digitString.substring(digitString.length - 2, digitString.length);

    // Проверка контрольного числа Страхового номера проводится только для номеров больше номера 001-001-998
    if (parseInt(digitString9, 10) > 1001998) {
      const sum = digitString9.split('').reduce((acc, s, index) => {
        acc += +s * (9 - index);
        return acc;
      }, 0);

      let remainderString = '' + (sum % 101);
      remainderString = remainderString.substring(remainderString.length - 2, remainderString.length);

      if (remainderString.length < 2) {
        remainderString = '0' + remainderString;
      }

      if (remainderString !== digitString1011) {
        return { message: 'некорректный номер' };
      }
    }

    // в номере XXX-XXX-XXX не может присутствовать одна и та же цифра три раза подряд
    let find3 = 1;
    let lastS;

    digitString9.split('').forEach((s) => {
      if (find3 === 3) {
        return;
      }

      if (lastS === s) {
        find3++;
      } else {
        find3 = 1;
      }

      lastS = s;
    });

    if (find3 === 3) {
      return { message: 'некорректный номер' };
    }

    return { message: null };
  },
  notfill0: (control: AbstractControl) => {
    if (control.value && ('' + control.value).replace(/0{1,}/g, '').length == 0) {
      return { message: 'некорректное значение' };
    }

    return { message: null };
  },
  required: (control: AbstractControl, args: any[]) => {
    return {
      message: requiredAndNotEmpty(control) !== null ? (args && args[0]) || 'Обязательное поле' : null,
    };
  },
  min: (control: AbstractControl, args: any[]) => {
    const result = {
      message: null,
    };

    if (args[0] !== 'undefined') {
      const num = +args[0];
      const r = Validators.min(num)(control);
      return r && r.min ? { message: `Не меньше, чем ${num}` } : result;
    }

    return result;
  },
  max: (control: AbstractControl, args: any[]) => {
    const result = {
      message: null,
    };

    if (args[0] !== 'undefined') {
      const num = +args[0];
      const r = Validators.max(num)(control);
      return r && r.max ? { message: `Не больше, чем ${num}` } : result;
    }

    return result;
  },
  minLength: (control, args) => {
    const result = {
      message: null,
    };

    if (args[0] !== 'undefined') {
      const num = +args[0];
      const r = Validators.minLength(num)(control);
      return r && r.minlength ? { message: `Не меньше, чем ${num} символов` } : result;
    }

    return result;
  },
  maxLength: (control, args) => {
    const result = {
      message: null,
    };

    if (args[0] !== 'undefined') {
      const num = +args[0];
      const r = Validators.maxLength(num)(control);
      return r && r.maxlength ? { message: `Не больше, чем ${num} символов` } : result;
    }

    return result;
  },
  between: (control: AbstractControl, args: any[]) => {
    const result = {
      message: null,
    };

    if (args[0] !== 'undefined' && args[1] !== 'undefined') {
      const min = +args[0];
      const max = +args[1];
      const r1 = Validators.min(min)(control);
      const r2 = Validators.max(max)(control);
      return (r1 && r1.min) || (r2 && r2.max) ? { message: `Не меньше, чем ${min} и не больше, чем ${max}` } : { message: null };
    }

    return result;
  },
  geojson: (control: AbstractControl, _args: any[]) => {
    const result: { message: string | null } = {
      message: null,
    };

    const errors = getErrorsForGeoJson(control.value);

    result.message = translateGeoJsonErrors(errors).join('\n');

    return result;
  },
  size: (control, args) => {
    const result = {
      message: null,
    };

    if (args[0] !== 'undefined') {
      const num = +args[0];
      const value = (control && control.value) || '';

      if (!value.length) {
        return result;
      }

      return value.length !== num ? { message: `Длина ${num}` } : result;
    }

    return result;
  },
  maxMinYearValidation: (control, args) => {
    const result = { message: null };
    const isEmptyValue = (v: any) => {
      return v === null || v.length === 0;
    };
    const value = parseFloat(control.value);

    if (
      '' + args[0] !== 'undefined' &&
      '' + args[0] !== 'null' &&
      !(isEmptyValue(control.value) || isEmptyValue(+args[0])) &&
      !isNaN(value) &&
      value < args[0]
    ) {
      return { message: `Не меньше ${args[0]} года` };
    }

    if (
      '' + args[1] !== 'undefined' &&
      '' + args[1] !== 'null' &&
      !(isEmptyValue(control.value) || isEmptyValue(+args[1])) &&
      !isNaN(value) &&
      value > args[1]
    ) {
      return { message: `Не больше ${args[1]} года` };
    }

    return result;
  },
  lowerThanZero: (control, _args) => {
    const result = { message: null };
    const value = parseFloat(control.value);

    if (value === null || value === undefined || value >= 0) {
      return { message: 'Сумма должна быть меньше нуля' };
    }

    return result;
  },
  twoDigitsAfterComma: (control) => {
    const result: { message: string | null } = { message: null };

    const v = '' + control.value;
    const re = /^\d+([.,]\d{2})$/;
    const valid = re.test(v);

    if (!valid) {
      result.message = 'Необходимо указать 2 знака после запятой';
    }

    return result;
  }
};

export type ValidatorsType = {
  [key in keyof typeof VALIDATORS]: key;
};

export const ValidatorsKEYS: ValidatorsType = Object.keys(VALIDATORS).reduce((obj, key) => {
  obj[key] = key;
  return obj;
}, {} as ValidatorsType);

export function ValidatorsFloat(digits: number) {
  return (control: AbstractControl) => {
    const result: { message: string | null } = { message: null };
    const value = control.value;

    if (
      value &&
      (isNaN(value) ||
        parseFloat('' + decimalRound(+value * Math.pow(10, digits))) !== parseInt('' + decimalRound(+value * Math.pow(10, digits)), 10))
    ) {
      result.message = `Только ${digits} знака после запятой`;
    }

    return result;
  };
}

export function ValidatorsRequired(message?: string) {
  return (c: AbstractControl) => {
    return VALIDATORS.required(c, [message]);
  };
}

export function ValidatorsMin(num: number, message?: string) {
  return (c: AbstractControl) => {
    const result = Validators.min(num)(c);
    return result && result.min ? { message: message || `Минимальное значение: ${num}` } : result;
  };
}

export function ValidatorsLength(lengthParam: number | CallBackNumber, required = true) {
  return (control: AbstractControl) => {
    const length = isFunction(lengthParam) ? lengthParam() : lengthParam;

    const result: { message: string | null } = { message: null };
    const value = control.value || '';

    if ((required && value.length !== length) || (!required && value.length && value.length !== length)) {
      result.message = `Длина ${length} (введено цифр: ${value.length})`;
    }

    return result;
  };
}

export function ValidatorsMaxLength(maxLength: number) {
  return (c: AbstractControl) => {
    const result = Validators.maxLength(maxLength)(c);
    const max = result && result.maxlength;
    return max ? { message: `Максимальная длина: ${max.requiredLength} символов (введено символов: ${max.actualLength})` } : result;
  };
}

export function ValidatorsMax(num: number, message?: string) {
  return (c: AbstractControl) => {
    const result = Validators.max(num)(c);
    return result && result.max ? { message: message || `Максимальное значение: ${num}` } : result;
  };
}

export function ValidatorsPattern(patternParam: string | RegExp | CallBackRegExp, messageParam: string | CallBackString) {
  return (c: AbstractControl) => {
    const pattern = isFunction(patternParam) ? patternParam() : patternParam;
    const message = isFunction(messageParam) ? messageParam() : messageParam;

    const result = Validators.pattern(pattern)(c);
    return result && result.pattern ? { message } : result;
  };
}

export class RulesValidator implements Validator {
  constructor(
    private validateFunction: IValidateInput,
    private validateArguments: any[] = [],
  ) {}

  validate(c: UntypedFormControl) {
    if (this.validateFunction) {
      let fns: IValidateInput;

      if (isArray(this.validateFunction)) {
        fns = this.validateFunction;
      } else {
        fns = [this.validateFunction];
      }

      for (const fn of fns) {
        let errors: ValidationErrors | null = null;

        if (isFunction(fn)) {
          errors = fn(c, this.validateArguments);
        } else if (VALIDATORS[fn]) {
          errors = VALIDATORS[fn](c, this.validateArguments);
        }

        if (errors && errors.message) {
          return errors;
        }
      }
    }

    return null;
  }
}
