import {
  ChangeDetectorRef,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  DoCheck,
  Directive,
  Output,
  EventEmitter,
  inject
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { uniqueId, get, isObject, isArray } from 'lodash';
import { MDBModalService, TooltipDirective } from 'ng-uikit-pro-standard';
import { Observable, Subscription, of, BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { ModalBig3ChatComponent } from 'src/app/components/modal-b3-chat/modal-b3-chat.component';
import { EInputType } from 'src/app/providers/_const/input.type.const';
import { DictionaryName, DictionaryDaDataName, DICT, DictKEYS } from 'src/app/providers/_dictionary';
import { IValidateInput, VALIDATORS, RulesValidator } from 'src/app/providers/_directives/validator/validators';
import { ICurrentTemplate } from 'src/app/providers/_interfaces/autocomplete-template.interface';
import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { undefinedOrNullOrEmptyString } from 'src/app/providers/_utils/utils';
import { EssenceSelectors } from 'src/app/store';

export type SlaveCtrlType = {
  [key: string]: AbstractControl | AbstractControl[];
};

export abstract class IAppFieldWithValidation {
  abstract setValidators(state: any, errorsState: any): void;
}
@Directive()
// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
export abstract class AppFormFieldClass<T> implements ControlValueAccessor, OnInit, OnChanges, DoCheck, OnDestroy, IAppFieldWithValidation {
  @Input() getResetValue: () => any;
  @Input() isDisabled: boolean;
  @Input() isReadonly: boolean;
  @Input() slaveControls: SlaveCtrlType = {};
  @Input() clearValidationControls: AbstractControl[];
  @Input() updateControls: AbstractControl[];
  @Input() label: string;
  @Input() labelCls = '';
  @Input() tooltip: string;
  @Input() showTooltipDiff = false;
  @Input() labelTop = '';
  @Input() placeholder = '';
  @Input() templateTags: { groupTag: string; formTag: string } = null;

  public modalService = inject(MDBModalService);

  public store = inject(Store);
  public hint$ = combineLatest([
    this.dictionaryService.disabledHints$,
    this.store.pipe(
      select(EssenceSelectors.selectCurrentRouteData),
      filter(x => !!x.currentEssence?.hints && !!this.formControlName),
    )
  ]).pipe(
    map(([disabledHints, data]) => {
      const reportHints = data.currentEssence.hints;

      if (!reportHints || disabledHints) return '';

      const addKey = data.treePath?.filter(x => x !== 'new' || !isNaN(x)).join('.') || '';
      const key = data.currentEssenceType + (addKey ? ('.' + addKey) : '');
      const sectionHints = reportHints[key];
      if (!sectionHints) return '';

      return '' + (sectionHints[this.formControlName] || '');
    })
  );
  private _userRequired = false;
  private _validatorsRequired = false;

  @Input() set required(newValue: boolean) {
    this._userRequired = newValue;
  }

  get required(): boolean {
    return this._userRequired || this._validatorsRequired;
  }

  @Input() size = 8;
  @Input() labelSize = 4;
  @Input() initControlName: string;
  @Input() formControlName: string;
  @Input() notSavedDataMessage = true;
  @Input() isFilter = false;
  @Input() public type: EInputType = EInputType.text;
  @Input() public inThousands = false;
  @Input() dictKey: DictionaryName | DictionaryDaDataName;
  @Input() searchKey = 'name';
  @Input() sendKey = 'id';
  @Input() id: string = uniqueId(`d-${Date.now()}-field`);
  @Input() hoverTooltip: string;

  @Input() specialCharacters: any = [];
  @Input() maskString = '';
  @Input() maskPatterns: any = [];
  @Input() showMask = true;
  @Input() ignoreValidators = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  public focusEvent$ = new BehaviorSubject(false);
  public isTooltipeVisible$ = this.focusEvent$.pipe(
    debounceTime(500),
    tap(() => this.cdr.markForCheck()),
  );

  readonly EInputType = EInputType;

  private _formControl: AbstractControl;

  get formControl(): UntypedFormControl {
    return (this._formControl || get(this.fcd, ['form', 'controls', this.formControlName])) as UntypedFormControl;
  }
  @Input() set formControl(newValue: UntypedFormControl) {
    this._formControl = newValue;
  }

  set pushToSubscriptions(s: Subscription) {
    this.subscriptions.push(s);
  }

  @ViewChild('tooltipDirective') public tooltipDirective: TooltipDirective;

  public DictKEYS = DictKEYS;

  public diff$: Observable<string | null>;
  public formValue: T | string | number | null;
  public control: AbstractControl = new UntypedFormControl('');
  public subscriptions: Subscription[] = [];

  private formPristine: boolean | null = false;

  protected destroyed = false;

  static providers(component: any) {
    return [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => component),
        multi: true,
      },
      {
        provide: IAppFieldWithValidation,
        useExisting: forwardRef(() => component),
      },
    ];
  }

  abstract writeValue(value: any): void;

  propagateChange = (_: T | string | number | null) => ({});
  registerOnChange(fn: (_: T | string | number | null) => any) {
    this.propagateChange = fn;
  }

  propagateTouched = (_: T | string | number | null) => ({});
  registerOnTouched(fn: (_: T | string | number | null) => any) {
    this.propagateTouched = fn;
  }

  constructor(
    public fcd: FormGroupDirective,
    public cdr: ChangeDetectorRef,
    public dictionaryService: DictionaryService,
  ) {
    this.formPristine = this.fcd.pristine;
  }

  getNativeElement(): HTMLInputElement | HTMLTextAreaElement | null {
    return document.querySelector('#' + this.id);
  }

  get initControl(): UntypedFormControl | null {
    return this.initControlName ? (this.fcd.form.controls[this.initControlName] as UntypedFormControl) : null;
  }

  updateSlaveControls(selectObject: any): void {
    for (const key of Object.keys(this.slaveControls)) {
      this.updateSlaveControlByKey(key, selectObject, this.slaveControls);
    }

    (this.updateControls || [])
      .filter((c) => !!c)
      .forEach((control) => {
        control.updateValueAndValidity();
      });

    (this.clearValidationControls || [])
      .filter((c) => !!c)
      .forEach((control) => {
        control.clearValidators();
        control.clearAsyncValidators();
        control.updateValueAndValidity();
      });
  }

  updateSlaveControlByKey(key: string, selectObject: any, hash: any): any {
    const getValue = (v: any) => {
      if (typeof v === 'object') {
        switch (key) {
          case '':
            return v;
          case 'value=0':
            return 0;
          case 'null':
            return null;
          case 'true':
            return true;
          case 'false':
            return false;
          case 'data.oktmo':
            return (get(v, key, '') || '').slice(0, 8);
          default:
            return get(v, key, null);
        }
      }

      return v;
    };

    const controlValue = getValue(selectObject);

    const setValue = (c: AbstractControl) => {
      let newValue = controlValue;
      if (key.indexOf('_date') >= 0) newValue = controlValue ? new Date(controlValue) : null;
      if (newValue !== c.value) {
        c.setValue(newValue);
        c.markAsDirty();
        c.updateValueAndValidity();
      }
    };

    const control = hash[key];
    if (control) {
      if (Array.isArray(control)) {
        control.forEach((c) => c && setValue(c));
      } else {
        setValue(control);
      }
    }
  }

  updateFormValue(v: T | string | number | null, options: { emitEvent: boolean } = { emitEvent: true }): void {
    this.formValue = v;
    if (options.emitEvent) {
      if (this.type === EInputType.currency) {
        v = (Math.round(parseFloat('' + v) * 100) / 100) as any;
      }
      try {
        this.propagateChange(v);
      } catch (err) {}
    }

    try {
      this.tooltipDirective && this.tooltipDirective.hide();
    } catch (err) {}

    this.cdr.detectChanges();
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled) {
      this.isDisabled === true ? this.control.disable({ emitEvent: false }) : this.control.enable({ emitEvent: false });
      this.cdr.detectChanges();
    }

    this.onLabelRequiredChanges(changes, 'label');
    this.onLabelRequiredChanges(changes, 'labelTop');
  }

  private updateLabel(labelKey: 'label' | 'labelTop', markForCheck: boolean) {
    if (!this[labelKey] || !this[labelKey].length) {
      return;
    }

    if (!!this.required && this[labelKey].indexOf('*') < 0) {
      this[labelKey] = this[labelKey] + '\u00A0' + '*';

      if (markForCheck) {
        this.cdr.markForCheck();
      }
    }

    if (!this.required) {
      if (this[labelKey].indexOf('*') >= 0 && this[labelKey][this[labelKey].length - 1] === '*') {
        this[labelKey] = this[labelKey].slice(0, -1);

        if (this[labelKey].indexOf('\u00A0') >= 0 && this[labelKey][this[labelKey].length - 1] === '\u00A0') {
          this[labelKey] = this[labelKey].slice(0, -1);
        }

        if (markForCheck) {
          this.cdr.markForCheck();
        }
      }
    }
  }

  private onLabelRequiredChanges(changes: SimpleChanges, labelKey: 'label' | 'labelTop') {
    if ((changes.required || changes[labelKey]) && this[labelKey] && this[labelKey].length) {
      this.updateLabel(labelKey, true);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngDoCheck(): void {
    if (this.fcd.pristine !== this.formPristine) {
      this.formPristine = this.fcd.pristine;

      if (this.formControl) {
        this.formControl.updateValueAndValidity({ emitEvent: false });
        this.cdr.detectChanges();
      }
    }
  }

  getOldItemName(oldValue: any): Observable<string> {
    if (undefinedOrNullOrEmptyString(oldValue)) {
      return of('пусто');
    }

    if (this.dictKey) {
      const dict = DICT[this.dictKey];
      const byId = dict.dictItemURL && !dict.needLoadAllItems && this.sendKey === 'id';

      if (byId) {
        return this.dictionaryService.fetchDictionaryItemsById(oldValue, this.dictKey).pipe(
          map((item: any) => {
            return item ? item[this.searchKey] : oldValue;
          }),
        );
      }

      return (this.dictionaryService.getDictionaryStorage(DICT, this.dictKey).dictionary$ as Observable<any>).pipe(
        map((d: IDictionaryItem[]) => {
          const item: any = (d || []).find((v: any) => v[this.sendKey] == oldValue);

          return item ? item[this.searchKey] : oldValue;
        }),
      );
    } else {
      return of(oldValue);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngOnInit() {
    if (this.formControl && !this.isFilter) {
      this.diff$ = this.formControl.valueChanges.pipe(
        filter(() => !!(this.showTooltipDiff && this.formControlName)),
        switchMap(() => {
          const oldValueControl = this.fcd.form.controls['old_' + this.formControlName];

          if (oldValueControl) {
            const old = '' + ((oldValueControl.value !== '0' ? oldValueControl.value : '') || '');
            const current = '' + ((this.formControl.value !== '0' ? this.formControl.value : '') || '');

            return old !== current ? this.getOldItemName(oldValueControl.value) : of(null);
          } else {
            return of(null);
          }
        }),
        map((text) => (undefinedOrNullOrEmptyString(text) ? null : `<div>До\u00A0изменения: <strong>${text}</strong></div>`)),
      );
    }
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngOnDestroy() {
    this.destroyed = true;
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled || this.isDisabled ? this.control.disable() : this.control.enable(); //  && this.initControlName !== 'doer'
  }

  setValidators(state: any, errorsState: any): void {
    const controlValidators = state && state[this.formControlName];
    const allErrors: any[] = errorsState || [];

    this._validatorsRequired = false;

    this.updateLabel('label', false);
    this.updateLabel('labelTop', false);

    if (!controlValidators) {
      return;
    }

    const rules: { [key: string]: any } = controlValidators.rules;
    const formErrors = allErrors.filter((err) => {
      const fields = err.fields || [];
      return isArray(fields) ? fields.findIndex((field) => field === this.formControlName) !== -1 : false;
    });

    const getValidatorForErrors = () => {
      const f = (control: AbstractControl) => (control.dirty ? null : { message: formErrors.map((e) => e.message).join(' \n') });
      return formErrors.length > 0 ? f : null;
    };

    const errorsValidator = getValidatorForErrors();

    if (isArray(rules) || !isObject(rules)) {
      if (errorsValidator) {
        this.formControl.setValidators(errorsValidator);
        this.formControl.updateValueAndValidity({ emitEvent: false });
        this.cdr.detectChanges();
      }

      return;
    }

    if (this.ignoreValidators) return;

    const ruleKeys = Object.keys(rules);
    const fixedRules: { [key: string]: any } = {};

    ruleKeys.forEach((validatorName) => {
      let nm = validatorName;

      if (validatorName === 'max' && ruleKeys.indexOf('string') >= 0) {
        nm = 'maxLength';
      }

      if (validatorName === 'min' && ruleKeys.indexOf('string') >= 0) {
        nm = 'minLength';
      }

      fixedRules[nm] = rules[validatorName];
    });

    const validators = Object.keys(fixedRules)
      .map((validatorName: string) => {
        const validatorArgs = fixedRules[validatorName]?.params || [];
        let validatorFunction: IValidateInput | null = null;

        if (VALIDATORS[validatorName]) {
          validatorFunction = VALIDATORS[validatorName];
        }

        if (!validatorFunction) {
          return null;
        }

        if (validatorName === 'required') {
          this._validatorsRequired = true;

          this.updateLabel('label', false);
          this.updateLabel('labelTop', false);
        }

        return (control: AbstractControl): { [key: string]: any } | null => {
          if (formErrors.length && this.fcd.pristine) {
            return { message: formErrors.map((e) => e.message).join(' \n') };
          }

          const errors = new RulesValidator(validatorFunction, validatorArgs).validate(<UntypedFormControl>control);
          return errors && validatorName === 'required' ? { required: true } : errors;
        };
      })
      .filter((v) => !!v);

    if (errorsValidator) validators.splice(0, 0, errorsValidator);

    this.formControl.setValidators(validators?.length ? validators : []);
    this.formControl.updateValueAndValidity({ emitEvent: false });
    this.cdr.detectChanges();
  }

  selectItemFromTemplate(event: ICurrentTemplate): void {
    this.formControl.setValue(event.value);
    this.formControl.markAsDirty();
  }

  likeFormControl(control: AbstractControl): FormControl {
    return control as FormControl;
  }

  openB3Chat(request?: string) {
    const modalChatRef = this.modalService.show(ModalBig3ChatComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      containerClass: '',
      class: 'modal-dialog--lg modal-fullscreen',
      animated: true,
      data: {
        content: {
          hide: () => modalChatRef.hide(),
          request
        },
      },
    });
  }
}
