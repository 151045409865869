<ng-container *ngIf="customButtons?.length; else standardButtons">
  <div class="rpn-navigation py-3">
    <div class="rpn-groups rpn-groups_right">
      <div *ngFor="let btn of customButtons" class="rpn-button-tooltip">
        <button
          *ngIf="btn?.isShowed ? btn.isShowed() : true"
          type="button"
          (click)="btn.action(data); action.emit(btn)"
          [disabled]="isDisabled || (btn?.isDisabled && (btn.isDisabled(data) | async))"
          class="rpn-button rpn-button_md rpn-button_action"
          [ngClass]="getBtnClass(btn.color)"
        >
          <span [mdbTooltip]="btn.title">{{ btn.title | trim : 40}}</span>
        </button>

        <div *ngIf="(btn.tooltip && btn.tooltip(data)) as tooltip"
          [ngStyle]="tooltip.styles"
          class="rpn-button-tooltip">
          {{tooltip.text}}
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #standardButtons>
  <div class="rpn-navigation py-3 rpn-navigation_sticky" *ngIf="!onlySave; else onlySaveTemplate">
    <div class="rpn-groups">
      <button *ngIf="hasBack"
        type="button"
        class="rpn-button rpn-button_bordered rpn-button_action rpn-button_md l-0 mr-3"
        [routerLink]="['../']">
        <app-icon #icon [name]="icon.IconsSet.chevronLeft" width="7px" height="15px"></app-icon>
        <span class="ml-2">Назад</span>
      </button>

      <button class="rpn-button rpn-button_bordered rpn-button_new rpn-button_action rpn-button_md mr-3"
        *ngIf="addTitle && !isDisabled"
        type="button" routerLink="new">
        <app-icon #icon [name]="icon.IconsSet.buttonIconPlus" width="15px" height="15px"></app-icon>
        <span class="ml-2" [mdbTooltip]="addTitle">{{ addTitle | trim : 40}}</span>
      </button>

      <ng-container *ngIf="customAdditionalButtons?.length">
        <ng-container  *ngFor="let btn of customAdditionalButtons">
          <ng-container *ngIf="!!btn.action">
            <button class="rpn-button rpn-button_bordered rpn-button_action rpn-button_md mr-3" type="button"
              *ngIf="btn.isShowed ? btn.isShowed(data) : true"
              [disabled]="btn.isDisabled && (btn.isDisabled(data) | async)"
              (click)="btn.action(data)">
              <span [mdbTooltip]="btn.title">{{ btn.title | trim : 40}}</span>
            </button>
          </ng-container>
          <ng-container *ngIf="btn.routerLink">
            <button class="rpn-button rpn-button_bordered rpn-button_action rpn-button_md mr-3" type="button"
              *ngIf="btn.isShowed ? btn.isShowed(data) : true"
              [disabled]="btn.isDisabled && (btn.isDisabled(data) | async)"
              [routerLink]="[btn.routerLink]">
              <span [mdbTooltip]="btn.title">{{ btn.title | trim : 40}}</span>
            </button>
          </ng-container>
        </ng-container>
      </ng-container>

    </div>

    <div class="rpn-groups rpn-groups_right">
      <button
        *ngIf="!addTitle && !isDisabled"
        appSpinner
        [sizeMultiplier]=".8"
        [spinnerColor]="'#3A7A68'"
        [spinnerTransparentText]="false"
        [showSpinner]="isShowSpinner$ | async"
        type="submit"
        class="rpn-button rpn-button_bordered rpn-button_bordered-green rpn-button_md rpn-button_save rpn-button_action"
      >
        <span [mdbTooltip]="saveTitle">{{ saveTitle | trim : 40}}</span>
      </button>

    </div>
  </div>

  <ng-template #onlySaveTemplate>
    <div class="rpn-groups_right rpn-groups_navigation_sticky">
      <button
        *ngIf="!addTitle && !isDisabled"
        appSpinner
        [sizeMultiplier]=".8"
        [spinnerColor]="'#3A7A68'"
        [spinnerTransparentText]="false"
        [showSpinner]="isShowSpinner$ | async"
        type="submit"
        class="rpn-button rpn-button_bordered rpn-button_bordered-green rpn-button_md rpn-button_save rpn-button_action"
      >
        <span [mdbTooltip]="saveTitle">{{ saveTitle | trim : 40}}</span>
      </button>

    </div>
  </ng-template>
</ng-template>
