import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';
export enum EPMSufdImportsParseStatusKeys {
  ok = 'ok',
  error = 'error',
  wait = 'wait',
}

export interface IPMSufdImportsParseStatus extends IDictionaryItem {
  id: EPMSufdImportsParseStatusKeys;
  name: string;
}

export type PMSufdImportsExtractKindKeys = '0' | '1';

export interface IPMSufdImportsExtractKind {
  id: PMSufdImportsExtractKindKeys;
  name: string;
}

export type PMSufdImportsBudgetLevelKeys = '1' | '2' | '3' | '4' | '5';

export interface IPMSufdImportsBudgetLevel {
  id: PMSufdImportsBudgetLevelKeys;
  name: string;
}

export enum EPMSufdImportsSufdTypeKeys {
  vtoper = 'vtoper',
  vtnoper = 'vtnoper',
}

export interface IPMSufdImportsSufdType extends IDictionaryItem {
  id: EPMSufdImportsSufdTypeKeys;
  name: string;
}

export enum EPMVtOperationsUfTypeKeys {
  payment_to_create = 'payment_to_create',
  payment_to_change = 'payment_to_change',
}

export interface IPMVtOperationsUfType extends IDictionaryItem {
  id: EPMVtOperationsUfTypeKeys;
  name: string;
}

export enum EPMVtOperationsDocCodeKeys {
  PP = 'PP',
  ZV = 'ZV',
  UF = 'UF',
  PL = 'PL',
  IZ = 'IZ',
  SF = 'SF',
  UN = 'UN',
  SP = 'SP',
  RC = 'RC',
  RD = 'RD',
  XX = 'XX',
}

export enum EPMVtOperationsDocCodeFiltersKeys {
  PP = 'PP',
  ZV = 'ZV',
  UF = 'UF',
  PL = 'PL',
  SF = 'SF',
  SP = 'SP',
  RC = 'RC',
  RD = 'RD',
  XX = 'XX',
}

export interface IPMVtOperationsDocCode extends IDictionaryItem {
  id: EPMVtOperationsDocCodeKeys;
  name: string;
}

export interface IPMVtOperationsDocCodeFilters extends IDictionaryItem {
  id: EPMVtOperationsDocCodeFiltersKeys;
  name: string;
}
