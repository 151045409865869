<ng-container *ngIf="isModalView; else cardContent">
  <mdb-modal-backdrop class="fade in show modal-backdrop"></mdb-modal-backdrop>
  <div class="fade modal show" tabindex="-1">
    <div [ngClass]="['modal-dialog', (br.isMobile | async) ? '': 'modal-dialog--lg']" role="document">
      <div class="modal-content modal-dynamic">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
              <span aria-hidden="true"></span>
            </button>
            <h4 class="modal-title w-100">{{showModalTransition ? showModalTransition.name + '?' : 'Заполните и отправьте форму' }}</h4>
          </div>
          <div class="modal-body" [ngClass]="{ 'card-no-overflow': noOverflow }">
            <h6 *ngIf="showModalTransition?.to_status">Заявление перейдет в статус «{{showModalTransition.to_status.name}}»</h6>
            <ng-container *ngTemplateOutlet="cardContent"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #cardContent>
  <mdb-card class="rpn-card">
    <mdb-card-body [ngStyle]="getCustomStyles()">
      <div class="row mb-3">
        <div class="col-sm-12 d-flex justify-content-between">
          <h5 class="rpn-title" *ngIf="title; else noTitle">
            {{ title }}
          </h5>

          <ng-template #noTitle>
            <app-label-route *ngIf="!hasHiddedTitle"></app-label-route>
          </ng-template>

          <div class="d-flex align-items-start rpn-relative">
            <div *ngIf="tooltip" class="ml-3">
              <mdb-icon
                far
                icon="question-circle"
                [mdbTooltip]="tooltip"
              ></mdb-icon>
            </div>

            <ng-container *ngFor="let button of titleButtons">

              <ng-container *ngIf="{isLoading: button.loading$ | async} as state">
                <button
                  *ngIf="button.isShowed() && !formData.disabled && !(isNewObject$ | async)"
                  type="button"
                  [placement]="'left'"
                  [mdbTooltip]="state.isLoading ? '' : button.tooltip"
                  [disabled]="isDisabled || button.isDisabled()"
                  class="rpn-button rpn-button_transparent rpn-button_icon ml-3"
                  (click)="!state.isLoading && button.action()"
                >
                  <small *ngIf="button.title && !(state.isLoading)" class="text-primary" >
                    {{button.title}}
                  </small>

                  <app-icon
                    class="ml-1"
                    *ngIf="button.icon && !state.isLoading"
                    [name]="button.icon"
                    width="16px"
                    height="16px">
                  </app-icon>

                  <div
                    appSpinner
                    [sizeMultiplier]="1"
                    [spinnerCenter]="true"
                    [spinnerDisplay]="'flex'"
                    [spinnerColor]="'#000'"
                    [showSpinner]="state.isLoading"
                    class="rpn_icon rpn-icon_card-spinner"
                    *ngIf="state.isLoading"></div>

                  <ng-container *ngIf="button.iconTemplate">
                    <ng-container *ngTemplateOutlet="button.iconTemplate; context: {
                      $implicit: {state: state}
                    }"></ng-container>
                  </ng-container>

                </button>
              </ng-container>
            </ng-container>
          </div>

        </div>

      </div>

      <ng-content></ng-content>

    </mdb-card-body>
  </mdb-card>

  <app-details-edit-mode-card-actions
    *ngIf="showActions"
    [customButtons]="customButtons"
    [customAdditionalButtons]="customAdditionalButtons"
    [customBtnTitles]="customBtnTitles"
    [data]="formData"
    [isDisabled]="isDisabled"
    [hasBack]="hasBack"
    [isShowSpinner]="false"
    (action)="action.emit($event)"
  >
  </app-details-edit-mode-card-actions>
</ng-template>
