import { UserScopesRepo } from 'src/app/providers/_scopes';

export const EnvHarmTreeSettings = {
  hideDisabled: (_branchName: string, _essence: any, _userScope: UserScopesRepo): boolean => {
    return false;
  },

  filterStatuses: (next_statuses, essence: any, userScope: UserScopesRepo): any[] => {
    return (next_statuses || []).filter((status) => {
      if (status.id === (essence && essence.status_id)) {
        return false;
      }

      if (userScope.admin.isActive) {
        return true;
      }

      if (userScope.envharm_to.isActive) {
        if (userScope.envharm_ca.isActive) {
          return true;
        }

        return ['draft', 'ca_check', 'ca_edit_request'].indexOf(status.code) >= 0;
      }

      if (userScope.envharm_ca.isActive) {
        return ['ca_edit_request', 'to_edit', 'done', 'cancelled'].indexOf(status.code) >= 0;
      }

      return false;
    });
  },
};
