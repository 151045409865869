import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IPaymentNvos } from 'src/app/providers/_interfaces/payment-nvos.interface';
import { PaymentNvosActionTypes, PaymentNvosUnion } from 'src/app/store/payment-nvos/actions';

export const adapter: EntityAdapter<IPaymentNvos> = createEntityAdapter<IPaymentNvos>({
  selectId: (instance) => instance.id,
});

export interface PaymentNvosState extends EntityState<IPaymentNvos> {
  currentDeclarationId: string;
  currentOnvosId: string;

  currentCorrections: any;

  currentSelectedOktmo: string;

  currentDeclarationWastes: any[];
  currentDeclarationWasteDocuments: any[];

  currentDeclarationSection: string;
  currentDeclarationDocuments: any[];

  currentDeclarationDocumentId: string;
  currentDeclarationDocumentSources: any;

  currentSourceId: string;
  currentSourcePollutants: any;

  currentDocumentPollutants: any;
  currentDeclarationWasteCorrections: any;
}

export const initialState: PaymentNvosState = adapter.getInitialState({
  currentDeclarationId: null,
  currentOnvosId: null,

  currentCorrections: null,

  currentSelectedOktmo: null,

  currentDeclarationWastes: null,
  currentDeclarationWasteDocuments: null,

  currentDeclarationSection: null,
  currentDeclarationDocuments: null,

  currentDeclarationDocumentId: null,
  currentDeclarationDocumentSources: null,

  currentSourceId: null,
  currentSourcePollutants: null,

  currentDocumentPollutants: null,
  currentDeclarationWasteCorrections: null,
});

export function paymentNvosReducer(state: PaymentNvosState = initialState, action: PaymentNvosUnion): PaymentNvosState {
  switch (action.type) {
    case PaymentNvosActionTypes.ListDeclarationsRequest:
      return {
        ...state,
      };

    case PaymentNvosActionTypes.DeclarationsListLoadedSuccess:
      return adapter.setAll(action.payload.data, {
        ...state,
        currentDeclarationId: null,
        currentOnvosId: null,

        currentCorrections: null,

        currentSelectedOktmo: null,

        currentDeclarationWastes: null,
        currentDeclarationWasteDocuments: null,

        currentDeclarationSection: null,
        currentDeclarationDocuments: null,

        currentDeclarationDocumentId: null,
        currentDeclarationDocumentSources: null,

        currentSourceId: null,
        currentSourcePollutants: null,

        currentDocumentPollutants: null,
        currentDeclarationWasteCorrections: null,
      });

    case PaymentNvosActionTypes.SelectDeclaration:
      return {
        ...state,
        currentOnvosId: action.payload.onvosId,
        currentDeclarationId: action.payload.id,
      };

    case PaymentNvosActionTypes.CorrectionsListLoadedSuccess:
      return {
        ...state,
        currentCorrections: action.payload.data,
      };

    case PaymentNvosActionTypes.ListDocumentsRequest:
      return {
        ...state,
        currentDeclarationSection: action.payload.section,
        currentSelectedOktmo: action.payload.oktmo,
        currentDeclarationDocuments: null,
      };

    case PaymentNvosActionTypes.DocumentsListLoadedSuccess:
      return {
        ...state,
        currentDeclarationDocuments: action.payload.data,
      };

    case PaymentNvosActionTypes.ListSourcesRequest:
      return {
        ...state,
        currentDeclarationDocumentId: action.payload.id,
      };

    case PaymentNvosActionTypes.SourcesListLoadedSuccess:
      return {
        ...state,
        currentDeclarationDocumentSources: action.payload.data,
      };

    case PaymentNvosActionTypes.ListSourcePollutantsRequest:
      return {
        ...state,
        currentSourceId: action.payload.id,
      };

    case PaymentNvosActionTypes.SourcePollutantsListLoadedSuccess:
      return {
        ...state,
        currentSourcePollutants: action.payload.data,
      };

    case PaymentNvosActionTypes.ListDocumentPollutantsRequest:
      return {
        ...state,
        currentDeclarationDocumentId: action.payload.id,
      };

    case PaymentNvosActionTypes.DocumentPollutantsListLoadedSuccess:
      return {
        ...state,
        currentDocumentPollutants: action.payload.data,
      };

    case PaymentNvosActionTypes.ListWastesRequest:
      return {
        ...state,
        currentSelectedOktmo: action.payload.oktmo,
        currentDeclarationSection: action.payload.section,
        currentDeclarationWastes: null,
        currentDeclarationWasteDocuments: null,
      };

    case PaymentNvosActionTypes.WastesListLoadedSuccess:
      return {
        ...state,
        currentDeclarationWastes: action.payload.data,
      };

    case PaymentNvosActionTypes.WasteDocumentsListLoadedSuccess:
      return {
        ...state,
        currentDeclarationWasteDocuments: action.payload.data,
      };

    case PaymentNvosActionTypes.WasteCorrectionsListLoadedSuccess:
      return {
        ...state,
        currentDeclarationWasteCorrections: action.payload.data,
      };

    default:
      return state;
  }
}
