import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PaymentNvosEffects } from 'src/app/store/payment-nvos/effects';
import * as PaymentNvosReducer from 'src/app/store/payment-nvos/reducer';
import { featureName } from 'src/app/store/payment-nvos/selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(featureName, PaymentNvosReducer.paymentNvosReducer),
    EffectsModule.forFeature([PaymentNvosEffects]),
  ],
})
export class PaymentNvosStoreModule {}
