<form class="row position-relative"
  (mouseover)="focusEvent$.next(true)"
  (mouseout)="focusEvent$.next(false)">

  <label
    [ngClass]="[
      labelSize ? 'col-sm-' + labelSize : 'col-sm-12',
      control.disabled || isDisabled || isReadonly || !isLoaded ? 'label_disabled' : '',
      'col-form-label',
      'position-relative'
    ]"
    [attr.for]="id" *ngIf="label">
    {{label}}

    <div class="position-absolute" style="top:5px; right: -10px;">
      <div *ngIf="tooltip">
        <mdb-icon far icon="question-circle" [mdbTooltip]="tooltip" #tooltipDirective="mdb-tooltip" class="mx-1"></mdb-icon>
      </div>
      <ng-container *ngIf="diff$ | async as diff">
        <div *ngIf="diff">
          <ng-template #diffTemplate>
            <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(diff)"></div>
          </ng-template>

          <mdb-icon fas icon="edit" [mdbTooltip]="diffTemplate" #tooltipDirective="mdb-tooltip" class="red-text mx-1"></mdb-icon>
        </div>
      </ng-container>
    </div>
  </label>

  <div [ngClass]="[label ? 'col-sm-' + size : 'col-sm-12']">
    <form class="input-group md-form mt-0" [ngClass]="position_class" >

      <div *ngIf="!isLoaded" class="rpn-skeleton form-control">&nbsp;</div>

      <div class="position-relative pr-3">
        <div *ngIf="isLoaded" class="form-check pl-2"
          [ngClass]="[control.disabled || isDisabled || isReadonly || !isLoaded ? 'form-check-disabled' : '']">
          <label [for]="id"
            [ngClass]="['rpn-switch-custom', control.disabled || isDisabled || isReadonly || !isLoaded ? 'label__disabled' : 'label__active']">
            <input type="checkbox" [id]="id" [name]="id + '-name'"
              [formControl]="likeFormControl(control)"
              (change)="selectValue($event)">
            <span [ngClass]="['b3-test_' + formControlName, 'slider round', control.disabled || isDisabled || isReadonly || !isLoaded ? 'slider__disabled' : '']"></span>
          </label>
        </div>

        <ng-container *ngIf="{isVisible: isTooltipeVisible$ | async, scopeRepo: auth.scopeRepo$ | async, hint: hint$ | async} as state">
          <div *ngIf="state?.hint && state.isVisible && formControl.enabled && control.enabled && isLoaded
            && !!(state?.scopeRepo?.admin?.isActive || state?.scopeRepo?.admin_menu?.isActive)"
            class="tooltip__helper"
            (mouseover)="focusEvent$.next(true)"
            (mouseout)="focusEvent$.next(false)">
            <div
              [mdbTooltip]="state?.hint || 'Переключатель'"
              [placement]="'left'"
              [container]="'body'"
              #tooltipDirective="mdb-tooltip" class="mx-1"
              (click)="openB3Chat(state?.hint)"
              [@inOutAnimation]
              class="rpn-cursor-pointer">
              <img src="/assets/svgs/b3-chat.svg" width="70">
            </div>
          </div>
        </ng-container>

      </div>

      <app-form-field-validation
        *ngIf="!isFilter && formControl && formControl.enabled && control.enabled && isLoaded"
        [errors]="formControl.errors"
        [dirty]="formControl.dirty"
        [notSavedDataMessage]="notSavedDataMessage"
        [valid]="formControl.valid"
        [value]="formControl.value">
      </app-form-field-validation>

    </form>
  </div>

</form>
