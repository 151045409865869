import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';

export enum EXmlEbType {
  accruals = 'accruals',
  reconciliations = 'reconciliations',
  statement = 'statement',
  group_statement = 'group_statement',
  statement_lost_income = 'statement_lost_income',
}

export enum EXmlEbName {
  accruals = 'Выгрузка начислений для ЭБ',
  reconciliations = 'Выгрузка результатов квитирования для ЭБ',
  statement = 'Ведомость начисления доходов бюджета',
  group_statement = 'Ведомость группового начисления доходов бюджета',
  statement_lost_income = 'Ведомость выпадающих доходов',
}

export const EXmlEbNameByType = {
  [EXmlEbType.accruals]: EXmlEbName.accruals,
  [EXmlEbType.reconciliations]: EXmlEbName.reconciliations,
  [EXmlEbType.statement]: EXmlEbName.statement,
  [EXmlEbType.group_statement]: EXmlEbName.group_statement,
  [EXmlEbType.statement_lost_income]: EXmlEbName.statement_lost_income,
};

export interface IXmlEbTypeDictItem extends IDictionaryItem {
  id: EXmlEbType;
  name: EXmlEbName;
}
