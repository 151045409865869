export const CModalOptions = {
  backdrop: true,
  keyboard: true,
  focus: true,
  show: false,
  ignoreBackdropClick: false,
  containerClass: '',
  class: '',
  animated: true,
  data: {
    heading: 'Modal heading',
    content: {
      heading: 'Content heading',
      description: 'Content description',
      result: null,
      closeModal: null,
    },
  },
};
