import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadedService {
  private loading$ = new BehaviorSubject(false);
  public isLoading$: Observable<boolean> = this.loading$.pipe(debounceTime(200), shareReplay({ bufferSize: 1, refCount: true }));

  public loadStart(): void {
    this.loading$.next(true);
  }
  public loadFinish(): void {
    this.loading$.next(false);
  }
}
