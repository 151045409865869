import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class PreloaderComponent {
  @Input() position: 'absolute' | 'fixed' = 'absolute';
  public isShow = false;
  public text = 'Подождите немного. Загружаем…';
}
