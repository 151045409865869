export const AutoDatasourceId = {
  11: true,
  21: true,
  61: true,
  71: true,
};

export enum TableTypeNumber {
  all_reports = 1,
  all_requests = 2,
}

export enum ESwitchCardTable {
  switchTable = 'switchTable',
  switchCard = 'switchCard',
}

export enum TableType {
  document_registry = 'document_registry',
  datasource = 'datasource',
  all_reports = 'all_reports',
  all_requests = 'all_requests',
  check_lists_self = 'check-lists-self',
  appointment = 'appointment',
  erp_check_lists = 'erp_check_lists',
  self_exam = 'self_exam',
  erp_inspections = 'erp_inspections',
  knd_inspection_request = 'knd_inspection_request',
  knd_registry = 'knd_registry',
  knd_card = 'knd_card',
  knd_plan = 'knd_plan',
  knd_quotas_program = 'knd_quotas_program',
  knd_quotas_card = 'knd_quotas_card',

  pm_visit_card = 'pm_visit_card',
  pm_warning_card = 'pm_warning_card',

  dpnvos = 'dpnvos',
  dpnvos611 = 'dpnvos611',
  dpnvos_requests = 'dpnvos_requests',
  dpnvos_clarification = 'dpnvos_clarification',
  dpnvos_actual_expenses = 'dpnvos_actual_expenses',
  dpnvos_control_act = 'dpnvos_control_act',
  dvos = 'dvos',
  onv_registry = 'onv',
  onv_request = 'onv_request',
  onv_request_inclusion = 'onv_request_inclusion',
  onv_request_inclusion_v2 = 'onv_request_inclusion_v2',
  onv_request_exclusion = 'onv_request_exclusion',
  onv_request_edit = 'onv_request_edit',
  onv_request_edit_v2 = 'onv_request_edit_v2',
  onv_request_correction = 'onv_request_correction',
  onv_request_adjust_v2 = 'onv_request_adjust_v2',
  gee_request = 'gee_request',
  gee_request_issue = 'gee_request_issue',
  gee_estimated_costs_validation = 'gee_estimated_costs_validation',
  gee_events_estimated_costs_validation = 'costs_events_validation',
  dkt_request = 'dkt_request',
  dfr_request = 'dfr_request',
  dfr2022_request = 'dfr2022_request',
  utilization_request = 'utilization_request',
  ecocharge_request = 'ecocharge_request',
  ecocharge_registry = 'ecocharge_registry',
  expertise_request = 'expertise_request',
  expense_reimbursement = 'expense_reimbursement',
  termination_request = 'termination_request',
  utilization_registry = 'utilization_registry',
  ecocharge_control_act = 'ecocharge_control_act',
  utilization_control_act = 'utilization_control_act',
  env_harm_request = 'env_harm_request',
  env_harm_report = 'env_harm_report',
  analytic_report = 'analytic_report',
  reports_history = 'reports_history',
  smev = 'smev',
  smev_archive = 'smev_archive',
  goskey_sign_messages = 'goskey_sign_messages',
  smev3_outgoings = 'smev3_outgoings',
  onvos_history = 'onvos_history',
  onvos_reports = 'onvos_reports',
  onvos_monitoring = 'onvos_monitoring',
  onvos_monitoring_source = 'onvos_monitoring_source',
  payments_analytics = 'payments_analytics',
  payments_x_eb = 'payments_x_eb',
  payments_payers = 'payments_payers',
  payments_debtors = 'payments_debtors',
  payments_charges = 'payments_charges',
  payments_charges_lk = 'payments_charges_lk',
  payments_accruals = 'payments_accruals',
  payments_management_gis_gmp_rrs = 'payments_management_gis_gmp_rrs',
  payments_management_gis_gmp_sar = 'payments_management_gis_gmp_sar',
  payments_management_gis_gmp_sac = 'payments_management_gis_gmp_sac',
  pm_sufd_wr = 'pm_sufd_wr',
  pm_sufd_imports_wr = 'pm_sufd_imports_wr',
  pm_sufd_zf = 'pm_sufd_zf',
  pm_sufd_imports_zf = 'pm_sufd_imports_zf',
  pm_sufd_imports_tofk = 'pm_sufd_imports_tofk',
  pm_gis_gmp_confirmation_results = 'pm_gis_gmp_confirmation_results',
  payments_accruals_lk = 'payments_accruals_lk',
  payments_declarations = 'payments_declarations',
  accruals_declarations = 'accruals_declarations',
  payments_acts = 'payments_acts',
  eco_refund_request = 'eco_refund_request',
  eco_revise_request = 'eco_revise_request',
  payments_payments = 'payments_payments',
  payments_opening_balance_imports = 'payments_opening_balance_imports',
  payments_sufd = 'payments_sufd',
  payments_sufd_uf = 'payments_sufd_uf',
  payments_sufd_bd = 'payments_sufd_bd',
  payments_sufd_imports_vt = 'payments_sufd_imports_vt',
  payments_sufd_imports_vt_operations = 'payments_sufd_imports_vt_operations',
  payments_payments_lk = 'payments_payments_lk',
  payments_management_offset = 'payments_management_offset',
  ker_request_issue = 'ker_request_issue',
  public_inspector_request = 'public_inspector_request',
  pek_request = 'pek_request', // ПЭК 2019
  pek_report = 'pek_report', // ПЭК 2020
  self_utilization_act = 'self_utilization_act',
  utilization_weight_experiment = 'utilization_weight_experiment',
  utilization_weight_act = 'utilization_weight_act',
  twotp_request_greenhouse_gases = 'twotp_request_greenhouse',
  twotp_request_air = 'twotp_request_air',
  twotp_request_waste = 'twotp_request_waste',
  twotp_request_reclamation = 'twotp_request_reclamation',
  license_request_issue = 'license_request_issue',
  license_request_correction = 'license_request_correction',
  license_request_writeout = 'license_request_writeout',
  request_department_writeout = 'request_department_writeout',
  license_request_replace = 'license_request_replace',
  license_requests = 'license_requests',
  license_registry = 'license_registry',
  license_report = 'license_report',
  license_request_termination = 'license_request_termination',
  license_onsite_check = 'license_onsite_check',
  license_documentary_check = 'license_documentary_check',
  request_forced_termination = 'request_forced_termination',
  license_request_insertion = 'license_request_insertion',
  license_request_modification = 'license_request_modification',
  groro_request_inclusion = 'groro_request_inclusion',
  groro_request_actualization = 'groro_request_actualization',
  groro_request_exclusion = 'groro_request_exclusion',
  groro_request_update = 'groro_request_update',
  groro_entry = 'groro_entry',
  groro_request = 'groro_request',
  groro_fkkos = 'groro_fkkos',
  groro_history = 'groro_history',
  dpnvos_revise_request = 'dpnvos_revise_request',
  dpnvos_refund_request = 'dpnvos_refund_request',
  requisites_change_request = 'requisites_change_request',
  nvos_offset_request = 'nvos_offset_request',
  eco_offset_request = 'eco_offset_request',
  payment_analytical = 'payment_analytical',
  eek_requests = 'eek_requests',
  eek_petition = 'eek_petition_for_certificate',
  eek_correction = 'eek_petition_for_correction',
  eek_duplicate = 'eek_petition_for_duplicate',
  eek_resolutions = 'eek_certificate_import_issue',
  cites_certificate_import = 'cites_certificate_import',
  waste_class_assignment_issue = 'waste_class_assignment_issue',
  animal_registry = 'animals_reestr_record',
  controlled_entities_registry = 'controlled_entities_registry',
  controlled_entity_objects = 'controlled_entity_objects',
  controlled_entity_reports = 'controlled_entity_reports',
  controlled_entity_checks = 'controlled_entity_checks',
  attorney_list = 'attorney_list',

  importReportXml = 'importReportXml',
  importRequestXml = 'importRequestXml',
  importXml = 'importXml',

  fgen_history = 'fgen_history',
  fgen_changes_logs = 'fgen_changes_logs',

  fgen_recult = 'fgen_recult',
  knm_object = 'knm_object',
  fgn_category_change = 'fgn_category_change',

  fgen_object = 'fgen_object',
  fggn_object = 'fggn_object',
  fgzn_object = 'fgzn_object',
  fgohn_object = 'fgohn_object',
  fgotn_object = 'fgotn_object',
  fglesn_object = 'fglesn_object',
  fgan_object = 'fgan_object',
  fgahn_object = 'fgahn_object',

  ancr_object = 'ancr_object',
  eroi_object = 'eroi_object',
  atex_object = 'atex_object',
  fkko_object = 'fkko_object',
  animals_object = 'animals_object',

  ozon_depleting_registry = 'ozon_depleting_registry_entry',
  addcharge = 'addcharge',
  registry_permission = 'registry_permission',
  commission_object = 'commission_object',
  liquidated_object = 'liquidated_object',
  cites_object = 'cites_object',

  news = 'news',
  recycler_inclusion = 'recycler_inclusion',
  recycler_actualization = 'recycler_actualization',
  recycler_actualization_custom = 'recycler_actualization_custom',
  rosnedra_notice = 'rosnedra_notice',

  control_objects_by_indicator = 'control_objects_by_indicator',
  expertise_request_clati = 'expertise_request_clati',
  key_metric = 'key_metric',
  pre_trial_appeal = 'pre_trial_appeal',

  administrative_matter = 'administrative_matter',
  administrative_matter_order = 'administrative_matter_order',
  matters_submission = 'matters_submission',
}

export enum InputCategory {
  input = 'input',
  textarea = 'textarea',
}

export enum InputAppend {
  price = 'price',
}

export enum ETypeAPI {
  'waste' = 'twotp/waste',
  'recultivation' = 'twotp/report_reclamation',
  'air' = 'twotp/air',
  'svc-61' = 'payment-nvos/request',
  'gos-eco-expert' = 'gee_request',
}

export enum GroroRequestType {
  groro_request_inclusion = 101,
  groro_request_actualization = 102,
  groro_request_exclusion = 103,
  groro_request_update = 105,
}

export enum OnvosRequestType {
  onv_request_inclusion = 21,
  onv_request_inclusion_v2 = 210,
  onv_request_exclusion = 23,
  onv_request_correction = 22,
  onv_request_edit = 30,
  onv_request_edit_v2 = 300,
  onv_request_adjust_v2 = 240,
}

export enum OnvosRequestUrlMap {
  '/api/svc/onv/v2/onv_request_inclusion_v2/{onv_request_inclusion_v2}/request_organization' = '/api/svc/onv/v2/onv_request_inclusion_v2/{onv_request_inclusion_v2}/statement/organization',

  '/api/svc/onv/v2/onv_request_edit_v2/{onv_request_edit_v2}/request_organization' = '/api/svc/onv/v2/onv_request_edit_v2/{onv_request_edit_v2}/statement/organization',

  '/api/svc/onv/v2/onv_request_adjust_v2/{onv_request_adjust_v2}/request_organization' = '/api/svc/onv/v2/onv_request_adjust_v2/{onv_request_adjust_v2}/statement/organization',

  '/api/svc/onv/v2/onv_request_inclusion_v2/{onv_request_inclusion_v2}/statement' = '/api/svc/onv/v2/onv_request_inclusion_v2/{onv_request_inclusion_v2}',

  '/api/svc/onv/v2/onv_request_edit_v2/{onv_request_edit_v2}/statement' = '/api/svc/onv/v2/onv_request_edit_v2/{onv_request_edit_v2}',

  '/api/svc/onv/v2/onv_request_adjust_v2/{onv_request_adjust_v2}/statement' = '/api/svc/onv/v2/onv_request_adjust_v2/{onv_request_adjust_v2}',

  '/api/svc/onv/v2/onv_request_correction_v2/{onv_request_correction_v2}/statement' = '/api/svc/onv/v2/onv_request_correction_v2/{onv_request_correction_v2}',

  '/api/svc/onv/v2/onv_request_exclusion_v2/{onv_request_exclusion_v2}/statement' = '/api/svc/onv/v2/onv_request_exclusion_v2/{onv_request_exclusion_v2}',
}

export enum LicenseRequestType {
  license_request_issue = 45,
  license_request_writeout = 46,
  request_department_writeout = 47,
  license_request_replace = 50,
  license_request_termination = 49,
}

export const enum IXMLDOMNodeType {
  NODE_ELEMENT = 1, // element
  NODE_ATTRIBUTE = 2, // attribute
  NODE_TEXT = 3, // text
  NODE_CDATA_SECTION = 4, // cdatasection
  NODE_ENTITY_REFERENCE = 5, // entityreference
  NODE_ENTITY = 6, // entity
  NODE_PROCESSING_INSTRUCTION = 7, // processinginstruction
  NODE_COMMENT = 8, // comment
  NODE_DOCUMENT = 9, // document
  NODE_DOCUMENT_TYPE = 10, // documenttype
  NODE_DOCUMENT_FRAGMENT = 11, // documentfragment
  NODE_NOTATION = 12, // notation
}

export enum Sort {
  asc = 'asc',
  desc = 'desc',
}

export enum ETypeOfModal {
  sign = 1,
  view = 4,
}

export enum UploadStatus {
  Ready = 'Ready',
  Requested = 'Requested',
  Started = 'Started',
  Failed = 'Failed',
  Completed = 'Completed',
  Initialized = 'Initialized',
}

// http://standart.gov.design/design/layout
export enum AppBreakpoints {
  'mobile-xs' = '(min-width: 320px) and (max-width: 359px)',
  'mobile-s' = '(min-width: 360px) and (max-width: 735px)',
  tablet = '(min-width: 736px) and (max-width: 1087px)',
  desktop = '(min-width: 1088px)',
}

export enum EnvharmReportParams {
  territory_org = 'ter_id',
}

export enum AnalyticReportTemplateCode {
  onv_general_pollute_substance = 'onv_general_pollute_substance',
  polluats_by_municipalitet = 'polluats_by_municipalitet',
  onv_olap_report = 'onv_olap_report',
  onv_info_all = 'onv_info_all',
  info_prower_size_products = 'info_prower_size_products',
  onv_olap_report_by_region = 'onv_olap_report_by_region',
}

export enum ReportParams {
  status_code = 'status_code',
  registry_category_id = 'registry_category_id',
  fkko_code = 'fkko_code',
  tnved_code = 'tnved_code',
  okpds = 'okpds',
  group_code = 'group_code',
  territory_org = 'territory_org',
  territory_org_is_active_notnull = 'territory_org_is_active_notnull',
  object_region_id = 'object_region_id',
  region_id = 'region_id',
  ter_id = 'ter_id',
  registry_category = 'registry_category',
  oktmo = 'oktmo',
  municipality = 'municipality',
  criterions = 'criterions',
  region = 'region',
  billId = 'billId',
  inn = 'inn',
  innFl = 'innFl',
  ogrn = 'ogrn',
  ogrnIp = 'ogrnIp',
  naimUlPoln = 'naimUlPoln',
  nomLic = 'nomLic',
  dataLic = 'dataLic',
  dataNachLic = 'dataNachLic',
  dataOkonLic = 'dataOkonLic',
  dataLicPrev = 'dataLicPrev',
  dataNachLicPrev = 'dataNachLicPrev',
  kodSlvd = 'kodSlvd',
  naimVd = 'naimVd',
  prDeistv = 'prDeistv',
  vidResh = 'vidResh',
  dataResh = 'dataResh',
  dataNachResh = 'dataNachResh',
  okogu = 'okogu',
  regionId = 'regionId',
  naimLoPoln = 'naimLoPoln',
  naimLoSokr = 'naimLoSokr',
  innLo = 'innLo',
  ogrnLo = 'ogrnLo',
  number = 'number',
  familyName = 'familyName',
  firstName = 'firstName',
  idDoc = 'idDoc',
  idDocIskl = 'idDocIskl',
  customsCode = 'customsCode',
  registrationDate = 'registrationDate',
  gtdNumber = 'gtdNumber',
  typePerson = 'typePerson',
  passportSeries = 'passportSeries',
  passportNumber = 'passportNumber',
  requestNumber = 'requestNumber',
  requestDate = 'requestDate',
  serviceId = 'serviceId',
  serialNumb = 'serialNumb',
  numb = 'numb',
  firmget = 'firmget',
  mode = 'mode',
  isPril = 'isPril',
  sureName = 'sureName',
  naimUL = 'naimUL',
  appId = 'appId',
  senderId = 'senderId',
  nomResh = 'nomResh',
  serLic = 'serLic',
  vidLic = 'vidLic',
  dataOkonResh = 'dataOkonResh',
  serial = 'serial',
  payerType = 'payerType',
  identityType = 'identityType',
  kio = 'kio',
  kpp = 'kpp',
  payerIdentifier = 'payerIdentifier',
  timestamp = 'timestamp',
  uuid4 = 'uuid4',
  solutionNumber = 'solutionNumber',
  objectName = 'objectName',
  placeName = 'placeName',
  ndsNumber = 'ndsNumber',
  nomLicPrev = 'nomLicPrev',
  startDate = 'startDate',
  endDate = 'endDate',
  kbkList = 'kbkList',
  linkedChargesIdentifiers = 'linkedChargesIdentifiers',
  territoryOrgId = 'territoryOrgId',
  report_year = 'report_year',
  year = 'year',
  report_id = 'report_id',
  onv_region_id = 'onv_region_id',
  okpd_code = 'okpd_code',
}

export enum OnvosProfileDataParams {
  'control-category' = 'control-category',
  'calc-criteria' = 'calc-criteria',
  'calc-criteria-r' = 'calc-criteria-r',
  'extended-profile' = 'extended-profile',
}

export enum OnvosInspectionsDataParams {
  'erp-inspections' = 'erp-inspections',
  'knd-registry' = 'knd-registry',
}

export enum OnvosMonitoringDataParams {
  'chart' = 'chart',
  'sensors' = 'sensors',
  'notifications' = 'notifications',
}

export enum UserScopes {
  uonvos_private = 'uonvos_private',
  uonvos_public = 'uonvos_public',
  smev_admin = 'smev_admin',
  smev_view = 'smev_view',
  smev_send = 'smev_send',
  gee_view = 'gee_view',
  admin = 'admin',
  demo = 'demo',
  payments_managment = 'payments_managment',
  ker = 'ker',
  envharm_view = 'envharm_view',
  public_inspector_request = 'public_inspector_request',
  lic_waste_view = 'lic_waste_view',
  lic_waste_view_public = 'lic_waste_view_public',
  license_onsite_check_view = 'license_onsite_check_view',
  documentary_check_view = 'documentary_check_view',
  groro_view = 'groro_view',
  default_esia = 'default_esia',
  eek_petition_lk_demo = 'eek_petition_lk_demo',
  check_lists_LK = 'check_lists_LK',
  emissions_monitoring = 'emissions_monitoring',
  messages = 'messages',
}

export enum SuperVisionLevelsEnum {
  Federal = 'Federal',
  Regional = 'Regional',
  FederalGeo = 'FederalGeo',
  RegionalGeo = 'RegionalGeo',
  Fcao = 'fcao',
}

export enum OrgTypeEnum {
  to = 'Federal',
  roiv = 'Regional',
}

export const payerTypeEnum = {
  1: 'для ФЛ',
  2: 'для ЮЛ – резидента РФ',
  3: 'ЮЛ – нерезидента РФ',
  4: 'для ИП',
};

export const SuperVisionLevelsNumberEnum = {
  1: 'Federal',
  2: 'Regional',
  3: 'Geo',
  4: 'Land',
};

export const SuperVisionLevelsTextEnum = {
  Federal: 1,
  Regional: 2,
  Geo: 3,
  Land: 4,
};

export enum SmevUIStatus {
  created = 'created',
  completed = 'completed',
  error = 'error',
  pending = 'pending',
  new = 'NEW',
}

export enum PaymentsManagmentUIStatus {
  new = 'new',
  uploaded = 'uploaded',
  partly_payed = 'partly_payed',
  payed = 'payed',
  cancelled = 'cancelled',
}

export enum PMVTOperationsDocCode {
  PP = 'PP',
  ZV = 'ZV',
  UF = 'UF',
  PL = 'PL',
  SF = 'SF',
  SP = 'SP',
  RC = 'RC',
  RD = 'RD',
  XX = 'XX',
}

export enum Status {
  new = 'Новый',
  cancelled = 'Отменён',
  draft = 'Черновик',
  declined = 'Отменён (исключён)',
  deleted = 'Удалён',
  done = 'Внесён в реестр',
  success = 'Принят',
  approved = 'Принят',
  warning = 'На рассмотрении',
  returned = 'На изменении',
}

export enum ECitesTypeOfKindIds {
  export = 2,
  import = 3,
  reexport = 4,
  origin = 5,
  preconvention = 6,
  exhibition = 7,
  introduction = 10,
}

export enum EEekStateServiceIds {
  eek_petition_for_certificate_id = 112,
  eek_petition_for_duplicate_id = 113,
  eek_petition_for_correction_id = 114,
}

export enum EInstructionsTag {
  instructions = 'instructions',
  references = 'references',
}

export enum DisclaimerNewsTags {
  // NEWS
  news = 'news',
  // MAIN PAGE
  lk_disclaimer_main = 'lk_disclaimer_main',
  lkoi_disclaimer_main = 'lkoi_disclaimer_main',
  lkae_disclaimer_main = 'lkae_disclaimer_main',
  ksv_disclaimer_main = 'ksv_disclaimer_main',
  uonvos_disclaimer_main = 'uonvos_disclaimer_main',
  // ONVOS REGISTRY TABLE
  lk_disclaimer_onvos_all = 'lk_disclaimer_onvos_all',
  ksv_disclaimer_onvos_all = 'ksv_disclaimer_onvos_all',
  // ONVOS REQUEST TABLE
  lk_disclaimer_onvos_request_all = 'lk_disclaimer_onvos_request_all',
  ksv_disclaimer_onvos_request_all = 'ksv_disclaimer_onvos_request_all',
  // ONVOS VIEW
  lk_disclaimer_onvos = 'lk_disclaimer_onvos',
  ksv_disclaimer_onvos = 'ksv_disclaimer_onvos',
  // REPORT TABLE
  lk_disclaimer_report_all = 'lk_disclaimer_report_all',
  ksv_disclaimer_report_all = 'ksv_disclaimer_report_all',
  // REPORT VIEW
  lk_disclaimer_report = 'lk_disclaimer_report',
  ksv_disclaimer_report = 'ksv_disclaimer_report',
  // REQUEST TABLE
  lk_disclaimer_request_all = 'lk_disclaimer_request_all',
  ksv_disclaimer_request_all = 'ksv_disclaimer_request_all',
  // REQUEST VIEW
  lk_disclaimer_request = 'lk_disclaimer_request',
  // SMEV TABLE
  ksv_disclaimer_smev_all = 'ksv_disclaimer_smev_all',
  // SMEV VIEW
  ksv_disclaimer_smev = 'ksv_disclaimer_smev',
  // LICENSE TABLE
  lk_disclaimer_license_all = 'lk_disclaimer_license_all',
  ksv_disclaimer_license_all = 'ksv_disclaimer_license_all',
  // PERMISSION TABLE
  lk_disclaimer_permission_all = 'lk_disclaimer_permission_all',
  ksv_disclaimer_permission_all = 'ksv_disclaimer_permission_all',
  // PAYMENTS TABLE
  lk_disclaimer_payments_all = 'lk_disclaimer_payments_all',
  ksv_disclaimer_payments_all = 'ksv_disclaimer_payments_all',
  // SMEV VIEW
  lk_disclaimer_smev = 'lk_disclaimer_smev',
  // PUBLIC LICENSE
  public_disclaimer_license = 'public_disclaimer_license',
  // PUBLIC ONVOS
  public_disclaimer_onvos = 'public_disclaimer_onvos',
  disabled_public_onvos = 'disabled_public_onvos',
  // PUBLIC REGISTRY
  public_disclaimer_registry = 'public_disclaimer_registry',
}

export enum ECountryIds {
  russia = 643,
  kazakhstan = 398,
}

export enum EModalType {
  syncError = 'syncError',
  onvosChanges = 'onvosChanges',
  syncSuccess = 'syncSuccess',
}

export enum EErvkStatusSync {
  sync = 'sync',
  null = 'null',
  not_need = 'not_need',
  error = 'error',
  sended = 'sended',
}

export enum RpnRegistryName {
  envharm = 'envharm',
  'documents-registry' = 'documents-registry',
}
