import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IPageError } from 'src/app/providers/_interfaces/page.error';
import { ErrorPageService } from 'src/app/providers/_services/error.page.service';

@Injectable({
  providedIn: 'root',
})
export class NotFoundPageGuard {
  constructor(private errorPageService: ErrorPageService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const error: IPageError = {
      status_code: 404,
      header: 'Страница не найдена',
      error_frontend_route: state.url,
    };
    try {
      this.errorPageService.clearErrorInStorage();
      sessionStorage.setItem('lastPageError', JSON.stringify(error));
    } catch (err) {}

    return of(true);
  }
}
