<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true"></span>
    </button>
  </div>

  <div id="b3-chat-container" class="modal-body b3-chat-container text-center">

  </div>
</div>
