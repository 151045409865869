export enum EGisGmpStatus {
  registration_waiting = 'registration_waiting',
  registered = 'registered',
  not_registered = 'not_registered',
  cancellation_waiting = 'cancellation_waiting',
  decancellation_waiting = 'decancellation_waiting',
  canceled = 'canceled',
}

export enum EGisGmpStatusText {
  registration_waiting = 'ожидает регистрации',
  registered = 'зарегистрирован',
  not_registered = 'не зарегистрирован',
  cancellation_waiting = 'ожидает аннуляции',
  decancellation_waiting = 'ожидает деаннуляции',
  canceled = 'отменен',
}

export enum EGisGmpRequestStatus {
  wait = 'wait',
  sent = 'sent',
  status_message = 'status_message',
  success = 'success',
  reject = 'reject',
  unknown_message_type = 'unknown_message_type',
}

export enum EGisGmpRequestStatusText {
  wait = 'Ожидает отправки',
  sent = 'Запрос отправлен',
  status_message = 'Запрос принят ГИС ГМП',
  success = 'Выполнен успешно',
  reject = 'Ошибка',
  unknown_message_type = 'Иное',
}

export interface IPaymentsManagementGisGmpStatusDictionaryItem {
  id: EGisGmpStatus;
  name: EGisGmpStatusText | string;
}

export enum EGisGmpRequestType {
  reconcile = 'reconcile',
  unreconcile = 'unreconcile',
  service_provided = 'service_provided',
  annulment_service_provided = 'annulment_service_provided',
  reconciliation_results_request = 'reconciliation_results_request',
}

export enum EGisGmpRequestTypeText {
  reconcile = 'Передача квитирования',
  unreconcile = 'Передача аннуляции квитирования',
  service_provided = 'Услуга оказана',
  annulment_service_provided = 'Услуга оказана - аннуляция',
  reconciliation_results_request = 'Запрос результатов квитирования',
}

export interface IPaymentsManagementGisGmpRequestTypeDictionaryItem {
  id: EGisGmpRequestType;
  name: EGisGmpRequestTypeText | string;
}

export enum ActionGisGmp {
  to_gis_gmp_by_hand = 'to_gis_gmp_by_hand',
  to_gis_gmp_annulation_by_hand = 'to_gis_gmp_annulation_by_hand',
  to_gis_gmp_deannulation_by_hand = 'to_gis_gmp_deannulation_by_hand',
  make_storno_by_hand = 'make_storno_by_hand',
  request_confirmations = 'request_confirmations',
  request_confirmations_2 = 'request_confirmations_2',
  send_confirmations = 'send_confirmations',
  cancel_confirmations = 'cancel_confirmations',
  send_confirmation = 'send_confirmation',
  cancel_confirmation = 'cancel_confirmation',
  remove_accrual = 'remove_accrual',
  edit_accrual = 'edit_accrual',
}

export enum ActionGisGmpAccrualText {
  to_gis_gmp_by_hand = 'Зарегистрировать начисление в ГИС ГМП',
  to_gis_gmp_annulation_by_hand = 'Аннулировать начисление в ГИС ГМП',
  to_gis_gmp_deannulation_by_hand = 'Деаннулировать начисление в ГИС ГМП',
  make_storno_by_hand = 'Сторнировать начисление',
  request_confirmations = 'Запросить результаты квитирования из ГИС ГМП',
  request_confirmations_2 = 'Запросить данные по задолженности из ГИС ГМП',
  send_confirmations = 'Передать квитирования в ГИС ГМП',
  cancel_confirmations = 'Аннулировать квитирования в ГИС ГМП',
  send_confirmation = 'Передать квитирование в ГИС ГМП',
  cancel_confirmation = 'Аннулировать квитирование в ГИС ГМП',
  remove_accrual = 'Удалить начисление',
  edit_accrual = 'Редактировать начисление',
  connect_penalty = 'Связать с начислением, по которому начислено пени',
}

export enum ActionGisGmpChargeText {
  to_gis_gmp_by_hand = 'Зарегистрировать платеж в ГИС ГМП',
  to_gis_gmp_annulation_by_hand = 'Аннулировать платеж в ГИС ГМП',
  to_gis_gmp_deannulation_by_hand = 'Деаннулировать платеж в ГИС ГМП',
}

export const PM_PAYMENTS_reconciliation_statuses = [
  { id: 'reconciled', name: 'сквитирован' },
  { id: 'partly_reconciled', name: 'частично сквитирован' },
  { id: 'not_reconciled', name: 'не сквитирован' },
  { id: 'canceled', name: 'сделано уточнение' }, // only front status
];

export const PM_ACCRUALS_payment_status = [
  { id: 'paid', name: 'оплачено' },
  { id: 'partly_paid', name: 'частично оплачено' },
  { id: 'not_paid', name: 'не оплачено' },
  { id: 'canceled', name: 'отменено' },
  { id: 'written_off', name: 'списано' },
];
