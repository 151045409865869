import {
  Directive,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef,
  SimpleChanges,
  EventEmitter,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { isEqual, isArray } from 'lodash';
import { IOption, SelectComponent } from 'ng-uikit-pro-standard';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, debounceTime } from 'rxjs/operators';
import { AppFormFieldClass } from 'src/app/providers/_classes/app.form.field.class';
import { DICT } from 'src/app/providers/_dictionary';
import { DictionaryStorage, IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';
import { IPagination } from 'src/app/providers/_interfaces/pagination.interface';
import { IOptionTransform } from 'src/app/providers/_pipes/options-transform.pipe';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import {
  filteringDictByFilters,
  rebuildSuggestOption,
  templateFilter,
  transformBooleanToString,
  transformStringToBoolean,
  undefinedOrNullOrEmptyString,
} from 'src/app/providers/_utils/utils';

const FILTER_RESET_STRING_VALUE = 'Все';

@Directive()
export class BaseSelectClass extends AppFormFieldClass<string | boolean> implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() resetScrollTopOnOpen = false;
  @Input() optionsKeys: string[]; // массив строк-ключей для саджест подсказок
  @Input() withOutBracketsOptionsKeys: string[] = []; // массив строк-ключей для которых не нужны квадратные скобки
  @Input() apiDictUrl: string;
  @Input() apiDictRelations: string;
  @Input() isLoaded = true;
  @Input() filled = false;
  @Input() showSuggestOnTop = false;
  @Input() tooltip: string;
  @Input() templateFilterFunction: (v: any, index?: number, arr?: any[]) => boolean;
  @Input() disableResetStringOption = false;
  @Input() filterResetString: string;
  @Input() resetStringOptionName;
  @Input() resetStringOptionIsLast = false;
  @Input() state_service_id: number;
  @Input() selectFirstOption = false;
  @Input() removeButton = true;
  @Output() selectChangeValueUserEvent = new EventEmitter();
  @Output() selectItemUserEvent = new EventEmitter();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @Output() optionCleared: EventEmitter<void> = new EventEmitter();

  public dictStorage: DictionaryStorage<any>;
  public resetStringOption: IDictionaryItem;
  public options$: Observable<IOption[]>;
  public isDictionaryItemLoaded = true;

  private needUpdateOptions$: BehaviorSubject<null> = new BehaviorSubject(null);
  private selectedId$: BehaviorSubject<any> = new BehaviorSubject(null);

  private staticOptions$ = new BehaviorSubject<any[]>(null);
  @Input() set staticOptions(val) {
    this.staticOptions$.next(val);
  }
  get staticOptions(): any {
    return this.staticOptions$.getValue();
  }

  public filters$: BehaviorSubject<any> = new BehaviorSubject({});
  @Input() set filters(val: any) {
    this.filters$.next(val);
  }
  get filters(): any {
    return this.filters$.value;
  }

  @ViewChild('selectComponent') selectComponent: SelectComponent;
  @Input() transformDict: (dict) => any = (dict) => dict;

  constructor(
    public dictionaryService: DictionaryService,
    public svcRestService: SvcRestService,
    public store: Store,
    public cdr: ChangeDetectorRef,
    public fcd: FormGroupDirective,
    public sanitizer: DomSanitizer,
    public elRef: ElementRef,
  ) {
    super(fcd, cdr, dictionaryService);
  }


  ngAfterViewInit(): void {
    const sub = this.selectComponent.opened.pipe(
      map((v) => {
        if (v.isOpen && this.resetScrollTopOnOpen) {
          this.resetScrollTop()
        }
      })
    ).subscribe();

    this.subscriptions.push(sub);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initDictionary();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.templateFilterFunction || changes.state_service_id) {
      this.needUpdateOptions$.next(null);
    }
  }

  resetScrollTop() {
    setTimeout(() => {
      const dropdownContent = this.elRef.nativeElement.querySelector('.dropdown-content .options');
      dropdownContent.scrollTop = 0;
    }, 100);
  }

  writeValue(id: any): void {
    this.formValue = id;
    this.selectedId$.next(id);
    if (!this.destroyed) this.cdr.markForCheck();
  }

  private mapOptions(dict: IDictionaryItem[]): IOptionTransform[] {
    let dictArray: IDictionaryItem[] = dict.slice(0);

    if (this.transformDict) dictArray = this.transformDict(dictArray);
    if (this.templateFilterFunction) dictArray = this.templateFilter(dictArray);

    if (this.state_service_id) {
      dictArray = dictArray.filter((x) => {
        if (x.state_service_id) return x.state_service_id === this.state_service_id;
        if (x.state_service_ids && isArray(x.state_service_ids)) {
          if (x.state_service_ids.length === 0) return false;
          return x.state_service_ids.indexOf(this.state_service_id) >= 0;
        }
        return true;
      });
    }

    if (this.isFilter && !this.disableResetStringOption) {
      this.resetStringOption = this.getResetStringOption(this.dictStorage, this.searchKey, this.sendKey);

      if (this.resetStringOptionIsLast) {
        dictArray.push(this.resetStringOption);
      } else {
        dictArray.unshift(this.resetStringOption);
      }
    }

    if (!dictArray?.length) return [];

    const options: IOptionTransform[] = [];
    dictArray.forEach((originalItem) => {
      const label = rebuildSuggestOption(originalItem, this.optionsKeys, this.searchKey, this.withOutBracketsOptionsKeys, this.sendKey);
      const value = transformBooleanToString(originalItem[this.sendKey]);
      options.push({ value, label, originalItem });
    });

    return options;
  }

  private initValue(options: IOptionTransform[], id: any): void {
    let checkedId = id;

    if (typeof id === 'boolean' && id === false) {
      checkedId = id + '';
    }

    const searchId = this.isFilter && !checkedId ? FILTER_RESET_STRING_VALUE : this.formValue || id;

    const initObject = options.find((x) => '' + x.value === '' + searchId) || { value: null, label: '', originalItem: {} };
    this.control.setValue(initObject.value);

    if (this.selectFirstOption && options?.length && this.fcd.form.get(this.formControlName)) {
      const needSelectFirst =
        undefinedOrNullOrEmptyString(this.control.value) || !this.staticOptions.find((o) => o[this.sendKey] === this.formValue);
      if (needSelectFirst) this.selectValue(options[0], false);
    }
  }

  private initDictionary(): void {
    this.isDictionaryItemLoaded = false;
    let dict$ = of([]);

    this.dictStorage = {
      dictionary$: new BehaviorSubject([]),
      ...(this.dictKey ? DICT[this.dictKey] : {}),
    };

    if (this.dictKey && !this.apiDictUrl && !this.staticOptions?.length) {
      /**
       * MAKE STORAGE FROM DICTIONARY
       */
      this.dictStorage = this.dictionaryService.getDictionaryStorage(DICT, this.dictKey);
      dict$ = this.dictStorage.dictionary$;
    } else if (this.apiDictUrl) {
      /**
       * MAKE STORAGE FROM API
       */
      dict$ = this.svcRestService
        .httpGetWithCache<
          IPagination<IDictionaryItem>
        >(this.apiDictUrl, { initParams: { relations: this.apiDictRelations }, filters: this.dictStorage.filters })
        .pipe(
          filter((x) => !!x),
          map(({ data }) => {
            this.dictStorage.dictionary$.next(data);
            return data;
          }),
        );
    } else {
      /**
       * MAKE STORAGE FROM STATIC OPTIONS
       */
      this.dictStorage.dictionary$ = this.staticOptions$;
      dict$ = this.dictStorage.dictionary$;
    }

    /**
     * TAKE OPTIONS FROM STORAGE and INIT VALUE
     */
    this.options$ = combineLatest([
      dict$,
      this.selectedId$,
      this.filters$.pipe(distinctUntilChanged((a, b) => isEqual(a, b))),
      this.needUpdateOptions$,
    ]).pipe(
      debounceTime(100),
      map(([dict, id, filters]) => {
        if (!dict) return [];
        const options = this.mapOptions(filteringDictByFilters(dict, filters, this.dictKey));
        this.initValue(options, id);
        this.isDictionaryItemLoaded = true;
        return options as IOption[];
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private getResetStringOption(dictionaryStorage: DictionaryStorage<any>, searchKey = 'name', sendKey = 'id'): IDictionaryItem {
    return {
      [searchKey]: dictionaryStorage.filterResetString ? dictionaryStorage.filterResetString : this.filterResetString || 'Все',
      [sendKey]: FILTER_RESET_STRING_VALUE,
    };
  }

  private isResetStringOption(optionValue: any): boolean {
    return optionValue === FILTER_RESET_STRING_VALUE;
  }

  public resetValue(): void {
    let value = null;
    if (this.getResetValue) value = this.getResetValue();

    this.control.setValue(value);
    this.updateFormValue(value);
    this.selectChangeValueUserEvent.emit(value);
    this.selectItemUserEvent.emit(value);
    this.optionCleared.emit();
  }

  public getPlaceholder(): string {
    const resetOptionName = this.resetStringOption ? this.resetStringOption.name || '' : this.resetStringOptionName || 'Выберите значение';
    return this.control.disabled && !this.control.value ? '' : resetOptionName;
  }

  public selectValue(event: IOptionTransform, byUser = true): void {
    this.propagateTouched(null);

    if (this.isResetStringOption(event.value)) {
      this.fcd.form.get(this.formControlName)?.reset('');
      this.optionCleared.emit();
      return;
    }

    if (!undefinedOrNullOrEmptyString(event.value)) {
      this.control.setValue(event.value);
      const value = transformStringToBoolean(event.value);
      const oldValue = this.formValue;
      this.updateFormValue(value);

      if (byUser) {
        if ('' + oldValue !== '' + value) this.selectChangeValueUserEvent.emit(value);
        this.selectItemUserEvent.emit(event.originalItem);
        this.itemSelected.emit(event.originalItem);
      }

      if (this.initControlName && this.initControlName !== this.formControlName) {
        this.fcd.form.controls[this.initControlName]?.setValue(event.originalItem);
      }
      this.updateSlaveControls(event.originalItem);
      this.onChange.emit();
    }
  }

  public clickOutside() {
    if (this.selectComponent.isOpen) this.selectComponent.close();
  }

  public templateFilter(arr: IDictionaryItem[]): IDictionaryItem[] {
    return templateFilter(arr, this.templateFilterFunction);
  }
}
