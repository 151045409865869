import { NgClass, NgIf } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, RouterLink],
})
export class NoContentComponent {
  @Input() searchValue: string;
  @Input() noDataText: string;
  @Input() fontSize: 'sm' | 'lg' | 'normal' | null = null;
  @Input() withOutBottomBorder: boolean;
  @Input() backUrl!: string;
}
