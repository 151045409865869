import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { isArray, isFunction } from 'lodash';
import { IValidateInput, VALIDATORS } from 'src/app/providers/_directives/validator/validators';

@Directive({
  selector: '[validateFunction]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AppValidatorDirective),
      multi: true,
    },
  ],
  standalone: true,
})
export class AppValidatorDirective implements Validator {
  @Input()
  public validateFunction: IValidateInput;

  @Input()
  public validateArguments: any[] = [];

  validate(c: AbstractControl) {
    if (this.validateFunction) {
      let fns: IValidateInput;

      if (isArray(this.validateFunction)) {
        fns = this.validateFunction;
      } else {
        fns = [this.validateFunction];
      }

      for (const fn of fns) {
        let errors: ValidationErrors | null = null;

        if (isFunction(fn)) {
          errors = fn(c, this.validateArguments);
        } else if (VALIDATORS[fn]) {
          errors = VALIDATORS[fn](c, this.validateArguments);
        }

        if (errors && errors.message) {
          return errors;
        }
      }
    }

    return null;
  }
}
