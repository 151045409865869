import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';

moment.locale('ru');
window['moment'] = moment;

@Pipe({
  name: 'momentFromNow',
  standalone: true,
})
export class MomentFromNowPipe implements PipeTransform {
  transform(date: string, addDay: number): Moment {
    return moment(date).add(+addDay, 'days');
  }
}
