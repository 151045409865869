export class PekUtils {
  static getAirMonitoringResultName(item: any, itemIndex: number, _itemLabel: string) {
    let name = `№ ${itemIndex}. ${item.observation_addr}`;

    if (item.pollutant) {
      name = `${name}. ${item.pollutant.code ? `${item.pollutant.code} - ` : ''}${item.pollutant.name || ''}`;
    }

    return name;
  }

  static getAirPollutantMonitoringName(item: any, itemIndex: number, _itemLabel: string) {
    let name = `№ ${itemIndex}`;

    if (item.pollutant) {
      name = `${name}. ${item.pollutant.code ? `${item.pollutant.code} - ` : ''}${item.pollutant.name || ''}`;
    }

    return name;
  }

  static getAirPollutantStationaryName(item: any, itemIndex: number, _itemLabel: string) {
    let name = `№ ${itemIndex}`;

    if (item.pollutant) {
      name = `${name}. ${item.pollutant.code ? `${item.pollutant.code} - ` : ''}${item.pollutant.name || ''}`;
    }

    return name;
  }

  static getLaboratoryInformationName(item: any, itemIndex: number, _itemLabel: string) {
    let name = `№ ${itemIndex}`;

    if (item) {
      name = `${item.laboratory_name}`;
    }

    return name;
  }

  static getStationarySourcesResultName(item: any, _itemIndex: number, _itemLabel: string) {
    let name = `Подразделение № ${item.division_number}, ${item.division_name}`;

    if (item.pollutant) {
      name = `${name}. ${item.pollutant.code ? `${item.pollutant.code} - ` : ''}${item.pollutant.name || ''}`;
    }

    return name;
  }

  static getUsedTechnologyName(item: any, _itemIndex: number, _itemLabel: string) {
    let name = 'Подразделение №';

    if (item) {
      name = `${name} ${item.division_number}, ${item.division_name}`;
    }

    return name;
  }

  static getWasteMonitoringResultName(item: any, _itemIndex: number, _itemLabel: string) {
    let name = 'Письмо №';

    if (item) {
      name = `${name} ${item.letter_number}, ${item.letter_date}`;
    }

    if (item.territory_org) {
      name = `${name}. ${item.territory_org.short_name}`;
    }

    return name;
  }

  static getWaterAccountingResultName(item: any, _itemIndex: number, _itemLabel: string) {
    let name = 'Письмо №';

    if (item) {
      name = `${name} ${item.letter_water_res_number}, ${item.letter_water_res_date}`;
    }

    return name;
  }

  static getWaterChecksResultName(item: any, _itemIndex: number, _itemLabel: string) {
    let name = 'Письмо №';

    if (item) {
      name = `${name} ${item.letter_quality_number}, ${item.letter_quality_date}`;
    }

    return name;
  }

  static getWaterMonitoringResultName(item: any, itemIndex: number, _itemLabel: string) {
    let name = `№ ${itemIndex}`;

    if (item.water_pollutant) {
      name = `${name}. ${item.water_pollutant.code ? `${item.water_pollutant.code} - ` : ''}${item.water_pollutant.name || ''}`;
    }

    return name;
  }
}
