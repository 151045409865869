import { Location } from '@angular/common';
import { Injectable, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { get, isArray, isEqual } from 'lodash';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { BehaviorSubject, concat, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take, finalize, catchError, debounceTime } from 'rxjs/operators';
import { DetailsEditModeService } from 'src/app/modules/details-edit-mode/details-edit-mode.service';
import { ModalShowTemplateService } from 'src/app/modules/modal-show-template/services/modal-show-template.service';
import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { CanComponentDeactivate } from 'src/app/providers/_interfaces/guard.intefaces';
import { TerritoryOrg } from 'src/app/providers/_interfaces/territory.org';
import { UserScopesRepo } from 'src/app/providers/_scopes';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { ExportTemplateService } from 'src/app/providers/_services/export.template.service';
import { LabelService } from 'src/app/providers/_services/label.service';
import { LoadedService } from 'src/app/providers/_services/load.service';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { ToastrService } from 'src/app/providers/_services/toastr.service';
import { TreeHelperService } from 'src/app/providers/_services/tree-helper.service';
import { getField } from 'src/app/providers/_utils/reactive-form.utils';
import { buildRouteUrlFromRoute } from 'src/app/providers/_utils/utils';
import { EssenceSelectors } from 'src/app/store';
import {
  DeleteAllSelectedObjectsRequest,
  EssenceActionTypes,
  ManualUpdateTree,
  RestoreAllSelectedObjectsRequest,
  SelectRoute,
  UpdateEssenceObjectRequest,
} from 'src/app/store/essence/actions';
import { CreateEssenceObjectRequest, DeleteEssenceObjectRequest } from 'src/app/store/essence/actions';
import { environment } from 'src/environments/environment';

export interface IBaseSectionForm {
  canActivate(): Observable<boolean>;
}

export interface IEssenceReactiveFormInitOptions {
  treeId: string;
  sectionName: string;
  fields: string[];
  route: any;
  operation: string;
  treePath: string[];
  routeParams: { [key: string]: string };
  relations: string;
  obj: any;
  defaultValue: { [key: string]: string };
  isEditable: boolean;
  extendCurrentObject: (currentObject: any) => any;
  defaultApplyStatus: string;
  cacheKey?: string;
  oldValuesScope?: boolean;
}

export interface IValueObject {
  value: any;
  disabled: boolean;
  isDirty?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BaseSectionFacade implements CanComponentDeactivate {
  currentStatusCode$ = new BehaviorSubject<string>('');
  isLoaded$ = new BehaviorSubject<boolean>(false);
  reportForm: UntypedFormGroup;
  guardConfirmSelect = true;

  public user = this.authenticationService.user$.getValue();
  public userTerOrg: TerritoryOrg = get(this.user, ['profile', 'organization', 'territory_org'], null);

  public get isLK(): boolean {
    return environment.SYSTEM_TYPE === EnvironmentType.lk;
  }
  public get isKSV(): boolean {
    return environment.SYSTEM_TYPE === EnvironmentType.ksv;
  }

  public currentSection: IBaseSectionForm | null;
  public currentRpnUrl: string;
  public reportDashboardIsAlreadyLoaded = false;
  public exportLoading$ = new BehaviorSubject(false);
  public exportSubscription: Subscription;
  public importSubscription: Subscription;

  constructor(
    public store: Store,
    public fb: UntypedFormBuilder,
    public authenticationService: AuthenticationService,
    public loadedService: LoadedService,
    public location: Location,
    public label: LabelService,
    public svc: SvcRestService,
    public modalShowTemplateService: ModalShowTemplateService,
    public modalService: MDBModalService,
    public toastr: ToastrService,
    public dictionaryService: DictionaryService,
    private actions$: Actions,
    private treeHelperService: TreeHelperService,
    private exportTemplateService: ExportTemplateService,
    public detailsEditModeService: DetailsEditModeService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.currentSection ? this.currentSection.canActivate() : of(true);
  }

  public createJsonForReactiveGroup(
    treeId: string,
    sectionName: string,
    fieldArr: string[],
    currentObject: { [key: string]: any },
    defaultValue: { [key: string]: any },
    currentEssence: { [key: string]: any },
    isEditable: boolean,
    isApplyDefaultValue: boolean,
  ): { [key: string]: IValueObject[] } {
    return fieldArr.reduce((prev: any, fieldName: string) => {
      switch (fieldName) {
        case 'approved_decisions': {
          const array = currentObject[fieldName] && currentObject[fieldName].length ? currentObject[fieldName] : [''];
          prev[fieldName] = [
            {
              value: this.fb.array(array.map((value: any) => ({ value, disabled: !isEditable }))),
              disabled: !isEditable,
            },
          ];
          break;
        }
        case 'advanced_members': {
          const array =
            currentObject[fieldName] && currentObject[fieldName].length ? currentObject[fieldName] : [{ name: '', comment: '' }];
          prev[fieldName] = [
            {
              value: this.fb.array(
                array.map((value: any) => ({
                  value: this.fb.group({ name: value.name, comment: value.comment }),
                  disabled: !isEditable,
                })),
              ),
              disabled: !isEditable,
            },
          ];
          break;
        }
        default: {
          const value = getField(treeId, currentObject, currentEssence, sectionName, fieldName, defaultValue, isApplyDefaultValue);
          if (value.hasOldValue) {
            prev['old_' + fieldName] = [{ ...{ value: value.oldValue || '' }, ...{ disabled: true } }];
          }
          delete value.hasOldValue;
          delete value.oldValue;
          prev[fieldName] = [{ ...value, ...{ disabled: !isEditable } }];
          break;
        }
      }
      return prev;
    }, {});
  }

  formDataApplyCopy(formGroup: UntypedFormGroup) {
    const copyValue = this.treeHelperService.formCopy;

    if (copyValue) {
      for (const key of Object.keys(formGroup.controls)) {
        const control = formGroup.controls[key];
        const isOldKey = key.indexOf('old_') === 0;
        const newValue = isOldKey ? null : copyValue[key];
        control.setValue(newValue);
        control.markAsDirty();
      }

      this.treeHelperService.formCopy = null;
    }
  }

  public essenceReactiveFormInit(options: IEssenceReactiveFormInitOptions): Observable<{ form: UntypedFormGroup; isLoaded: boolean }> {
    const {
      treeId,
      sectionName,
      fields,
      route,
      operation,
      treePath,
      routeParams,
      relations,
      obj,
      defaultValue,
      isEditable,
      extendCurrentObject,
      defaultApplyStatus,
      cacheKey,
      oldValuesScope,
    } = options;

    this.store.dispatch(new SelectRoute({ operation, route, treePath, routeParams, relations, sectionName, obj, cacheKey, oldValuesScope }));

    let formData = this.createJsonForReactiveGroup(treeId, sectionName, fields, {}, {}, {}, isEditable, false);
    const formGroup = this.fb.group(formData);

    return concat(
      of({ form: formGroup, isLoaded: false }),
      this.store.pipe(
        select(EssenceSelectors.selectCurrentRouteData),
        debounceTime(100),
        filter((v) => !!v?.currentObject),
        filter((v) => isEqual(v.treePath, treePath)),
        map(({ currentObject, currentEssence }) => {
          return {
            currentObject: extendCurrentObject(currentObject),
            currentEssence,
          };
        }),
        map(({ currentObject, currentEssence }) => {
          const statusCode = get(currentEssence, ['status', 'code']) || '';
          this.currentStatusCode$.next(statusCode);

          formData = this.createJsonForReactiveGroup(
            treeId,
            sectionName,
            fields,
            currentObject,
            defaultValue,
            currentEssence,
            isEditable,
            statusCode === defaultApplyStatus,
          );

          Object.keys(formData).forEach((x) => {
            let control = formGroup.get(x);
            if (!control) formGroup.addControl(x, new UntypedFormControl([]));
            control = formGroup.get(x);
            const obj = get(formData, [x, 0], { isDirty: false, value: null, disabled: false });
            const newValue = obj.value;

            if (((newValue !== null) || (!defaultValue[x] && (operation !== 'new'))) && (control.value !== newValue)) {
              control.setValue(newValue);
            }

            if (obj.isDirty) {
              control.markAsDirty();
            } else {
              control.markAsPristine();
            }
            delete obj.isDirty;

            if (obj.disabled) {
              control.disable();
            }
          });

          formGroup.clearValidators();

          return { form: formGroup, isLoaded: true };
        }),
      ),
    );
  }

  public updateEssenceSection(
    body: any,
    route: any,
    routeParams: { [key: string]: string },
    objectId: string,
    reload_flag: boolean,
    returnBack = true,
    cacheKey?: string,
    withOldBody?: boolean,
  ): void {
    if (objectId === 'new') {
      this.store.dispatch(new CreateEssenceObjectRequest({ body, route, routeParams, reload_flag }));
    } else {
      this.store.dispatch(
        new UpdateEssenceObjectRequest({ body, route, routeParams, objectId, reload_flag, returnBack, cacheKey, withOldBody }),
      );
    }
  }

  public restoreSelectObject(body: any, route: any, routeParams: { [key: string]: string }): void {
    this.store.dispatch(new RestoreAllSelectedObjectsRequest({ body, route, routeParams }));
  }

  public getRpnUrl(route) {
    const fullUrl = buildRouteUrlFromRoute(route.root);
    let rpnUrl = null;
    const details = 'details';
    const detailsIndex = fullUrl.indexOf(details);
    if (detailsIndex !== -1) rpnUrl = fullUrl.substr(0, detailsIndex + details.length);

    return rpnUrl;
  }

  public deleteEssenceObject(route: any, routeParams: { [key: string]: string }, cascadeDelete: boolean): void {
    this.store.dispatch(new DeleteEssenceObjectRequest({ route, routeParams, cascadeDelete }));
  }

  public deleteAllSelectedObjects(route: any, routeParams: { [key: string]: string }, ids: number[] = [], cascadeDelete: boolean): void {
    this.store.dispatch(new DeleteAllSelectedObjectsRequest({ route, routeParams, ids, cascadeDelete }));
  }

  public submitSuccessSubscribe(): Observable<any> {
    return this.actions$.pipe(
      ofType(
        EssenceActionTypes.EssenceUpdatedSuccess,
        EssenceActionTypes.EssenceObjectCreatedSuccess,
        EssenceActionTypes.EssenceObjectUpdatedSuccess,
        EssenceActionTypes.SelectRoute,
      ),
    );
  }

  public submitErrorSubscribe(): Observable<any> {
    return this.actions$.pipe(
      ofType(
        EssenceActionTypes.AllSelectedObjectsRestoredError,
        EssenceActionTypes.EssenceObjectCreatedError,
        EssenceActionTypes.EssenceObjectUpdatedError,
        EssenceActionTypes.EssenceObjectDeletedError,
        EssenceActionTypes.ManualUpdateTreeError,
      ),
    );
  }

  public exportTemplate(templateCode: string, cdr?: ChangeDetectorRef) {
    this.exportLoading$.next(true);
    this.exportSubscription?.unsubscribe();

    this.exportSubscription = this.store
      .pipe(
        select(EssenceSelectors.selectCurrentEssence),
        filter((x) => !!x),
        take(1),
      )
      .pipe(
        switchMap((report) => this.exportTemplateService.exportTemplateRequest(templateCode, { report_id: report.id })),
        take(1),
        finalize(() => this.exportLoading$.next(false)),
      )
      .subscribe(() => {
        this.exportLoading$.next(false);
        cdr?.detectChanges();
      });
  }

  importFromONVOS(
    importUrl: string,
    sourcesTag: string,
    userScopes: UserScopesRepo,
    reload$: BehaviorSubject<boolean>,
    disabledImport$: BehaviorSubject<boolean>,
  ): void {
    this.importSubscription?.unsubscribe();

    if (!userScopes || !userScopes.rpn_lk || !userScopes.rpn_lk.isActive) {
      const openModal = this.modalShowTemplateService.openModal(
        'Внимание, импорт не доступен!',
        'Недостаточно прав для просмотра непубличной части реестра. Войдите через ЕСИА (портал госуслуг).',
        null,
      );
      this.importSubscription = openModal.closeModal$.subscribe();
      return;
    }

    disabledImport$.next(true);

    this.importSubscription = this.svc
      .postByUrl(importUrl)
      .pipe(
        catchError(() => {
          disabledImport$.next(false);
          return of(null);
        }),
        switchMap((data) => {
          const importResult = (data && isArray(data) && data[0] && +data[0][sourcesTag]) || null;
          let importText = '';

          if (importResult !== null) {
            if (importResult >= 0) {
              importText = `Импортировано ${importResult} записей`;
            } else if (+importResult === -2) {
              importText = 'Необходимо сначала сохранить Объект НВОС';
            } else if (+importResult === -3) {
              importText = 'Объект НВОС не найден в реестре';
            } else {
              importText = 'Техническая ошибка';
            }
          } else {
            importText = 'У объекта НВОС не найдено записей';
          }

          return this.modalShowTemplateService.openModal(importResult >= 0 ? 'Импорт выполнен' : 'Импорт НЕ выполнен', importText, null)
            .closeModal$;
        }),
      )
      .subscribe(() => {
        reload$.next(null);
        this.store.dispatch(new ManualUpdateTree());
      });
  }
}
