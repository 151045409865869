import { IEssenseContent } from 'src/app/modules/treeview/treeview.interfaces';

export interface TModalBaseOptions {
  backdrop: boolean;
  keyboard: boolean;
  focus: boolean;
  show: boolean;
  ignoreBackdropClick: boolean;
  containerClass: string;
  class: string;
  animated: boolean;
}

export interface TModalOptions extends TModalBaseOptions {
  data: {
    heading: string;
    content: IEssenseContent;
  };
}

export const MODAL_BASE_OPTIONS: TModalBaseOptions = {
  backdrop: true,
  keyboard: true,
  focus: true,
  show: false,
  ignoreBackdropClick: false,
  containerClass: '',
  class: '',
  animated: true,
};

export const MODAL_OPTIONS: TModalOptions = {
  ...MODAL_BASE_OPTIONS,
  data: {
    heading: 'Modal heading',
    content: {
      heading: 'Content heading',
      description: 'Content description',
      statusId: null,
      transition: null,
      essenceId: null,
      type: null,
      result: null,
      closeModal: null,
    },
  },
};
