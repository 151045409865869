import { Pipe, PipeTransform } from '@angular/core';
import { CORRECTION_CASE } from 'src/app/providers/_const/correction-case.const';
import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { IReportTransition } from 'src/app/providers/_interfaces/report.interface';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { environment } from 'src/environments/environment';

function isLK(): boolean {
  return environment.SYSTEM_TYPE === EnvironmentType.lk;
}
function isKSV(): boolean {
  return environment.SYSTEM_TYPE === EnvironmentType.ksv;
}

function isTransitionHidden(transition: IReportTransition, essence: any, authenticationService: AuthenticationService): boolean {
  const correction_case = CORRECTION_CASE[essence.advanced_documents_comment];

  if (
    transition.properties.is_visible == false ||
    transition.properties.frontend_hide === 'true' ||
    (transition.properties.if_smev_hide === 'true' && !!essence.smev_order_id) ||
    (transition.properties.if_smev_show === 'true' && !essence.smev_order_id) ||
    (transition.properties.if_redirected_hide === 'true' && !essence.is_redirected) ||
    (transition.properties.if_redirected_show === 'true' && !!essence.is_redirected) ||
    (transition.properties.is_foiv_visible === 'true' && !authenticationService.isTO()) ||
    (transition.properties.is_territory_org_visible === 'true' &&
      !(authenticationService.isTO() || authenticationService.isROIV() || authenticationService.isSECURE())) ||
    (transition.properties.is_territory_org_hide === 'true' &&
      (authenticationService.isTO() || authenticationService.isROIV() || authenticationService.isSECURE())) ||
    (transition.properties.datasource_id && +transition.properties.datasource_id !== essence.datasource_id) ||
    (transition.properties.datasource_id_exclude && +transition.properties.datasource_id_exclude === essence.datasource_id) ||
    (transition.properties.correction_case && transition.properties.correction_case !== correction_case)
  ) {
    return true;
  }

  return false;
}

export function filterTransitions(transitions: IReportTransition[], essence: any, authenticationService): IReportTransition[] {
  return (transitions || []).filter((transition) => {
    const lk = transition.properties && transition.properties.lkpp_only;
    const ksv = transition.properties && transition.properties.ksv_only;

    if (lk === 'true') {
      return isLK();
    }

    if (ksv === 'true') {
      return isKSV();
    }

    return !isTransitionHidden(transition, essence, authenticationService);
  });
}

@Pipe({
  name: 'filterTransitions',
  standalone: true,
})
export class FilterTransitionsPipe implements PipeTransform {
  constructor(private authenticationService: AuthenticationService) {}

  transform(transitions: IReportTransition[], essence: any): IReportTransition[] {
    return filterTransitions(transitions, essence, this.authenticationService);
  }
}
