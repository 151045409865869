import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { get, isString } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { ISetControlOptions } from 'src/app/providers/_interfaces/report.interface';
import { decimalRound, undefinedOrNullOrEmptyString } from 'src/app/providers/_utils/utils';

export function getDirtyValues(form: UntypedFormGroup | UntypedFormArray): { [key: string]: any } {
  const dirtyValues = {};

  Object.keys(form.controls).forEach((key) => {
    const currentControl = form.controls[key];
    if (currentControl.controls) {
      const value = getDirtyValues(currentControl);
      if (value && Object.keys(value).length) {
        dirtyValues[key] = value;
      }
    } else if (currentControl.value && currentControl.value.controls) {
      const value = getDirtyValues(currentControl.value);
      if (value && Object.keys(value).length) {
        dirtyValues[key] = value;
      }
    } else {
      if (currentControl.dirty) {
        dirtyValues[key] = currentControl.value;
      }
    }
  });

  return dirtyValues;
}

export function clearingForm<T>(formValue: T) {
  for (const filterName of Object.keys(formValue || {})) {
    if (undefinedOrNullOrEmptyString(formValue[filterName])) {
      delete formValue[filterName];
    } else if (isString(formValue[filterName])) {
      formValue[filterName] = formValue[filterName].trim();
    }
  }

  return formValue;
}

export function serializerForValue(obj): any {
  const keys = Object.keys(obj);
  const res = {};
  keys.forEach((key) => {
    switch (key) {
      case 'latitude':
      case 'longitude': {
        try {
          res[key] = +obj[key];
        } catch (err) {
          console.error(err);
        }
        break;
      }
      case 'oktmo': {
        try {
          res[key] = obj[key] ? '' + obj[key] : null;
        } catch (err) {
          console.error(err);
        }
        break;
      }
      case 'is_personally':
      case 'is_legal_agent':
      case 'is_located_in_sea':
      case 'is_excluded':
      case 'is_leader': {
        try {
          res[key] = '' + obj[key] === 'true';
        } catch (err) {
          console.error(err);
        }
        break;
      }

      default: {
        res[key] = obj[key];
        break;
      }
    }

    try {
      const a = key.split('_');
      if (a[a.length - 1] === 'id') {
        res[key] = obj[key] === null || obj[key] === '' ? null : +obj[key];
      }
    } catch (err) {
      console.log(err);
    }
  });
  return res;
}

export function getField(
  treeId: string,
  currentObject: { [key: string]: any },
  currentEssence: { [key: string]: any },
  sectionName: string,
  fieldName: string,
  defaultValue: { [key: string]: any },
  isApplyDefaultValue: boolean,
): { isDirty?: boolean; value: string | number | boolean | any[]; hasOldValue?: boolean; oldValue?: string | number | boolean | any[] } {
  const currentObjectHasValue = currentObject && currentObject[fieldName] !== null && currentObject[fieldName] !== undefined;
  const oldFieldName = 'old_' + fieldName;
  const currentObjectHasOldValue =
    currentObject &&
    Object.keys(currentObject).length &&
    Object.keys(currentObject).indexOf(oldFieldName) >= 0 &&
    !!(currentEssence && currentEssence.onv_id);

  if (sectionName) {
    if (sectionName === 'estimate_preparation') {
      if (currentObjectHasValue) {
        if (['cost', 'expert_cost', 'lead_cost'].indexOf(fieldName) >= 0) {
          return { value: currentObject[fieldName] / 100 };
        }
        return { value: currentObject[fieldName] };
      } else if (isApplyDefaultValue) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const value = autofillEstimatePreparation(currentEssence, fieldName, defaultValue);
        if (value) {
          return { isDirty: true, value };
        }
        return { value };
      }
    }

    if (sectionName === 'harm_info') {
      if (currentObjectHasValue) {
        if (
          [
            'penalty_amount',
            'harm_amount',
            'agreed_harm_amount',
            'presented_harm_amount',
            'unforced_amount',
            'trial_harm_amount',
            'unforced_trial_fact_amount',
            'executive_amount',
          ].indexOf(fieldName) >= 0
        ) {
          return { isDirty: false, value: currentObject[fieldName] / 100 };
        }
        return { value: currentObject[fieldName] };
      } else {
        if (['is_located_in_sea'].indexOf(fieldName) >= 0) {
          if (currentObjectHasValue) {
            return { isDirty: false, value: currentObject[fieldName] };
          } else {
            return { isDirty: true, value: false };
          }
        }

        return { value: null };
      }
    }

    if (sectionName === 'print_result') {
      return { isDirty: false, value: get(currentObject, [sectionName, fieldName]) };
    }
  }

  if (currentObjectHasValue) {
    return {
      isDirty: false,
      value: currentObject[fieldName],
      hasOldValue: !!currentObjectHasOldValue,
      oldValue: currentObject[oldFieldName],
    };
  } else if (
    defaultValue &&
    defaultValue[fieldName] !== null &&
    defaultValue[fieldName] !== undefined &&
    defaultValue[fieldName] !== currentObject[fieldName] &&
    isApplyDefaultValue
  ) {
    if (currentObjectHasOldValue) {
      return {
        isDirty: true,
        value: defaultValue[fieldName],
        hasOldValue: true,
        oldValue: currentObject[oldFieldName],
      };
    }
    return { isDirty: true, value: defaultValue[fieldName], hasOldValue: false };
  } else {
    return { value: null, hasOldValue: currentObjectHasOldValue };
  }
}

export function autofillEstimatePreparation(currentEssence, fieldName, defaultValue): any {
  switch (fieldName) {
    case 'expert_cost': {
      return defaultValue ? defaultValue.expert_cost / 100 : 0;
    }
    case 'lead_cost': {
      return defaultValue ? defaultValue.lead_cost / 100 : 0;
    }
    case 'expert_count': {
      return currentEssence && currentEssence.criterion && currentEssence.criterion.criteria ? currentEssence.criterion.criteria.length : 0;
    }
  }
}

export function markDirty(formGroup: UntypedFormGroup): void {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  markGroupDirty(formGroup);
}

export function markGroupDirty(formGroup: UntypedFormGroup): void {
  Object.keys(formGroup.controls).forEach((key) => {
    if (formGroup.get(key) instanceof UntypedFormGroup) {
      markGroupDirty(<UntypedFormGroup>formGroup.get(key));
    } else if (formGroup.get(key) instanceof UntypedFormArray) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      markArrayDirty(<UntypedFormArray>formGroup.get(key));
    } else if (formGroup.get(key) instanceof UntypedFormControl) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      markControlDirty(<UntypedFormControl>formGroup.get(key));
    }
  });
}

export function markArrayDirty(formArray: UntypedFormArray): void {
  formArray.controls.forEach((control) => {
    if (control instanceof UntypedFormGroup) {
      markGroupDirty(<UntypedFormGroup>control);
    } else if (control instanceof UntypedFormArray) {
      markArrayDirty(<UntypedFormArray>control);
    } else if (control instanceof UntypedFormControl) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      markControlDirty(<UntypedFormControl>control);
    }
  });
}

export function markControlDirty(formControl: UntypedFormControl): void {
  const control = formControl.value;
  if (control && control.constructor) {
    if (control instanceof UntypedFormGroup) {
      markGroupDirty(<UntypedFormGroup>control);
    } else if (control instanceof UntypedFormArray) {
      markArrayDirty(<UntypedFormArray>control);
    } else if (control instanceof UntypedFormControl) {
      markControlDirty(<UntypedFormControl>control);
    }
  }
  formControl.markAsDirty();
}

export function getFieldsValues$(
  formGroup$: Observable<UntypedFormGroup>,
  fieldKeys: string[],
  mapValue: (val: string) => string | number = (x) => +x || 0,
): Observable<number[]> {
  return formGroup$.pipe(
    filter((reportForm) => !fieldKeys.find((key) => !reportForm.controls[key])),
    switchMap((reportForm) =>
      combineLatest([
        of(reportForm as any),
        ...fieldKeys.map((key) => {
          return reportForm.controls[key].valueChanges.pipe(
            startWith(reportForm.controls[key].value),
            distinctUntilChanged(),
            map(mapValue),
          );
        }),
      ]),
    ),
    debounceTime(100),
    filter(([reportForm]: [UntypedFormGroup, ...number[]]) => !!fieldKeys.find((key) => reportForm.controls[key].dirty)),
    map(([, ...values]: [UntypedFormGroup, ...number[]]) => values),
  );
}

export function getALCFormGroupFunctions(fgroup: UntypedFormGroup): {
  setValue: (name: string, value: number | string, decimalPlaces?: number) => void;
  asNumber: (name: string) => number;
  compareAndFix: (key: number | string, maxValue: number, minValue?: number, decimalPlaces?: number) => number;
} {
  const getValue = (name) => {
    return fgroup.controls[name].value;
  };

  const setRawValue = (name, num: number) => {
    fgroup.controls[name].setValue('' + num, { emitEvent: false });
    fgroup.controls[name].markAsDirty();
  };

  const setValue = (name: string, value: number | string, decimalPlaces?: number) => {
    const num = decimalRound(value, decimalPlaces);
    setRawValue(name, num);
  };

  const asNumber = (name: string) => {
    const n = +getValue(name);

    if (isNaN(n)) {
      return 0;
    }

    return n;
  };

  const compareAndFix = (key: string, maxValue: number, minValue: number = 0, decimalPlaces?: number) => {
    let val = asNumber(key);

    if (val > maxValue) {
      val = maxValue;
      setValue(key, val, decimalPlaces);
    } else if (val < minValue) {
      val = minValue;
      setValue(key, val, decimalPlaces);
    }

    return val;
  };

  return {
    setValue,
    asNumber,
    compareAndFix,
  };
}

export function clearField(fg: UntypedFormGroup, key, isClearValidators = true) {
  if (!fg || !fg.controls[key]) {
    return;
  }

  fg.controls[key].setValue(null);

  if (isClearValidators) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    clearValidators(fg, key);
  }
}

export function clearValidators(fg: UntypedFormGroup, key) {
  if (!fg || !fg.controls[key]) {
    return;
  }

  fg.controls[key].clearValidators();
  fg.controls[key].clearAsyncValidators();
  fg.controls[key].updateValueAndValidity();
}

export function setControlValue(form: UntypedFormGroup, name: string, value: any, options: ISetControlOptions = { emitEvent: false }) {
  if (form && form.controls && form.controls[name]) {
    form.controls[name].setValue(value, options);
    form.controls[name].markAsDirty();
    form.controls[name].updateValueAndValidity();
  }
}

export function resetControls(form: UntypedFormGroup, selectedControlNames: string[] = [], callback?: () => void) {
  selectedControlNames.forEach((controlName) => setControlValue(form, controlName, null));

  if (callback) callback();

  form.markAllAsTouched();
  form.updateValueAndValidity();
}
