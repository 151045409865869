import { NgIf, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { get } from 'lodash';
import { ButtonsModule } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { AppIconComponent } from 'src/app/modules/app-icon/app-icon.component';
import { IconsSet } from 'src/app/modules/app-icon/icons';
import { IServerError } from 'src/app/providers/_interfaces/common';

@Component({
  selector: 'app-modal-show-error',
  templateUrl: './modal-show-error.component.html',
  styleUrls: ['./modal-show-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AppIconComponent, NgIf, NgFor, ButtonsModule],
})
export class ModalShowErrorComponent {
  public errors: any[];

  content: {
    error: IServerError;
    closeModal$: Subject<void>;
  };

  get IconsSet() {
    return IconsSet;
  }

  public close(): void {
    this.content.closeModal$.next();
  }

  public get isStandardDialog() {
    this.errors = get(this.content, 'error.error.context.errors');
    const error: any = get(this.content, 'error.payload.error.error');
    if (error?.message) this.errors = [error];
    return !this.errors;
  }
}
