import { saveAs } from 'file-saver';

export function saveReportFile(blobPart: BlobPart, name: string, extension = 'xlsx'): void {
  if (blobPart) {
    const dt = new Date();
    saveAs(
      new Blob([blobPart], { type: 'application/octet-stream' }),
      `${name}-${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}.${extension}`,
    );
  }
}
