import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';

export enum EPayerType {
  private = 'private',
  legal = 'legal',
  individual = 'individual',
  nonresident_legal = 'nonresident_legal',
  absent = 'absent',
}

export interface IPaymentsManagementPayerTypeDictionaryItem {
  id: EPayerType;
  name: string;
}

export enum EGisGmpPayerType {
  private = 1,
  legal_resident = 2,
  legal_non_resident = 3,
  individual = 4,
}

export interface IPaymentsManagementGisGmpPayerTypeDictionaryItem extends IDictionaryItem {
  id: EGisGmpPayerType;
  name: string;
}

export enum EPayerDocumentId {
  pasport_rf = 1,
  snils = 2,
}

export enum EPayerDocumentCode {
  pasport_rf = '01',
  snils = '14',
}

export interface IPaymentsManagementPayerDocumentCodeDictionaryItem {
  id: EPayerDocumentCode;
  name: string;
}
