import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EssenceEffects } from 'src/app/store/essence/effects';
import * as EssenceReducer from 'src/app/store/essence/reducer';
import { featureName } from 'src/app/store/essence/selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(featureName, EssenceReducer.essenceReducer),
    EffectsModule.forFeature([EssenceEffects])
  ],
})
export class EssenceStoreModule {}
