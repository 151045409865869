import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthFullGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.checkUser().pipe(
      map((value) => {
        const isPublicRoute = this.authenticationService.publicRoutesByEnv.find((r) => r.environment === environment.SYSTEM_TYPE);

        if (!value && isPublicRoute) {
          this.authenticationService.user$.next({
            id: null,
            email: null,
            email_verified_at: null,
            full_name: '',
            surname: '',
            name: '',
            patronymic: '',
            esia_authorized: null,
            inn: null,
            created_at: null,
            updated_at: null,
            messages_received_unread_count: null,
            unread_notifications_count: null,
            profile: null,
            roles: [isPublicRoute.role],
            is_public: true,
          });

          return true;
        }

        if (!this.authenticationService.user$.value || (this.authenticationService.user$.value.id === null)) {
          let i = 0;

          while (i < this.authenticationService.publicRoutesByUrl.length) {
            const publicRoute = this.authenticationService.publicRoutesByUrl[i];

            if (publicRoute.fn(next)) {
              this.authenticationService.user$.next({
                id: null,
                email: null,
                email_verified_at: null,
                full_name: '',
                surname: '',
                name: '',
                patronymic: '',
                esia_authorized: null,
                inn: null,
                created_at: null,
                updated_at: null,
                messages_received_unread_count: null,
                unread_notifications_count: null,
                profile: null,
                roles: [publicRoute.role],
                is_public: true,
              });

              return true;
            }

            i++;
          }
        }

        return value;
      }),
      tap((isFullAuth) => {
        if (!isFullAuth) {
          if (environment.SYSTEM_TYPE === EnvironmentType.registries) {
            if (this.router.url === '/') window.location.pathname = '/rpn/public-reestrs';
            return;
          }

          this.router.navigate(['/login']);
        }
      }),
    );
  }
}
