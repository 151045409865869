import { HttpBackend, HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, delay, expand, filter, finalize, map, mapTo, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  IAccumulationFacility,
} from 'src/app/modules/details-edit-mode/onv-requests/sections/negative-impact-info/accumulation-facilities/accumulation-facility.interface';
import { ISupportManualGroup } from 'src/app/pages/documents/documents-registry/documents-registry.interface';
import {
  IEnvironmentalImpact,
} from 'src/app/pages/onvos/onvos-view/sections/environmental-impact/environmental-impact-table.component';
import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { OpenTypeMap } from 'src/app/providers/_const/fgen-object.const';
import { SourceType } from 'src/app/providers/_const/onvos.const';
import { OnvosRequestUrlMap, ReportParams, Sort, TableType } from 'src/app/providers/_enum';
import { ICheckListDataInstance, IErpInspection, IKndRegistry } from 'src/app/providers/_interfaces/check-list.interface';
import { DatasourceTemplate} from 'src/app/providers/_interfaces/datasource.interface';
import { IDictionaryItem} from 'src/app/providers/_interfaces/dictionary';
import { IDischargeSourceFact} from 'src/app/providers/_interfaces/discharge-source-fact.interface';
import { IDischargeSource} from 'src/app/providers/_interfaces/discharge-source.interface';
import {
  EJobStatus,
  EJobStatusTranslate,
  IJobResponse,
  IJobStatus,
} from 'src/app/providers/_interfaces/download.file.interface';
import { EDownloadBlobFileType, EFileType } from 'src/app/providers/_interfaces/download.interface';
import { IEmissionObject } from 'src/app/providers/_interfaces/emission-object.interface';
import { IEvent } from 'src/app/providers/_interfaces/event.interface';
import { IEnvHarmStatistics } from 'src/app/providers/_interfaces/evnharm.interface';
import { IMapView, MapItemPoint } from 'src/app/providers/_interfaces/geo.interface';
import { IGroroRegistry, IGroroRequest } from 'src/app/providers/_interfaces/groro/groro.interface';
import { MessagesSettingsData } from 'src/app/providers/_interfaces/message-settings.interface';
import { MessageTemplate } from 'src/app/providers/_interfaces/message-template.interface';
import { IMessageSrc, Message} from 'src/app/providers/_interfaces/message.interface';
import {
  IMonitoringCertificate,
  IMonitoringDeviceConfigRequest,
  IMonitoringPermission,
  IMonitoringSource,
  IMonitoringSourceConfigRequest,
} from 'src/app/providers/_interfaces/monitoring.interfaces';
import { INatureUser } from 'src/app/providers/_interfaces/nature-user.interface';
import { INegativeImpactNeutralizeTool } from 'src/app/providers/_interfaces/negative-impact-neutralize-tool.interface';
import { INegativeImpactTool } from 'src/app/providers/_interfaces/negative-impact-tool.interface';
import { INeutralizeFacility } from 'src/app/providers/_interfaces/neutralize-facility.interface';
import { INeutralizeFact } from 'src/app/providers/_interfaces/neutralize-fact.interface';
import { INotification } from 'src/app/providers/_interfaces/notification.interface';
import { OkopfOrg } from 'src/app/providers/_interfaces/okopfs.interface';
import { IOnvosChange } from 'src/app/providers/_interfaces/onv.change.interface';
import {
  IOnvos,
  IOnvosN,
  IOnvosRegistry,
  IOnvosRequest,
  IOnvosRiskByDirection,
} from 'src/app/providers/_interfaces/onvos/onvos.interface';
import {
  IProfileDataInstance,
  IProfileDataInstanceControlCategory,
} from 'src/app/providers/_interfaces/onvos.profile.interface';
import { IPagination } from 'src/app/providers/_interfaces/pagination.interface';
import { IPaymentNvos } from 'src/app/providers/_interfaces/payment-nvos.interface';
import {
  IPermittingDocument,
  IPermittingDocumentSeparate,
} from 'src/app/providers/_interfaces/permitting-document.interface';
import { IPrintResponse } from 'src/app/providers/_interfaces/print.response.interface';
import { IProcessingFacility}  from 'src/app/providers/_interfaces/processing-facility.interface';
import { IProcessingFact } from 'src/app/providers/_interfaces/processing-fact.interface';
import { IProduct } from 'src/app/providers/_interfaces/product.interface';
import { IReportTransitionBody } from 'src/app/providers/_interfaces/report.interface';
import {
  EPnvosModelToQueryType,
  ERequestExtended,
  FilesForOldSystem,
  IRequest,
} from 'src/app/providers/_interfaces/request.interface';
import { Smev4Request, SmevRequest, SmevResponse } from 'src/app/providers/_interfaces/smev.response.interface';
import { IStationarySource } from 'src/app/providers/_interfaces/stationar-source.interface';
import { IStationarySourceFact } from 'src/app/providers/_interfaces/stationary-source-fact.interface';
import { IUtilizationFacility } from 'src/app/providers/_interfaces/utilization-facility.interface';
import { IUtilizationFact } from 'src/app/providers/_interfaces/utilization-fact.interface';
import { IWasteFact } from 'src/app/providers/_interfaces/waste-fact.interface';
import { IWastePoint } from 'src/app/providers/_interfaces/waste-point.interface';
import { ToastrService } from 'src/app/providers/_services/toastr.service';
import { clearingForm, serializerForValue } from 'src/app/providers/_utils/reactive-form.utils';
import { createImageFromBlob, deepClone, endOfDay, buildCacheKey } from 'src/app/providers/_utils/utils';
import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';

interface IRouteParams {
  [key: string]: any;
}

export interface ICalcUrlVariables {
  territoryOrg?: string;
  id?: number;
  is_async?: boolean;
  method?: 'POST' | 'PATCH' | 'PUT' | 'GET' | undefined;
}

export interface IHttpOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  observe?: any;
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
  body?: any;
}

export interface RpnHttpParams {
  pagination?: { page?: number; per_page?: number; limit?: number | null };
  initParams?: { relations?: string; [key: string]: any };
  filters?: { [key: string]: any };
  orderBy?: { [key: string]: Sort };
  download?: string;
  params?: { [key: string]: any };
  responseType?: string;
  type?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SvcRestService implements OnDestroy {
  private instanceCache = new Map<string, { requestBehaviorSubject: BehaviorSubject<any>; expired_at: number }>();
  private requestCacheMap = new Map<string, Observable<any>>();
  private cacheTtl = 15; // min
  private subscriptions: Subscription[] = [];
  get isLK(): boolean {
    return environment.SYSTEM_TYPE === EnvironmentType.lk;
  }

  urlByflag = {
    is_async: (essenceId) => `/api/svc/license-activity-waste/license_request/${essenceId}/set_status_job`,
  };

  private wInterceptorsHttpClient: HttpClient;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    backend: HttpBackend,
  ) {
    this.wInterceptorsHttpClient = new HttpClient(backend);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
  }

  instruction(id) {
    return this.http.get(`/api/svc/nsi/instruction/${id}`);
  }

  manual(id) {
    return this.http.get(`/api/svc/support/manuals/${id}`);
  }

  emchdList(params: RpnHttpParams = {}) {
    return this.http.get<any>('/api/emchd/v1/poa/search-by-employee', { params: this.setHttpParams(params) });
  }

  emchdFileData(emchd_number) {
    return this.http.get<any>(`/api/emchd/v1/poa/info/${emchd_number}`)
  }

  emchdCheck(emchd_number) {
    return this.http.get(`/api/emchd/v1/poa/check-revocation-full/${emchd_number}`);
  }

  listOnvosPublicProductInfo(onv_id, params: RpnHttpParams = {}) {
    return this.fetchByUrl<IPagination<any>>(`/api/svc/onv/open/onvs/${onv_id}/card/products`, params);
  }

  listOnvosPublicStationary(onv_id, params: RpnHttpParams = {}) {
    return this.fetchByUrl<IPagination<any>>(`/api/svc/onv/open/onvs/${onv_id}/card/stationary`, params);
  }

  listOnvosPublicDischarges(onv_id, params: RpnHttpParams = {}) {
    return this.fetchByUrl<IPagination<any>>(`/api/svc/onv/open/onvs/${onv_id}/card/discharges`, params);
  }

  listOnvosPublicDisposals(onv_id, params: RpnHttpParams = {}) {
    return this.fetchByUrl<IPagination<any>>(`/api/svc/onv/open/onvs/${onv_id}/card/disposals`, params);
  }

  listOnvosPublicReduceMeasures(onv_id, params: RpnHttpParams = {}) {
    return this.fetchByUrl<IPagination<any>>(`/api/svc/onv/open/onvs/${onv_id}/card/reduce_measures`, params);
  }

  searchMyONVOS(search: string): Observable<IOnvos[]> {
    return this.fetchByUrl<IPagination<IOnvos>>('/api/svc/onv/my', {
      filters: { is_excluded: false, 'filter_contains[number]': search },
    }).pipe(
      map(({ data }) =>
        (data || []).map((item) => {
          item.isOwned = true;
          return item;
        }),
      ),
    );
  }

  searchOpenONVOS(search: string): Observable<IOnvos[]> {
    return this.fetchByUrl<IPagination<IOnvos>>('/api/svc/onv/open/onvs', { initParams: { search }, filters: { is_excluded: false } }).pipe(
      map(({ data }) =>
        (data || []).map((item) => {
          item.isOwned = false;
          return item;
        }),
      ),
    );
  }

  private setInitParams(parameters: { [key: string]: any }): HttpParams {
    let params = new HttpParams();
    ['initParams', 'pagination'].forEach((item) => {
      if (parameters && parameters[item] && Object.keys(parameters[item]).length) {
        const a = parameters[item];
        if (a && Object.keys(a).length) {
          for (const filterName in a) {
            if (Object.prototype.hasOwnProperty.call(a, filterName) && a[filterName]) {
              params = params.append(filterName, a[filterName]);
            }
          }
        }
      }
    });
    return params;
  }

  setHttpParams(parameters: RpnHttpParams, api?: string): HttpParams {
    parameters = deepClone(parameters);

    let params = this.setInitParams(parameters);
    if (parameters && parameters.orderBy) {
      for (const key of Object.keys(parameters.orderBy)) {
        const val = parameters.orderBy[key];

        if (val) {
          params = params.append(`order_by[${key}]`, parameters.orderBy[key]);
        }
      }
    }

    if (parameters && parameters.download) {
      params = params.append('download', parameters.download);
    }

    if (parameters && parameters.params) {
      const paramsKeys = Object.keys(parameters.params);

      if (paramsKeys.length) {
        for (const key of paramsKeys) {
          params = params.append(key, '' + parameters.params[key]);
        }
      }
    }

    if (parameters && parameters.filters && Object.keys(parameters.filters).length) {
      const filters = clearingForm(parameters.filters);

      for (const filterName of [
        'actual_date',
        'date',
        'sent_at',
        'scope[byReviewData]',
        'registered_at',
        'inclusion_date',
        'updated_at',
        'done_at',
        'done_at_certificated',
        'exclusion_date',
        'created_at',
        'document_date',
        'payment_order_date',
        'has[attestation_information][filter_date_between][order_date]',
        'has[attestation_information][filter_date_between][attestation_certificate_issue_date]',
        'has[all_certificate][filter_date_between][certificate_issue_date]',
        'has[all_certificate][filter_date_between][issue_date]',
        'has[additional_data][filter_date_between][order_date]',
        'attestation_certificate_issue_date',
        'last_certificate_issue_date',
        'order_date',
        'permit_date',
        'valid_until',
        'correction_date',
        'published_at',
        'validity_date',
        'has[attestation_information][filter_date_between][validity_date]',
        'review_date',
        'exec_date',
        'declined_at',
        'accepted_at',
        'has[common][filter_date_between][knm_start_date]',
        'has[common][filter_date_between][knm_end_date]',
        'has[termination_of_service_info][filter_date_between][terminated_at]',
        'has[commission][filter_date_between][holding_date]',
        'has[licenses][has][violations][filter_date_between][eliminate_to_date]',
        'has[common][filter_date_between][detection_date]',
        'has[common][filter_date_between][sent_at]',
        'has[common][filter_date_between][send_date]',
        'has[appeal][filter_date_between][date_plan_complete]',
        'has[appeal][filter_date_between][date_processing]',
        'has[appeal][filter_date_between][date_complete]',
        'has[description][filter_date_between][date]',
        'has[info_postponement][filter_date_between][new_date]',
      ]) {
        let from = '';
        let to = '';

        if (filters[filterName]) {
          from = new Date(filters[filterName]).toISOString().split('T')[0];
          delete filters[filterName];
        }

        if (filters[filterName + '_to']) {
          to = endOfDay(new Date(filters[filterName + '_to']))
            .toISOString()
            .split('T')[0];
          delete filters[filterName + '_to'];
        }

        if (from || to) {
          from = from || new Date(1970).toISOString().split('T')[0];
          to = to || endOfDay(new Date()).toISOString().split('T')[0];

          params = params.append(
            (filterName.includes('filter_date_between') || filterName.includes('scope')) ? filterName : `filter_date_between[${filterName}]`,
            `${from}...${from === to ? to + 'T23:59:59' : to}`,
          );
        }
      }

      for (const filterName in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, filterName)) {
          if (filterName && filterName.indexOf('not_send_filter[') === -1) {
            if (filterName.indexOf('range_filter[min]') === 0) {
              const name = filterName.split('range_filter[min]')[1];
              const value = [filters[`range_filter[min]${name}`], filters[`range_filter[max]${name}`]].filter((v) => v);

              params = params.append(`filter[${name}]`, value.join(','));
            } else if (filterName.indexOf('filter_between') >= 0) {
              let from = '';
              let to = '';

              if (filters[filterName]) {
                from = filters[filterName];
                delete filters[filterName];
              }

              if (filters[filterName + '_to']) {
                to = filters[filterName + '_to'];
                delete filters[filterName + '_to'];
              }

              params = params.append(filterName, `${from}...${to}`);
            } else if (
              filterName.indexOf('filter_contains[') === 0 ||
              filterName.indexOf('filter_except[') === 0 ||
              filterName.indexOf('filter[') === 0 ||
              filterName.indexOf('filters[') === 0 ||
              filterName.indexOf('filter_json[') === 0 ||
              filterName.indexOf('scope[') === 0 ||
              filterName.indexOf('filter_date_between[') === 0 ||
              filterName.indexOf('has[') === 0 ||
              filterName.indexOf('filter_with_many_fields[') === 0 ||
              filterName.indexOf('filter_json_array[') === 0
            ) {
              params = params.append(filterName, '' + filters[filterName]);
            } else {
              switch (filterName) {
                case 'is_active':
                  params = params.append(`filter[${filterName}]`, '' + filters[filterName]);
                  break;
                case 'search':
                  if (filters[filterName] && filters[filterName].key) {
                    params = params.append(filters[filterName].key, '' + filters[filterName].value);
                  } else {
                    params = params.append(`${filterName}`, '' + filters[filterName]);
                  }
                  break;
                case 'issue_date':
                  params = params.append(
                    'filter_between[issue_date]',
                    `${filters.issue_date + (filters.sent_at_to ? '...' + filters.sent_at_to : '')}`,
                  );
                  break;
                case 'issue_date_to':
                  break;
                case 'id':
                  if (api === 'prototype') {
                    params = params.append(`filter[_${filterName}]`, '' + filters[filterName]);
                  } else {
                    params = params.append(`filter[${filterName}]`, '' + filters[filterName]);
                  }
                  break;
                case 'it_system':
                  params = params.append(filterName, '' + filters[filterName]);
                  break;
                default: {
                  params = params.append(`filter[${filterName}]`, '' + filters[filterName]);
                }
              }
            }
          }
        }
      }
    }
    return params;
  }

  checkCacheExpiryDate(): void {
    this.instanceCache.forEach((value, key) => {
      if (value.expired_at < +new Date()) {
        this.instanceCache.delete(key);
      }
    });
  }

  manualGroups(params: RpnHttpParams = {}) {
    return this.httpGetWithCache<ISupportManualGroup[]>('/api/svc/support/manual-groups', params).pipe(
      catchError(() => of([]))
    );
  }

  manualGroupsBySlug<T>(slug: string, params: RpnHttpParams = {}) {
    let url = '/api/svc/support/manual-groups';

    if (slug) {
      url += `/${slug}`;
    }

    return this.httpGetWithCache<T[]>(url, params).pipe(
      catchError(() => of([]))
    );
  }

  smev3Outgoings(service_request_id) {
    return this.httpGetWithCache<IPagination<any>>(`/api/v2/requests/${service_request_id}/smev3/outgoings`);
  }

  updateGoskey(service_request_id, body) {
    return this.http.post(`/api/v2/requests/${service_request_id}/smev3/outgoings/goskey/files`, body);
  }

  sendToSign(service_request_id, template) {
    return this.http.post(`/api/v2/requests/${service_request_id}/smev3/outgoings/goskey/sign`, { template });
  }

  listManuals<T>(params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<T>>('/api/svc/support/manuals', params)
      .pipe(map((data) => (data?.meta ? { ...data.meta, data: data.data } : data)));
  }

  listManualsTags(params: RpnHttpParams = {}) {
    return this.httpGetWithCache<string[]>('/api/svc/support/manuals/tags', params);
  }

  getManual(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<any>(`/api/svc/support/manuals/${id}`, { params: this.setHttpParams(params) });
  }

  removeManual(id): Observable<any> {
    return this.http.delete(`/api/svc/support/manuals/${id}`);
  }

  createManual(body: any): Observable<any> {
    return this.http.post('/api/svc/support/manuals', body);
  }

  updateManual(id: number, body: any): Observable<any> {
    return this.http.patch(`/api/svc/support/manuals/${id}`, body);
  }

  getCache<T = any>(key: string): BehaviorSubject<T> {
    this.checkCacheExpiryDate();
    let requestBehaviorSubject = this.instanceCache.get(key)?.requestBehaviorSubject;

    if (!requestBehaviorSubject) {
      requestBehaviorSubject = new BehaviorSubject(null);
      this.instanceCache.set(key, { requestBehaviorSubject, expired_at: +new Date() + this.cacheTtl * 60 * 1000 });
    }

    return requestBehaviorSubject;
  }

  setCache<T>(key: string, request$: Observable<T>): void {
    this.requestCacheMap.set(key, request$);

    let requestBehaviorSubject = this.instanceCache.get(key)?.requestBehaviorSubject;

    if (!requestBehaviorSubject) {
      requestBehaviorSubject = new BehaviorSubject(null);
      this.instanceCache.set(key, { requestBehaviorSubject, expired_at: +new Date() + this.cacheTtl * 60 * 1000 });
    }

    this.subscriptions.push(
      request$.pipe(finalize(() => this.requestCacheMap.delete(key))).subscribe((res) => requestBehaviorSubject?.next(res)),
    );
  }

  clearCache(): void {
    this.instanceCache.clear();
  }

  httpGetWithCache<T = any>(itemUrl: string, params: RpnHttpParams = null, options = {}, throwError?: boolean): Observable<T> {
    return of(null).pipe(
      switchMap(() => {
        if (!itemUrl) return of(undefined);

        const key = buildCacheKey(itemUrl, params, options);
        return this.getCache<T>(key).pipe(
          switchMap((res: T) => {
            if (res) return of(res);

            let request$ = this.requestCacheMap.get(key);

            if (!request$) {
              request$ = this.http.get<T>(itemUrl, { ...(params ? { params: this.setHttpParams(params) } : {}), ...options }).pipe(
                catchError(e => {
                  if (throwError) {
                    throw e;
                  } else {
                    return of(undefined);
                  }
                }),
                shareReplay({ bufferSize: 1, refCount: true }),
              );

              this.setCache<T>(key, request$);
            }

            return request$;
          }),
        );
      }),
    );
  }

  httpPostWithCache<T = any>(itemUrl: string, body = {}, options = {}): Observable<T> {
    if (!itemUrl) return of(undefined);
    const key = itemUrl + (body ? JSON.stringify(body) : '');

    return this.getCache<T>(key).pipe(
      switchMap((res: T) => {
        if (res) return of(res);

        let request$ = this.requestCacheMap.get(key);

        if (!request$) {
          request$ = this.http.post<T>(itemUrl, body, options).pipe(
            catchError(() => of(undefined)),
            shareReplay({ bufferSize: 1, refCount: true }),
          );

          this.setCache<T>(key, request$);
        }

        return request$;
      }),
    );
  }

  objectInfo(onv_request_id) {
    return this.http.get<any>(`/api/svc/onv/v2/onv_request_inclusion_v2/${onv_request_id}/statement/emission_object/object_info`);
  }

  createOnvosInclusionForAdjust(onv_request_parent_id) {
    return this.http.post(`/api/svc/onv/adjust_v2/${onv_request_parent_id}/create_inclusion`, {});
  }

  createOnvosExclusionForAdjust(onv_request_parent_id, body) {
    return this.http.post(`/api/svc/onv/adjust_v2/${onv_request_parent_id}/create_exclusion`, body);
  }

  listOnvosRequestsByParentId(params: RpnHttpParams) {
    return this.http.get<IPagination<any>>('/api/svc/onv/onv_request', { params: this.setHttpParams(params) });
  }

  // DICTIONARY
  fetchDictionary(
    dictApi: string | undefined,
    params: RpnHttpParams = {},
  ): Observable<{ data: IDictionaryItem[]; current_page: number; last_page: number }> {
    return dictApi
      ? this.httpGetWithCache<{ data: IDictionaryItem[]; current_page: number; last_page: number }>(`${dictApi}`, params)
      : of({ data: [], current_page: 0, last_page: 0 });
  }

  fetchDictionaryItemsById(dictApi: string, id: string, params: RpnHttpParams = {}): Observable<IDictionaryItem> {
    return this.httpGetWithCache<IDictionaryItem>(`${dictApi}/${id}`, params);
  }

  // TABLES
  listOnvTable<T>(type: TableType, params: RpnHttpParams = {}): Observable<IPagination<T>> {
    return this.httpGetWithCache<IPagination<T>>(`/api/svc/${type}`, params);
  }

  // TABLES
  listOpenOnvTable<T>(type: TableType, params: RpnHttpParams = {}): Observable<IPagination<T>> {
    return this.httpGetWithCache<IPagination<T>>(`/api/svc/${type}/open/onvs`, params);
  }

  // SMEV ARCHIVE TABLES
  listSmevArchiveRequests(params: RpnHttpParams = {}): Observable<IPagination<SmevRequest>> {
    return this.http.get<IPagination<SmevRequest>>('/api/svc/smev3/archive', { params: this.setHttpParams(params) });
  }

  // SMEV REGISTRY TABLES
  listSmevRegistryRequests(params: RpnHttpParams = {}): Observable<IPagination<SmevRequest>> {
    return this.http.get<IPagination<SmevRequest>>('/api/svc/smev3/registry', { params: this.setHttpParams(params) });
  }

  // SMEV4 REGISTRY TABLES
  listSmev4RegistryRequests(params: RpnHttpParams = {}): Observable<IPagination<SmevRequest>> {
    return this.http.get<IPagination<SmevRequest>>('/api/svc/smev4/registry', { params: this.setHttpParams(params) });
  }

  // SMEV4 Archive TABLES
  listSmev4ArchiveRegistryRequests(params: RpnHttpParams = {}): Observable<IPagination<SmevRequest>> {
    return this.http.get<IPagination<SmevRequest>>('/api/svc/smev4/archive', { params: this.setHttpParams(params) });
  }

  calcUrl(type: TableType, variables: ICalcUrlVariables = {}, params: RpnHttpParams = {}): string {
    const { territoryOrg, id, is_async, method } = variables;

    switch (type) {
      case TableType.all_reports: {
        params.filters = { ...(params.filters || {}), ...{ 'scope[stateServiceType]': '2' } };
        return '/api/v2/requests';
      }
      case TableType.all_requests: {
        params.filters = { ...(params.filters || {}), ...{ 'scope[stateServiceType]': '1' } };
        return '/api/v2/requests';
      }
      case TableType.payments_management_gis_gmp_rrs: {
        return '/api/payments-management/registry-gis-gmp/requests-responses';
      }
      case TableType.pm_sufd_zf:
      case TableType.pm_sufd_imports_zf: {
        return '/api/payments-management/sufd/zf';
      }
      case TableType.pm_sufd_wr:
      case TableType.pm_sufd_imports_wr: {
        return '/api/payments-management/sufd/wr';
      }
      case TableType.pm_sufd_imports_tofk: {
        return '/api/payments-management/sufd/sf';
      }
      case TableType.payments_management_gis_gmp_sar: {
        return '/api/payments-management/registry-gis-gmp/accruals-registration';
      }
      case TableType.payments_management_gis_gmp_sac: {
        return '/api/payments-management/registry-gis-gmp/accruals-change';
      }
      case TableType.pm_gis_gmp_confirmation_results: {
        return '/api/payments-management/registry-gis-gmp/reconciliations';
      }
      case TableType.dpnvos:
      case TableType.dpnvos611: {
        return `/api/svc/payment-nvos/v2/${type}`;
      }
      case (TableType.dpnvos_requests): {
        return '/api/svc/payment-nvos/v2/list_dpnvos_request?scope[accruals]&scope[isActualExpensesRequest]&scope[isControlActRequest]&scope[isClarificationRequestRequest]';
      }
      case (TableType.dpnvos_clarification): {
        return '/api/svc/payment-nvos/v2/list_dpnvos_request/clarification_request?relations=service_request&scope[isExpiredFromModel]';
      }
      case (TableType.dpnvos_actual_expenses): {
        return '/api/svc/payment-nvos/v2/list_dpnvos_request/actual_expenses_request?relations=service_request&scope[isExpiredFromModel]';
      }
      case (TableType.dpnvos_control_act): {
        return '/api/svc/payment-nvos/v2/list_dpnvos_request/control_act?relations=service_request';
      }
      case (TableType.dvos): {
        return `/api/svc/dvos/v2/${type}`;
      }
      case TableType.pek_request: {
        return `/api/svc/pek/v2/${type}`;
      }
      case TableType.pek_report: {
        return `/api/svc/pek_report/v2/${type}`;
      }
      case TableType.dfr_request:
      case TableType.dfr2022_request: {
        return `/api/svc/disposal-facilities-report/v2/${type}`;
      }
      case TableType.ecocharge_registry: {
        return '/api/svc/ecocharge/v2/ecocharge?scope[accruals]';
      }
      case TableType.utilization_registry: {
        return '/api/svc/utilization/v2/utilization';
      }
      case TableType.ecocharge_control_act: {
        return '/api/svc/ecocharge/v2/ecocharge/control_act';
      }
      case TableType.utilization_control_act: {
        return '/api/svc/utilization/v2/utilization/control_act';
      }
      case (TableType.rosnedra_notice): {
        return '/api/svc/reestr/v2/rosnedra_notice';
      }
      case (TableType.self_utilization_act): {
        return '/api/svc/self-utilization/v2/self_utilization_act';
      }
      case (TableType.utilization_weight_experiment): {
        return '/api/svc/rop/v2/utilization_weight_experiment';
      }
      case (TableType.utilization_weight_act): {
        return '/api/svc/rop/v2/utilization_weight_act';
      }
      case (TableType.gee_events_estimated_costs_validation): {
        return '/api/svc/gee/v2/costs_events_validation';
      }
      case TableType.dpnvos_refund_request:
      case TableType.dpnvos_revise_request:
      case TableType.nvos_offset_request:
      case TableType.requisites_change_request:
      case TableType.eco_offset_request: {
        return `/api/svc/payments/v2/${type}`;
      }
      case TableType.env_harm_request: {
        return `/api/svc/envharm/v2/${type}`;
      }
      case TableType.payments_analytics: {
        return '/api/payments-management/analytical-reports';
      }
      case TableType.payments_x_eb: {
        return '/api/payments-management/xml_eb_registry/grid';
      }
      case TableType.appointment: {
        return '/api/svc/appointment/v2/appointment_request';
      }
      case TableType.payments_payers: {
        return '/api/payments-management/payers/grid';
      }
      case TableType.payments_debtors: {
        return '/api/payments-management/payers/grid?with_closing_balance=true&with_details=true';
      }
      case TableType.payments_charges: {
        return id ? '/api/payments-management/charges' : '/api/payments-management/charges/grid';
      }
      case TableType.payments_charges_lk: {
        return '/api/payments-management/charges/grid_lk';
      }
      case TableType.payments_accruals: {
        params = { ...params, initParams: { relations: 'accrual_type' } };
        return '/api/payments-management/accruals/grid';
      }
      case TableType.payments_accruals_lk: {
        return '/api/payments-management/accruals/grid_lk';
      }
      case TableType.payments_declarations: {
        return '/api/payments-management/charge_declarations/grid';
      }
      case TableType.accruals_declarations: {
        return '/api/payments-management/accrual_declarations/grid';
      }
      case TableType.payments_acts: {
        params = { ...params, initParams: { relations: 'type_of_income' } };
        return '/api/payments-management/acts';
      }
      case TableType.eco_refund_request:
      case TableType.eco_revise_request: {
        params = { ...params, initParams: { relations: 'type_of_income' } };
        return `/api/svc/payments/v2/${type}`;
      }
      case TableType.payments_payments: {
        return '/api/payments-management/payment/list';
      }
      case TableType.payments_opening_balance_imports: {
        return '/api/payments-management/balance/xls';
      }
      case TableType.payments_payments_lk: {
        return '/api/payments-management/payment/list_lk';
      }
      case TableType.payments_sufd: {
        return '/api/payments-management/sufd/payments';
      }
      case TableType.payments_sufd_uf: {
        return '/api/payments-management/sufd/uf';
      }
      case TableType.payments_sufd_bd: {
        return '/api/payments-management/sufd/bd';
      }
      case TableType.payments_sufd_imports_vt: {
        return '/api/payments-management/sufd/vt';
      }
      case TableType.payments_sufd_imports_vt_operations: {
        return '/api/payments-management/sufd/vt-operations';
      }
      case TableType.public_inspector_request: {
        return `/api/svc/pir/v2/${type}`;
      }
      case TableType.license_requests:
      case TableType.license_request_issue:
      case TableType.license_request_correction:
      case TableType.license_request_writeout:
      case TableType.request_department_writeout:
      case TableType.license_request_replace:
      case TableType.license_request_termination:
      case TableType.request_forced_termination:
      case TableType.license_request_insertion:
      case TableType.license_request_modification:
      case TableType.license_onsite_check:
      case TableType.license_documentary_check: {
        if (is_async) return '/api/svc/license-activity-waste/license_request';
        return `/api/svc/license-activity-waste/v2/${type}`;
      }
      case TableType.payment_analytical: {
        return `/api/svc/payment-analytical/territory_org/${territoryOrg}`;
      }
      case TableType.cites_certificate_import: {
        return `/api/svc/cites/v2/${type}`;
      }
      case TableType.fgn_category_change: {
        return '/api/svc/knd/v2/fgn_category_change';
      }
      case TableType.erp_check_lists: {
        return '/api/svc/check-lists-ksv/v2/check_list_ksv';
      }
      case TableType.erp_inspections: {
        return `/api/svc/nsi/${type}`;
      }
      case TableType.waste_class_assignment_issue: {
        return `/api/svc/nvos-waste-certification/v2/${type}`;
      }
      case TableType.animal_registry: {
        return `/api/svc/animals/v2/${type}`;
      }
      case TableType.fgen_recult:
      case TableType.fgen_object:
      case TableType.fggn_object:
      case TableType.fgzn_object:
      case TableType.fgan_object:
      case TableType.fgahn_object:
      case TableType.fgohn_object:
      case TableType.fgotn_object:
      case TableType.fglesn_object:
      case TableType.ancr_object:
      case TableType.eroi_object:
      case TableType.recycler_inclusion:
      case TableType.recycler_actualization:
      case TableType.atex_object:
      case TableType.animals_object:
      case TableType.knm_object:
      case TableType.registry_permission:
      case TableType.fkko_object:
      case TableType.liquidated_object:
      case TableType.commission_object:
      case TableType.cites_object: {
        return environment.SYSTEM_TYPE === EnvironmentType.registries
          ? `/api/svc/public-registries/${OpenTypeMap[type]}`
          : `/api/svc/reestr/v2/${type}`;
      }

      case TableType.check_lists_self: {
        return '/api/svc/knd/v2/check_list';
      }
      case TableType.knd_registry: {
        return '/api/svc/knd/registry';
      }

      case (TableType.administrative_matter_order):{
        return id
          ? '/api/svc/knd/v2/administrative_matter_order'
          : '/api/svc/knd/v2/administrative_matter_order?scope[byAccrualInfoData]';
      }

      case TableType.self_exam:
      case TableType.pm_visit_card:
      case TableType.pm_warning_card:
      case TableType.control_objects_by_indicator:
      case TableType.knd_plan:
      case TableType.expertise_request_clati:
      case TableType.pre_trial_appeal:
      case TableType.key_metric:
      case TableType.administrative_matter:
      case TableType.matters_submission:
      case TableType.knd_card: {
        return `/api/svc/knd/v2/${type}`;
      }

      case TableType.expertise_request: {
        return '/api/svc/reestr/v2/expertise_request';
      }
      case TableType.expense_reimbursement: {
        return '/api/svc/reestr/v2/expense_reimbursement';
      }
      case TableType.termination_request: {
        return '/api/svc/reestr/v2/termination_request';
      }
      case TableType.onv_request: {
        return `/api/svc/onv/${type}`;
      }
      case TableType.ozon_depleting_registry: {
        return `/api/svc/ozon-depleting-registry/v2/${type}`;
      }
      case TableType.eek_resolutions: {
        return `/api/svc/eek/v2/${type}`;
      }
      case TableType.groro_request_actualization:
      case TableType.groro_request_exclusion:
      case TableType.groro_request_update: {
        if (method === 'POST') {
          return `/api/svc/groro/v2/${type}/groro/` + id;
        }
        return `/api/svc/groro/v2/${type}`;
      }

      case TableType.addcharge: {
        return '/api/svc/payment-nvos/v2/addcharge';
      }

      case TableType.news: {
        return '/api/svc/nsi/news';
      }

      case TableType.recycler_actualization_custom: {
        return '/api/svc/reestr/v2/store_recycler_actualization';
      }

      default: {
        if (!type) return '';

        const node = type.split('_')[0];
        return node ? `/api/svc/${node}/v2/${type}` : '';
      }
    }
  }


  fetchTable<T = any>(
    type: TableType,
    paramsValue: RpnHttpParams = {},
    territoryOrg: string = '',
    withCache = true,
  ): Observable<IPagination<T>> {
    const params = deepClone(paramsValue);
    const itemUrl = this.calcUrl(type, { territoryOrg }, params);

    const source$ = withCache
      ? this.httpGetWithCache(itemUrl, params)
      : this.http.get(itemUrl, { ...(params ? { params: this.setHttpParams(params) } : {}) });

    return source$.pipe(
      map((res) => {
        const emptyRes = {
          current_page: 1,
          per_page: paramsValue.pagination.per_page,
          last_page: 1,
          limit: paramsValue.pagination.limit,
          data: [],
        };
        return res !== undefined ? res : emptyRes;
      }),
    );
  }

  fetchDataSourceList() {
    return this.httpGetWithCache('/api/svc/api-export/datasource');
  }

  fetchDataSource(datasource_name: string) {
    return this.httpGetWithCache(`/api/svc/api-export/datasource:${datasource_name}`);
  }

  fetchDataSourceTemplates(params: RpnHttpParams) {
    return this.httpGetWithCache('/api/svc/api-export/templates', params);
  }

  createDataSourceTemplates(datasource_name: string, body: DatasourceTemplate) {
    return this.postByUrlWithParams<DatasourceTemplate>(`/api/svc/api-export/datasource:${datasource_name}/templates`, body, {});
  }

  saveDataSourceTemplate(uuid: string, body: DatasourceTemplate) {
    return this.postByUrlWithParams<DatasourceTemplate>(`/api/svc/api-export/template:${uuid}`, body, {});
  }

  removeDataSourceTemplate(uuid: string) {
    return this.http.delete(`/api/svc/api-export/template:${uuid}`);
  }

  fetchDataSourceHistory(datasource_name: string) {
    return this.httpGetWithCache(`/api/svc/api-export/datasource:${datasource_name}/results`);
  }

  //FETCH OPEN ONVOS CARD
  fetchOpenOnvItemById(id: number, params: RpnHttpParams = {}): Observable<IOnvosRegistry> {
    return this.httpGetWithCache<IOnvosRegistry>(`/api/svc/onv/open/onvs/${id}`, params);
  }

  //FETCH ONVOS CARD
  fetchOnvItemById(id: number, params: RpnHttpParams = {}): Observable<IOnvosRegistry> {
    return this.httpGetWithCache<IOnvosRegistry>(`/api/svc/onv/${id}`, params);
  }

  // FETCH Statistics
  fetchEnvHarmStatistics(params: RpnHttpParams = {}): Observable<IEnvHarmStatistics> {
    return this.httpGetWithCache<IEnvHarmStatistics>('/api/svc/envharm/env_harm_request_stats', params);
  }

  // FETCH Monitoring statistics
  public fetchMonitoringStatistics(params: RpnHttpParams = {}) {
    return this.httpGetWithCache('/api/svc/onv-monitoring/stats/stationary', params);
  }

  // FETCH ROOT ESSENCE
  fetchEssenceByID<T = any>(type: TableType, id: number, params: RpnHttpParams = {}): Observable<T | null> {
    const urlPart = this.calcUrl(type, {id}, params);
    if (!urlPart) return of(null);
    return this.httpGetWithCache(`${urlPart}/${id}`, deepClone(params)).pipe(catchError(() => of(null)));
  }

  rebuildUrlFromParams(url: string, routeParams: IRouteParams): string {
    if (!url) return '';

    for (const param in routeParams) {
      if (Object.prototype.hasOwnProperty.call(routeParams, param)) {
        url = url.replace(`{${param}}`, '' + routeParams[param]);
      }
    }

    return url;
  }

  // FETCH ESSENCE BY ROUT
  fetchEssenceByRoute(url: string, routeParams: IRouteParams, params: RpnHttpParams = {}): Observable<any> {
    const fullUrl = this.rebuildUrlFromParams(OnvosRequestUrlMap[url] || url, routeParams);

    return fullUrl ? this.httpGetWithCache(fullUrl, params) : of(null);
  }

  // CREATE ESSENCE
  createEssence(url: string, routeParams: IRouteParams, body: any = {}): Observable<any> {
    const fullUrl = this.rebuildUrlFromParams(url, routeParams);

    return this.http.post(fullUrl, {
      ...serializerForValue(body),
      ...{ service_request_id: +routeParams.essenceId },
    });
  }

  // UPDATE ROOT ESSENCE
  updateEssence(type: TableType, id: number, body: any = {}, params: RpnHttpParams = {}): Observable<any> {
    const node = type.split('_')[0];
    if (type && id) {
      return this.http.patch(`/api/svc/${node}/v2/${type}/${id}`, body, { params: this.setHttpParams(params) });
    }
    return of(null);
  }

  updateEssenceByRoute(url: string, routeParams: IRouteParams, body: any = {}, method: string = 'patch'): Observable<any> {
    if (OnvosRequestUrlMap[url]) url = OnvosRequestUrlMap[url];

    const fullUrl = this.rebuildUrlFromParams(url, routeParams);

    if (method === 'patch') {
      return this.http.patch(fullUrl, {
        ...serializerForValue(body),
        ...{ service_request_id: +routeParams.essenceId },
      });
    }

    return this.http.post(fullUrl, {
      ...serializerForValue(body),
      ...{ service_request_id: +routeParams.essenceId },
    });
  }

  deleteEssenceByRoute(url: string, routeParams: IRouteParams, cascadeDelete: boolean): Observable<any> {
    const paramsValue: RpnHttpParams = { initParams: {} };
    if (cascadeDelete) paramsValue.initParams.cascade = 'true';

    return this.http.delete(this.rebuildUrlFromParams(url, routeParams), {
      headers: new HttpHeaders(),
      params: this.setHttpParams(paramsValue),
    });
  }

  deleteSelectByRoute(url: string, routeParams: IRouteParams, ids: number[], cascadeDelete: boolean): Observable<any> {
    const fullUrl = this.rebuildUrlFromParams(url, routeParams);

    const paramsValue: RpnHttpParams = { initParams: {} };
    if (cascadeDelete) paramsValue.initParams.cascade = 'true';
    const params = this.setHttpParams(paramsValue);

    const options = {
      headers: new HttpHeaders(),
      body: { ids },
      params,
    };
    return this.http.delete(fullUrl + `/${ids[0]}`, options);
  }

  checkEssenceByRoute<T>(url: string, routeParams: IRouteParams): Observable<any> {
    const fullUrl = this.rebuildUrlFromParams(url, routeParams);
    return this.http.get<T>(fullUrl);
  }

  createReport(type: TableType, data: any = {}): Observable<any> {
    if (!type) {
      return of({});
    }
    const itemUrl = this.calcUrl(type, { id: data.id, method: 'POST' });

    return this.http.post(itemUrl, data);
  }

  // CREATE OBJECT
  createObject(type: TableType, id: number, sectionName: string, body: any = {}): Observable<any> {
    const node = type.split('_')[0];
    return this.http.post(`/api/svc/${node}/v2/${type}/${id}/${sectionName}`, {
      ...serializerForValue(body),
      ...{ service_request_id: +id },
    });
  }

  // UPDATE OBJECT
  updateObject(type: TableType, id: number, sectionName: string, objectId: string, body: any = {}): Observable<any> {
    const node = type.split('_')[0];
    return this.http.patch(`/api/svc/${node}/v2/${type}/${id}/${sectionName}/${objectId}`, {
      ...serializerForValue(body),
      ...{ service_request_id: +id },
    });
  }

  // OTHER
  downloadFile(url: string, fileName?: string, fileExt?: string, cb?: () => any): Observable<boolean> {
    const isLoaded$ = new BehaviorSubject(false);
    if (url.indexOf('/api/svc/') > 0) url = '/api/svc/' + url.split('/api/svc/')[1];

    this.subscriptions.push(
      this.get<HttpResponse<Blob>>(url, { observe: 'response', responseType: 'blob' })
        .pipe(
          map((response) => {
            const content_disposition = response.headers.get('content-disposition') || '';
            const type = response.headers.get('content-type');
            const str = content_disposition.split('filename=')[1]?.split('; ')[0] || '';
            const lastNodeInName = str.split('.')?.slice(-1)?.[0];
            const ext = fileExt || EFileType[type];
            const filename = fileName
              ? ( fileName + (ext ? '.' + ext : '') )
              : decodeURIComponent(
                escape(str.replace(/"/g, '') || v4() + (type && (lastNodeInName !== type) ? '.' + ext : ''))
              );
            return [response.body, filename];
          }),
          catchError(() => of([null, null])),
        )
        .subscribe(([blobPart, filename]: [Blob, string]) => {
          if (blobPart) saveAs(new Blob([blobPart]), filename);

          if (typeof cb === 'function') {
            cb();
          }

          isLoaded$.next(true);
        }),
    );
    return isLoaded$.pipe(debounceTime(1000));
  }

  sentMessage(body: IMessageSrc): Observable<any> {
    return this.http.post('/api/auth/messages', body);
  }

  fetchSentMessages(): Observable<any> {
    return this.httpGetWithCache('/api/auth/messages/sent');
  }

  fetchReceivedMessages(): Observable<any> {
    return this.httpGetWithCache('/api/auth/messages/received');
  }

  fetchBarcode(value: string): Observable<string | ArrayBuffer | null> {
    return this.http
      .get(`/api/common/bar-code?code=${value}`, { responseType: 'blob' })
      .pipe(switchMap((data) => createImageFromBlob(data)));
  }

  fetchQRcode(value: string): Observable<any> {
    return this.http.get(`/api/common/qr-code?code=${value}`, { responseType: 'blob' });
  }

  fetchMessages(params: RpnHttpParams = {}): Observable<IPagination<Message>> {
    params.orderBy = { created_at: Sort.desc };
    return this.httpGetWithCache<IPagination<IMessageSrc>>(
      '/api/svc/messages/received',
      null,
      { params: this.setHttpParams(params) }
    ).pipe(map((src) => Message.buildPaginationFromSrc(src)));
  }

  fetchMessagesAll(params: RpnHttpParams = {}): Observable<IPagination<Message>> {
    params.orderBy = { created_at: Sort.desc };
    return this.httpGetWithCache<IPagination<IMessageSrc>>(
      '/api/svc/messages/received_with_orgs',
      null,
      { params: this.setHttpParams(params) }
    ).pipe(map((src) => Message.buildPaginationFromSrc(src)));
  }

  markMessageAsRead(message: Message): Observable<boolean> {
    message.readAt = +new Date();
    return this.http.post(`/api/svc/messages/${message.id}/read`, {}).pipe(
      mapTo(true),
      catchError(() => of(false)),
    );
  }

  fetchNotifications(): Observable<any> {
    return this.http.get('/api/auth/notifications');
  }

  markReadNotifications(arrayOfID: string[]): Observable<any> {
    return this.http.post('/api/auth/notifications/read', arrayOfID);
  }

  private getSetStatusUrl(type, essenceId: number, is_async: boolean): string {
    if (is_async) return `${this.calcUrl(type, { is_async, id: essenceId })}/${essenceId}/set_status_job`; // set_status_job

    return `${this.calcUrl(type, {id: essenceId})}/${essenceId}/set-status`;
  }

  changeStatus(type: TableType, essenceId: number, status_id: number, parameters: IReportTransitionBody = {}): Observable<any> {
    if (!parameters.result_comment) parameters.result_comment = '';
    const body = { status_id, parameters };
    return this.http.post(this.getSetStatusUrl(type, essenceId, false), body);
  }

  changeStatusByTransition(
    type: TableType,
    essenceId: number,
    status_transition_id: number,
    is_async: boolean,
    parameters: IReportTransitionBody = {},
  ): Observable<any> {
    if (!parameters.result_comment) parameters.result_comment = '';
    const body = { status_transition_id, parameters };

    if (is_async) {
      const loadStatus$ = (jobResult) => {
        return this.http
          .get<IJobStatus>(jobResult.status_link)
          .pipe(
            tap(
              (res: IJobStatus) =>
                !(res.status === EJobStatus.completed || res.status === EJobStatus.failed) &&
                this.toastr.info('', EJobStatusTranslate[res.status]),
            ),
          );
      };

      return this.http.post(this.getSetStatusUrl(type, essenceId, true), body).pipe(
        switchMap((jobResponse: IJobResponse) =>
          loadStatus$(jobResponse).pipe(
            delay(5000),
            filter((x) => !!x),
            expand((jobResult: IJobStatus) => {
              return jobResult.status === EJobStatus.completed || jobResult.status === EJobStatus.failed
                ? EMPTY
                : loadStatus$(jobResult).pipe(delay(5000));
            }),
          ),
        ),
        switchMap((jobResult: IJobStatus) => {
          if (jobResult.status === EJobStatus.completed && jobResult?.output?.status?.id) {
            this.toastr.success('', 'Выполнено');
            location.reload();
          } else if (jobResult.status === EJobStatus.failed) {
            this.toastr.error(jobResult.error || '', 'Ошибка');
          }
          return EMPTY;
        }),
      );
    }

    return this.http.post(this.getSetStatusUrl(type, essenceId, false), body);
  }

  fetchSources(params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<any>>('/api/svc/onv/sources', params);
  }

  copyCorrectionSources(onv_id, new_request_id) {
    return this.http.post(`/api/svc/onv/sources_copy/${onv_id}/${new_request_id}`, {});
  }

  // TABLES
  fetchOnvTable<T>(type: string, params: RpnHttpParams = {}): Observable<IPagination<T>> {
    return this.httpGetWithCache<IPagination<T>>(`/api/svc/${type}`, params);
  }

  fetchOnvos(objectId: string): Observable<any> {
    return this.httpGetWithCache(`/api/svc/onv/${objectId}`);
  }

  updateOnvosControlDirections(onvId: number, control_directions_ids: number[]) {
    return this.http.patch(`/api/svc/onv/control-directions/${onvId}`, { control_directions_ids });
  }

  createAfterPatchOnvosControlDirections(onvId: number) {
    return this.http.post(`/api/svc/onv/risk-category/${onvId}/autoupdate`, {});
  }

  startTaskCreating(fileUrl: string, item: any): Observable<IJobResponse> {
    return this.http.post<IJobResponse>(fileUrl, item);
  }

  fetchJobStatus(jobResult: IJobResponse | IJobStatus): Observable<IJobStatus> {
    return this.http
      .get<IJobStatus>(jobResult.status_link)
      .pipe(
        tap(
          (res: IJobStatus) =>
            !(res.status === EJobStatus.completed || res.status === EJobStatus.failed) &&
            this.toastr.info('', EJobStatusTranslate[res.status]),
        ),
      );
  }

  fetchOnvosStatementApplicationDocuments(onv_request_id: number) {
    return this.httpGetWithCache(`/api/svc/onv/v2/onv_request_inclusion_v2/${onv_request_id}/statement/application_documents`);
  }

  fetchOnvosDocumentsForOldSystem(id) {
    return this.httpGetWithCache<FilesForOldSystem>(`/api/svc/onv/request/${id}/documents`);
  }

  listOnvosMonitoringCertificates(onv_number: string, params: RpnHttpParams = {}) {
    return this.http.get<IPagination<IMonitoringCertificate>>(`/api/svc/onv-monitoring/onvs/${onv_number}/certificates`, {
      params: this.setHttpParams(params),
    });
  }

  registerCertificateMonitoringForOnv(onv_number: string, certificate_serial: string, device_uuid?: string) {
    let url = `/api/svc/onv-monitoring/onvs/${onv_number}/certificates/attach/${certificate_serial}`;

    if (device_uuid) {
      url = `${url}/${device_uuid}`;
    }

    return this.http.post<IMonitoringCertificate>(url, {});
  }

  registerSourceMonitoringForOnv(
    onv_number: string,
    certificate_serial: string,
    device_uuid: string,
    source_id: number,
    source_type: string,
  ) {
    const attachBySourceType = {
      stationary_sources: 'attach_stationary',
      discharges_sources: 'attach_discharges',
    };

    return this.http.post<IMonitoringSource>(
      `/api/svc/onv-monitoring/onvs/${onv_number}/certificates` +
        `/${attachBySourceType[source_type]}/${certificate_serial}/${device_uuid}/${source_id}`,
      {},
    );
  }

  configSourceMonitoringForOnv(
    onv_number: string,
    certificate_serial: string,
    device_uuid: string,
    source_identity: string,
    body: IMonitoringSourceConfigRequest,
    source_type: SourceType,
  ) {
    return this.http.post<IMonitoringSource>(
      `/api/svc/onv-monitoring/certificate/${certificate_serial}/${onv_number}/${device_uuid}/${source_identity}/${source_type}`,
      body,
    );
  }

  deleteSourceMonitoringForOnv(certificate: IMonitoringCertificate, onv_number: string) {
    const { certificate_serial, device_uuid } = certificate;

    return this.http.delete<IMonitoringSource>(`/api/svc/onv-monitoring/certificate/${certificate_serial}/${onv_number}/${device_uuid}`);
  }

  configDeviceMonitoringForOnv(onv_number: string, certificate_serial: string, device_uuid: string, body: IMonitoringDeviceConfigRequest) {
    return this.http.post(`/api/svc/onv-monitoring/certificate/${certificate_serial}/${onv_number}/${device_uuid}`, body);
  }

  addPollutantToDevice(device_id, code, power, power_ker) {
    return this.http.post(`/api/svc/onv-monitoring/onvos_devices/${device_id}/device_pollutants`, {
      code,
      power,
      power_ker,
    });
  }

  updateDeviceData(device_id, operating_start_date, comment, attachments) {
    return this.http.post(`/api/svc/onv-monitoring/onvos_devices/${device_id}/activate`, {
      operating_start_date,
      comment,
      attachments,
    });
  }

  listOnvosPermittingDocsSeparate(onv_id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IPermittingDocumentSeparate>>(`/api/svc/onv/${onv_id}/permitting_docs`, params);
  }

  fetchOnvosPermittingDocSeparate(onv_id: number, doc_id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPermittingDocumentSeparate>(`/api/svc/onv/${onv_id}/permitting_docs/${doc_id}`, params);
  }

  createOnvosPermittingDocSeparate(onv_id: number, body: IPermittingDocumentSeparate) {
    return this.http.post<IPermittingDocumentSeparate>(`/api/svc/onv/${onv_id}/permitting_docs`, body);
  }

  updateOnvosPermittingDocSeparate(onv_id: number, doc_id: number, body: IPermittingDocumentSeparate) {
    return this.http.patch<IPermittingDocumentSeparate>(`/api/svc/onv/${onv_id}/permitting_docs/${doc_id}`, body);
  }

  createONVOSRequest(type: TableType, onv_id: number, alien?: boolean): Observable<any> {
    if (!type) {
      return of(null);
    }

    if (type === TableType.onv_request_inclusion || type === TableType.onv_request_inclusion_v2) {
      return this.createReport(type).pipe(
        tap((res) => {
          this.router.navigate([this.isLK ? '/rpn/requests/all_requests/details' : '/rpn/pto-uonvos/onv_request/details', type, res.id]);
        }),
      );
    } else {
      const loadStatus$ = (jobResult: IJobResponse | IJobStatus) => this.fetchJobStatus(jobResult);
      let counter = 0;

      let urlType: string = type;
      let isJobMode = true;

      if (type === TableType.onv_request_adjust_v2) {
        urlType = 'onv_request_adjust';
      } else if (type === TableType.onv_request_edit_v2 && alien) {
        urlType = 'edit_v2_by_number';
        isJobMode = false;
      }

      return this.startTaskCreating(`/api/svc/onv/replicate/${urlType}/${onv_id}`, {}).pipe(
        switchMap((response: IJobResponse | any) => {
          if (isJobMode) {
            return loadStatus$(response).pipe(
              delay(5000),
              filter((x) => !!x),
              expand((jobResult: IJobStatus) => {
                return jobResult.status === EJobStatus.completed || jobResult.status === EJobStatus.failed || ++counter > 30
                  ? EMPTY
                  : loadStatus$(jobResult).pipe(delay(5000));
              }),
            );
          } else {
            return of({
              status: EJobStatus.completed,
              output: response,
            });
          }
        }),
        switchMap((jobResult: IJobStatus) => {
          if (jobResult.status === EJobStatus.completed && jobResult?.output?.id) {
            this.toastr.success('', 'Выполнено');
            this.router.navigate([
              this.isLK ? '/rpn/requests/all_requests/details' : '/rpn/pto-uonvos/onv_request/details',
              type,
              jobResult.output.id,
            ]);
          } else if (jobResult.status === EJobStatus.failed) {
            this.toastr.error(jobResult.error || '', 'Ошибка');
          }
          return EMPTY;
        }),
      );
    }
  }

  fetchConsolidateReportBlobPart(fileUrl: string): Observable<BlobPart> {
    return this.http.get<any>(fileUrl, {
      responseType: 'blob' as 'json',
    });
  }

  fetchCheckList(id: number): Observable<ICheckListDataInstance> {
    return this.httpGetWithCache<ICheckListDataInstance>(`/api/svc/knd/v2/check_list/${id}`);
  }

  fetchErpCheckList(id: number): Observable<ICheckListDataInstance> {
    return this.httpGetWithCache<ICheckListDataInstance>(`/api/svc/check-lists-ksv/v2/check_list_ksv/${id}`);
  }

  fetchErpInspection(id: number): Observable<IErpInspection> {
    return this.httpGetWithCache<IErpInspection>(`/api/svc/nsi/erp_inspections/${id}`);
  }

  listErpInspectionsByParams(params: RpnHttpParams): Observable<IPagination<IErpInspection>> {
    return this.httpGetWithCache<IPagination<IErpInspection>>('/api/svc/nsi/erp_inspections', params);
  }

  listKndRegistryByParams(params: RpnHttpParams, torKnd = false): Observable<IPagination<IKndRegistry>> {
    let getParams = this.setHttpParams(params);
    getParams = getParams.append('scope[byHasErpInspection]', '' + torKnd);

    return this.http.get<IPagination<IKndRegistry>>('/api/svc/knd/registry', { params: getParams });
  }

  updateCheckList(id: number, body: any): Observable<any> {
    return this.http.patch(`/api/svc/knd/v2/check_list/${id}`, body);
  }

  updateErpCheckList(id: number, body: any): Observable<any> {
    return this.http.patch(`/api/svc/check-lists-ksv/v2/check_list_ksv/${id}`, body);
  }

  fetchOnvosObjectControlCategories(objectId: number) {
    return this.httpGetWithCache<IProfileDataInstanceControlCategory[]>(`/api/svc/onv/v2/onvos_control_categories/${objectId}`);
  }

  updateOnvosObjectControlCategories(objectId: number, body: any) {
    return this.http.patch(`/api/svc/onv/onvos_control_categories/${objectId}`, body);
  }

  listOnvosPermittingDocuments(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IPermittingDocument>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/permitting_documents`,
      params,
    );
  }

  listOnvosChanges(onvId: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<IOnvosChange>>(`/api/svc/onv/onv-common-logs/${onvId}`, params);
  }

  updateOnvosPermittingDocument(document: IPermittingDocument, update: Partial<IPermittingDocument>) {
    return this.http.post(`/api/svc/onv/request/${document.service_request_id}/permitting_documents/${document.id}`, update);
  }

  fetchOnvosEmissionObject(onvId: number): Observable<any> {
    return this.httpGetWithCache(`/api/svc/onv/request/${onvId}/emission_object`);
  }

  updateOnvosSecret(onvId: number, body) {
    return this.http.patch(`/api/svc/onv/make-secret/${onvId}`, body);
  }

  sendFakeMonitoring(body) {
    return this.http.post('/api/svc/onv-monitoring/faker/stationary', body);
  }

  updateOnvosEmissionObject(onvId: number, update: any): Observable<any> {
    return this.http.patch(`/api/svc/onv/v2/onv_request/${onvId}/emission_object`, update);
  }

  updateOnvosEmissionObjectAdjustV2(onvId: number, update: any): Observable<any> {
    return this.http.patch(`/api/svc/onv/v2/onv_request_adjust_v2/${onvId}/statement/emission_object`, update);
  }

  updateOnvosEmissionObjectEditV2(onvId: number, update: any): Observable<any> {
    return this.http.patch(`/api/svc/onv/v2/onv_request_edit_v2/${onvId}/statement/emission_object`, update);
  }

  updateOnvosEmissionObjectInclusionV2(onvId: number, update: any): Observable<any> {
    return this.http.patch(`/api/svc/onv/v2/onv_request_inclusion_v2/${onvId}/statement/emission_object`, update);
  }

  createOnvosAdjustNotification(onvId: number, data: any) {
    return this.http.post(`/api/svc/onv/correction-notification/${onvId}`, data);
  }

  // PAYMENT NVOS
  listPNvos(onvosId: number, paramsValue: RpnHttpParams = {}): Observable<[IPaymentNvos[], IPaymentNvos[], IPaymentNvos[]]> {
    const params: RpnHttpParams = { ...paramsValue, initParams: { relations: 'territory_org' } };
    return combineLatest([
      this.httpGetWithCache<IPaymentNvos[]>(`/api/svc/payment-nvos/v2/onv/${onvosId}/payment_nvos_requests`, params),
      this.httpGetWithCache<IPaymentNvos[]>(`/api/svc/payment-nvos/v2/onv/${onvosId}/payment_nvos_correction_requests`, params),
      this.httpGetWithCache<IPaymentNvos[]>(`/api/svc/payment-nvos/v2/onv/${onvosId}/payment_nvos_requests_ksv`, params),
    ]);
  }

  listPNvosTotals(
    declarationId: string,
    declarationType: keyof typeof EPnvosModelToQueryType,
    onvosId: string,
    params: RpnHttpParams = {},
  ): Observable<IPagination<any>> {
    const queryType = EPnvosModelToQueryType[declarationType];

    if (queryType) {
      return this.http.get<any>(`/api/svc/payment-nvos/v2/${queryType}/${declarationId}/onv/${onvosId}/totals`, {
        params: this.setHttpParams(params),
      });
    } else {
      return of({
        data: [],
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 0,
      });
    }
  }

  public getMonitoringOnvStats(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache(`/api/svc/onv-monitoring/stats/stationary/${id}`, params);
  }

  public getEmissionsMonitoringStats(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<any>(`/api/svc/onv-monitoring/stats/summary/${id}`, params);
  }

  listOnvosEmissionsMeasureTools(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<INegativeImpactTool>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/emissions_measure_tools`,
      params,
    );
  }

  listOnvosDischargesMeasureTools(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<INegativeImpactTool>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/discharges_measure_tools`,
      params,
    );
  }

  listOnvosAirMeasureTools(id: number) {
    return this.httpGetWithCache<IPagination<INegativeImpactTool>>(
      `/api/svc/onv/open/onvs/${id}/actual_request_v2/emission_object/air_measure_tools`,
    );
  }

  listOnvosWaterMeasureTools(id: number) {
    return this.httpGetWithCache<IPagination<INegativeImpactTool>>(
      `/api/svc/onv/open/onvs/${id}/actual_request_v2/emission_object/water_measure_tools`,
    );
  }

  listOnvosEmissionsNeutralizeTools(id: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<INegativeImpactNeutralizeTool>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/emissions_neutralize_tools`,
      params,
    );
  }

  listOnvosPermittedDocumentsOpen(id: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<IPermittingDocument>>(`/api/svc/onv/open/onvs/${id}/card/permitting_documents`, params);
  }

  listOnvosPresentPermittedDocumentsOpen(id: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<IPermittingDocument>>(`/api/svc/onv/open/onvs/${id}/card/reports`, params);
  }

  listOnvosDischargesNeutralizeTools(id: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<INegativeImpactNeutralizeTool>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/discharges_neutralize_tools`,
      params,
    );
  }

  listOnvosDisposalsNeutralizeTools(id: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<INegativeImpactNeutralizeTool>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/disposals_neutralize_tools`,
      params,
    );
  }

  listOnvosNegativeImpactNeutralizeToolsOpen(id: number) {
    return this.httpGetWithCache<IPagination<INegativeImpactNeutralizeTool>>(
      `/api/svc/onv/open/onvs/${id}/actual_request_v2/emission_object/onv_neutralize_tools`,
    );
  }

  listOnvosReduceMeasures(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IEvent>>(`/api/svc/onv/${id}/actual_request_v2/emission_object/reduce_measures`, params);
  }

  versionForStr(ver: string, str: string) {
    const v2Index = str.length - ver.length;
    return str.indexOf(ver) === v2Index;
  }

  listOnvosWasteDisposalByRequestType(id: number, requestType: string, params: RpnHttpParams = {}) {
    const path = this.versionForStr('_v2', requestType) ? 'statement/negative_impact_info' : 'emission_object';

    return this.httpGetWithCache<IPagination<IWastePoint>>(
      `/api/svc/onv/v2/${requestType}/${id}/${path}/waste_disposal_facilities`,
      params,
    );
  }

  listOnvosStationarSourcesByRequestType(id: number, requestType: string, params: RpnHttpParams = {}) {
    const path = this.versionForStr('_v2', requestType) ? 'statement/negative_impact_info' : 'emission_object';

    return this.httpGetWithCache<IPagination<IStationarySource>>(
      `/api/svc/onv/v2/${requestType}/${id}/${path}/emissions_stationary_sources`,
      params,
    );
  }

  listOnvosDischargesSourcesByRequestType(id: number, requestType: string, params: RpnHttpParams = {}) {
    const path = this.versionForStr('_v2', requestType) ? 'statement/negative_impact_info' : 'emission_object';

    return this.httpGetWithCache<IPagination<IDischargeSource>>(`/api/svc/onv/v2/${requestType}/${id}/${path}/discharges_sources`, params);
  }

  listOnvosWasteDisposalV1(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IWastePoint>>(
      `/api/svc/onv/v2/onv_request/${id}/emission_object/waste_disposal_facilities`,
      params,
    );
  }

  listOnvosStationarSourcesV1(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IStationarySource>>(
      `/api/svc/onv/v2/onv_request/${id}/emission_object/emissions_stationary_sources`,
      params,
    );
  }

  listOnvosDischargesSourcesV1(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IDischargeSource>>(
      `/api/svc/onv/v2/onv_request/${id}/emission_object/discharges_sources`,
      params,
    );
  }

  listOnvosGeoLicense(id: number) {
    return this.httpGetWithCache(`/api/svc/onv/${id}/actual_request_v2/emission_object/geo_license`);
  }

  listOnvosWasteLicense(id: number) {
    return this.httpGetWithCache(`/api/svc/onv/${id}/actual_request_v2/emission_object/waste_license`);
  }

  listOnvosProcessingFacilities(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IProcessingFacility>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/processing_facilities`,
      params,
    );
  }

  listOnvosUtilizationFacilities(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IUtilizationFacility>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/utilization_facilities`,
      params,
    );
  }

  listOnvosNeutralizeFacilities(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<INeutralizeFacility>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/neutralize_facilities`,
      params,
    );
  }

  listOnvosAccumulationFacilities(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IAccumulationFacility>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/accumulation_facilities`,
      params,
    );
  }

  listOnvosWasteDisposalV2(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IWastePoint>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/waste_disposal_facilities`,
      params,
    );
  }

  listOnvosStationarSources(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IStationarySource>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/emissions_stationary_sources`,
      params,
    );
  }

  listOnvosDischargesSourcesV2(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IDischargeSource>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/discharges_sources`,
      params,
    );
  }

  listOnvosEmissionObjectProducts(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IProduct>>(`/api/svc/onv/${id}/actual_request_v2/emission_object/onv_products`, params);
  }

  listOnvosAppliedTechnologies(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<any>>(`/api/svc/onv/${id}/actual_request/emission_object/applied_technologies`, params);
  }

  listOnvosAppliedTechnologiesV2(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<any>>(`/api/svc/onv/${id}/actual_request_v2/emission_object/applied_technologies`, params);
  }

  listOnvosAppliedTechnologiesV2Open(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<any>>(`/api/svc/onv/open/onvs/${id}/card/applied_technologies`, params);
  }

  listOnvosNeutralizeToolsOpen(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<any>>(`/api/svc/onv/open/onvs/${id}/card/neutralize_tools`, params);
  }

  listOnvosStationarySourceMonitoring(service_request_id: number, source_id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<any>>(
      `/api/svc/onv/v2/onv_request/${service_request_id}/emission_object/emissions_stationary_sources/${source_id}/monitoring`,
      params,
    );
  }

  listOnvosDischargeSourceMonitoring(service_request_id: number, source_id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<any>>(
      `/api/svc/onv/v2/onv_request/${service_request_id}/emission_object/discharges_sources/${source_id}/monitoring`,
      params,
    );
  }

  fetchOnvosNatureUser(id: number): Observable<INatureUser> {
    return this.httpGetWithCache<INatureUser>(`/api/svc/onv/${id}/actual_request_v2/request_organization`);
  }

  listOnvosRequests(onvId: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<IOnvosRequest>>(`/api/svc/onv/${onvId}/requests`, params);
  }

  listOnvosHistoryRequests(onvId: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<IRequest>>(`/api/svc/onv/${onvId}/card_requests`, params);
  }

  listOnvosHistoryRequestsV2(onvId: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<IRequest>>(`/api/svc/onv/${onvId}/card_requests_v2`, params);
  }

  fetchRequestsDiffJob(first_request_id, second_request_id) {
    return this.http.get(`/api/svc/diff/test-job/${first_request_id}/${second_request_id}`, { params: this.setHttpParams({}) });
  }

  monitoringPermission() {
    return this.httpGetWithCache<IMonitoringPermission>('/api/svc/onv-monitoring/monitoring_permission');
  }

  listOnvosPollutants<T>(params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<T>>('/api/svc/onv/tmp_json_onvs_pollutants', params);
  }

  listOnvosEmissionMass<T>(params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<T>>('/api/svc/onv/onv_emission_mass', params);
  }

  environmentalImpactReports(onv_id, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IEnvironmentalImpact>>(`/api/svc/onv/${onv_id}/environmental_impact_reports`, params);
  }

  listOnvosCheckLists(params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<any>>('/api/svc/check-lists-ksv/v2/check_list_ksv', params);
  }

  listOnvosCheckListsSelf(params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<any>>('/api/svc/knd/v2/check_list', params);
  }

  fetchOnvosEmissionObjectInfo(serviceRequestId: number) {
    return this.httpGetWithCache<IEmissionObject>(`/api/svc/onv/request/${serviceRequestId}/emission_object`);
  }

  fetchPrevServiceRequestId(serviceRequestId: number) {
    return this.httpGetWithCache<string>(`/api/svc/onv/prev_service_request_id/${serviceRequestId}`);
  }

  fetchOnvosEmissionObjectInfoByObjectId(id: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IEmissionObject>(`/api/svc/onv/${id}/actual_request_v2/emission_object`, params);
  }

  // Возвращает отходы источников отходов по заявке ОНВОС
  listOnvosRequestWasteFacts(id: number, wasteDisposalFacilityId: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IWasteFact>>(
      // eslint-disable-next-line max-len
      `/api/svc/onv/${id}/actual_request_v2/emission_object/waste_disposal_facilities/${wasteDisposalFacilityId}/onvos_request_waste_facts`,
      params,
    );
  }

  listOnvosRequestProcessingFacts(id: number, processingFacilityId: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IProcessingFact>>(
      // eslint-disable-next-line max-len
      `/api/svc/onv/${id}/actual_request_v2/emission_object/processing_facilities/${processingFacilityId}/facts`,
      params,
    );
  }

  listOnvosRequestProcessingDispatches(id: number, processingFacilityId: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IProcessingFact>>(
      // eslint-disable-next-line max-len
      `/api/svc/onv/${id}/actual_request_v2/emission_object/processing_facilities/${processingFacilityId}/dispatches`,
      params,
    );
  }

  listOnvosRequestUtilizationFacts(id: number, utilizationFacilityId: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IUtilizationFact>>(
      // eslint-disable-next-line max-len
      `/api/svc/onv/${id}/actual_request_v2/emission_object/utilization_facilities/${utilizationFacilityId}/facts`,
      params,
    );
  }

  listOnvosRequestUtilizationDispatches(id: number, utilizationFacilityId: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<IUtilizationFact>>(
      // eslint-disable-next-line max-len
      `/api/svc/onv/${id}/actual_request_v2/emission_object/utilization_facilities/${utilizationFacilityId}/dispatches`,
      params,
    );
  }

  listOnvosRequestNeutralizeFacts(id: number, neutralizeFacilityId: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<INeutralizeFact>>(
      // eslint-disable-next-line max-len
      `/api/svc/onv/${id}/actual_request_v2/emission_object/neutralize_facilities/${neutralizeFacilityId}/facts`,
      params,
    );
  }

  listOnvosRequestNeutralizeDispatches(id: number, neutralizeFacilityId: number, params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<INeutralizeFact>>(
      // eslint-disable-next-line max-len
      `/api/svc/onv/${id}/actual_request_v2/emission_object/neutralize_facilities/${neutralizeFacilityId}/dispatches`,
      params,
    );
  }

  // Критерии расчёта категории риска
  listOnvosCategoryCalcCriteries(onvosId: number): Observable<IProfileDataInstance[]> {
    return this.httpGetWithCache<IProfileDataInstance[]>(`/api/svc/onv/v2/onvos_risk_category_calc_criteria/${onvosId}`);
  }
  updateCategoryCalcCriteria(onvosId: number, body: any): Observable<any> {
    return this.http.patch(`/api/svc/onv/onvos_risk_category_calc_criteria/${onvosId}`, body);
  }

  // Риски Объекта НВОС
  listOnvosRiskByOnvosId(onvosId: number, params: RpnHttpParams = {}): Observable<IPagination<IOnvosRiskByDirection>> {
    return this.httpGetWithCache<IPagination<IOnvosRiskByDirection>>(`/api/svc/onv/risk-category/${onvosId}`, params);
  }

  listFgenRisksByOnvosId(onvosId: number, params: RpnHttpParams = {}): Observable<IOnvosRiskByDirection> {
    return this.httpGetWithCache<IOnvosRiskByDirection>(`/api/svc/onv/fgen-risks/${onvosId}`, params);
  }

  updateOnvosRisk(onvosId: number, body: any): Observable<any> {
    return this.http.patch(`/api/svc/onv/risk-category/${onvosId}`, body);
  }

  // Расширенный профиль
  listExtendedProfiles(onvosId: number): Observable<IProfileDataInstance[]> {
    return this.httpGetWithCache<IProfileDataInstance[]>(`/api/svc/onv/v2/onvos_extended_profile/${onvosId}`);
  }
  updateExtendedProfile(onvosId: number, body: any) {
    return this.http.patch(`/api/svc/onv/onvos_extended_profile/${onvosId}`, body);
  }

  // Возвращает загрязняющие вещества стационарных источников выбросов по заявке ОНВОС
  listRequestEmissionsStationarySourceFacts(id: number, emissionStationarySourceId: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<IStationarySourceFact>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/emissions_stationary_sources/` +
        `${emissionStationarySourceId}/emissions_stationary_source_facts`,
      params,
    );
  }

  // Возвращает загрязняющие вещества стационарных источников сбросов по заявке ОНВОС
  listRequestEmissionsDischargeSourceFacts(id: number, dischargeSourceId: number, params: RpnHttpParams) {
    return this.httpGetWithCache<IPagination<IDischargeSourceFact>>(
      `/api/svc/onv/${id}/actual_request_v2/emission_object/discharges_sources/` +
        `${dischargeSourceId}/onvos_request_discharges_source_facts`,
      params,
    );
  }

  listPNvosOnvosDocuments(
    declarationId: string,
    declarationType: keyof typeof EPnvosModelToQueryType,
    onvosId: string,
    sectionName: string,
    oktmo: string,
  ): Observable<any[]> {
    const queryType = EPnvosModelToQueryType[declarationType];

    if (queryType) {
      return this.httpGetWithCache<any>(
        `/api/svc/payment-nvos/v2/${queryType}/${declarationId}/section/${sectionName}/onv/${onvosId}/permissions`,
        { initParams: { oktmo } },
      );
    }

    return of([]);
  }

  listPNvosDocumentSources(sectionName: string, documentId: string, oktmo: string): Observable<any[]> {
    return this.httpGetWithCache<any>(`/api/svc/payment-nvos/v2/section/${sectionName}/permissions/${documentId}/sources`, {
      initParams: { oktmo },
    });
  }

  listPNvosSourcePollutants(sectionName: string, sourceId: string, params: RpnHttpParams = {}): Observable<any[]> {
    return this.httpGetWithCache<any>(`/api/svc/payment-nvos/v2/section/${sectionName}/source/${sourceId}/pollutants`, params);
  }

  listPNvosOnvosCorrections(
    declarationId: string,
    declarationType: keyof typeof EPnvosModelToQueryType,
    onvosId: string,
    sectionName: string,
    params: RpnHttpParams = {},
  ): Observable<IPagination<any>> {
    const queryType = EPnvosModelToQueryType[declarationType];

    if (queryType) {
      return this.httpGetWithCache<any>(
        `/api/svc/payment-nvos/v2/${queryType}/${declarationId}/section/${sectionName}/onv/${onvosId}/adjustments`,
        params,
      );
    }

    return of({
      data: [],
      total: 0,
      current_page: 1,
      last_page: 1,
      per_page: 0,
    });
  }

  listPNvosWastes(
    declarationId: string,
    declarationType: keyof typeof EPnvosModelToQueryType,
    onvosId: string,
    sectionName: string,
    oktmo: string,
  ): Observable<any[]> {
    const queryType = EPnvosModelToQueryType[declarationType];

    if (queryType) {
      return this.httpGetWithCache<any>(
        `/api/svc/payment-nvos/v2/${queryType}/${declarationId}/section/${sectionName}/onv/${onvosId}/wastes`,
        { initParams: { oktmo } },
      );
    }

    return of([]);
  }

  listPNvosWasteDocuments(sectionName: string, wasteid: string): Observable<any[]> {
    return this.httpGetWithCache<any>(`/api/svc/payment-nvos/v2/section/${sectionName}/waste/${wasteid}/permissions`);
  }

  listPNvosFkkosForDocument(sectionName: string, documentId: string, params: RpnHttpParams = {}): Observable<any[]> {
    return this.httpGetWithCache<any>(`/api/svc/payment-nvos/v2/section/${sectionName}/permission/${documentId}/fkkos`, params);
  }

  listPNvosCorrectionsForWaste(sectionName: string, wasteId: string, params: RpnHttpParams = {}): Observable<any[]> {
    return this.httpGetWithCache<any>(`/api/svc/payment-nvos/v2/section/${sectionName}/waste/${wasteId}/adjustments`, params);
  }

  fetchSmevResponse(uid: string) {
    return this.httpGetWithCache<{ data: SmevRequest }>(`/api/svc/smev3/registry/${uid}`);
  }

  fetchSmev4Response(uid: string) {
    return this.httpGetWithCache<{ data: Smev4Request }>(`/api/svc/smev4/registry/${uid}`);
  }

  fetchSmev4ArchiveResponse(uid: string) {
    return this.httpGetWithCache<{ data: Smev4Request }>(`/api/svc/smev4/archive/${uid}`);
  }

  fetchSmevArchiveResponse(uid: string) {
    return this.httpGetWithCache<{ data: SmevRequest }>(`/api/svc/smev3/archive/${uid}`);
  }

  sendSmevRequest(template: string, payload: { [K in ReportParams]: any }, it_system: 'RPRN01' | 'U222902' = 'RPRN01') {
    return this.http.post<SmevResponse>('/api/svc/smev3/registry/', { it_system, template, payload });
  }

  sendSmev4Request(query: string, parameters: { name: ReportParams; value: any }[], limit: any) {
    const body = { query, parameters, limit };
    return this.http.post<SmevResponse>('/api/svc/smev4/registry/', body);
  }

  sendSmevExclusion(templateId: string, body: { [K in ReportParams]: any }) {
    return this.http.post<SmevResponse>(`/api/svc/smev/exclusion/${templateId}`, body);
  }

  fetchMapItemsOpen(mapView: IMapView, maxClusteringZoom: number, withGeometry = false) {
    let url = `/api/svc/map/open/${mapView.boundsStr_latlon}/${mapView.zoom}/${maxClusteringZoom}`;

    if (withGeometry) {
      url += '?withGeometry=true';
    }

    return this.http.get<MapItemPoint[]>(url, {
      params: this.setHttpParams({}),
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  listMapItems(mapView: IMapView, params: any, maxClusteringZoom: number, withGeometry = false) {
    let url = `/api/svc/map/${mapView.boundsStr_latlon}/${mapView.zoom}/${maxClusteringZoom}`;
    if (withGeometry) url += '?withGeometry=true';

    return this.http.get<MapItemPoint[]>(url, {
      params: this.setHttpParams(params),
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  fetchMapItemDetails(id: number) {
    return this.http.get<MapItemPoint>(`/api/svc/map/geometry/${id}/`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  fetchMapItemDetailsOpen(id: number) {
    return this.http.get<MapItemPoint>(`/api/svc/map/open/geometry/${id}/`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  listMapNearObjects(radius, lat: number, lng: number, onvId?: number, params: RpnHttpParams = {}) {
    let url = `/api/svc/map/objects/${radius}/${lng}/${lat}`;
    if (onvId) url += `/${onvId}`;

    return this.http.get<IPagination<MapItemPoint>>(url, {
      params: this.setHttpParams(params),
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  errorHandle(error: any) {
    // client-side or server-side error
    const errorMessage =
      error.error instanceof ErrorEvent ? error.error.message : `Ошибка: статус-${error.status}\nСообщение от сервера: ${error.message}`;

    console.warn(errorMessage);
    return throwError(errorMessage);
  }

  fetchStatusLog(id: number, params: RpnHttpParams): Observable<any> {
    return this.httpGetWithCache<any[]>(`/api/v2/requests/${id}/status-logs`, params);
  }

  fetchChangeLog(id: number, params: RpnHttpParams = {}): Observable<any> {
    return this.httpGetWithCache<any[]>(`/api/v2/requests/${id}/logs`, params);
  }

  fetchReestrDiffIds<T>(requestId: number, params: RpnHttpParams): Observable<IPagination<T>> {
    return requestId ? this.httpGetWithCache<IPagination<T>>(`/api/svc/reestr/v2/diff/${requestId}`, params) : of(null);
  }

  fetchReestrDiff<T>(requestId: number, diffId: number): Observable<{ [key: string]: any }> {
    return requestId && diffId ? this.httpGetWithCache<IPagination<T>>(`/api/svc/reestr/v2/diff/${requestId}/${diffId}`) : of({});
  }

  getExportResults<T>(params: RpnHttpParams): Observable<IPagination<T>> {
    return this.httpGetWithCache<IPagination<T>>('/api/svc/xls-export/results', params);
  }

  createPrintPreviewJob(templateId: string, id: number, with_pep?: boolean): Observable<any> {
    return this.http.post(`/api/svc/printer/${templateId}/${id}${with_pep ? '?with_pep=true' : ''}`, {});
  }

  fetchPrintResult(templateId: string, id: number): Observable<IPrintResponse> {
    return this.http.get<IPrintResponse>(`/api/svc/printer/${templateId}/${id}/result`);
  }

  fetchSignedPrintPreview(templateId: string, id: number): Observable<IPrintResponse> {
    return this.http.get<IPrintResponse>(`/api/svc/printer/${templateId}/${id}/result/signed`);
  }

  downloadPreviewBlob(url: string, type: EDownloadBlobFileType): Observable<{ blob: Blob; blobUrl: string }> {
    return this.httpGetWithCache(url, null, { responseType: 'blob' }).pipe(
      map((resp) => {
        const blob = new Blob([resp], { type });
        return { blob, blobUrl: URL.createObjectURL(blob) };
      }),
    );
  }

  sentSignedResponse(templateId: string, id: number, printId: number, body: any, status_transition_id?: number): Observable<any> {
    const params = status_transition_id ? { params: { status_transition_id } } : {};
    return this.http.post(`/api/svc/printer/${templateId}/${id}/results/${printId}/sign`, body, { params: this.setHttpParams(params) });
  }

  sentSignedAttachment(id: number, md5: string, body: any): Observable<any> {
    return this.http.post(`/api/svc/attachment/sign/${id}/${md5}`, body);
  }

  multiSign(service_request_id: string | number, model_id: string | number, template_slug: string, body: any): Observable<any> {
    return this.http.post(`/api/svc/knd/sign/${service_request_id}/${model_id}/${template_slug}/sign`, body);
  }

  postByUrl<T>(url: string, body: any = {}, options = {}): Observable<T> {
    return this.http.post<T>(url, body, options);
  }

  postByUrlWithParams<T>(url: string, body: any = {}, params: RpnHttpParams, options = {}): Observable<T> {
    return this.http.post<T>(url, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: this.setHttpParams(params),
      ...options,
    });
  }

  patchByUrl<T>(url: string, body: any = {}, options = {}): Observable<T> {
    return this.http.patch<T>(url, body, options);
  }

  putByUrl<T>(url: string, body: any = {}, options = {}): Observable<T> {
    return this.http.put<T>(url, body, options);
  }

  deleteByUrl<T>(url: string, options = {}): Observable<T> {
    return this.http.delete<T>(url, options);
  }

  request<T>(req): Observable<HttpEvent<T>> {
    return this.http.request(req);
  }

  fetchByUrl<T>(url: string, params: RpnHttpParams = {}): Observable<T> {
    return this.http.get<T>(url, { params: this.setHttpParams(params) });
  }

  fetchByUrlWithParams<T>(url: string, params: RpnHttpParams, options = {}): Observable<T> {
    return this.http.get<T>(url, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: this.setHttpParams(params),
      ...options,
    });
  }

  get<T>(url: string, options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(url, options);
  }

  post<T>(url: string, body: any, options?: IHttpOptions): Observable<T> {
    return this.http.post<T>(url, body, options);
  }

  patch<T>(url: string, body: any, options?: IHttpOptions): Observable<T> {
    return this.http.patch<T>(url, body, options);
  }

  put<T>(url: string, body: any, options?: IHttpOptions): Observable<T> {
    return this.http.put<T>(url, body, options);
  }

  delete<T>(url: string, options?: IHttpOptions): Observable<T> {
    return this.http.delete<T>(url, options);
  }

  // ONV transfer territory org
  transferOnv(id: number, body: any): Observable<any> {
    return this.http.patch(`/api/svc/onv/init-ter-org/${id}`, body);
  }

  approveOnvTransfer(id: number): Observable<any> {
    return this.http.patch(`/api/svc/onv/approve-ter-org/${id}`, {});
  }

  rejectOnvTransfer(id: number): Observable<any> {
    return this.http.patch(`/api/svc/onv/reject-ter-org/${id}`, {});
  }

  importPlaceFkkos(type: TableType, id: number, place_id: number, body: any) {
    return this.http.post(`/api/svc/license-activity-waste/v2/${type}/${id}/places/${place_id}/import_place_activities`, body);
  }

  importGroroFkkos(type: TableType, id: number, body: any) {
    return this.http.post(`/api/svc/groro/v2/${type}/${id}/statement/oro_object/import_fkkos`, body);
  }

  deletedAllGroroFkkos(type: TableType, id: number, body: any = {}) {
    return this.http.post(`/api/svc/groro/v2/${type}/${id}/delete_all_fkkos`, body);
  }

  // license registry
  listLicenseRegistry(params: RpnHttpParams = {}, isKSV: boolean = true): Observable<IPagination<any>> {
    const KSV_URL = '/api/svc/license-activity-waste/registry';
    const URL = '/api/svc/license-activity-waste/registry/open';

    return this.http.get<IPagination<any>>(isKSV ? KSV_URL : URL, { params: this.setHttpParams(params) });
  }

  fetchLicenseById(id: number, params: RpnHttpParams = {}): Observable<any> {
    return this.http.get<any>(`/api/svc/license-activity-waste/registry/${id}`, { params: this.setHttpParams(params) });
  }

  listPlacesForLicense(id: number, params: RpnHttpParams = {}): Observable<IPagination<any>> {
    return this.http.get<IPagination<any>>(`/api/svc/license-activity-waste/registry/${id}/places`, {
      params: this.setHttpParams(params),
    });
  }

  listPlaceActivitiesForLicense(id: number, place_id: number, params: RpnHttpParams = {}): Observable<IPagination<any>> {
    return this.http.get<IPagination<any>>(`/api/svc/license-activity-waste/registry/${id}/places/${place_id}/activities`, {
      params: this.setHttpParams(params),
    });
  }

  listLicenseHistory(id: number, params: RpnHttpParams = {}): Observable<IPagination<any>> {
    return this.http.get<IPagination<any>>(`/api/svc/license-activity-waste/registry/${id}/history`, {
      params: this.setHttpParams(params),
    });
  }

  listLicensePlaceHistory(id: number, place_id: number, params: RpnHttpParams = {}): Observable<IPagination<any>> {
    return this.http.get<IPagination<any>>(`/api/svc/license-activity-waste/registry/${id}/places/${place_id}/history`, {
      params: this.setHttpParams(params),
    });
  }

  listOpenOnvosObjectByNumberList(onvNumber: string): Observable<IPagination<IOnvosN>> {
    return this.http.get<IPagination<IOnvosN>>(`/api/svc/onv/open/list-number/${onvNumber}`);
  }

  fetchGroro(params: RpnHttpParams = {}) {
    return this.http.get<IPagination<any>>('/api/svc/groro/v2/groro_entry', { params: this.setHttpParams(params) });
  }

  fetchGroroEntryById(groroId: number, params: RpnHttpParams = {}): Observable<IGroroRegistry> {
    return this.http.get<IGroroRegistry>(`/api/svc/groro/v2/groro_entry/${groroId}`, { params: this.setHttpParams(params) });
  }

  listGroroRequests(groro_id: number, params: RpnHttpParams = {}): Observable<IPagination<IGroroRequest>> {
    return this.http.get<IPagination<IGroroRequest>>(`/api/svc/groro/requests_by_oro_object/${groro_id}`, {
      params: this.setHttpParams(params),
    });
  }

  fetchAddWorkDays(date: string, days: number): Observable<{ date: string }> {
    return this.http.get<{ date: string }>(`/api/svc/nsi/calendar/to_work_days/${date}/${days}`);
  }

  listControlledEntitiesRegistry(params: RpnHttpParams = {}): Observable<IPagination<any>> {
    return this.http.get<IPagination<any>>('/api/svc/controlled-entities/registry', {
      params: this.setHttpParams(params),
    });
  }

  createControlledEntity(data: any = {}): Observable<any> {
    return this.http.post('/api/svc/controlled-entities/registry', data);
  }

  patchControlledEntityById(id: number, data: any = {}): Observable<any> {
    return this.http.patch<any>(`/api/svc/controlled-entities/registry/${id}`, data);
  }

  listControlledEntityById(id: number, params: RpnHttpParams = {}): Observable<any> {
    return this.http.get<any>(`/api/svc/controlled-entities/registry/${id}`, { params: this.setHttpParams(params) });
  }

  listControlledEntityObjects(id: number, params: RpnHttpParams = {}): Observable<IPagination<any>> {
    return this.http.get<IPagination<any>>(`/api/svc/controlled-entities/registry/${id}/controlled_objects`, {
      params: this.setHttpParams(params),
    });
  }

  listControlledEntityReports(id: number, params: RpnHttpParams = {}): Observable<IPagination<any>> {
    return this.http.get<IPagination<any>>(`/api/svc/controlled-entities/registry/${id}/reports`, {
      params: this.setHttpParams(params),
    });
  }

  listControlledEntityChecks(id: number, params: RpnHttpParams = {}): Observable<IPagination<any>> {
    return this.http.get<IPagination<any>>('/api/svc/nsi/erp_inspections', {
      params: this.setHttpParams(params),
    });
  }

  changeLicensePlaceActivities(id: number, type: TableType, placeId: string, data: any = {}): Observable<any> {
    return this.http.post<any>(`/api/svc/license-activity-waste/v2/${type}/${id}/places/${placeId}/activity`, data);
  }

  fetchTreeByRequestId(id: number): Observable<any> {
    return this.http.get<any>(`/api/tree/${id}`);
  }

  sendNotifyToObjectOwner(inspection_id: number) {
    return this.http.get(`/api/svc/knd/knd_result_notify/${inspection_id}`);
  }

  // eek
  listEekCertificateImportIssue(params: RpnHttpParams = {}): Observable<IPagination<any>> {
    return this.http.get<IPagination<any>>('/api/svc/eek/v2/eek_certificate_import_issue', { params: this.setHttpParams(params) });
  }

  // payments
  deletePaymentsManagementPaymentById(id: number) {
    return this.http.delete<any>(`/api/payments-management/payment/${id}`);
  }

  copyFacts(request_type: ERequestExtended.onv_request_inclusion_v2 | ERequestExtended.onv_request_edit_v2, source_id: number) {
    let type = '';

    if (request_type === ERequestExtended.onv_request_inclusion_v2) {
      type = 'inclusion_v2';
    } else if (request_type === ERequestExtended.onv_request_edit_v2) {
      type = 'edit_v2';
    } else if (request_type === ERequestExtended.onv_request_adjust_v2) {
      type = 'adjust_v2';
    }

    return this.http.post(`/api/svc/onv/${type}/disposal_storage_facts/${source_id}`, {});
  }

  updateOkopfFields(request_type: string, service_request_id: number, params: OkopfOrg) {
    return this.http.post(`/api/svc/onv/${request_type}/${service_request_id}/org_okopf`, params);
  }

  monitoringMeasurements(number: string, params: RpnHttpParams = {}) {
    return this.http.get<IPagination<any>>(`/api/svc/onv-monitoring/onvs/${number}/measurements`, {
      params: this.setHttpParams(params),
    });
  }

  onvosReports(onv_id: number, params) {
    return this.http.get<IPagination<any>>(`/api/svc/onv/${onv_id}/reports`, {
      params: this.setHttpParams(params),
    });
  }

  employee(params = {}) {
    return this.http.get<any>('/api/svc/nsi/v2/rpn-structure/employee', {
      params: this.setHttpParams(params),
    });
  }

  dashboards(params: RpnHttpParams = {}) {
    return this.httpGetWithCache<IPagination<any>>(
      '/svc/dataexport/api/dashboards',
      params,
    );
  }

  approveRoutesBulkUpdate(onv_request_id: number, body) {
    return this.http.patch(`/api/approval_routes/bulk_update/${onv_request_id}`, body);
  }

  knmList(onv_id: number) {
    return this.http.get<IPagination<any>>(`/api/svc/onv/v2/onvos_erknm_requests?onv_id=${onv_id}`)
  }

  elkLogs(onv_request_id: number, params: RpnHttpParams) {
    return this.httpGetWithCache(`/api/v2/requests/${onv_request_id}/elk-logs`, params)
  }

  attorneyList() {
    return this.httpGetWithCache('/svc/pass/api/attorney_documents', {})
  }

  erknmRequest(body) {
    return this.http.post('/api/svc/onv/v2/onvos_erknm_requests', body)
  }

  integrationPtoToUonvosWithKnmPtk(params: RpnHttpParams) {
    return this.httpGetWithCache('/api/svc/knd/v2/integration_pto_to_uonvos_with_knm_ptk', {
      params: this.setHttpParams(params),
    })
  }

  inboxMessages(params: RpnHttpParams) {
    return this.http.get<IPagination<INotification>>('/svc/multisender/api/inbox', {
      params: this.setHttpParams(params),
    })
  }

  sentMessages(params: RpnHttpParams) {
    return this.http.get<IPagination<INotification>>('/svc/multisender/api/sent', {
      params: this.setHttpParams(params),
    })
  }

  readMessage(uuid: string) {
    return this.http.get(`/svc/multisender/api/inbox/read?uuids[]=${uuid}`)
  }

  messagesByOnvId(onv_id: number) {
    return this.http.get<IPagination<any>>(`/svc/multisender/api/all?tags[]=onv_id:${onv_id}`);
  }

  eventTypes() {
    return this.http.get<IPagination<MessageTemplate>>('/svc/multisender/api/event-types');
  }

  messagesSelectors(params: RpnHttpParams) {
    return this.http.get<IPagination<any>>('/svc/multisender/api/selectors', {
      params: this.setHttpParams(params),
    });
  }

  allMessages(params: RpnHttpParams) {
    return this.http.get<IPagination<any>>('/svc/multisender/api/all', {
      params: this.setHttpParams(params),
    });
  }

  multisenderSettings(params: RpnHttpParams) {
    return this.http.get<{data: MessagesSettingsData}>('/svc/multisender/api/settings', {
      params: this.setHttpParams(params),
    });
  }

  multisenderSaveSettings(body) {
    return this.http.post('/svc/multisender/api/settings', body);
  }

  monitoringTrain(body) {
    return this.http.post<any>('/api/svc/onv-monitoring/ai/train', body);
  }

  monitoringPredict(body) {
    return this.http.post<any>('/api/svc/onv-monitoring/ai/predict', body);
  }

  saveAttorneyDocument(onv_request_id, emchd_number) {
    return this.http.post(`/api/v2/attorney_documents/${onv_request_id}`, {emchd_number})
  }

  getAttorneyDocument(onv_request_id) {
    return this.http.get<any>(`/api/v2/attorney_documents/${onv_request_id}`)
  }

  features() {
    return this.http.get('/api/svc/onv/features');
  }

  mapFeatures() {
    return this.http.get('/api/svc/map/features');
  }

  monitoringCalculations(_id, _params: RpnHttpParams) {
    return of({data: [123,456,789,142,1251225], per_page: null, current_page: null});
  }
}
