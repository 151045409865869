import { Injectable } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { IBreadcrumb } from 'src/app/providers/_interfaces/common';
import { LabelService } from 'src/app/providers/_services/label.service';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  constructor(
    private store: Store,
    private router: Router,
    private label: LabelService,
  ) {}

  buildBreadcrumbs(): Array<IBreadcrumb> {
    const breadcrumbs: IBreadcrumb[] = [];
    const routes: ActivatedRoute[] = [];

    let cursor: ActivatedRoute = this.router.routerState.root;

    while (cursor) {
      routes.push(cursor);
      cursor = cursor.firstChild;
    }

    let routerLink = '/';
    let index = 0;

    for (const route of routes) {
      if (!route || !route.routeConfig) {
        continue;
      }

      const { path } = (route.routeConfig || {}) as Route;

      if (!path) {
        continue;
      }

      const routerLinkParams = route.snapshot.params;

      let url = `${path}/`;

      for (const key of Object.keys(routerLinkParams)) {
        const val = routerLinkParams[key];
        const regexp = new RegExp(`\\:${key}`);

        url = url.replace(regexp, val);
      }

      routerLink += url;

      breadcrumbs.push({
        routerLink,
        index,
        labelData: this.label.get(route, 'breadcrumbs'),
      });

      index++;
    }

    return breadcrumbs;
  }
}
