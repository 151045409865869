import { get } from 'lodash';
import { BRModFunctionType, TransformFunctionType, MapFunctionType } from 'src/app/providers/_dictionary/dictionary';
import { OnvosRequestType } from 'src/app/providers/_enum';
import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';
import { IErknmStatuses } from 'src/app/providers/_interfaces/knd-inspections';
import { getStatuses } from 'src/app/providers/_utils/statuses';

export const onv_code_list_brm: BRModFunctionType = (params, url) => {
  const search =
    get(params, 'filters.search.value') ||
    get(params, 'filters.search') ||
    get(params, 'filters.number.value') ||
    get(params, 'filters.number');

  if (search) {
    url = `${url}/${search}`;
    delete params.filters.search;
    delete params.filters.number;
  } else {
    return { params: null, url: null };
  }

  return { params, url };
};

export const groro_code_list_brm: BRModFunctionType = (params, url) => {
  const search =
    get(params, 'filters.search.value') ||
    get(params, 'filters.search') ||
    get(params, 'filters.groro_number.value') ||
    get(params, 'filters.groro_number');

  if (search) {
    url = `${url}/${search}`;
    delete params.filters.search;
    delete params.filters.groro_number;
  } else {
    return { params: null, url: null };
  }

  return { params, url };
};

export const type_of_income_for_act_create_transform: TransformFunctionType = <C>(dict: C[]) =>
  dict.filter((i: any) => ['charge-nvos', 'charge-eco'].indexOf(i.type) > -1);

export const onv_request_type_map: MapFunctionType = <C>(dict: C[]) => {
  let item;
  let cursor = 0;
  const items: any[] = [];
  const itemsByShortName = {};

  while (dict[cursor]) {
    item = dict[cursor];

    if (!OnvosRequestType[item.id as string]) {
      cursor++;
      continue;
    }

    if (itemsByShortName[item.short_name]) {
      itemsByShortName[item.short_name].id += `,${item.id}`;
    } else {
      const newItem = { name: item.short_name, id: item.id };

      itemsByShortName[item.short_name] = newItem;
      items.push(newItem);
    }

    cursor++;
  }

  return items;
};

export const nsi_erknm_statuses_filter_map: MapFunctionType = (dict: any[]) => {
  return dict.map((i: IErknmStatuses) => ({ name: i.name, id: '' + i.status_codes }));
};

export const eek_state_services_map: MapFunctionType = (dict: IDictionaryItem[]) => {
  return dict
    .filter((i) => ['112', '113', '114'].indexOf(i.id + '') >= 0)
    .map((i: any) => ({ name: i.short_name, id: i.id }))
    .sort((a, b) => +a.id - +b.id);
};

export const onv_request_statuses_map: MapFunctionType = (dict: IDictionaryItem[]) => {
  return getStatuses(
    dict,
    Object.keys(OnvosRequestType)
      .map((i) => parseInt(i))
      .filter((i) => !isNaN(i)),
    (status) => status.name,
    ['новый'],
  );
};

export const groro_statuses_map: MapFunctionType = (dict: IDictionaryItem[]) => {
  return getStatuses(
    dict,
    [
      101, // groro_inclusion
      102, // groro_correction
      103, // groro_exclusion
      105, // groro_update
    ],
    (status) => status.name,
  );
};

export const waste_class_assignment_statuses_map = (dict: IDictionaryItem[]) => {
  return getStatuses(
    dict,
    [
      154, // waste_class_assignment
    ],
    (status) => status.name,
  );
};

export const eek_petition_statuses_map = (dict: IDictionaryItem[]) => {
  return getStatuses(dict, [112, 113, 114], (status) => status.name);
};
