import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OnvosRequestService } from 'src/app/modules/details-edit-mode/onv-requests/onv-request.service';
import { IEssenceTreeMetaHash, ILabelGeneratorContext, ILabel, IRouteCustomData } from 'src/app/providers/_interfaces/common';
import { TreeHelperService } from 'src/app/providers/_services/tree-helper.service';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  public extraLabelDataForRoute = new BehaviorSubject<Map<ActivatedRoute, Observable<any>>>(new Map());

  constructor(
    private treeHelperService: TreeHelperService,
    private onvosRequestService: OnvosRequestService,
  ) {}

  setExtraData(data: Observable<any>, route: ActivatedRoute) {
    this.extraLabelDataForRoute.value.set(route, data);
    this.extraLabelDataForRoute.next(this.extraLabelDataForRoute.value);
  }

  getLabelForPath(meta: IEssenceTreeMetaHash, path: string, parentPath: string, identity: string) {
    const a = meta[path]?.find(x => x.identity.includes(identity)) || meta[parentPath]?.find(x => x.identity.includes(identity));
    const node = a || meta[path]?.[0] || meta[parentPath]?.[0];
    return node?.name || '';
  }

  geRecursiveParentNode(snapshot): string[] {
    const path: string = snapshot.routeConfig?.path || '';

    const isSkip = path.includes(':');
    const isEnd = isSkip && path.includes('/:id');

    const parentPath = !isEnd && snapshot.parent
      ? (this.geRecursiveParentNode(snapshot.parent) || [])
      : [];

    if (path && !isSkip) parentPath.push(path);

    return parentPath;
  }

  get(route: ActivatedRoute, context?: ILabelGeneratorContext): Observable<ILabel> {
    let path = route.snapshot.routeConfig?.path;
    let parentPath = route.snapshot.parent?.routeConfig?.path;

    if (!path) {
      path = route.snapshot.parent?.routeConfig?.path;
      parentPath = route.snapshot.parent?.parent?.routeConfig?.path;
    }

    if (path && (path[0] === ':')) {
      path = route.snapshot.params[path.slice(1)] || route.parent.snapshot.params[path.slice(1)];
    }

    const identity = this.geRecursiveParentNode(route.snapshot).join('.');

    return combineLatest([
      this.treeHelperService.meta$,
      this.extraLabelDataForRoute.pipe(
        map((val) => (val ? val.get(route.component ? route : route.firstChild) || null : null)),
        switchMap((v) => (v ? v : of(null))),
      ),
    ]).pipe(
      map((data) => {
        const { 0: meta, 1: extraData } = data;

        const label = this.getLabelForPath(meta, path, parentPath, identity) || '';

        const routeCustomData = route && route.routeConfig && route.routeConfig.data ? route.routeConfig.data : ({} as IRouteCustomData);
        const { labelGenerator } = routeCustomData;

        if (typeof labelGenerator === 'function') {
          return { ...{ label, tooltip: '' }, ...labelGenerator(route, context, extraData, this.onvosRequestService.version) };
        }

        return { label };
      }),
    );
  }
}
