<div class="flex-center rpn-maintenance flex-column">
  <div *ngIf="timer_min + timer_sec > 0">
    <div class="d-flex justify-content-center">
      <div class="rpn-maintenance_code">
        {{maintenance.throttle_text}}
      </div>
    </div>

    <hr>

    <div class="d-flex align-items-center justify-content-center flex-column">
      <div class="rpn-maintenance_message">
        Вы сможете {{maintenance.throttle_limit_percent === 100 ? 'попытаться' : ''}} зайти через
      </div>
      <div class="rpn-maintenance_timer">
        {{('00' + timer_min).slice(-2)}}:{{('00' + timer_sec).slice(-2)}}
      </div>
    </div>
  </div>

  <div class="d-flex" *ngIf="timer_min + timer_sec <= 0">
    <a [routerLink]="['/']" (click)="returnToMain()" class="rpn-link mr-3">
      На главную
    </a>
    <a [routerLink]="[currentUrl || previousUrl || '/']" class="rpn-link">
      Назад
    </a>
  </div>
</div>

<div class="rpn-maintenance_merci">
  Приносим извинения за доставленные неудобства.
</div>
