import { NgIf, NgClass, NgFor, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroupDirective, FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { uniq, has } from 'lodash';
import {
  UploadOutput,
  MDBModalRef,
  ModalDirective,
  IconsModule,
  TooltipModule,
  BadgeModule,
  ButtonsModule,
  FileInputModule,
  InputsModule,
  ModalModule,
} from 'ng-uikit-pro-standard';
import { BehaviorSubject, Observable, of, combineLatest, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap, delay, filter, startWith, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { FormFieldValidationComponent } from 'src/app/components/form-field-validation/form-field-validation.component';
import { ModalAttachmentSignProcessComponent } from 'src/app/components/modal-attachment-sign-process/modal-attachment-sign-process.component';
import { ModalAttachmentSignResultComponent } from 'src/app/components/modal-attachment-sign-result/modal-attachment-sign-result.component';
import { AppIconComponent } from 'src/app/modules/app-icon/app-icon.component';
import { inOutAnimation } from 'src/app/providers/_animations/in-out-animation';
import { AppFormFieldClass } from 'src/app/providers/_classes/app.form.field.class';
import { CModalOptions } from 'src/app/providers/_const/modal-options.const';
import { ETypeOfModal, UploadStatus } from 'src/app/providers/_enum';
import { IFileResponse, UploadFileUI } from 'src/app/providers/_interfaces/common';
import { AuthenticationService } from 'src/app/providers/_services/authentication.service';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';
import { FileUploadService } from 'src/app/providers/_services/file-upload.service';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { deepClone } from 'src/app/providers/_utils/utils';


function getFileExtension(fileName: string): string {
  const index = fileName.lastIndexOf('.');

  if (index === -1) {
    return null;
  }
  const res = fileName?.slice(index);
  return res ? res.toLowerCase() : fileName;
}

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  providers: AppFormFieldClass.providers(UploadFileComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [inOutAnimation],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    IconsModule,
    TooltipModule,
    BadgeModule,
    FormsModule,
    NgFor,
    AppIconComponent,
    ButtonsModule,
    NgTemplateOutlet,
    FileInputModule,
    InputsModule,
    FormFieldValidationComponent,
    ModalModule,
    AsyncPipe,
  ],
})
export class UploadFileComponent extends AppFormFieldClass<number[] | number> implements OnInit, OnChanges, OnDestroy {
  @Input() isSingleFile: boolean;
  @Input() showPreview: boolean;
  @Input() isLoaded = true;
  @Input() tooltip = 'Размер одного файла ограничен 50 MB. Файлы большего размера рекомендуется разбить на части.';

  @Input() showSigns = false;
  @Input() canSign = false;

  @Input() isEnabledSignButton: boolean;

  @Input() accept = '.jpeg,.jpg,.png,.bmp,.webp,.tiff,.gif,.docx,.doc,.pdf,.xml,.json,.rtf,.txt,.odt,.xls,.xlsx,.zip,.rar,.sig,.p7s,.html';
  @Input() uploadUrl = '/api/file/upload';
  @Input() uploadFileText = 'Загрузить файл';
  @Input() altButtonForUpload = false;
  @Input() showDownloadButton = true;
  @Input() customOpenPdfFunction;
  @Input() canUploadByClick = true;
  @Input() needWatchFiles = false;
  @Input() showSignedBadge = false;
  @Input() isCheckExtension = true;
  @Input() massUploadFile: (files$: BehaviorSubject<UploadFileUI[]>, formControlName: string) => any;

  @Output() fileSigned = new EventEmitter();
  @Output() removeCompleted = new EventEmitter<UploadFileUI[]>();
  @Output() uploadCompleted = new EventEmitter<UploadFileUI>();
  @Output() uploadStarted = new EventEmitter<UploadFileUI>();
  @Output() hasFiles = new EventEmitter<boolean>();

  @ViewChild('previewModal', { static: true }) previewModal: ModalDirective;

  private modalOptions = CModalOptions;
  private modalSignProcessRef: MDBModalRef;
  private modalSignResultRef: MDBModalRef;

  atLeastOneSigned$ = new BehaviorSubject(false);
  ETypeOfModal = ETypeOfModal;

  public closeSignProcessModal$: Subject<any> = new Subject();

  public cancel$ = new Subject();
  public files$: BehaviorSubject<UploadFileUI[]> = new BehaviorSubject<UploadFileUI[]>([]);
  public state$: Observable<{ completed: UploadFileUI[]; progress: UploadFileUI[] }>;

  public has = has;
  public photoUrl = '';

  writeValue(filesIds: any): void {
    if (filesIds) {
      const viewValue$ = this.initControl;

      if (viewValue$) {
        this.subscriptions.push(
          viewValue$.valueChanges
            .pipe(
              startWith(viewValue$.value),
              filter((x) => !!x),
            )
            .subscribe((viewValue) => {
              if (viewValue) this.initValue(Array.isArray(viewValue) ? viewValue : [viewValue]);
              this.cdr.markForCheck();
            }),
        );
      }
    } else {
      this.initValue([]);
    }
  }

  private initValue(files: IFileResponse[]): void {
    this.files$.next([]);
    const newFiles = (this.files$.value || []).slice();

    for (const file of files) {
      newFiles.push({
        nativeFile: undefined,
        response: file,
        name: file.name,
        status: UploadStatus.Initialized,
        progress: 100,
      });
    }

    this.files$.next(newFiles);
    this.control.markAsPristine();
  }

  get errors() {
    if (this.formControl.errors) {
      return this.formControl.errors;
    }

    const files = uniq(this.files$.value.map((x) => x && x.response && x.response.id));

    if (this.required && files.length === 0) {
      return { required: true };
    }

    return null;
  }

  get valid() {
    if (this.required) {
      const files = uniq(this.files$.value.map((x) => x && x.response && x.response.id));

      return files.length != 0;
    }

    return this.formControl.valid;
  }

  constructor(
    private fileUploadService: FileUploadService,
    public fcd: FormGroupDirective,
    public cdr: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    public dictionaryService: DictionaryService,
    private svc: SvcRestService,
    public auth: AuthenticationService,
  ) {
    super(fcd, cdr, dictionaryService);
  }

  private filterByStatus(files: UploadFileUI[], status: UploadStatus): UploadFileUI[] {
    return files.filter((v) => v.status === status);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.cancel$.next(true);
    this.isLoaded = true;
  }

  ngOnInit() {
    super.ngOnInit();

    const completed$: Observable<UploadFileUI[]> = this.files$.pipe(
      map((files: UploadFileUI[]) => {
        return files.filter((v) => v.status === UploadStatus.Initialized || v.status === UploadStatus.Completed);
      }),
      delay(0),
      tap((res: UploadFileUI[]) => {
        const findNotInitialized = res.find((f) => f.status !== UploadStatus.Initialized);
        if (findNotInitialized) {
          this.updateData(res);
        }
      }),
    );

    const progress$: Observable<UploadFileUI[]> = this.files$.pipe(map((files) => this.filterByStatus(files, UploadStatus.Started)));

    this.state$ = combineLatest([completed$, progress$]).pipe(map(([completed, progress]) => ({ completed, progress })));

    this.subscriptions.push(
      this.closeSignProcessModal$
        .pipe(
          tap(() => this.modalSignProcessRef.hide()),
          filter((x) => !!x),
          switchMap(({ result }) => {
            if (result) {
              const modalOptions = deepClone(this.modalOptions);

              modalOptions.class = 'modal-dialog--sm';

              this.modalSignResultRef = this.modalService.show(ModalAttachmentSignResultComponent, {
                data: {
                  content: {
                    result: result,
                    hide: () => this.modalSignResultRef.hide(),
                  },
                },
              });

              return of(result);
            }

            return of(null);
          }),
          filter((x) => !!x),
          tap(() => {
            const newFiles = (this.files$.value || []).slice();
            this.files$.next(newFiles);
            this.updateData(newFiles);
            this.propagateTouched(null);
            this.fileSigned.emit();
          }),
        )
        .subscribe(),
    );

    this.watchFiles();
    this.atLeastOneSigned();

    if (typeof this.massUploadFile === 'function') {
      this.massUploadFile(this.files$, this.formControlName);
    }
  }

  private atLeastOneSigned() {
    if (!this.showSignedBadge) {
      return;
    }

    this.subscriptions.push(
      this.state$
        .pipe(
          tap(({ completed }) => {
            if (completed?.[0]?.response?.signs_count > 0) {
              this.atLeastOneSigned$.next(true);
            } else {
              this.atLeastOneSigned$.next(false);
            }
          }),
        )
        .subscribe(),
    );
  }

  private watchFiles() {
    this.subscriptions.push(
      this.files$
        .asObservable()
        .pipe(
          distinctUntilChanged(),
          tap((files) => this.hasFiles.emit(!!files?.length)),
        )
        .subscribe(),
    );
  }

  public get hasActiveUploads(): boolean {
    const isFileUploaded = (file: UploadFileUI): boolean => {
      return file.status === UploadStatus.Initialized || file.status === UploadStatus.Completed;
    };

    return (this.files$.value || []).filter((file) => !isFileUploaded(file)).length > 0;
  }

  public cancelUploads(): void {
    this.cancel$.next(true);

    const newFiles = (this.files$.value || []).filter((file) => file.status !== UploadStatus.Started);

    this.files$.next(newFiles);
    this.updateData(newFiles);
    this.isLoaded = true;
  }

  getFilePlaceholder(file) {
    let placeholder = '';

    if (file.name) {
      placeholder += file.name;
    }

    if (file.response && file.response.formatted_size) {
      placeholder += `(${file.response.formatted_size})`;
    }

    return placeholder;
  }

  uploadFile(event: UploadOutput) {
    if (event?.type === 'addedToQueue') {
      this.isLoaded = false;
      const file = event.file?.nativeFile;

      const fileUI: UploadFileUI = {
        name: file?.name,
        status: UploadStatus.Requested,
        progress: 0,
        response: null,
        nativeFile: file,
      };

      if (this.accept) {
        const validExts = this.accept.split(',').map((v) => v?.toLowerCase());

        if (validExts.indexOf(getFileExtension(file.name)) === -1) {
          return;
        }
      }

      const newFiles = (this.files$.value || []).slice();
      newFiles.push(fileUI);
      this.files$.next(newFiles);
      this.updateData(newFiles);
      this.propagateTouched(null);

      if (file) {
        this.uploadStarted.next(fileUI);

        if (typeof this.massUploadFile === 'function') {
          this.fileUploadService.modByHttpEvent({status: 200, type: HttpEventType.Response}, fileUI);
          this.files$.next(newFiles);
          this.uploadCompleted.next(fileUI);
          this.isLoaded = true;
          this.cdr.detectChanges();
        } else {
          this.subscriptions.push(
            this.fileUploadService
              .uploadFile(file, this.uploadUrl)
              .pipe(
                takeUntil(this.cancel$),
                tap((httpEvent) => {
                  this.fileUploadService.modByHttpEvent(httpEvent, fileUI);
                  this.files$.next(newFiles);

                  if (httpEvent?.type === HttpEventType.Response && [200, 201].indexOf(httpEvent?.status) >= 0) {
                    this.uploadCompleted.next(fileUI);
                    this.isLoaded = true;
                  }
                }),
                catchError(() => of({} as any)),
              )
              .subscribe(),
          );
        }
      }
    }
  }

  removeFile(file: UploadFileUI) {
    const newFiles = (this.files$.value || []).slice();
    const index = newFiles.indexOf(file);
    if (index >= 0) {
      newFiles.splice(index, 1);
      this.files$.next(newFiles);
      this.updateData(newFiles);
      this.removeCompleted.emit(newFiles);
      this.propagateTouched(null);
    }
  }

  private updateData(res: UploadFileUI[]): void {
    const filesIdsOnForm = uniq(res.map((x) => x && x.response && x.response.id));

    if (this.isSingleFile) {
      this.updateFormValue(filesIdsOnForm[0] || null);
    } else {
      this.updateFormValue(filesIdsOnForm && filesIdsOnForm.length ? filesIdsOnForm : null);
    }
  }

  public openModal(fileToSign: IFileResponse, step = ETypeOfModal.sign): void {
    const modalOptions = deepClone(this.modalOptions);

    modalOptions.class = 'modal-dialog--lg';
    modalOptions.data.content = {
      ...modalOptions.data.content,
      ...{
        fileToSign,
        closeModal: this.closeSignProcessModal$,
        step
      },
    };
    this.modalSignProcessRef = this.modalService.show(ModalAttachmentSignProcessComponent, modalOptions);
  }

  public openPdf(pdf_link: string) {
    if (typeof this.customOpenPdfFunction === 'function') {
      this.customOpenPdfFunction();
    } else {
      window.open(pdf_link);
    }
  }

  public downloadAttachSign(file: IFileResponse) {
    if (!file || !file.signs_count || !file.signature_download_link) {
      return;
    }

    this.svc.downloadFile(file?.signature_download_link);
  }

  public openModalPreview(url): void {
    this.photoUrl = url;
    this.previewModal.show();
  }
}
