import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadStatus } from 'src/app/providers/_enum';
import { UploadFileUI, IFileResponse } from 'src/app/providers/_interfaces/common';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private httpClient: HttpClient) {}

  public uploadFile(file: File, url: string): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('files', file, file.name);

    const options = {
      reportProgress: true,
    };

    const req = new HttpRequest('POST', url, formData, options);

    return this.httpClient.request(req);
  }

  public modByHttpEvent(event: any, fileUI: UploadFileUI): UploadFileUI {
    switch (event.type) {
      case HttpEventType.Sent: {
        fileUI.progress = 0;
        fileUI.status = UploadStatus.Started;
        break;
      }
      case HttpEventType.UploadProgress: {
        fileUI.progress = Math.round((100 * event.loaded) / event.total);
        break;
      }
      case HttpEventType.Response: {
        if ([200, 201].indexOf(event.status) >= 0) {
          event = event as HttpResponse<IFileResponse>;
          const file = event.body;

          fileUI.status = UploadStatus.Completed;
          fileUI.progress = 100;
          fileUI.error = null;
          fileUI.response = file;
        } else {
          fileUI.status = UploadStatus.Failed;
          fileUI.error = event.statusText;

          throw fileUI.error;
        }

        break;
      }
    }

    return fileUI;
  }
}
