import { ITreeOptions, TREE_ACTIONS, KEYS, TreeNode, TreeModel } from '@ali-hm/angular-tree-component';
import { get } from 'lodash';
import { PekUtils } from 'src/app/modules/details-edit-mode/pek-requests/utils/pek-utils';
import { EnvHarmTreeSettings } from 'src/app/providers/_const/envharm.const';
import { TableType } from 'src/app/providers/_enum';
import { ERequestModel, ERequestModelToType } from 'src/app/providers/_interfaces/request.interface';

export const TREE_OPTIONS: ITreeOptions = {
  displayField: 'name',
  isExpandedField: 'expanded',
  idField: 'id',
  childrenField: 'children',
  actionMapping: {
    mouse: {
      dblClick: (tree: TreeModel, node: TreeNode, $event: any) => {
        if (node.hasChildren && !node.isCollapsed && node.data && node.data.is_readable) {
          TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        }
      },
      click: (tree: TreeModel, node: TreeNode, $event: any) => {
        if (node.hasChildren && node.data && node.isCollapsed && node.data.is_readable) {
          TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        }
      },
    },
    keys: {
      [KEYS.ENTER]: (_tree: TreeModel, node: TreeNode, _$event: any) => node.expandAll(),
    },
  },
  getChildren: (node: TreeNode) => {
    return node.data.allChildren;
  },
  allowDragoverStyling: false,
  levelPadding: 20,
  useVirtualScroll: false,
  animateExpand: true,
  scrollOnActivate: false,
  animateSpeed: 30,
  animateAcceleration: 1.2,
  nodeClass: (node: TreeNode) => {
    return !node.data.children || (node.data.children && node.data.children.length === 0) ? 'empty-tree' : '';
  },
};

export const CApplicantInformationSubSection = [
  {
    id: 'org-info',
    name: 'Сведения об организации (ИП)',
    router_link: 'org-info',
  },
  {
    id: 'requisites',
    name: 'Реквизиты',
    router_link: 'requisites',
  },
  {
    id: 'contact-info',
    name: 'Контактная информация',
    router_link: 'contact-info',
  },
  {
    id: 'legal-address',
    name: 'Юридический адрес',
    router_link: 'legal-address',
  },
  {
    id: 'actual-address',
    name: 'Фактический (почтовый) адрес',
    router_link: 'actual-address',
  },
  {
    id: 'bank-details',
    name: 'Банковские реквизиты',
    router_link: 'bank-details',
  },
  {
    id: 'ceo',
    name: 'Руководитель организации / обособленного подразделения (по доверенности)',
    router_link: 'ceo',
  },
  {
    id: 'accountant',
    name: 'Главный бухгалтер / бухгалтер обособленного подразделения (по доверенности)',
    router_link: 'accountant',
  },
  {
    id: 'additional',
    name: 'Дополнительно',
    router_link: 'additional',
  },
  {
    id: 'additional-info',
    name: 'Дополнительные сведения',
    router_link: 'additional-info',
  },
  {
    id: 'okved',
    name: 'ОКВЭД',
    router_link: 'okved',
  },
  {
    id: 'responsible-person',
    name: 'Ответственное лицо',
    router_link: 'responsible-person',
  },
];

export const EssenceTreeSettings = {
  env_harm_request: EnvHarmTreeSettings,
};

export const TreeEntitySettings: {
  [key: string]: {
    is_essence?: boolean;
    hideSection?: (parentData: any, rootData: any) => boolean;
    name_field?: ((item: any, itemIndex: number) => string) | string;
    canDeleteChild?: boolean;
    ignore_childs?: boolean;
  };
} = {
  statement: {
    is_essence: true,
  },
  process: {
    is_essence: true,
  },
  doer: {
    is_essence: true,
  },
  revoke_approved: {
    is_essence: true,
  },
  generate_certificate: {
    is_essence: true,
  },
  cancel_statement: {
    is_essence: true,
  },
  resend_to: {
    is_essence: true,
  },
  registry_inclusion: {
    is_essence: true,
  },
  applied_technologies: {
    hideSection: (parentData: any, rootData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'object_declared_category_id') && parentData.object_declared_category_id === 1) {
        if (Object.prototype.hasOwnProperty.call(parentData, 'is_secret')) {
          return parentData.is_secret;
        } else if (rootData && rootData.emission_object && Object.prototype.hasOwnProperty.call(rootData.emission_object, 'is_secret')) {
          return rootData.emission_object.is_secret;
        }
      }

      return true;
    },
  },
  onv_neutralize_tools: {
    // per_page: 10,
    // name_field: 'name',
    hideSection: (parentData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'has_emissions_disposal')) {
        return !parentData.has_emissions_disposal;
      }

      return true;
    },
  },
  onv_measure_tools: {
    // per_page: 10,
    // name_field: 'name',
    hideSection: (parentData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'has_emissions_measurement')) {
        return !parentData.has_emissions_measurement;
      }

      return true;
    },
  },
  discharges_neutralize_tools: {
    // per_page: 10,
    // name_field: 'name',
    hideSection: (parentData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'has_discharges_disposal')) {
        return !parentData.has_discharges_disposal;
      }

      return true;
    },
  },
  discharges_measure_tools: {
    // per_page: 10,
    // name_field: 'name',
    hideSection: (parentData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'has_discharges_measurement')) {
        return !parentData.has_discharges_measurement;
      }

      return true;
    },
  },
  wastes_neutralize_tools: {
    // per_page: 10,
    // name_field: 'name',
    hideSection: (parentData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'has_waste_disposal')) {
        return !parentData.has_waste_disposal;
      }

      return true;
    },
  },
  reduce_measures: {
    hideSection: (parentData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'has_nvos_reduce_events')) {
        return !parentData.has_nvos_reduce_events;
      }

      return true;
    },
  },
  emissions_stationary_sources: {
    // per_page: 10,
    // name_field: 'name',
    hideSection: (parentData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'has_stationary_sources')) {
        return !parentData.has_stationary_sources;
      }

      return true;
    },
  },
  emissions_stationary_source_facts: {
    name_field: 'air_pollutant_code',
  },
  discharges_sources: {
    // name_field: 'name',
    hideSection: (parentData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'has_discharges_sources')) {
        return !parentData.has_discharges_sources;
      }

      return true;
    },
  },
  // disharges_sources: {
  //   // name_field: 'name',
  //   hideSection: (parentData) => {
  //     if (parentData.hasOwnProperty('has_discharges_sources')) {
  //       return !parentData.has_discharges_sources;
  //     }

  //     return true;
  //   },
  // },
  onvos_request_discharges_source_facts: {
    name_field: 'water_pollutant_code',
  },
  waste_disposal_facilities: {
    // name_field: 'name',
    hideSection: (parentData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'has_waste_disposal_facilities')) {
        return !parentData.has_waste_disposal_facilities;
      }

      return true;
    },
  },
  onvos_request_waste_facts: {
    name_field: 'fkko.code',
  },

  pollutant_emissions: {
    // name_field: 'pollutant_code',
    name_field: (item: any, _itemIndex: number) => {
      return `${item.pollutant_code} ${item.name}`;
    },
    canDeleteChild: false,
  },

  specific_pollutant_emissions: {
    // name_field: 'pollutant.code',
    name_field: (item: any, _itemIndex: number) => {
      return `${get(item, 'pollutant.code', '')} ${item.name}`;
    },
  },

  sources_pollutions: {
    ignore_childs: true,
  },

  performance_activities: {
    // name_field: 'pollutant.code',
    name_field: (item: any, itemIndex: number) => {
      return `${itemIndex} ${item.name}`;
    },
  },

  pollutant_emissions_groups: {
    // name_field: 'code',
    name_field: (item: any, _itemIndex: number) => {
      return `${get(item, 'code')} ${item.name}`;
    },
    canDeleteChild: false,
  },

  violations: {
    canDeleteChild: false,
  },

  'rosnedra_notice.licenses.violations': {
    canDeleteChild: true,
  },

  'eroi_object.knm_information.violations': {
    canDeleteChild: true,
  },

  'knd_card.result.violations': {
    canDeleteChild: true,
  },

  'knd_quotas_card.result.violations': {
    canDeleteChild: true,
  },

  soil_uses: {
    ignore_childs: true,
  },

  regional_operators: {
    name_field: (item: any, _itemIndex: number) => {
      return `${get(item, 'nsi_fkko.code', `${item.id}`)}`;
    },
  },

  tko_regional_operators: {
    name_field: (item: any, _itemIndex: number) => {
      return `${get(item, 'nsi_fkko.code', `${item.id}`)}`;
    },
  },

  landfill_agreements: {
    ignore_childs: true,
  },

  'knd_card.fgn_objects.check_list': {
    canDeleteChild: false,
  },

  total: {
    canDeleteChild: false,
    name_field: 'onvos_oktmo_10',
  },

  report_documents: {
    is_essence: true,
  },

  products: {
    name_field: (item: any, itemIndex: number) => {
      let code = '';
      let group = '';

      if (item.product_group) {
        group = ` группа ${item.product_group.number} - ${item.product_group.group_name},`;
      }

      if (item.okpd2) {
        code = ` ОКПД ${item.okpd2.code}`;
      } else if (item.tnved) {
        code = ` ТНВЭД ${item.tnved.code}`;
      }

      return `Товар ${itemIndex}${group}${code}`;
    },
  },
  packages: {
    name_field: (item: any, itemIndex: number) => {
      let code = '';
      let group = '';

      if (item.product_group) {
        group = ` группа ${item.product_group.number} - ${item.product_group.group_name},`;
      }

      if (item.okpd2) {
        code = ` ОКПД ${item.okpd2.code}`;
      } else if (item.tnved) {
        code = ` ТНВЭД ${item.tnved.code}`;
      }

      return `Упаковка ${itemIndex}${group}${code}`;
    },
  },
  onvos: {
    name_field: (item: any, _itemIndex: number) => `Объект № ${item.number || ''}, ОКТМО ${item.oktmo || ''}`,
    hideSection: (parentData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'last_page')) {
        // for pagination
        return false;
      }

      if (Object.prototype.hasOwnProperty.call(parentData, 'enabled')) {
        return parentData.enabled !== true;
      }

      return true;
    },
  },
  documents: {
    name_field: (item: any, _itemIndex: number) =>
      `${(item.permitting_type && item.permitting_type.name) || 'Документ'} № ${item.number || ''}`,
  },
  sources: {
    name_field: (item: any, _itemIndex: number) => `Источник № ${item.number || ''}, ОКТМО ${item.oktmo || ''}`,
  },
  calc: {
    name_field: (item: any, itemIndex: number) => {
      let name = `Расчет № ${itemIndex}`;

      if (item.fkko) {
        name = `${item.fkko.code ? `${item.fkko.code} - ` : ''}${item.fkko.name || ''}`;
      } else if (item.pollutant) {
        name = `${item.pollutant.code ? `${item.pollutant.code} - ` : ''}${item.pollutant.name || ''}`;
      }

      return `${name}`;
    },
  },
  places: {
    name_field: 'address',
  },
  place_activities: {
    name_field: (item: any, _itemIndex: number) => {
      return `${get(item, 'fkko_code', `${item.id}`)}`;
    },
  },
  used_technologies: {
    name_field: (item: any, itemIndex: number) => {
      return PekUtils.getUsedTechnologyName(item, itemIndex, '');
    },
  },
  laboratories_information: {
    name_field: (item: any, itemIndex: number) => {
      return PekUtils.getLaboratoryInformationName(item, itemIndex, '');
    },
  },
  air_pollutants_stationary: {
    name_field: (item: any, itemIndex: number) => {
      return PekUtils.getAirPollutantStationaryName(item, itemIndex, '');
    },
  },
  stationary_sources_results: {
    name_field: (item: any, itemIndex: number) => {
      return PekUtils.getStationarySourcesResultName(item, itemIndex, '');
    },
  },
  air_pollutants_monitoring: {
    name_field: (item: any, itemIndex: number) => {
      return PekUtils.getAirPollutantMonitoringName(item, itemIndex, '');
    },
  },
  air_monitoring_results: {
    name_field: (item: any, itemIndex: number) => {
      return PekUtils.getAirMonitoringResultName(item, itemIndex, '');
    },
  },
  water_accounting_results: {
    name_field: (item: any, itemIndex: number) => {
      return PekUtils.getWaterAccountingResultName(item, itemIndex, '');
    },
  },
  water_checks_results: {
    name_field: (item: any, itemIndex: number) => {
      return PekUtils.getWaterChecksResultName(item, itemIndex, '');
    },
  },
  water_monitoring_results: {
    name_field: (item: any, itemIndex: number) => {
      return PekUtils.getWaterMonitoringResultName(item, itemIndex, '');
    },
  },
  waste_monitoring_results: {
    name_field: (item: any, itemIndex: number) => {
      return PekUtils.getWasteMonitoringResultName(item, itemIndex, '');
    },
  },

  object_technologies: {
    hideSection: (parentData: any, rootData: any) => {
      if (parentData && Object.prototype.hasOwnProperty.call(parentData, 'is_secret')) {
        return parentData.is_secret;
      } else if (rootData && rootData.emission_object && Object.prototype.hasOwnProperty.call(rootData.emission_object, 'is_secret')) {
        return rootData.emission_object.is_secret;
      }
    },
  },

  onv_products: {
    name_field: 'okpd.code',
    hideSection: (parentData: any, rootData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'is_secret')) {
        return parentData.is_secret;
      } else if (rootData && rootData.emission_object && Object.prototype.hasOwnProperty.call(rootData.emission_object, 'is_secret')) {
        return rootData.emission_object.is_secret;
      }
    },
  },

  negative_impact_info: {
    hideSection: (parentData: any, rootData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'is_secret')) {
        return parentData.is_secret;
      } else if (rootData && rootData.emission_object && Object.prototype.hasOwnProperty.call(rootData.emission_object, 'is_secret')) {
        return rootData.emission_object.is_secret;
      }
    },
  },

  all_permission_documents: {
    hideSection: (parentData: any, rootData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'is_secret')) {
        return parentData.is_secret;
      } else if (rootData && rootData.emission_object && Object.prototype.hasOwnProperty.call(rootData.emission_object, 'is_secret')) {
        return rootData.emission_object.is_secret;
      }
    },
  },

  object_okveds: {
    hideSection: (parentData: any, rootData: any) => {
      if (Object.prototype.hasOwnProperty.call(parentData, 'is_secret')) {
        return parentData.is_secret;
      } else if (rootData && rootData.emission_object && Object.prototype.hasOwnProperty.call(rootData.emission_object, 'is_secret')) {
        return rootData.emission_object.is_secret;
      }
    },
  },

  writeout: {
    hideSection: (parentData: any, rootData: { type: ERequestModel; is_need_writeout: boolean }) => {
      const type = ERequestModelToType[rootData && rootData.type];

      if ([TableType.license_request_issue, TableType.license_request_correction, TableType.license_request_replace].indexOf(type) < 0) {
        return false;
      }

      if (rootData && Object.prototype.hasOwnProperty.call(rootData, 'is_need_writeout')) {
        return !rootData.is_need_writeout;
      } else {
        return false;
      }
    },
  },

  order: {
    hideSection: (parentData: any, rootData: { type: ERequestModel }) => {
      const type = ERequestModelToType[rootData && rootData.type];
      if (
        [
          TableType.groro_request_inclusion,
          TableType.groro_request_actualization,
          TableType.groro_request_update,
          TableType.groro_request_exclusion,
        ].indexOf(type) < 0
      ) {
        return false;
      }
      if (parentData && Object.prototype.hasOwnProperty.call(parentData, 'is_ca_accepted')) {
        if (parentData.is_ca_accepted === null || parentData.is_ca_accepted === undefined) {
          return true;
        }
        return parentData.is_ca_accepted === false;
      } else {
        return true;
      }
    },
  },

  reject: {
    hideSection: (parentData: any, rootData: { type: ERequestModel }) => {
      const type = ERequestModelToType[rootData && rootData.type];
      if ([TableType.groro_request_inclusion, TableType.groro_request_actualization, TableType.groro_request_exclusion].indexOf(type) < 0) {
        return false;
      }
      if (parentData && Object.prototype.hasOwnProperty.call(parentData, 'is_ca_accepted')) {
        if (parentData.is_ca_accepted === null || parentData.is_ca_accepted === undefined) {
          return true;
        }
        return parentData.is_ca_accepted === true;
      } else {
        return true;
      }
    },
  },
};
