import { EPmImportFileApi, IPmImportFileOpen } from 'src/app/pages/pm/pm-common/helpers/pm-import-file/pm-import-file.interface';

export enum EPmOpeningBalanceImportsParseStatusKeys {
  all = 'all',
  partially = 'partially',
  error = 'error',
  duplicated = 'duplicated',
}

export interface EPmOpeningBalanceImportsParseStatus {
  id: EPmOpeningBalanceImportsParseStatusKeys;
  name: string;
}

export const PM_OPENING_BALANCE_IMPORT_OPEN: IPmImportFileOpen = {
  modalHeader: 'Выберите файлы для импорта',
  importUrl: '/api/payments-management/balance/xls',
  importExt: ['xlsx', 'xls'],
  importAccept: '.xlsx,.xls',
  importApi: EPmImportFileApi.opening_balance,
  submitText: 'Импортировать входящие сальдо',
};
