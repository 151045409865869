import { Location, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { takeWhile, finalize, tap, take } from 'rxjs/operators';
import { MaintenanceState } from 'src/app/providers/_interfaces/page.error';
import { MaintenanceService } from 'src/app/providers/_services/maintenance.service';

@Component({
  selector: 'app-page-wait',
  templateUrl: './page-wait.component.html',
  styleUrls: ['./page-wait.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, RouterLink],
})
export class PageWaitComponent implements OnInit, OnDestroy {
  public timer_min = 0;
  public timer_sec = 0;
  public maintenance: MaintenanceState;
  public throttle_timeout_min = 0;
  public currentUrl: string;
  public previousUrl: string;

  private needRootNavigate = false;
  private subscription: Subscription;
  private subscriptionReturn: Subscription;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private maintenanceService: MaintenanceService,
  ) {}

  public returnToPrevious(): void {
    this.maintenanceService
      .checkByEnvOrFile()
      .pipe(
        tap(() => this.subscription?.unsubscribe()),
        take(1),
      )
      .subscribe((is) => {
        if (is) {
          this.check();
        } else {
          if (this.currentUrl || this.previousUrl) {
            this.router.navigate([this.currentUrl || this.previousUrl]);
          } else {
            this.returnToMain();
          }
        }
      });
  }

  public returnToMain(): void {
    this.needRootNavigate = true;
    this.router.navigate(['/'], { skipLocationChange: true });
  }

  ngOnInit(): void {
    try {
      this.currentUrl = sessionStorage.getItem('currentUrl');
    } catch (err) {}
    try {
      this.previousUrl = sessionStorage.getItem('previousUrl');
    } catch (err) {}
    this.check();
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
    this.subscriptionReturn && this.subscriptionReturn.unsubscribe();
  }

  check(): void {
    try {
      this.maintenance = JSON.parse(sessionStorage.getItem('maintenance'));
      this.throttle_timeout_min = (this.maintenance.throttle_timeout_min || 0) * 60 * 1000;

      const te = this.maintenance.created_at_timestamp + this.throttle_timeout_min - +new Date();
      const timer_end_timestamp = te ? Math.round(te) / 1000 : 0;

      this.timer_min = timer_end_timestamp ? Math.trunc(timer_end_timestamp / 60) : 0;
      this.timer_sec = timer_end_timestamp ? timer_end_timestamp % 60 : 0;
    } catch (err) {
      this.timer_min = 0;
      this.timer_sec = 0;
      console.log(err);
    }

    if (this.timer_min + this.timer_sec > 0) {
      this.subscription = interval(500)
        .pipe(
          takeWhile(() => this.timer_min + this.timer_sec > 0),
          finalize(() => {
            this.timer_min = 0;
            this.timer_sec = 0;
            if (!this.needRootNavigate) this.returnToPrevious();
          }),
        )
        .subscribe(() => {
          const te = this.maintenance.created_at_timestamp + this.throttle_timeout_min - +new Date();
          const timer_end_timestamp = te ? Math.round(te / 1000) : 0;
          this.timer_min = timer_end_timestamp ? Math.trunc(timer_end_timestamp / 60) : 0;
          this.timer_sec = timer_end_timestamp ? timer_end_timestamp % 60 : 0;
          this.cdr.markForCheck();
        });
    } else {
      this.timer_min = 0;
      this.timer_sec = 0;
      this.returnToPrevious();
    }
  }
}
