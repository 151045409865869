import { NgIf, AsyncPipe, JsonPipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IconsModule, TooltipModule } from 'ng-uikit-pro-standard';
import { LabelService } from 'src/app/providers/_services/label.service';

@Component({
  selector: 'app-label-route',
  templateUrl: './label-route.component.html',
  styleUrls: ['./label-route.component.scss'],
  standalone: true,
  imports: [NgIf, IconsModule, TooltipModule, AsyncPipe, JsonPipe],
})
export class LabelRouteComponent {
  constructor(
    public label: LabelService,
    public route: ActivatedRoute,
  ) {}
}
