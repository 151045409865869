import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { of } from 'rxjs';
import { map, catchError, switchMap, withLatestFrom, filter } from 'rxjs/operators';
import { IPaymentNvos } from 'src/app/providers/_interfaces/payment-nvos.interface';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { PaymentNvosSelectors } from 'src/app/store';
import {
  PaymentNvosActionTypes,
  ListDocumentsRequest,
  DocumentsListLoadedSuccess,
  ListSourcesRequest,
  SourcesListLoadedError,
  SourcePollutantsListLoadedSuccess,
  ListDocumentPollutantsRequest,
  DocumentPollutantsListLoadedSuccess,
  DocumentPollutantsListLoadedError,
  ListDeclarationsRequest,
  DeclarationsListLoadedSuccess,
  DeclarationsListLoadedError,
  ListCorrectionsRequest,
  CorrectionsListLoadedError,
  WastesListLoadedError,
  WasteDocumentsListLoadedSuccess,
  WasteDocumentsListLoadedError,
  ListWasteCorrectionsRequest,
  WasteCorrectionsListLoadedSuccess,
  SourcesListLoadedSuccess,
  ListSourcePollutantsRequest,
  SourcePollutantsListLoadedError,
  CorrectionsListLoadedSuccess,
  DocumentsListLoadedError,
  ListWastesRequest,
  WastesListLoadedSuccess,
  ListWasteDocumentsRequest,
  WasteCorrectionsListLoadedError,
} from 'src/app/store/payment-nvos/actions';

@Injectable({ providedIn: 'root' })
export class PaymentNvosEffects {
  // List declarations

  listDeclarations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentNvosActionTypes.ListDeclarationsRequest),
      switchMap((action: ListDeclarationsRequest) =>
        this.svcRestService
          .listPNvos(action.payload.id, {
            filters: {
              status_code: 'accepted',
            },
          })
          .pipe(
            map(
              ([requests, corrections, ksv]: [IPaymentNvos[], IPaymentNvos[], IPaymentNvos[]]) =>
                new DeclarationsListLoadedSuccess({
                  data: requests.concat(corrections, ksv),
                }),
            ),
            catchError(() => of(new DeclarationsListLoadedError())),
          ),
      ),
    ),
  );

  // List corrections
  listCorrections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentNvosActionTypes.ListCorrectionsRequest),
      withLatestFrom(
        this.store.pipe(select(PaymentNvosSelectors.selectCurrentOnvosId)),
        this.store.pipe(select(PaymentNvosSelectors.selectCurrentDeclaration)),
        this.store.pipe(select(PaymentNvosSelectors.selectCurrentSelectedSection)),
      ),
      switchMap(([action, onvosId, declaration, sectionSelector]: [ListCorrectionsRequest, any, any, any]) =>
        this.svcRestService
          .listPNvosOnvosCorrections(
            sectionSelector.payment_onvos_id,
            declaration && declaration.type,
            onvosId,
            sectionSelector.section_name,
            {
              pagination: action.payload.pagination,
              initParams: { relations: 'pollutant' },
            },
          )
          .pipe(
            map((data: any) => new CorrectionsListLoadedSuccess({ data })),
            catchError(() => of(new CorrectionsListLoadedError())),
          ),
      ),
    ),
  );

  // List documents
  listDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentNvosActionTypes.ListDocumentsRequest),
      withLatestFrom(
        this.store.pipe(select(PaymentNvosSelectors.selectCurrentId)),
        this.store.pipe(select(PaymentNvosSelectors.selectCurrentDeclaration)),
        this.store.pipe(select(PaymentNvosSelectors.selectCurrentOnvosId)),
      ),
      filter(
        ([action, declarationId, currentDeclaration, onvosId]: [ListDocumentsRequest, string, any, string]) =>
          !!action && !!declarationId && !!onvosId && !!currentDeclaration,
      ),
      switchMap(([action, declarationId, declaration, onvosId]: [ListDocumentsRequest, string, any, string]) =>
        this.svcRestService
          .listPNvosOnvosDocuments(declarationId, declaration && declaration.type, onvosId, action.payload.section, action.payload.oktmo)
          .pipe(
            map((data: any) => new DocumentsListLoadedSuccess({ data })),
            catchError(() => of(new DocumentsListLoadedError())),
          ),
      ),
    ),
  );

  // List sources
  listSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentNvosActionTypes.ListSourcesRequest),
      withLatestFrom(this.store.pipe(select(PaymentNvosSelectors.selectCurrentSelectedSection))),
      switchMap(([action, sectionSelector]: [ListSourcesRequest, any]) =>
        this.svcRestService.listPNvosDocumentSources(sectionSelector.section_name, action.payload.id, sectionSelector.oktmo).pipe(
          map((data: any) => new SourcesListLoadedSuccess({ data })),
          catchError(() => of(new SourcesListLoadedError())),
        ),
      ),
    ),
  );

  // List source pollutants
  listSourcePollutants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentNvosActionTypes.ListSourcePollutantsRequest),
      withLatestFrom(this.store.pipe(select(PaymentNvosSelectors.selectCurrentSelectedSource))),
      switchMap(([action, sectionSelector]: [ListSourcePollutantsRequest, any]) =>
        this.svcRestService
          .listPNvosSourcePollutants(sectionSelector.section_name, action.payload.id, {
            pagination: action.payload.pagination,
            initParams: { relations: 'pollutant' },
          })
          .pipe(
            map((data: any) => new SourcePollutantsListLoadedSuccess({ data })),
            catchError(() => of(new SourcePollutantsListLoadedError())),
          ),
      ),
    ),
  );

  // List wastes
  listWastes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentNvosActionTypes.ListWastesRequest),
      withLatestFrom(
        this.store.pipe(select(PaymentNvosSelectors.selectCurrentId)),
        this.store.pipe(select(PaymentNvosSelectors.selectCurrentDeclaration)),
      ),
      switchMap(([action, declarationId, declaration]: [ListWastesRequest, any, any]) =>
        this.svcRestService
          .listPNvosWastes(declarationId, declaration && declaration.type, action.payload.id, action.payload.section, action.payload.oktmo)
          .pipe(
            map((data: any) => new WastesListLoadedSuccess({ data })),
            catchError(() => of(new WastesListLoadedError())),
          ),
      ),
    ),
  );

  // List waste documents
  listWasteDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentNvosActionTypes.ListWasteDocumentsRequest),
      switchMap((action: ListWasteDocumentsRequest) =>
        this.svcRestService.listPNvosWasteDocuments(action.payload.section, action.payload.id).pipe(
          map((data: any) => new WasteDocumentsListLoadedSuccess({ data })),
          catchError(() => of(new WasteDocumentsListLoadedError())),
        ),
      ),
    ),
  );

  // List waste corrections
  listWasteCorrections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentNvosActionTypes.ListWasteCorrectionsRequest),
      withLatestFrom(this.store.pipe(select(PaymentNvosSelectors.selectCurrentSelectedSection))),
      switchMap(([action, sectionSelector]: [ListWasteCorrectionsRequest, any]) =>
        this.svcRestService
          .listPNvosCorrectionsForWaste(sectionSelector.section_name, action.payload.id, {
            pagination: action.payload.pagination,
            initParams: { relations: 'fkko' },
          })
          .pipe(
            map((data: any) => new WasteCorrectionsListLoadedSuccess({ data })),
            catchError(() => of(new WasteCorrectionsListLoadedError())),
          ),
      ),
    ),
  );

  // List document pollutants
  listDocumentPollutants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentNvosActionTypes.ListDocumentPollutantsRequest),
      withLatestFrom(this.store.pipe(select(PaymentNvosSelectors.selectCurrentSelectedSection))),
      switchMap(([action, sectionSelector]: [ListDocumentPollutantsRequest, any]) =>
        this.svcRestService
          .listPNvosFkkosForDocument(sectionSelector.section_name, action.payload.id, {
            pagination: action.payload.pagination,
            initParams: { relations: 'fkko' },
          })
          .pipe(
            map((data: any) => new DocumentPollutantsListLoadedSuccess({ data })),
            catchError(() => of(new DocumentPollutantsListLoadedError())),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private svcRestService: SvcRestService,
  ) {}
}
