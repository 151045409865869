export enum EJobStatus {
  completed = 'completed',
  failed = 'failed',
  pending = 'pending',
  running = 'running',
}

export enum EJobStatusTranslate {
  completed = 'Выполнено',
  failed = 'Ошибка',
  pending = 'В очереди на выполнение',
  running = 'Выполняется',
}

export interface IJobResponse {
  id: string;
  job_id: string;
  message: string;
  status_link: string;
}

export interface IStatus {
  status: EJobStatus; // "failed"
  error: string | null; // "Undefined variable: serviceRequest"
  name: string;
  output: { link?: string; id?: string; status?: { id: number }; path?: string } | null;
}

export interface IJobStatus extends IStatus {
  attempts?: number; // 1
  created_at?: string; // "2021-01-19T17:20:17.331000Z"
  env?: string; // "develop"
  error: string | null; // "Undefined variable: serviceRequest"
  finished_at?: string; // "2021-01-19T17:20:19.705000Z"
  input?: {
    request_uuid: string; // "ec3986b2-22fb-4e00-9310-cd1b54b1e735"
  };
  job_id?: number; // 1960633
  log?: string[]; // ["Запускается импорт", "Старт записи", "Распаковка XML",…]
  name: string; // "ServiceRequestXmlImportJob"
  output: {
    link?: string;
    id?: string;
    status?: { id: number };
    path?: string;
    service_request_type?: string;
    service_request_id?: string;
    exception?: string;
  };
  progress_max?: number;
  progress_now?: number;
  queue?: string; // "default"
  started_at?: string; // "2021-01-19T17:20:19.514000Z"
  status: EJobStatus; // "failed"
  status_link?: string; //
  type?: string; // "ServiceRequestXmlImportJob"
  updated_at?: string; // "2021-01-19T17:20:19.706000Z"
  user_id?: number; // 1
  slug: string;
}
