export const REGEXP = {
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/,
  phone: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
  phoneStrict: /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}(\sдоб.\s\d{1,5})?$/,
  mineralLicense: /^[А-Я]{1,3}\s[0-9]{1,6}\s[А-Я]{1,2}$/,
  groro: /^[0-9]{2}\-[0-9]{5}\-(Х|З){1}\-[0-9]{5}\-[0-9]{6}$/,
  passportSeria: /\d{4}/,
  passportNumber: /\d{6}/,
  snilsNumber: /\d{3}\-\d{3}\-\d{3}\s\d{2}/,
  snilsFlat: /\d{11}/,
};
