import { Action } from '@ngrx/store';

export enum ChargeActionTypes {
  LoadObjectRequest = '[ChargeView Bid/Charge] Load Object',
  ObjectLoadedSuccess = '[ChargeView Bid/Charge] Object Loaded Success',
  ObjectLoadedError = '[ChargeView Bid/Charge] Object Loaded Error',
}

// LOAD
export class LoadChargeRequest implements Action {
  readonly type = ChargeActionTypes.LoadObjectRequest;

  constructor(public payload: { id: string }) {}
}

export class ChargeLoadedSuccess implements Action {
  readonly type = ChargeActionTypes.ObjectLoadedSuccess;

  constructor(public payload: { data: any }) {}
}

export class ChargeLoadedError implements Action {
  readonly type = ChargeActionTypes.ObjectLoadedError;
}

export type ChargeUnion = LoadChargeRequest | ChargeLoadedSuccess | ChargeLoadedError;
