import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { TableType } from 'src/app/providers/_enum';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { ChargeActionTypes, LoadChargeRequest, ChargeLoadedSuccess, ChargeLoadedError } from 'src/app/store/payment-charge/actions';

@Injectable({ providedIn: 'root' })
export class ChargeEffects {
  // REPORTS
  loadObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChargeActionTypes.LoadObjectRequest),
      switchMap((action: LoadChargeRequest) =>
        this.svcRestService.fetchEssenceByID(TableType.payments_charges, +action.payload.id).pipe(
          map((data: any) => new ChargeLoadedSuccess({ data })),
          catchError(() => of(new ChargeLoadedError())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private svcRestService: SvcRestService,
  ) {}
}
