import { BehaviorSubject } from 'rxjs';
import { DictionaryOf } from 'src/app/providers/_dictionary/dictionary.interface';
import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';
import { RpnHttpParams } from 'src/app/providers/_services/svc.rest.service';


export type BRModFunctionType = (params: RpnHttpParams, url: string) => { params: RpnHttpParams; url: string };
export type TransformFunctionType = <C>(dict: C[]) => C[];
export type MapFunctionType = <C extends IDictionaryItem>(dict: C[]) => any[];

export class Dictionary<T extends IDictionaryItem> {
  expiryDate = 0;
  loading = false;
  needLoadAllItems = false;

  dictURL: string;
  dictURL$ = new BehaviorSubject('');
  dictItemURL: string;
  dictionary$: BehaviorSubject<T[]>;

  filterResetString: string;
  parentName: keyof DictionaryOf;
  filters: { [key: string]: string | number | boolean };
  initKey: string;

  beforeRequestMod: BRModFunctionType;
  map: (dict: T[]) => T[];
  transform: (dict: T[]) => T[];

  constructor(initDictValue: T[] = null) {
    this.expiryDate = 0;
    this.dictionary$ = new BehaviorSubject<T[]>(initDictValue);
    if (initDictValue) this.loading = true;
  }

  public setUrl(value: string): Dictionary<T> {
    this.dictURL = value;
    this.dictURL$.next(value);
    return this;
  }

  public setItemUrl(value: string): Dictionary<T> {
    this.dictItemURL = value;
    return this;
  }

  public loadAll(): Dictionary<T> {
    this.needLoadAllItems = true;
    return this;
  }

  public beforeRequestModFunc(value: BRModFunctionType): Dictionary<T> {
    this.beforeRequestMod = value;
    return this;
  }

  public setInitKey(value: string): Dictionary<T> {
    this.initKey = value;
    return this;
  }

  public addFilters(value: { [key: string]: string | number | boolean }): Dictionary<T> {
    this.filters = value;
    return this;
  }

  public transformFunc(value: TransformFunctionType): Dictionary<T> {
    this.transform = value;
    return this;
  }

  public setParentName(value: keyof DictionaryOf): Dictionary<T> {
    this.parentName = value;
    return this;
  }

  public addMapper(value: MapFunctionType): Dictionary<T> {
    this.map = value;
    return this;
  }
}
