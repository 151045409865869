import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';

export interface IPaymentsManagementAnalyticsReport {
  id: number;
  status: EPaymentsManagementAnalyticsReportStatusKeys;
  year: number;
  quarter: number;
  region_id: number;
  territory_org_id: string;
  territory_org?: {
    name: string;
  };
  user_id: number;
  user?: {
    full_name: string;
    tel: string;
  };
  ceo: string;
  user_phone: string;
  form_type: EPaymentsManagementAnalyticsReportFormTypeKeys;
  created_at: string;
  form: {
    envharm_info?: IPMAnalyticsForm2Item[];
    total?: number;
    [key: string]: any;
  };
}

export interface IPMAnalyticsForm2Item {
  number: number; // id
  case_number?: string;
  comments?: string;
  environment_component_name?: string;
  kbk?: string;
  payer_inn?: string;
  payer_name?: string;
  payments_federal_budget?: number;
  payments_municipal_budget?: number;
  payments_regional_budget?: number;
  payments_total?: number;
  region_name?: string;
  service_request_id?: number;
  kbk_name: string;
  subject_name: string;
  harm_reason: string;
  detection_date: string;
  amount_paid: string;
  collection_date: string[];
  collection_way: string[];
  sufd_payment_ids?: number[];
}

export enum EPMAnalyticsReportCreationModeKeys {
  auto = 'auto',
  hand = 'hand',
}

export enum EPaymentsManagementAnalyticsReportStatusKeys {
  draft = 'draft',
  confirmed = 'confirmed',
}

export interface IPaymentsManagementAnalyticsReportStatus extends IDictionaryItem {
  id: EPaymentsManagementAnalyticsReportStatusKeys;
  name: string;
}

export interface IPMAnalyticsReportCreationMode extends IDictionaryItem {
  id: EPMAnalyticsReportCreationModeKeys;
  name: string;
}

export enum EPaymentsManagementAnalyticsReportFormTypeKeys {
  form_2 = 'form_2',
  form_3 = 'form_3',
  form_4 = 'form_4',
  form_6 = 'form_6',
  form_9 = 'form_9',
  form_10 = 'form_10',
}

export interface IPaymentsManagementAnalyticsFormType extends IDictionaryItem {
  id: EPaymentsManagementAnalyticsReportFormTypeKeys;
  name: string;
}

export interface IPMAnalyticsFormEnvironmentComponentName {
  id: number;
  name: string;
}
