import { Action } from '@ngrx/store';
import { TableType } from 'src/app/providers/_enum/index';
import { ITreeRoute } from 'src/app/providers/_interfaces/common';
import { IEssence } from 'src/app/providers/_interfaces/essence.interface';

export enum EssenceActionTypes {
  LoadEssenceRequest = '[DetailsEditMode Bid/Essence] Load Essence',
  EssenceLoadedSuccess = '[DetailsEditMode Bid/Essence] Essence Loaded Success',
  EssenceLoadedError = '[DetailsEditMode Bid/Essence] Essence Loaded Error',

  SelectRoute = '[DetailsEditMode Bid/Essence] Select Route',
  RouteLoadedSuccess = '[DetailsEditMode Bid/Essence] Route Loaded Success',
  RouteLoadedError = '[DetailsEditMode Bid/Essence] Route Loaded Error',

  UpdateEssenceRequest = '[DetailsEditMode Bid/Essence] Update Essence Request',
  EssenceUpdatedSuccess = '[DetailsEditMode Bid/Essence] Essence Updated Success',
  EssenceUpdatedError = '[DetailsEditMode Bid/Essence] Essence Updated Error',

  CreateEssenceObjectRequest = '[DetailsEditMode Bid/Essence] Create Essence Object Request',
  EssenceObjectCreatedSuccess = '[DetailsEditMode Bid/Essence] Essence Object Created Success',
  EssenceObjectCreatedError = '[DetailsEditMode Bid/Essence] Essence Object Created Error',

  UpdateEssenceObjectRequest = '[DetailsEditMode Bid/Essence] Update Essence Object Request',
  EssenceObjectUpdatedSuccess = '[DetailsEditMode Bid/Essence] Essence Object Updated Success',
  EssenceObjectUpdatedError = '[DetailsEditMode Bid/Essence] Essence Object Updated Error',

  RestoreAllSelectedObjectsRequest = '[DetailsEditMode Bid/Essence] Restore All Selected Objects Request',
  AllSelectedObjectsRestoredSuccess = '[DetailsEditMode Bid/Essence] All Selected Objects Restored Success',
  AllSelectedObjectsRestoredError = '[DetailsEditMode Bid/Essence] All Selected Objects Restored Error',

  DeleteEssenceObjectRequest = '[DetailsEditMode Bid/Essence] Delete Essence Object Request',
  EssenceObjectDeletedSuccess = '[DetailsEditMode Bid/Essence] Essence Object Deleted Success',
  EssenceObjectDeletedError = '[DetailsEditMode Bid/Essence] Essence Object Deleted Error',

  DeleteAllSelectedObjectsRequest = '[DetailsEditMode Bid/Essence] Delete All Selected Objects Request',
  AllSelectedObjectsDeletedSuccess = '[DetailsEditMode Bid/Essence] All Selected Objects Deleted Success',
  AllSelectedObjectsDeletedError = '[DetailsEditMode Bid/Essence] All Selected Objects Deleted Error',

  ManualUpdateTree = '[DetailsEditMode Bid/Essence] ManualUpdateTree',
  ManualUpdateTreeSuccess = '[DetailsEditMode Bid/Essence] ManualUpdateTree Success',
  ManualUpdateTreeError = '[DetailsEditMode Bid/Essence] ManualUpdateTree Error',
}

// LOAD REPORT
export class LoadEssenceRequest implements Action {
  readonly type = EssenceActionTypes.LoadEssenceRequest;
  constructor(public payload: { essenceType: TableType; essenceId: number; rpnUrl: string }) {}
}
export class EssenceLoadedSuccess implements Action {
  readonly type = EssenceActionTypes.EssenceLoadedSuccess;
  constructor(public payload: { essence: IEssence; forms: any }) {}
}
export class EssenceLoadedError implements Action {
  readonly type = EssenceActionTypes.EssenceLoadedError;
  constructor(public payload: { error: any }) {}
}

// Select Route
export class SelectRoute implements Action {
  readonly type = EssenceActionTypes.SelectRoute;
  constructor(
    public payload: {
      route: ITreeRoute;
      treePath: any;
      routeParams: { [key: string]: string };
      relations: string;
      operation?: string;
      obj?: any;
      sectionName?: string;
      needLoadRoot?: boolean;
      cacheKey?: string;
      oldBody?: any;
      withNewTree?: any;
      oldValuesScope?: boolean;
    },
  ) {}
}

export class RouteLoadedSuccess implements Action {
  readonly type = EssenceActionTypes.RouteLoadedSuccess;
  constructor(public payload: { object: any; treePath: any; sectionName: string | undefined; cacheKey?: string }) {}
}

export class RouteLoadedError implements Action {
  readonly type = EssenceActionTypes.RouteLoadedError;
  constructor(public payload: { error: any }) {}
}

// Essence Update
export class UpdateEssenceRequest implements Action {
  readonly type = EssenceActionTypes.UpdateEssenceRequest;
  constructor(public payload: { body: any }) {}
}

export class EssenceUpdatedSuccess implements Action {
  readonly type = EssenceActionTypes.EssenceUpdatedSuccess;
  constructor(public payload: { essence: any }) {}
}

export class EssenceUpdatedError implements Action {
  readonly type = EssenceActionTypes.EssenceUpdatedError;
  constructor(public payload: { error: any }) {}
}

// Essence Object Create
export class CreateEssenceObjectRequest implements Action {
  readonly type = EssenceActionTypes.CreateEssenceObjectRequest;
  constructor(
    public payload: {
      route: ITreeRoute;
      routeParams: { [key: string]: string };
      body: any;
      reload_flag: boolean;
    },
  ) {}
}

export class EssenceObjectCreatedSuccess implements Action {
  readonly type = EssenceActionTypes.EssenceObjectCreatedSuccess;
  constructor(public payload: { object: any; returnBack: boolean; withNewTree?: any }) {}
}

export class EssenceObjectCreatedError implements Action {
  readonly type = EssenceActionTypes.EssenceObjectCreatedError;
  constructor(public payload: { error: any }) {}
}

// Essence Object Update
export class UpdateEssenceObjectRequest implements Action {
  readonly type = EssenceActionTypes.UpdateEssenceObjectRequest;
  constructor(
    public payload: {
      route: ITreeRoute;
      routeParams: { [key: string]: string };
      body: any;
      objectId?: any;
      method?: string;
      reload_flag: boolean;
      returnBack: boolean;
      cacheKey: string;
      withOldBody: boolean;
    },
  ) {}
}

export class EssenceObjectUpdatedSuccess implements Action {
  readonly type = EssenceActionTypes.EssenceObjectUpdatedSuccess;
  constructor(public payload: { object: any; returnBack: boolean; withNewTree?: any }) {}
}

export class EssenceObjectUpdatedError implements Action {
  readonly type = EssenceActionTypes.EssenceObjectUpdatedError;
  constructor(public payload: { error: any }) {}
}
export class RestoreAllSelectedObjectsRequest implements Action {
  readonly type = EssenceActionTypes.RestoreAllSelectedObjectsRequest;
  constructor(public payload: { route: ITreeRoute; routeParams: { [key: string]: string }; body: any }) {}
}

export class AllSelectedObjectsRestoredSuccess implements Action {
  readonly type = EssenceActionTypes.AllSelectedObjectsRestoredSuccess;
  constructor(public payload: { withNewTree?: any }) {}
}

export class AllSelectedObjectsRestoredError implements Action {
  readonly type = EssenceActionTypes.AllSelectedObjectsRestoredError;
  constructor(public payload: { error: any }) {}
}

// Essence Object Delete
export class DeleteEssenceObjectRequest implements Action {
  readonly type = EssenceActionTypes.DeleteEssenceObjectRequest;
  constructor(
    public payload: {
      route: ITreeRoute;
      routeParams: { [key: string]: string };
      cascadeDelete: boolean;
    },
  ) {}
}

export class EssenceObjectDeletedSuccess implements Action {
  readonly type = EssenceActionTypes.EssenceObjectDeletedSuccess;
  constructor(public payload: { returnBack: boolean; withNewTree?: any } = { returnBack: true }) {}
}

export class EssenceObjectDeletedError implements Action {
  readonly type = EssenceActionTypes.EssenceObjectDeletedError;
  constructor(public payload: { error: any }) {}
}

export class DeleteAllSelectedObjectsRequest implements Action {
  readonly type = EssenceActionTypes.DeleteAllSelectedObjectsRequest;
  constructor(
    public payload: {
      route: ITreeRoute;
      routeParams: { [key: string]: string };
      ids: number[];
      cascadeDelete: boolean;
    },
  ) {}
}

export class AllSelectedObjectsDeletedSuccess implements Action {
  readonly type = EssenceActionTypes.AllSelectedObjectsDeletedSuccess;
  constructor(public payload: { withNewTree: any }) {}
}

export class AllSelectedObjectsDeletedError implements Action {
  readonly type = EssenceActionTypes.AllSelectedObjectsDeletedError;
  constructor(public payload: { error: any }) {}
}

export class ManualUpdateTree implements Action {
  readonly type = EssenceActionTypes.ManualUpdateTree;
  constructor(public payload: { returnBack: boolean } = { returnBack: false }) {}
}

export class ManualUpdateTreeSuccess implements Action {
  readonly type = EssenceActionTypes.ManualUpdateTreeSuccess;
  constructor(public payload: { returnBack: boolean; withNewTree?: any } = { returnBack: false }) {}
}

export class ManualUpdateTreeError implements Action {
  readonly type = EssenceActionTypes.ManualUpdateTreeError;
  constructor(public payload: { error: any }) {}
}

export type EssenceUnion =
  | LoadEssenceRequest
  | EssenceLoadedSuccess
  | EssenceLoadedError
  | SelectRoute
  | RouteLoadedSuccess
  | RouteLoadedError
  | UpdateEssenceRequest
  | EssenceUpdatedSuccess
  | EssenceUpdatedError
  | CreateEssenceObjectRequest
  | EssenceObjectCreatedSuccess
  | EssenceObjectCreatedError
  | UpdateEssenceObjectRequest
  | EssenceObjectUpdatedSuccess
  | EssenceObjectUpdatedError
  | DeleteEssenceObjectRequest
  | EssenceObjectDeletedSuccess
  | EssenceObjectDeletedError
  | DeleteAllSelectedObjectsRequest
  | AllSelectedObjectsDeletedSuccess
  | AllSelectedObjectsDeletedError
  | RestoreAllSelectedObjectsRequest
  | AllSelectedObjectsRestoredSuccess
  | AllSelectedObjectsRestoredError
  | ManualUpdateTree
  | ManualUpdateTreeSuccess
  | ManualUpdateTreeError;
