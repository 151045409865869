import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { Effects } from 'src/app/store/breadcrumbs/effects';
import { breadcrumbsReducer } from 'src/app/store/breadcrumbs/reducer';
import { featureName } from 'src/app/store/breadcrumbs/selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([Effects]),
    StoreModule.forFeature(featureName, breadcrumbsReducer)
  ],
})
export class BreadcrumbsStoreModule {}
