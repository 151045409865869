import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { IOpenModalResponse, ModalShowTemplateService } from 'src/app/modules/modal-show-template/services/modal-show-template.service';
import { MODAL_BASE_OPTIONS } from 'src/app/modules/treeview/treeview.const';
import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { IPageError } from 'src/app/providers/_interfaces/page.error';
import { ERequestModelToType } from 'src/app/providers/_interfaces/request.interface';
import { findLastRouteWithUrl } from 'src/app/providers/_utils/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorPageService {
  private _modal: IOpenModalResponse;

  get modal(): IOpenModalResponse {
    return this._modal;
  }

  set modal(modal: IOpenModalResponse) {
    this.closeModal();
    this._modal = modal;
  }

  private _closeModalSubscription: Subscription;

  set closeModalSubscription(s: Subscription) {
    this._closeModalSubscription?.unsubscribe();
    this._closeModalSubscription = s;
  }

  public get lastErrorState(): IPageError {
    let a: IPageError;
    try {
      a = JSON.parse(sessionStorage.getItem('lastPageError'));
    } catch (err) {}
    return a;
  }

  constructor(
    private router: Router,
    private modalShowTemplateService: ModalShowTemplateService,
    private modalService: MDBModalService,
  ) {}

  public updateErrorInStorage(error: IPageError) {
    console.log(error);
    const errorStr = JSON.stringify(error);
    try {
      sessionStorage.setItem('lastPageError', errorStr);
      setTimeout(() => this.router.navigate(['/error-page'], { skipLocationChange: false }), 500);
    } catch (err) {}
  }

  public clearErrorInStorage() {
    sessionStorage.removeItem('lastPageError');
  }

  onvRequestEditAlreadyExists(error): Observable<any> {
    if (error.error.context && error.error.context.id) {
      if (confirm(error.error.message + '. Вернуться к редактированию?')) {
        this.router.navigate([
          environment.SYSTEM_TYPE === EnvironmentType.lk
            ? `/rpn/requests/all_requests/details/${ERequestModelToType[error.error.context.type]}/${error.error.context.id}`
            : `/rpn/pto-uonvos/onv_request/details/${ERequestModelToType[error.error.context.type]}/${error.error.context.id}`,
        ]);
      }
    }

    return of(null);
  }

  onvRequestExceptionWithModal(message, title): Observable<any> {
    const closeModal = new Subject();
    const modalRef = this.modalService.show(ModalConfirmComponent, {
      ...MODAL_BASE_OPTIONS,
      class: 'modal-dialog--sm',
      data: {
        isHtml: true,
        closeModal,
        title,
        text: message,
        confirm: 'Хорошо',
        type: null,
      },
    });

    this._closeModalSubscription = closeModal
      .pipe(
        map(() => {
          modalRef.hide();
          this._closeModalSubscription.unsubscribe();
        }),
      )
      .subscribe();

    return of(null);
  }

  invalidSumException(): Observable<any> {
    window.location.reload();
    return of(null);
  }

  deleteException(): Observable<any> {
    const currentUrlWithoutParams = this.router.url.split('?')[0];
    this.router
      .navigate(['../'], {
        relativeTo: findLastRouteWithUrl(this.router.routerState.root),
        skipLocationChange: true,
        queryParams: { random: '' },
      })
      .then(() => this.router.navigateByUrl(currentUrlWithoutParams));
    return of(null);
  }

  private closeModal() {
    if (this.modal) {
      this.modal.modalRef.hide();
      this._modal = null;
    }
  }
}
