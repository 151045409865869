import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
  standalone: true,
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number | string, digitsInfo?: string, groupSeparator?: string): string {
    const num = this.decimalPipe.transform(value, digitsInfo);

    if (num && groupSeparator) {
      return num.split(',').join(groupSeparator);
    }

    return num;
  }
}
