import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateNull',
  standalone: true,
})
export class DateNullPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: Date | string | number | null | undefined, format?: string, nullString = ''): string {
    return (value && this.datePipe.transform(value, format)) || nullString;
  }
}
