import { Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseSection } from 'src/app/modules/details-edit-mode/base-section/base-section.class';
import { EInputType } from 'src/app/providers/_const/input.type.const';
import { DictionaryOf } from 'src/app/providers/_dictionary/dictionary.interface';

export type ObservableOr<A> = A | Observable<A>;
export enum FieldType {
  'input' = 'input',
  'autocomplete' = 'autocomplete',
  'checkbox' = 'checkbox',
  'date' = 'date',
  'file' = 'file',
  'select' = 'select',
  'template' = 'template',
  'caption' = 'caption',
  'disableView' = 'disableView',
}

export interface IFieldParams {
  sendKey?: ObservableOr<string>;
  searchKey?: ObservableOr<string>;
  isOn?: ObservableOr<boolean>;
  required?: ObservableOr<boolean>;
  options?: ObservableOr<any>;
  dictKey?: ObservableOr<keyof DictionaryOf>;
  position?: ObservableOr<number>;
  isLoaded?: ObservableOr<boolean>;
  showTooltipDiff?: ObservableOr<boolean>;
  isDisabled?: ObservableOr<boolean>;
  size?: ObservableOr<number>;
  template?: string;
  versions?: string[];
  name?: ObservableOr<string>;
  label?: ObservableOr<string>;
  index?: number;

  type: ObservableOr<FieldType>;
  valueMod?: (value: any) => any;
}

export interface IFieldParamsCompleted {
  sendKey: Observable<string>;
  searchKey: Observable<string>;
  name: Observable<string>;
  isOn: Observable<boolean>;
  required: Observable<boolean>;
  options: Observable<any>;
  dictKey: Observable<keyof DictionaryOf>;
  showTooltipDiff: Observable<boolean>;
  type: Observable<FieldType>;
  isLoaded: Observable<boolean>;
  isDisabled: Observable<boolean>;
  label: Observable<string>;
  size: Observable<number>;
  template: string;

  valueMod?: (value: any) => any;
}

export class SectionFields {
  public onlyViewTypes = {
    [FieldType.caption]: true,
    [FieldType.template]: true,
  };
  public params: IFieldParamsCompleted[] = [];
  public component: BaseSection;
  public acc: { [key: string]: Observable<any>[] } = {};

  getParamProp<A>(param: ObservableOr<A>): Observable<A> {
    return typeof param === 'object' && param !== null ? (param as Observable<A>) : (of(param) as Observable<A>);
  }

  constructor(params: IFieldParams[], component: BaseSection) {
    params = params.slice(0);
    params.sort((a, b) => a.index - b.index);

    for (let param of params) {
      const completedParams = {} as IFieldParamsCompleted;
      param = Object.assign({}, param);

      const isOn = this.getParamProp(param.name).pipe(
        withLatestFrom(this.getParamProp(param.type)),
        filter(([value, type]) => {
          return this.onlyViewTypes[type] || component.fieldIsOn(value);
        }),
      );

      if (!Object.prototype.hasOwnProperty.call(param, 'sendKey')) {
        param.sendKey = of('id');
      }

      if (!Object.prototype.hasOwnProperty.call(param, 'searchKey')) {
        param.searchKey = of('name');
      }

      if (!Object.prototype.hasOwnProperty.call(param, 'isDisabled')) {
        param.isDisabled = component.formLoaded$.pipe(
          filter((value) => value.isLoaded),
          map((value) => value.form),
          switchMap((form) => {
            return form.valueChanges.pipe(
              startWith(component.reportForm.disabled),
              map(() => component.reportForm.disabled),
            );
          }),
        );
      }

      if (!Object.prototype.hasOwnProperty.call(param, 'isOn')) {
        param.isOn = isOn.pipe(map((v) => !!v));
      } else {
        const origRef = param.isOn;

        param.isOn = isOn.pipe(switchMap(() => this.getParamProp(origRef)));
      }

      if (!Object.prototype.hasOwnProperty.call(param, 'isLoaded')) {
        param.isLoaded = component.isDataLoaded$;
      }

      if (!Object.prototype.hasOwnProperty.call(param, 'showTooltipDiff')) {
        param.showTooltipDiff = this.getParamProp(component.showTooltipDiff);
      }

      if (!Object.prototype.hasOwnProperty.call(param, 'options')) {
        const options = {} as any;

        if (param.type === FieldType.input) {
          options.type = EInputType.text;
        }

        param.options = of(options);
      }

      for (const key of Object.keys(param)) {
        let completedParam;

        switch (key) {
          case 'showTooltipDiff':
            completedParam = this.getParamProp(component.showTooltipDiff);
            break;
          case 'isLoaded':
            completedParam = this.getParamProp(param.isLoaded);
            break;
          case 'template':
          case 'valueMod':
            completedParam = param[key];
            break;
          case 'isDisabled':
            completedParam = component.formLoaded$.pipe(
              filter((data) => data.isLoaded),
              switchMap(() => this.getParamProp(param.isDisabled)),
            );
            break;
          default:
            completedParam = this.getParamProp(param[key]);
        }

        completedParams[key] = completedParam;
      }

      this.acc.name = this.acc.name || [];

      if (param.name) {
        this.acc.name.push(this.getParamProp(param.name));
      }

      this.params.push(completedParams);
      this.component = component;
    }
  }
}
