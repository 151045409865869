import { TableSortComponent } from 'src/app/modules/table/table-sort/table-sort.component';
import { Sort } from 'src/app/providers/_enum';
import { fromEnum } from 'src/app/providers/_interfaces/common';
import { undefinedOrNullOrEmptyString } from 'src/app/providers/_utils/utils';

export type TablePagination<A> = { [K in 'current_page' | 'per_page' | 'last_page' | 'limit']: A };

export interface SavedFilterOptions {
  path: string;
  filters: { [key: string]: any };
  pagination?: TablePagination<any>;
  orderBy: any;
  params?: any;
  expiryDate: number;
}

export function addTableSort(orderByState: TableSortComponent[], tableSortComponent: TableSortComponent) {
  orderByState.push(tableSortComponent);
}

export function removeTableSort(orderByState: TableSortComponent[], tableSortComponent: TableSortComponent) {
  const i = orderByState.indexOf(tableSortComponent);
  orderByState.splice(i, 1);
}

export function getOrderByValue(orderByState: TableSortComponent[]): fromEnum<any, Sort> {
  const res = {} as fromEnum<any, Sort>;
  for (const o of orderByState) {
    res[o.field] = o.sort$.value;
  }
  return res;
}

export function resetOrderBy(orderByState: TableSortComponent[]): void {
  for (const o of orderByState) {
    o.sort$.next(null);
  }
}

/**
 *
 * @param restoreFilterFromStorage Флаг, указывающий нужно выполнять функцию или нет
 * @param currentUrl Путь, чьи опции хотим получить
 * @returns Путь и его опции: пагинацию, фильтры, параметры, сортировку и срок истекания; либо null
 */
export function restoreFilterOptions(restoreFilterFromStorage: boolean, currentUrl: string): SavedFilterOptions {
  if (!restoreFilterFromStorage) {
    return null;
  }

  try {
    const lastFilter = sessionStorage.getItem(currentUrl);

    if (!lastFilter) {
      return null;
    }

    const filterOptions: SavedFilterOptions = JSON.parse(lastFilter);

    if (filterOptions.expiryDate < Date.now()) {
      return null;
    }

    return filterOptions;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export function installFilterDependency(a: string, b: string, filters, emptyValue: any) {
  const aValue = filters[a];
  const bValue = filters[b];

  if (!aValue && !bValue) return;
  if (!aValue) filters[a] = emptyValue;
  if (!bValue) filters[b] = emptyValue;
}

export function pickCompositeFilter(keys: string[], name: string, filters, mod?: (compositeValue: any, keys: string[]) => void) {
  const compositeValue = {};

  if (typeof mod === 'function') {
    mod(compositeValue, keys);
  } else {
    for (const key of keys) {
      const value = filters[key];

      if (!undefinedOrNullOrEmptyString(value)) {
        compositeValue[key] = value;
      }

      delete filters[key];
    }
  }

  if (Object.keys(compositeValue).length) {
    filters[name] = JSON.stringify(compositeValue);
  }
}

export function clearNullFilters(filters) {
  return Object.keys(filters).reduce((obj, key) => {
    if (!undefinedOrNullOrEmptyString(filters[key])) {
      obj[key] = filters[key];
    }

    return obj;
  }, {});
}
