import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IBreadcrumb } from 'src/app/providers/_interfaces/common';
import { BreadcrumbsActionsTypes, BreadcrumbsUnion } from 'src/app/store/breadcrumbs/actions';

export type BreadcrumbsState = EntityState<IBreadcrumb>;

export const adapter: EntityAdapter<IBreadcrumb> = createEntityAdapter<IBreadcrumb>({
  selectId: (instance) => instance.index,
});

export const initialState: BreadcrumbsState = adapter.getInitialState();

export function breadcrumbsReducer(state: BreadcrumbsState = initialState, action: BreadcrumbsUnion): BreadcrumbsState {
  switch (action.type) {
    case BreadcrumbsActionsTypes.AddBreadcrumbsSuccess:
    case BreadcrumbsActionsTypes.UpdateBreadcrumbsSuccess:
      return adapter.addMany(action.payload.breadcrumbs, initialState);

    default:
      return state;
  }
}
