import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { DictionaryDaDataName } from 'src/app/providers/_dictionary';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DadataService {
  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    private svc: SvcRestService,
  ) {}

  public getDadataSuggest(query: any, type: DictionaryDaDataName): Observable<any> {
    const params: { query: string; type?: string } = { query };
    let partyType: string;

    switch (type) {
      case 'partyLegal': {
        partyType = 'party';
        params.type = 'LEGAL';
        break;
      }
      case 'partyIndividual': {
        partyType = 'party';
        params.type = 'INDIVIDUAL';
        break;
      }
      default: {
        partyType = type;
        break;
      }
    }

    return this.svc.httpPostWithCache(
      `${environment.ADDRESS_SUGGEST_URL}/suggestions/api/4_1/rs/suggest/${partyType}`,
      JSON.stringify(params),
      this.httpOptions,
    );
  }

  public getDadataAddress(params: { lat: number; lon: number; count: number }): Observable<any> {
    return this.svc.httpPostWithCache(
      `${environment.ADDRESS_SUGGEST_URL}/suggestions/api/4_1/rs/geolocate/address`,
      JSON.stringify(params),
      this.httpOptions,
    );
  }

  public getDadataStat(): Observable<any> {
    const d = new Date();
    const date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(`${environment.ADDRESS_DADATA_STAT_URL}/api/v2/stat/daily?date=${date}`, httpOptions);
  }

  public setFormControlValue(formGroup: UntypedFormGroup, key: string, item: { [key: string]: any }): void {
    formGroup.controls[key].setValue(item.value);
    formGroup.controls.zip_code.setValue(item.data.postal_code);
  }

  public initFormValue(formGroup: UntypedFormGroup, key: string): void {
    if (formGroup.controls[key].value) {
      formGroup.controls[key].setValue(formGroup.controls[key].value);
    }
  }
}
