import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EnvironmentType } from 'src/app/providers/_const/environment.type';
import { MaintenanceState } from 'src/app/providers/_interfaces/page.error';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { environment } from 'src/environments/environment';

const ConfigFileUrl = '/throttle.json';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private state: MaintenanceState = {
    is: false,
    throttle_limit_percent: environment.throttle_limit_percent,
    throttle_timeout_min: environment.throttle_timeout_min,
    throttle_random_number: 0,
    created_at_timestamp: 0,
    throttle_text: 'Более 50 000 пользователей сегодня подают отчеты',
  };

  constructor(
    private svcRestService: SvcRestService,
    private router: Router,
  ) {}

  private checkThrottle(throttle: MaintenanceState): boolean {
    if (throttle.throttle_limit_percent <= 0 || throttle.throttle_limit_percent > 100 || environment.SYSTEM_TYPE !== EnvironmentType.lk) {
      try {
        sessionStorage.removeItem('maintenance');
      } catch (err) {}
      return false;
    }

    this.state = throttle;
    this.initActiveState();

    if (this.state.is) {
      let currentUrl = '';
      try {
        currentUrl = sessionStorage.getItem('currentUrl') || '';
      } catch (err) {}
      if (
        !currentUrl &&
        !location.pathname.includes('error-page') &&
        !location.pathname.includes('wait-page') &&
        !location.pathname.includes('login')
      ) {
        try {
          sessionStorage.setItem('currentUrl', location.pathname + location.search);
        } catch (err) {}
      }
      setTimeout(() => this.router.navigate(['/wait-page'], { skipLocationChange: true }));
    }

    return this.state.is;
  }

  private hasNeedTossUp(throttle_limit_percent, throttle_timeout_min, throttle_timeout_timestamp, currentTs): boolean {
    return (
      !this.state || // нет последнего розыгрыша
      this.state.created_at_timestamp + throttle_timeout_timestamp < currentTs ||
      this.state.throttle_timeout_min !== throttle_timeout_min || // сменилось время ожидания
      (throttle_limit_percent === 100 && this.state.throttle_limit_percent !== 100) || // включили на 100%
      (throttle_limit_percent !== 100 && this.state.throttle_limit_percent === 100)
    ); // отключили 100%
  }

  private initActiveState(): void {
    const throttle_limit_percent = this.state.throttle_limit_percent; // процент из throttle.json
    const throttle_timeout_min = this.state.throttle_timeout_min; // время из throttle.json в мин
    const throttle_timeout_timestamp = throttle_timeout_min * 60 * 1000; // время из throttle.json в мс
    const throttle_text = this.state.throttle_text; // текст из throttle.json

    const currentTs = +new Date();

    try {
      const stateParse: string | null = sessionStorage.getItem('maintenance');
      if (stateParse) this.state = JSON.parse(stateParse); // если есть последний розыгрыш из localStorage - берем его
    } catch (err) {}

    this.state.throttle_text = throttle_text; // обновление текста

    if (this.hasNeedTossUp(throttle_limit_percent, throttle_timeout_min, throttle_timeout_timestamp, currentTs)) {
      // стоит ли переиграть?

      this.state.throttle_random_number = this.state.is ? 100 : Math.random() * 100;

      this.state.is = this.state.throttle_random_number <= this.state.throttle_limit_percent;
      this.state.throttle_limit_percent = throttle_limit_percent;

      this.state.throttle_timeout_min =
        this.state.throttle_timeout_min !== throttle_timeout_min ? throttle_timeout_min : this.state.throttle_timeout_min;

      this.state.created_at_timestamp =
        this.state.created_at_timestamp + throttle_timeout_timestamp > currentTs ? this.state.created_at_timestamp : currentTs;
    }

    try {
      sessionStorage.setItem('maintenance', JSON.stringify(this.state));
    } catch (err) {}
  }

  checkByEnvOrFile(): Observable<boolean> {
    return this.svcRestService.get<MaintenanceState>(ConfigFileUrl).pipe(
      catchError(() => {
        return of({
          is: false,
          throttle_limit_percent: environment.throttle_limit_percent, // процент трафика уходящих на режим техобслуживания
          throttle_timeout_min: environment.throttle_timeout_min, // время в режиме техобслуживания
          throttle_random_number: 0, // разыгранный процент
          created_at_timestamp: 0, // время розыгрыша
          throttle_text: 'Более 50 000 пользователей сегодня подают отчеты', // текст сообщения
        });
      }),
      map((throttle) => {
        return this.checkThrottle({
          is: false,
          throttle_limit_percent: throttle?.throttle_limit_percent || 0, // процент трафика уходящих на режим техобслуживания
          throttle_timeout_min: throttle?.throttle_timeout_min || 0, // время в режиме техобслуживания
          throttle_random_number: 0, // разыгранный процент
          created_at_timestamp: 0, // время розыгрыша
          throttle_text: throttle?.throttle_text || 'Более 50 000 пользователей сегодня подают отчеты', // текст сообщения
        });
      }),
    );
  }
}
