<div class="no-content" [ngClass]="[withOutBottomBorder ? '' : 'rpn_no_content__with_border']">
  <svg width="128" height="116" viewBox="0 0 128 116" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M125.96 92.8446L77.0346 8.10422C74.3141 3.39223 69.4414 0.578735 63.9999 0.578735C58.5586 0.578735 53.6856 3.39223 50.9652 8.10422L2.0405 92.8443C-0.680247 97.5568 -0.680247 103.183 2.0405 107.896C4.76124 112.608 9.63373 115.421 15.0752 115.421H112.925C118.366 115.421 123.239 112.608 125.96 107.896C128.68 103.183 128.68 97.5568 125.96 92.8446ZM119.465 104.146C118.1 106.511 115.655 107.922 112.925 107.922H15.0752C12.3447 107.922 9.89973 106.511 8.53474 104.146C7.16974 101.782 7.16974 98.9588 8.53474 96.5943L57.4599 11.854C58.8249 9.48947 61.2699 8.07797 64.0001 8.07797C66.7301 8.07797 69.1754 9.48947 70.5404 11.854L119.465 96.5943C120.83 98.9588 120.83 101.782 119.465 104.146Z" fill="#848E99"/>
    <path d="M67.7491 38.0024H60.2501V75.4978H67.7491V38.0024Z" fill="#848E99"/>
    <path d="M63.9999 82.9973C61.2431 82.9973 59.0004 85.2401 59.0004 87.9968C59.0004 90.7536 61.2431 92.9963 63.9999 92.9963C66.7564 92.9963 68.9994 90.7536 68.9994 87.9968C68.9994 85.2401 66.7566 82.9973 63.9999 82.9973Z" fill="#848E99"/>
  </svg>

  <ng-container *ngIf="!searchValue?.length; else emptySearchTemplate">
    <div #ref [ngClass]="['rpn_font_size--' + fontSize]">
      <ng-content></ng-content>
    </div>
    <div *ngIf="ref.childNodes.length == 0" class="no-data" [ngClass]="['rpn_font_size--' + fontSize]">
      <ng-container *ngIf="!noDataText; else noDataTextTemplate">
        Нет данных
      </ng-container>
      <ng-template #noDataTextTemplate>
        <div class="d-flex flex-direction__column aic">

          <span class="pb24" [ngClass]="['rpn_font_size--' + fontSize]" [innerHTML]="noDataText"></span>

          <div *ngIf="backUrl" class="temp">
            <a class="button-size_m" [routerLink]="backUrl">
              <span>Назад</span>
            </a>
          </div>

        </div>
      </ng-template>
    </div>
  </ng-container>

  <ng-template #emptySearchTemplate>
    <span [ngClass]="['rpn_font_size--' + fontSize]">
      По запросу <strong>{{searchValue}}</strong> ничего не найдено
    </span>
  </ng-template>

</div>
