import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { catchError, delay, expand, filter, map, switchMap, tap } from 'rxjs/operators';
import { DICT } from 'src/app/providers/_dictionary';
import { IJobStatus, EJobStatus, EJobStatusTranslate, IJobResponse } from 'src/app/providers/_interfaces/download.file.interface';
import { SvcRestService } from 'src/app/providers/_services/svc.rest.service';
import { ToastrService } from 'src/app/providers/_services/toastr.service';
import { saveReportFile } from 'src/app/providers/_utils/file';

export interface IExportResult {
  error: any;
  result: any;
  extension: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExportTemplateService {
  private loadStatus$ = (jobResult) => {
    return this.svcRestService.get<IJobStatus>(jobResult.status_link).pipe(
      catchError((error) => of({ status: EJobStatus.failed, error })),
      tap(
        (res: IJobStatus) =>
          !(res.status === EJobStatus.completed || res.status === EJobStatus.failed) &&
          this.toastrService.info('', EJobStatusTranslate[res.status]),
      ),
    );
  };

  constructor(
    private svcRestService: SvcRestService,
    private toastrService: ToastrService,
  ) {}

  public expandByLoadStatus(jobResponse): Observable<IJobStatus> {
    return this.loadStatus$(jobResponse).pipe(
      delay(5000),
      filter((x) => !!x),
      expand((jobResult: IJobStatus) => {
        return jobResult.status === EJobStatus.completed || jobResult.status === EJobStatus.failed
          ? EMPTY
          : this.loadStatus$(jobResult).pipe(delay(5000));
      }),
    );
  }

  public exportTemplateRequest(templateCode: string, filters): Observable<any> {
    const dictUrl = `${DICT.xml_export_templates.dictURL}?filter[code]=${templateCode}`;

    return this.svcRestService.fetchByUrl(dictUrl).pipe(
      filter((x) => !!x),
      map((response) => (response as any).data || []),
      map((templates) =>
        templates.find((template) => {
          return template.code === templateCode;
        }),
      ),
      switchMap((template) => this.exportTemplateRequestByTemplateId(template?.id, templateCode, filters)),
    );
  }

  public exportTemplateRequestByTemplateId(templateId: number, templateCode: string, filters): Observable<IExportResult> {
    return this.svcRestService.startTaskCreating(`/api/svc/xls-export/run/${templateId || templateCode}`, { params: { ...filters } }).pipe(
      switchMap((jobResponse: IJobResponse) => this.expandByLoadStatus(jobResponse)),
      switchMap((jobResult: IJobStatus) => {
        if (jobResult.status === EJobStatus.completed) {
          if (!jobResult?.output?.path) {
            return of({ error: 'Нет ссылки на файл', result: null, extension: '' });
          } else {
            return this.svcRestService
              .get<BlobPart>('/api/svc/xls-export/result/' + jobResult.output.id + '/download', { responseType: 'blob' as 'json' })
              .pipe(
                catchError((res) => of({ error: res.message, result: null, extension: '' })),
                map((result) => {
                  const paths: string[] = jobResult.output.path.split('/');
                  const lastPath: string = paths[paths.length - 1];
                  const partsOfName: string[] = lastPath.split('.');
                  const extension = partsOfName[partsOfName.length - 1];
                  return { error: null, result, extension };
                }),
              );
          }
        } else if (jobResult.status === EJobStatus.failed) {
          return of({ error: jobResult.error, result: null, extension: '' });
        }

        return of(null);
      }),
      filter((x) => !!x),
      catchError((res) => of({ error: res.message, result: null, extension: '' })),
      tap(({ error, result, extension }: IExportResult) => {
        if (error) {
          this.toastrService.error(error || '', 'Ошибка');
        } else if (result) {
          this.toastrService.success('', 'Выполнено');
          saveReportFile(result, templateCode, extension);
        }
      }),
    );
  }
}
