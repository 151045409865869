<ng-container
  *ngIf="{
    download: downloadedFile$ | async,
    pdfLink: pdfLink$ | async,
  } as state"
>

<!-- HEADER -->
<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="close(null)">
    <app-icon [name]="IconsSet.closeModal" width="11px" height="11px"></app-icon>
  </button>

  <ng-container [ngSwitch]="step">
    <h4 class="modal-title" *ngSwitchCase="1">
      Подписание файла
    </h4>

    <h4 class="modal-title" *ngSwitchCase="2">
      Подтверждение
    </h4>

    <h4 class="modal-title" *ngSwitchCase="3">
      Подписание
    </h4>

    <h4 class="modal-title" *ngSwitchCase="4">
      Просмотр
    </h4>
  </ng-container>
</div>
<!-- /HEADER -->

<!-- BODY -->
<div class="modal-body sign-modal-body w-100 h-100">
  <div class="mx-auto w-100">
    <div class="d-flex w-100 jcc">
      <div class="modal-wrapper w-100">
        <ng-container [ngSwitch]="step">

          <ng-container *ngSwitchCase="1">
            <div [ngClass]="[state.pdfLink ? 'd-flex' : '']">
              <div [ngClass]="['rpn-sign-container aic pr-30 pl-30', state.pdfLink ? 'd-flex' : '']">

                <div [ngClass]="['h-100', state.pdfLink ? 'rpn-sign-block' : '']">
                  <div class="rpn-sign-header-container">
                    <div appSpinner
                      class="h-100 w-100"
                      [sizeMultiplier]="1"
                      [spinnerCenter]="true"
                      [showSpinner]="loading">
                      <div class="w-100 text-center" *ngIf="!loading">
                        Будет подписан следующий файл
                        <div [mdbTooltip]="fileToSign?.file_name" class="green-text">{{ fileToSign?.file_name | trim:35 }}</div> <div>({{ fileToSign?.formatted_size }})</div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex rpn-sign-body">
                    <div class="w-100 h-100 pt-10">
                      <mdb-card class="d-flex flex-column mt-0 aic p-3">
                        <ng-container *ngIf="certificates && certificates.length; else haveNotSign">
                          <div #selectButton class="rpn-item btn btn-lg d-flex aic rpn-sign-item" *ngFor="let cert of certificates; let id = index;"
                            (click)="selectCert(cert)">

                            <div class="d-flex flex-column m-0 align-items-start" [ngClass]="{'green-text': select === cert?.value?.Thumbprint}">
                              <div class="d-flex flex-column">
                                <strong class="text-left" *ngIf="cert?.value?.SN || cert?.value?.G">
                                  {{cert?.value?.SN}} {{cert?.value?.G}}
                                </strong>
                                <strong class="text-left" *ngIf="!(cert?.value?.SN || cert?.value?.G) && cert?.value?.CN">
                                  {{cert?.value?.CN}}
                                </strong>
                                <p class="text-left m-0">
                                  <span>с {{cert?.value?.FD | date:'dd.MM.yyyy'}} до {{cert?.value?.TD | date:'dd.MM.yyyy'}}</span>
                                  <span>{{cert?.value?.O}}</span>
                                </p>
                              </div>
                              <div *ngIf="cert?.error" class="red-text w-100 text-center">{{cert.error}}</div>
                            </div>

                          </div>
                        </ng-container>

                        <ng-template #haveNotSign>
                          <div class="w-100 text-center">Нет доступных сертификатов</div>
                        </ng-template>
                      </mdb-card>
                    </div>
                  </div>
                </div>

                <div class="rpn-iframe-container position-relative" *ngIf="state.pdfLink as link">
                  <iframe
                    *ngIf="link"
                    [src]="link | safe:'RESOURCE_URL'"
                    frameborder="0"
                    class="rpn-iframe"
                    title="print-preview"
                    (oncontextmenu)="false"
                    (load)="!loading"
                  ></iframe>
                </div>

              </div>
            </div>
          </ng-container>

          <div *ngSwitchCase="2" class="rpn-sign-step2-container">

            <p class="text-center">
              Файл будет подписан и отправлен со следующими реквизитами:
            </p>
            <div class="rpn-sign-step2-body-container">
              <div class="rpn-flex">
                <span class="rpn-dt"><strong>Сертификат: </strong></span>
                <span class="rpn-dd">{{signatureService.selectCertificate.NN}}</span>
              </div>
              <div class="rpn-flex">
                <span class="rpn-dt"><strong>Владелец: </strong></span>
                <span class="rpn-dd" *ngIf="signatureService.selectCertificate.SN ||
                  signatureService.selectCertificate.GN || signatureService.selectCertificate.G; else CNTemplate">
                  {{signatureService.selectCertificate.SN}} {{signatureService.selectCertificate.GN || signatureService.selectCertificate.G}}
                </span>
                <ng-template #CNTemplate>
                  <span class="rpn-dd">
                    {{signatureService.selectCertificate.CN}}
                  </span>
                </ng-template>
              </div>
              <div class="rpn-flex">
                <span class="rpn-dt"><strong>Действителен: </strong></span>
                <span class="rpn-dd">
                  с {{signatureService.selectCertificate.FD | date:'dd.MM.yyyy'}} до
                  {{signatureService.selectCertificate.TD | date:'dd.MM.yyyy'}}
                </span>
              </div>
            </div>

          </div>

          <div *ngSwitchCase="3" class="position-relative h-100">

            <div style="height: 100px;">
              <div class="text-center" class="position-absolute w-100 h-100">
                <div class="d-flex jcc">
                  <span class="rpn-text-preloader">Подписываем файл... {{signProgressPercent$ | async}}%</span>
                </div>
                <div class="d-flex jcc"><mdb-spinner spinnerType="big"></mdb-spinner></div>
              </div>
            </div>

          </div>

          <div *ngSwitchCase="4">

            <div class="rpn-sign-container aic pr-30 pl-30">
              <div class="rpn-iframe-container position-relative w-100" *ngIf="state.pdfLink as link">
                <iframe
                  *ngIf="link"
                  [src]="link | safe:'RESOURCE_URL'"
                  frameborder="0"
                  class="rpn-iframe"
                  title="print-preview"
                  (oncontextmenu)="false"
                  (load)="!loading"
                ></iframe>
              </div>
            </div>

          </div>
        </ng-container>

      </div>
    </div>
  </div>

</div>
<!-- /BODY -->

<!-- FOOTER -->
<div class="modal-footer">
  <ng-container [ngSwitch]="step">
    <div class="modal-wrapper modal-wrapper--sm justify-content-end" *ngSwitchCase="1">
      <button mdbBtn type="button" color="primary" (click)="step = 2" [disabled]="!signatureService?.selectCertificate || !downloadResponse">
        Подписать ЭП
      </button>
    </div>
    <div class="modal-wrapper modal-wrapper--sm justify-content-end" *ngSwitchCase="2">
      <button mdbBtn type="button" color="primary" (click)="sentSignedResponse(downloadResponse.blob)">
        Подтверждаю
      </button>
      <button mdbBtn type="button" color="default" (click)="close(null)" >
        Отмена
      </button>
    </div>
  </ng-container>
</div>
<!-- /FOOTER -->

</ng-container>
