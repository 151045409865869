export const UITextCounted: { [key: string]: { one: string; two: string; five: string } } = {
  запись: {
    one: 'запись',
    two: 'записи',
    five: 'записей',
  },
  штука: {
    one: 'штука',
    two: 'штуки',
    five: 'штук',
  },
  эксперт: {
    one: 'эксперт',
    two: 'эксперта',
    five: 'экспертов',
  },
  день: {
    one: 'день',
    two: 'дня',
    five: 'дней',
  },
  секунда: {
    one: 'секунда',
    two: 'секунды',
    five: 'секунд',
  },
  минута: {
    one: 'минута',
    two: 'минуты',
    five: 'минут',
  },
  час: {
    one: 'час',
    two: 'часа',
    five: 'часов',
  },
  инструкция: {
    one: 'инструкция',
    two: 'инструкции',
    five: 'инструкций',
  },
  позиция: {
    one: 'позиция',
    two: 'позиции',
    five: 'позиций',
  },
  days_left: {
    one: 'остался % рабочий день',
    two: 'осталось % рабочих дня',
    five: 'осталось % рабочих дней',
  },
  days_past: {
    one: 'прошел % рабочий день',
    two: 'прошло % рабочих дня',
    five: 'прошло % рабочих дней',
  },
};
