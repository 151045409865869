// import { MyHammerConfig } from './app/app.module';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { enableProdMode, LOCALE_ID, ErrorHandler, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { init, createErrorHandler } from '@sentry/angular-ivy';
import { ToastModule, MDBBootstrapModulesPro, MDB_DATE_OPTIONS } from 'ng-uikit-pro-standard';
import { CookieService } from 'ngx-cookie-service';
import { provideNgxMask } from 'ngx-mask';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { AppInterceptor } from 'src/app/auth/services/app-interceptor.service';
import { AuthFullGuard } from 'src/app/providers/_guards/auth-full.guard';
import { DateNullPipe } from 'src/app/providers/_pipes/date-null.pipe';
import { DictionaryPipe } from 'src/app/providers/_pipes/dictionary.pipe';
import { MomentFromNowPipe } from 'src/app/providers/_pipes/moment-from-now.pipe';
import { NumberFormatPipe } from 'src/app/providers/_pipes/number-format.pipe';
import { SumPipe } from 'src/app/providers/_pipes/sum.pipe';
import { BreadcrumbsStoreModule } from 'src/app/store/breadcrumbs/breadcrumbs-store.module';
import { EssenceStoreModule } from 'src/app/store/essence/essence-store.module';
import { OnvosStoreModule } from 'src/app/store/onvos/onvos-store.module';
import { ChargeStoreModule } from 'src/app/store/payment-charge/charge-store.module';
import { PaymentNvosStoreModule } from 'src/app/store/payment-nvos/payment-onvos-store.module';
import { environment } from 'src/environments/environment';
registerLocaleData(localeRu);

const providers = environment.production ? [{ provide: ErrorHandler, useValue: createErrorHandler() }] : [];
// declare const Hammer: any;

if (environment.production) {
  init({
    dsn: 'https://a72b980b9aa24aee9f4570638ad77360@sentry.rpn.big3.ru/6',
    ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
  });
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      CommonModule,
      Router,
      ToastModule.forRoot(),
      MDBBootstrapModulesPro.forRoot(),
      TreeModule,
      EffectsModule.forRoot([]),
      StoreModule.forRoot({}),
      EssenceStoreModule,
      BreadcrumbsStoreModule,
      OnvosStoreModule,
      ChargeStoreModule,
      PaymentNvosStoreModule,
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
      }),
      AuthFullGuard,
      CookieService,
    ),
    provideAnimations(),
    provideNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },
    {
      provide: MDB_DATE_OPTIONS,
      useValue: { showTodayBtn: false },
    },
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: MyHammerConfig,
    // },
    ...providers,

    DatePipe,
    SumPipe,
    MomentFromNowPipe,
    DateNullPipe,
    DictionaryPipe,
    NumberFormatPipe,
    DecimalPipe,
    CurrencyPipe,
  ],
}).catch((err) => console.error(err));
