import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';

export enum ERefundBudget {
  'Федеральный бюджет' = 'Федеральный бюджет',
  'Бюджет субъекта РФ' = 'Бюджет субъекта РФ',
  'Местный бюджет' = 'Местный бюджет',
  'Бюджет ГВФ РФ' = 'Бюджет ГВФ РФ',
  'Бюджет ТГВФ' = 'Бюджет ТГВФ',
  'Средства ЮЛ' = 'Средства ЮЛ',
}

export interface IRefundBudgetDictionary extends IDictionaryItem {
  id: ERefundBudget;
  name: ERefundBudget;
}

export enum ERefundStatues {
  waiting_request_approval = 'waiting_request_approval',
  waiting_request_rejection = 'waiting_request_rejection',
  waiting_treasury_request = 'waiting_treasury_request',
}
