<!-- HEADER -->
<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <app-icon [name]="IconsSet.closeModal" width="11px" height="11px"></app-icon>
  </button>

  <ng-container *ngIf="!result?.error">
    <div class="d-flex flex-column align-items-center justify-content-center modal-wrapper w-100 ng-star-inserted">
      <app-icon [name]="IconsSet.docSended" class="mb-2"></app-icon>
      <h4 class="modal-title">Файл успешно подписан</h4>
    </div>
  </ng-container>

  <ng-container *ngIf="result?.error">
    <div class="d-flex flex-column align-items-center modal-wrapper w-100">
      <app-icon [name]="IconsSet.docError"></app-icon>
      <h4 class="modal-title">Не удалось подписать файл</h4>
    </div>
  </ng-container>
</div>
<!-- /HEADER -->

<!-- BODY -->
<div class="modal-body">

  <ng-container  *ngIf="!result?.error">
    <div class="modal-wrapper modal-wrapper--sm">
      <div class="d-flex flex-column">
        <div class="rpn-dl d-flex flex-column">
          <span class="rpn-dt">Дата подписания</span>
          <span class="rpn-dd">{{result?.updated_at | date:'dd.MM.yyyy'}}</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container  *ngIf="result?.error">
    <div class="modal-wrapper modal-wrapper--sm">
      <div class="rpn-dl d-flex flex-column">
        <span class="rpn-dt">Код ошибки</span>
        <span class="rpn-dd">
          <pre>{{result?.error?.type}}</pre>
        </span>
      </div>
      <div class="rpn-dl d-flex flex-column">
        <span class="rpn-dt">Текст ошибки</span>
        <span class="rpn-dd">{{result?.error?.header}}</span>
        <span class="rpn-dd">{{result?.error?.message}}</span>
      </div>
    </div>
  </ng-container>
</div>
<!-- /BODY -->

<!-- FOOTER -->
<div class="modal-footer">

  <ng-container *ngIf="result?.error">
    <div class="modal-wrapper modal-wrapper--sm">
      <p class="text-left blue-grey-text">
        Если вам нужна помощь, обратитесь в службу технической поддержки
        <a href="tel:84955653438"  class="href">8 (495) 565 34 38</a>,
        <a href="mailto:helpdesk@rpn.gov.ru" class="href">helpdesk&#64;rpn.gov.ru</a>.
    </div>
  </ng-container>

</div>
<!-- /FOOTER -->

