import { Directive, ElementRef, Input } from '@angular/core';
import { IXMLDOMNodeType } from 'src/app/providers/_enum';


enum SkeletonType {
  text,
  time,
}

@Directive({
  selector: '[appSkeleton]',
  exportAs: 'appSkeleton',
  standalone: true,
})
export class SkeletonDirective {
  @Input() skeletonType: SkeletonType = SkeletonType.text;
  @Input() set appSkeleton(value: any) {
    this.newValue = value;
    this.checkEmpty();
    this.afterInputChange();
  }
  get appSkeleton() {
    return this.currentValue;
  }
  @Input() disableParentNode = false;

  private newValue: any;
  private currentValue: any;
  private changedNodes: HTMLElement[] = [];

  public SkeletonType = SkeletonType;
  public observer: MutationObserver;

  constructor(private elRef: ElementRef) {}

  modEndNodes(element: HTMLElement, mod: (endNode: HTMLElement) => void) {
    for (const childNode of <HTMLElement[]>Array.from(element.childNodes)) {
      if (childNode.childNodes.length) {
        this.modEndNodes(childNode, mod);
      } else {
        switch (childNode.nodeType) {
          case IXMLDOMNodeType.NODE_TEXT:
          case IXMLDOMNodeType.NODE_COMMENT:
            // eslint-disable-next-line no-case-declarations
            const parentNode = <HTMLElement>childNode.parentNode;
            // eslint-disable-next-line no-case-declarations
            const isDisabled = parentNode === this.elRef.nativeElement && this.disableParentNode;

            if (!isDisabled && this.nodeIsEmpty(parentNode) && !(parentNode instanceof HTMLTableRowElement)) {
              mod(parentNode);
            }
            break;
          case IXMLDOMNodeType.NODE_ELEMENT:
            mod(childNode);
            break;
        }
      }
    }
  }

  afterInputChange() {
    this.observer = new MutationObserver(this.onDomMutation);
    const config = { attributes: true, childList: true, characterData: true };
    this.observer.observe(this.elRef.nativeElement, config);
  }

  checkEmpty() {
    if (!this.newValue) {
      this.modEndNodes(this.elRef.nativeElement, (endNode) => {
        if (endNode instanceof HTMLTableCellElement) {
          return;
        }
        endNode.classList.add('rpn-skeleton');
        this.changedNodes.push(endNode);
      });
    } else if (this.newValue && !this.currentValue) {
      for (const changedNode of this.changedNodes) {
        changedNode.classList.remove('rpn-skeleton');
      }

      this.changedNodes.length = 0;
    }

    this.currentValue = this.newValue;
  }

  onDomMutation = () => {
    this.checkEmpty();
    this.observer.disconnect();
  };

  nodeIsEmpty(element: HTMLElement): boolean {
    const text = (element.textContent || '').replace(/\r\n|\r|\n|\s/g, '');

    return !text.length;
  }
}
