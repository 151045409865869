import { Action } from '@ngrx/store';
import { IBreadcrumb } from 'src/app/providers/_interfaces/common';

export enum BreadcrumbsActionsTypes {
  AddBreadcrumbsRequest = '[Breadcrumbs] Add Breadcrumbs Request',
  AddBreadcrumbsSuccess = '[Breadcrumbs] Add Breadcrumbs Success',
  AddBreadcrumbsError = '[Breadcrumbs] Add Breadcrumbs Error',

  UpdateBreadcrumbsRequest = '[Breadcrumbs] Update Breadcrumbs Request',
  UpdateBreadcrumbsSuccess = '[Breadcrumbs] Update Breadcrumbs Success',
  UpdateBreadcrumbsError = '[Breadcrumbs] Update Breadcrumbs Error',
}

// ADD
export class AddBreadcrumbsRequest implements Action {
  readonly type = BreadcrumbsActionsTypes.AddBreadcrumbsRequest;
}

export class AddBreadcrumbsSuccess implements Action {
  readonly type = BreadcrumbsActionsTypes.AddBreadcrumbsSuccess;

  constructor(public payload: { breadcrumbs: IBreadcrumb[] }) {}
}

export class AddBreadcrumbsError implements Action {
  readonly type = BreadcrumbsActionsTypes.AddBreadcrumbsError;
}

// UPDATE
export class UpdateBreadcrumbsRequest implements Action {
  readonly type = BreadcrumbsActionsTypes.UpdateBreadcrumbsRequest;
}

export class UpdateBreadcrumbsSuccess implements Action {
  readonly type = BreadcrumbsActionsTypes.UpdateBreadcrumbsSuccess;

  constructor(public payload: { breadcrumbs: IBreadcrumb[] }) {}
}

export class UpdateBreadcrumbsError implements Action {
  readonly type = BreadcrumbsActionsTypes.UpdateBreadcrumbsError;
}

export type BreadcrumbsUnion =
  | AddBreadcrumbsRequest
  | AddBreadcrumbsSuccess
  | AddBreadcrumbsError
  | UpdateBreadcrumbsSuccess
  | UpdateBreadcrumbsError
  | UpdateBreadcrumbsRequest;
