import { IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';

export enum TerritoryOrgType {
  'roiv' = 'roiv', // 'roiv' для региональных контролирующих органов
  'to' = 'to', // 'to' для федеральных контролирующих органов
  'fcao' = 'fcao',
  'ca' = 'ca',
  'secure' = 'secure',
}

export const roivTerOrgsWithFederalRights = { 52: 1, 121: 1, 1499: 1 }; // Крым, Севастополь

export interface TerritoryOrg extends IDictionaryItem {
  id: number;
  root_id: number;
  is_active: boolean;
  federal_district_id: number;
  region_ids: number[];
  name: string;
  inn: string;
  kpp: string;
  ogrn: string;
  short_name: string;
  full_name: string;
  code: string;
  acceptable_state_service_ids: number[];
  rpn_oid: string;
  org_type: TerritoryOrgType;
  is_licensor: boolean;
  okogu: string;
  address: boolean;
  is_payments_administrator: boolean;
  ervk_code: string;
  ervk_name: string;
  ervk_record_id: string;
  regulation_requisites: any;
  roiv_to_comparison_id: string;
  to_foiv_comparison_ids: string[];
  uin_code: string;
  parent_ids?: number[];
}
