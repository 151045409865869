import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum',
  standalone: true,
})
export class SumPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number | string, digitsInfo?: string, nullString = '0.00', postfix = ''): string {
    const sum = this.decimalPipe.transform(value, digitsInfo);
    return sum ? `${sum}${postfix}` : nullString;
  }
}
