import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { DictionaryName } from 'src/app/providers/_dictionary';
import { DictionaryService } from 'src/app/providers/_services/dictionary.service';

const supportedTypes = { number: true, string: true, boolean: true, array: true };

@Pipe({
  name: 'dictionary',
  standalone: true,
})
export class DictionaryPipe implements PipeTransform {
  constructor(private dictionaryService: DictionaryService) {}

  /**
   * id param example:
   * 1 | '1' | '1,1,2,3'
   */
  transform(
    id: number | string | boolean | (number | string | boolean)[],
    dictionaryName: DictionaryName,
    key: string = 'name',
    idName: string = 'id',
  ): Observable<string> {
    let type;

    if (Array.isArray(id)) {
      type = 'array';
    } else {
      type = typeof id;
    }

    if (!supportedTypes[type]) {
      return of('');
    }

    const ids: any[] = Array.isArray(id) ? id : id.toString().split(',');

    return combineLatest([
      ...ids.map((ind) => {
        return this.dictionaryService.fetchDictionaryItemsById(ind, dictionaryName, {}, idName);
      }),
    ]).pipe(
      debounceTime(100),
      map((results) => {
        return results.map((res) => get(res, key, '')).join(', ');
      }),
    );
  }
}
